import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { getOrders } from "../../api/orderAPI";
import { selectAppUserLevel } from "../../redux/reducers/userLevelsSlice";
import { selectUser } from "../../redux/reducers/userSlice";

const useOrders = ({ outstandingOrders }) => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const contact = useSelector(selectUser);

  const {
    data: orders = [],
    error,
    isError,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["orders", outstandingOrders, contact?._id, appUserLevel],
    queryFn: () =>
      getOrders({
        appUserLevel,
        contactID: contact._id,
        outstandingOrders,
      }),
    enabled: !!appUserLevel || !!contact?._id,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
  });

  return {
    error,
    orders,
    isError,
    isLoading,
    isRefetching,
  };
};

export default useOrders;
