import { useMutation, useQueryClient } from "@tanstack/react-query";
import { approveServiceOrderRequest } from "../../api/serviceOrderAPI";

const useApproveServiceOrderRequest = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: approveServiceOrderRequest,
    onSuccess: async () => {
      await queryClient.invalidateQueries("serviceOrders");
    },
  });
};

export default useApproveServiceOrderRequest;
