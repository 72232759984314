import axios from "axios";

export const getServiceOrderByID = async (serviceOrderID) => {
  return axios
    .get("/api/service-order/get-by-id", { params: { serviceOrderID } })
    .then((res) => res?.data?.serviceOrder);
};

export const getServiceOrdersByFacility = async (facilityID) => {
  return axios
    .get("/api/service-order/get-by-facility", {
      params: { facilityID },
    })
    .then((res) => res?.data?.serviceOrders);
};

export const getServiceOrdersBySupplier = async (supplierID) => {
  return axios
    .get("/api/service-order/get-by-supplier", {
      params: { supplierID },
    })
    .then((res) => res?.data?.serviceOrders);
};

export const addServiceOrder = async (body) => {
  return axios.post("/api/service-order/add", body);
};

export const createServiceOrderRequest = async (body) => {
  return axios.post("/api/service-order/create-request", body);
};

export const assignServiceOrderRequest = async (body) => {
  return axios.put("/api/service-order/assign-request", body);
};

export const denyServiceOrderRequest = async (body) => {
  return axios.put("/api/service-order/deny-request", body);
};

export const requestServiceOrderFacilityApproval = async (body) => {
  return axios.put("/api/service-order/request-facility-approval", body);
};

export const approveServiceOrderRequest = async (body) => {
  return axios.put("/api/service-order/approve-request", body);
};

export const completeServiceOrderRequest = async (body) => {
  return axios.put("/api/service-order/complete-request", body);
};

export const addServiceOrderServiceDates = async (body) => {
  return axios.put("/api/service-order/add-service-dates", body);
};

export const updateServiceOrder = async (body) => {
  return axios.put("/api/service-order/update", body);
};

export const updateServiceOrderServiceDate = async (body) => {
  return axios.put("/api/service-order/update-service-date", body);
};

export const requestServiceOrderServiceDateChange = async (body) => {
  return axios.put("/api/service-order/request-service-date-change", body);
};

export const serviceOrderImmediateSupportRequest = async (body) => {
  return axios.put("/api/service-order/immediate-support-request", body);
};

export const requestNewServiceProvider = async (body) => {
  return axios.put("/api/service-order/request-new-service-provider", body);
};

export const requestServiceOrderQuoteUpdate = async (body) => {
  return axios.put("/api/service-order/request-quote-update", body);
};

export const serviceOrderCancelRequest = async (body) => {
  return axios.put("/api/service-order/cancel-request", body);
};
