import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createServiceContractRequest } from "../../api/serviceContractOrderAPI";

const useCreateServiceContractRequest = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createServiceContractRequest,
    onSuccess: () => {
      queryClient.invalidateQueries("serviceContracts");
    },
  });
};

export default useCreateServiceContractRequest;
