import { useMutation } from "@tanstack/react-query";
import { contactCheckIfEmailExists } from "../../api/contactAPI";

const useCheckIfEmailExists = () => {
  return useMutation({
    mutationFn: contactCheckIfEmailExists,
  });
};

export default useCheckIfEmailExists;
