import { useMutation, useQueryClient } from "@tanstack/react-query";
import { contactAddProfilePicture } from "../../api/contactAPI";

const useAddContactProfilePicture = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (formData) => contactAddProfilePicture({ formData }),
    onSuccess: () => {
      queryClient.invalidateQueries("contact");
      queryClient.invalidateQueries("contacts");
    },
  });
};

export default useAddContactProfilePicture;
