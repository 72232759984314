import React, { useState } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
} from "@mui/material";
import TermsAndConditionsOfUse from "../../pdf/TermsAndConditionsOfUse.pdf";
import AppError from "../ErrorBoundaries/AppError";
import PDFViewer from "../sharedComponents/PDFViewer";
import useSetContactTermsAndConditions from "../../customHooks/contacts/useSetContactTermsAndConditions";

const TermsAndConditions = (props) => {
  const { contact } = props;
  const setContactTermsAndConditions = useSetContactTermsAndConditions();
  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(false);
  const [submitError, setSubmitError] = useState(false);

  const onSubmit = async () => {
    try {
      await setContactTermsAndConditions.mutateAsync({
        id: contact._id,
      });
    } catch (error) {
      setSubmitError(true);
      throw error;
    }
  };

  if (submitError)
    return (
      <AppError
        message="There was a problem accepting the Terms and Conditions."
        type="Terms And Conditions"
      />
    );

  return (
    <Grid
      container
      spacing={2}
      className="centered-container"
      sx={{ mt: 2, mb: 2 }}
    >
      <Paper
        sx={{
          height: "100%",
          padding: 2,
        }}
      >
        <Grid item xs={12}>
          <PDFViewer file={TermsAndConditionsOfUse} />
        </Grid>

        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  // disabled={!readTermsAndConditions}
                  required
                  checked={acceptedTermsAndConditions}
                  onChange={(e) => {
                    setAcceptedTermsAndConditions(e.target.checked);
                  }}
                />
              }
              label={
                "Check here to indicate that you have read and agree to the terms and conditions above."
              }
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            disabled={
              !acceptedTermsAndConditions ||
              setContactTermsAndConditions.isLoading
            }
            fullWidth
            onClick={onSubmit}
          >
            {"Agree"}
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
};
export default TermsAndConditions;
