import { superUserEmails } from "../globalConstants";

export const getAppUserLevel = (contact) => {
  if (!contact) return;
  const tempUserLevels = [];
  if (superUserEmails.includes(contact.email)) return ["Super User"];
  if (contact.isFacilityManager) tempUserLevels.push("Facility Manager");
  if (contact.isSupplier || contact.isServiceProvider)
    tempUserLevels.push("Supplier");
  if (contact.isShipper) tempUserLevels.push("Shipper");
  if (tempUserLevels.length) return tempUserLevels;
  if (contact.newUser) return ["New User"];
  return ["Facility"];
};

//moved to callback getFacilityUserLevelCallback
// export const getFacilityUserLevel = async (contact, facility) => {
//   if (!facility || !contact) return;

//   const tempUserLevels = [];
//   if (superUserEmails.includes(contact.email))
//     tempUserLevels.push("Super User");

//   if (
//     facility?.owners?.find(
//       (owner) => owner === contact._id || owner._id === contact._id
//     )
//   )
//     tempUserLevels.push("Owner");

//   if (
//     facility?.administrators?.find(
//       (administrator) =>
//         administrator === contact._id || administrator._id === contact._id
//     )
//   )
//     tempUserLevels.push("Administrator");
//   if (
//     facility?.managers?.find(
//       (manager) => manager === contact._id || manager._id === contact._id
//     )
//   )
//     tempUserLevels.push("Manager");
//   if (
//     facility?.leadOperators?.find(
//       (leadOperator) =>
//         leadOperator === contact._id || leadOperator._id === contact._id
//     )
//   )
//     tempUserLevels.push("Lead Operator");
//   if (
//     facility?.operators?.find(
//       (operator) => operator === contact._id || operator._id === contact._id
//     )
//   )
//     tempUserLevels.push("Operator");
//   if (
//     facility?.regulators?.find(
//       (regulator) => regulator === contact._id || regulator._id === contact._id
//     )
//   )
//     tempUserLevels.push("Regulator");

//   if (contact.isServiceProvider) {
//     const serviceProviderRes = await axios.get(
//       `/api/facility/check-if-service-provider?facilityID=${facility._id}&contactID=${contact._id}`
//     );

//     if (serviceProviderRes.data.isServiceProvider)
//       tempUserLevels.push("Supplier");
//   }
//   if (contact.isSupplier) {
//     const supplierRes = await axios.get(
//       `/api/facility/check-if-supplier?facilityID=${facility._id}&contactID=${contact._id}`
//     );
//     if (supplierRes.data.isSupplier) tempUserLevels.push("Supplier");
//     return tempUserLevels;
//   }
// };

export const getSupplierUserLevel = (contact, supplierCompany) => {
  if (!contact || !supplierCompany) return;
  const tempUserLevels = [];
  if (superUserEmails.includes(contact.email))
    tempUserLevels.push("Super User");

  if (
    supplierCompany.contact._id === contact._id ||
    supplierCompany.administrators?.find(
      (administrator) => administrator._id === contact._id
    )
  )
    tempUserLevels.push("Administrator");

  if (supplierCompany.managers?.find((manager) => manager._id === contact._id))
    tempUserLevels.push("Manager");

  if (
    supplierCompany.representatives?.find(
      (representative) => representative._id === contact._id
    )
  )
    tempUserLevels.push("Representative");

  if (
    supplierCompany.serviceRepresentatives?.find(
      (serviceRepresentative) => serviceRepresentative._id === contact._id
    )
  )
    tempUserLevels.push("Service Representative");

  return tempUserLevels;
};

export const getShipperUserLevel = (contact, shipperCompany) => {
  if (!contact || !shipperCompany) return;
  const tempUserLevels = [];
  if (superUserEmails.includes(contact.email))
    tempUserLevels.push("Super User");

  if (shipperCompany.contact._id === contact._id) tempUserLevels.push("Owner");

  if (
    shipperCompany.administrators?.find(
      (administrator) => administrator._id === contact._id
    )
  )
    tempUserLevels.push("Administrator");

  if (shipperCompany.managers?.find((manager) => manager._id === contact._id))
    tempUserLevels.push("Manager");

  return tempUserLevels;
};

//Allowed to submit orders
export const checkFacilityUserLevelOrder = (userLevels) => {
  if (!userLevels?.length) return false;
  if (
    userLevels.includes("Super User") ||
    userLevels.includes("Owner") ||
    userLevels.includes("Administrator") ||
    userLevels.includes("Manager")
  )
    return true;
};

//can add and edit maintenance procedures
export const checkUserLevelMaintenance = (userLevels) => {
  if (!userLevels?.length) return false;
  if (
    userLevels.includes("Super User") ||
    userLevels.includes("Manager") ||
    userLevels.includes("Lead Operator")
  )
    return true;
};

export const checkFacilityUserLevelEditor = (userLevels) => {
  if (!userLevels?.length) return false;
  if (
    userLevels.includes("Super User") ||
    userLevels.includes("Owner") ||
    userLevels.includes("Administrator") ||
    userLevels.includes("Manager") ||
    userLevels.includes("Lead Operator")
  )
    return true;
};

//Access to facility
//can request orders
//can submit logs
export const checkFacilityUserLevelLogger = (userLevels) => {
  if (!userLevels?.length) return false;
  if (
    userLevels.includes("Super User") ||
    userLevels.includes("Owner") ||
    userLevels.includes("Administrator") ||
    userLevels.includes("Manager") ||
    userLevels.includes("Lead Operator") ||
    userLevels.includes("Operator") ||
    userLevels.includes("Service Provider")
  )
    return true;
};

//Access to facility
export const checkFacilityUserLevelViewer = (userLevels) => {
  if (!userLevels?.length) return false;
  if (
    userLevels.includes("Super User") ||
    userLevels.includes("Owner") ||
    userLevels.includes("Administrator") ||
    userLevels.includes("Manager") ||
    userLevels.includes("Lead Operator") ||
    userLevels.includes("Operator") ||
    userLevels.includes("Service Provider") ||
    userLevels.includes("Manufacturer") ||
    userLevels.includes("Regulator")
  )
    return true;
};
