import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import AppError from "../ErrorBoundaries/AppError";
import { CustomModal, FormSwitch } from "../sharedComponents";
import {
  getContactOptionLabel,
  getServiceOptionLabel,
} from "../../sharedFunctions/labels";
import PDFViewer from "../sharedComponents/PDFViewer";
import useAddServiceContract from "../../customHooks/serviceContractOrder/useAddServiceContract";
import useCancelServiceContract from "../../customHooks/serviceContractOrder/useCancelServiceContract";
import useImage from "../../customHooks/image/useImage";
import useUpdateServiceContract from "../../customHooks/serviceContractOrder/useUpdateServiceContract";
import DecimalTextField from "../sharedComponents/TextFields/DecimalTextField";
import useServices from "../../customHooks/service/useServices";
import ServiceRequestTotal from "../services/ServiceRequestTotal";
import FormSelect from "../sharedComponents/selectors/FormSelect";
import useServiceTotals from "../../customHooks/service/useServiceTotals";
import useFacilitySystems from "../../customHooks/facility/useFacilitySystems";
import { selectAppUserLevel } from "../../redux/reducers/userLevelsSlice";
import customDollarRound from "../../sharedFunctions/purchasingFunctions/customDollarRound";
import ManageServiceContractDates from "./ManageServiceContractDates";
import calculateServicePrice from "../../sharedFunctions/purchasingFunctions/calculateServicePrice";
import useExchangeRate from "../../customHooks/exchangeRates/useExchangeRate";
import useContactServiceProviders from "../../customHooks/contacts/useContactServiceProviders";
import useCreateServiceContractRequest from "../../customHooks/serviceContractOrder/useCreateServiceContractRequest";
import useServiceContractByID from "../../customHooks/serviceContractOrder/useServiceContractByID";
import ServiceContractActionButtons from "../orders/orderDisplay/ServiceContractActionButtons";
import useUpdateServiceContractRequest from "../../customHooks/serviceContractOrder/useUpdateServiceContractRequest";
import ContactButton from "../contacts/ContactButton";

const ServiceContractForm = ({
  close,
  editMode,
  serviceContractID,
  supplierCompany,
  serviceContractToRenew,
}) => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const facilities = supplierCompany?.facilitiesSupplied ?? [];
  const { serviceProviders } = useContactServiceProviders();
  const { data: serviceContract } = useServiceContractByID({
    serviceContractID,
  });

  const { file } = useImage(serviceContract?.contract?.fileID);
  const { services: servicesAvailable } = useServices();

  const [autoRenew, setAutoRenew] = useState(
    serviceContract?.autoRenew ?? false
  );
  const [contract, setContract] = useState(serviceContract?.contract ?? "");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [supplierOrderNumber, setSupplierOrderNumber] = useState(
    serviceContract?.supplierOrderNumber ?? ""
  );
  const [error, setError] = useState("");
  const [hasServiceDateAutomation, setHasServiceDateAutomation] = useState(
    serviceContract?.hasServiceDateAutomation ?? false
  );
  const [invoiceFrequency, setInvoiceFrequency] = useState(
    serviceContract?.invoiceFrequency ??
      serviceContractToRenew?.invoiceFrequency ??
      "Annually"
  );
  const [isSubContracted, setIsSubContracted] = useState(
    serviceContract?.isSubContracted ?? false
  );
  const [maxDaysBetweenService, setMaxDaysBetweenService] = useState(
    serviceContract?.maxDaysBetweenService ??
      serviceContractToRenew?.maxDaysBetweenService ??
      ""
  );
  const [name, setName] = useState(
    serviceContract?.name ?? serviceContractToRenew?.name ?? ""
  );
  const [removalReason, setRemovalReason] = useState("");
  const [saving, setSaving] = useState(false);
  const [services, setServices] = useState(
    serviceContract?.services ?? serviceContractToRenew?.services ?? [[]]
  );
  const [serviceCompletion, setServiceCompletion] = useState(
    serviceContract?.serviceCompletion
      ? moment(serviceContract?.serviceCompletion)
      : moment().add(1, "year").subtract(1, "day")
  );
  const [subContractor, setSubContractor] = useState(
    serviceContract?.subContractor ?? ""
  );
  const [subContractorPercentage, setSubContractorPercentage] = useState(
    serviceContract?.subContractorPercentage
      ? serviceContract?.subContractorPercentage * 100
      : ""
  );
  const [facility, setFacility] = useState(
    serviceContract?.facility ?? serviceContractToRenew?.facility ?? ""
  );
  const [serviceLength, setServiceLength] = useState(
    serviceContract?.serviceLength ?? serviceContractToRenew?.serviceLength ?? 1
  );
  const [serviceStart, setServiceStart] = useState(
    moment(serviceContract?.serviceStart) ?? null
  );
  const [serviceRepresentative, setServiceRepresentative] = useState(
    serviceContract?.serviceRepresentative ??
      serviceContractToRenew?.serviceRepresentative ??
      ""
  );
  const [submitError, setSubmitError] = useState(false);
  const [systems, setSystems] = useState(
    serviceContract?.systems?.map((system) => ({
      ...system.systemID,
      systemType: system.systemType,
    })) ??
      serviceContractToRenew?.systems.map((system) => ({
        ...system.systemID,
        systemType: system.systemType,
      })) ??
      []
  );
  const [valid, setValid] = useState(false);
  const [fileOpen, setFileOpen] = useState(false);

  const { exchangeRate, isInitialLoading } = useExchangeRate({
    currency:
      serviceContract?.supplier?.currency ??
      supplierCompany?.contact?.currency ??
      serviceContractToRenew?.supplier?.currency,
  });
  const addServiceContract = useAddServiceContract();
  const updateServiceContract = useUpdateServiceContract();
  const updateServiceContractRequest = useUpdateServiceContractRequest();
  const createServiceContractRequest = useCreateServiceContractRequest();
  const cancelServiceContract = useCancelServiceContract();
  const { data: facilitySystems, isLoading: isLoadingFacilitySystems } =
    useFacilitySystems(facility?._id);

  const supplier =
    serviceContract?.supplier ??
    supplierCompany?.contact ??
    serviceContractToRenew?.supplier ??
    "";

  const {
    subtotal,
    GST,
    PST,
    total,
    supplierSubtotal,
    supplierGST,
    supplierPST,
    supplierTotal,
  } = useServiceTotals(exchangeRate, services.flat(), supplier?._id, facility);

  const getServiceRepresentatives = (supplierCompany) => {
    return [...(supplierCompany?.serviceRepresentatives || [])];
  };

  useEffect(() => {
    //if service length changes update services array
    const newServices = [...services];

    // If serviceLength exceeds the current length of services, add new inner arrays
    while (newServices.length < serviceLength) {
      newServices.push([]);
    }

    // If serviceLength is less than the current length of services, trim excess arrays
    while (newServices.length > serviceLength) {
      newServices.pop();
    }

    // Update the services state if any changes were made
    if (newServices.length !== services.length) {
      setServices(newServices);
    }
  }, [serviceLength, services, setServices]);

  useEffect(() => {
    if (
      name === "" ||
      !supplier?._id ||
      !serviceStart ||
      !serviceLength ||
      !facility ||
      !services?.length ||
      !serviceCompletion ||
      !invoiceFrequency
    )
      return setValid(false);

    if (hasServiceDateAutomation && !maxDaysBetweenService)
      return setValid(false);

    if (
      services.some((yearServices) => {
        if (!yearServices.length) return true;
        return yearServices.some(
          (service) =>
            !service.service || !service.quantity || service.quantity <= 0
        );
      })
    )
      return setValid(false);

    if (isSubContracted && (!subContractor || !subContractorPercentage)) {
      return setValid(false);
    }

    if (contract && contract.src?.size > 50000000) {
      setError("File may not exceed 50MB");
      return setValid(false);
    }

    setError("");
    setValid(true);
  }, [
    contract,
    name,
    serviceStart,
    serviceContract,
    facility,
    supplier?._id,
    serviceLength,
    serviceCompletion,
    invoiceFrequency,
    maxDaysBetweenService,
    services,
    isSubContracted,
    subContractor,
    subContractorPercentage,
    hasServiceDateAutomation,
  ]);

  const closeFileModal = (event, reason) => {
    if (reason !== "backdropClick") setFileOpen(false);
  };

  const openFileModal = () => {
    setFileOpen(true);
  };

  const closeConfirmRemoveServiceContract = async (event, reason, remove) => {
    if (reason === "backdropClick") return;
    if (remove) {
      const body = {
        serviceContractID: serviceContract._id,
        reason: removalReason,
      };
      await cancelServiceContract.mutateAsync(body);

      close();
    }
    setConfirmModalOpen(false);
  };

  const getServicePriceDetails = (service, supplierID) => {
    const supplierServiceDetails = service.service.supplierServiceDetails?.find(
      (supplierServiceDetail) =>
        (supplierServiceDetail?.supplier?._id?.toString() ??
          supplierServiceDetail?.supplier?.toString()) ===
        supplierID?.toString()
    );

    const cost = customDollarRound(supplierServiceDetails?.cost);
    const price = calculateServicePrice(
      supplierServiceDetails?.cost,
      exchangeRate?.rate,
      supplierServiceDetails?.supplier?._id
    );
    const total = customDollarRound(price * service.quantity);
    return { cost, price, total };
  };

  const onSubmit = async () => {
    try {
      setSaving(true);
      const formData = new FormData();
      if (contract.src)
        formData.append("file", contract.src, contract.src.name);
      formData.append("autoRenew", autoRenew);
      if (supplierOrderNumber)
        formData.append("supplierOrderNumber", supplierOrderNumber);
      formData.append("name", name);
      formData.append("invoiceFrequency", invoiceFrequency);
      formData.append("hasServiceDateAutomation", hasServiceDateAutomation);
      formData.append("maxDaysBetweenService", maxDaysBetweenService);
      if (GST) formData.append("GST", GST);
      if (PST) formData.append("PST", PST);
      formData.append("serviceCompletion", serviceCompletion.toISOString());
      formData.append("facility", facility?._id);
      formData.append("serviceLength", serviceLength);
      formData.append("supplier", supplier._id);
      formData.append("isSubContracted", isSubContracted);
      if (isSubContracted) {
        formData.append("subContractor", subContractor._id);
        formData.append(
          "subContractorPercentage",
          parseFloat(subContractorPercentage) / 100
        );
      }
      if (services?.length)
        formData.append(
          "services",
          JSON.stringify(
            services.map((s) =>
              s.map((service) => {
                return {
                  cost: service.cost,
                  price: service.price,
                  service: service.service._id,
                  quantity: service.quantity,
                  total: service.total,
                };
              })
            )
          )
        );
      formData.append("serviceStart", serviceStart.toISOString());
      if (serviceRepresentative)
        formData.append("serviceRepresentative", serviceRepresentative._id);
      formData.append("subtotal", subtotal);
      formData.append("supplierGST", supplierGST);
      formData.append("supplierPST", supplierPST);
      formData.append("supplierSubtotal", supplierSubtotal);
      formData.append("supplierTotal", supplierTotal);
      formData.append("total", total);
      if (systems?.length)
        formData.append(
          "systems",
          JSON.stringify(
            systems.map((s) => {
              return { systemID: s._id, systemType: s.systemType };
            })
          )
        );

      if (serviceContractID && serviceContract?.isApproved) {
        formData.append("_id", serviceContract._id);
        await updateServiceContract.mutateAsync(formData);
      } else if (serviceContractID && !serviceContract?.isApproved) {
        formData.append("_id", serviceContract._id);

        await updateServiceContractRequest.mutateAsync(formData);
      } else {
        await addServiceContract.mutateAsync(formData);
      }

      setSaving(false);
      close(null, null, true);
    } catch (error) {
      setSaving(false);
      setSubmitError(true);
      throw error;
    }
  };

  const onSubmitRequest = async () => {
    try {
      setSaving(true);
      const body = {
        serviceContract: {
          name,
          facility: facility._id,
          supplier: supplier._id,
          serviceRepresentative: serviceRepresentative?._id,
          serviceStart: serviceStart.toISOString(),
          serviceLength,
          serviceCompletion: serviceCompletion.toISOString(),
          hasServiceDateAutomation,
          maxDaysBetweenService,
          invoiceFrequency,
          systems: systems.map((s) => ({
            systemID: s._id,
            systemType: s.systemType,
          })),
          services: services.map((service) =>
            service.map((s) => ({
              service: s.service._id,
              quantity: s.quantity,
              cost: s.cost,
              price: s.price,
              total: s.total,
            }))
          ),
          autoRenew,
          isSubContracted,
          subtotal,
          GST,
          PST,
          total,
          supplierSubtotal,
          supplierGST,
          supplierPST,
          supplierTotal,
          supplierOrderNumber,
        },
      };

      if (isSubContracted) {
        body.serviceContract.subContractor = subContractor?._id;
        body.serviceContract.subContractorPercentage =
          parseFloat(subContractorPercentage) / 100;
      }

      await createServiceContractRequest.mutateAsync(body);

      setSaving(false);
      close(null, null, true);
    } catch (error) {
      setSaving(false);
      setSubmitError(true);
      throw error;
    }
  };

  const uploadContract = async () => {
    setSaving(true);
    const formData = new FormData();
    if (contract.src) formData.append("file", contract.src, contract.src.name);
    formData.append("_id", serviceContract._id);
    await updateServiceContract(formData);
    setSaving(false);
  };

  const serviceContractDetailsSection = () => {
    return (
      <>
        <Grid container item spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              disabled={!editMode}
              required
              label="Name"
              value={name}
              variant="outlined"
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              disabled={!editMode || !!serviceContractID}
              options={facilities}
              getOptionLabel={(option) => option.name ?? ""}
              isOptionEqualToValue={(option, value) => {
                if (!value?.length) return [];
                return option._id === value._id;
              }}
              value={facility}
              onChange={(e, value) => {
                setFacility(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autoComplete="off"
                  label={`Serviced Facility`}
                  required
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              value={supplier.name}
              autoComplete="off"
              label={`Service Provider`}
              required
              disabled={true}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            {supplierCompany ? (
              <Autocomplete
                disabled={!editMode}
                options={getServiceRepresentatives(supplierCompany)}
                getOptionLabel={getContactOptionLabel}
                isOptionEqualToValue={(option, value) => {
                  if (!value?.length) return [];
                  return option._id === value._id;
                }}
                value={serviceRepresentative}
                onChange={(e, value) => {
                  setServiceRepresentative(value);
                }}
                style={{ display: "flex" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="off"
                    label={`Service Representative`}
                  />
                )}
              />
            ) : (
              <ContactButton
                xs={12}
                contact={serviceRepresentative}
                label="Service Representative: "
              />
            )}
          </Grid>

          {appUserLevel?.includes("Super User") && (
            <FormSwitch
              disabled={!editMode}
              checked={isSubContracted}
              onChange={(e) => setIsSubContracted(e.target.checked)}
              label={"Sub Contracted"}
              xs={4}
            />
          )}

          {isSubContracted && (
            <>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disabled={!editMode}
                  options={serviceProviders}
                  getOptionLabel={getContactOptionLabel}
                  isOptionEqualToValue={(option, value) => {
                    if (!value.length) return [];
                    return option._id === value._id;
                  }}
                  value={subContractor}
                  onChange={(e, value) => setSubContractor(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sub Contractor"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DecimalTextField
                  editMode={editMode}
                  label="Sub Contractor Percentage"
                  value={subContractorPercentage}
                  variant="outlined"
                  onChange={(e) => setSubContractorPercentage(e.target.value)}
                  allowMinus={false}
                  required={true}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
        {serviceContract?.recipient && (
          <ContactButton
            contact={serviceContract?.recipient}
            label={"Facility Representative: "}
            xs={12}
          />
        )}

        <Grid item xs={12} md={3}>
          <DatePicker
            disabled={!editMode || !!serviceContractID}
            label="Service Start Date *"
            onChange={(date) => {
              setServiceStart(date);
              setServiceCompletion(
                moment(date).add(serviceLength, "year").subtract(1, "day")
              );
            }}
            value={serviceStart}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormSelect
            editMode={editMode}
            disabled={!!serviceContractID}
            label="Service Length (Years)"
            value={serviceLength}
            onChange={(e) => {
              const selectedLength = parseFloat(e.target.value, 10);
              setServiceLength(selectedLength);

              const serviceCompletion = moment(serviceStart)
                .add(selectedLength, "year")
                .subtract(1, "day");

              setServiceCompletion(serviceCompletion);
            }}
            menuItems={[1, 2, 3, 4, 5]}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePicker
            disabled={true}
            label="Service Completion *"
            value={serviceCompletion}
          />
        </Grid>
        <FormSwitch
          disabled={!editMode}
          checked={hasServiceDateAutomation}
          onChange={(e) => setHasServiceDateAutomation(e.target.checked)}
          label={"Service Date Automation"}
          xs={6}
          md={3}
          infoText="Service Date Automation will automatically schedule the next service date based on the maximum days between services."
        />

        {hasServiceDateAutomation && (
          <Grid item xs={12} md={3}>
            <DecimalTextField
              editMode={editMode}
              label="Days Between Service"
              value={maxDaysBetweenService}
              variant="outlined"
              onChange={(e) => setMaxDaysBetweenService(e.target.value)}
              required
            />
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <FormSelect
            editMode={editMode}
            disabled={!!serviceContractID}
            label="Invoice Frequency"
            value={invoiceFrequency}
            onChange={(e) => setInvoiceFrequency(e.target.value)}
            menuItems={["Monthly", "Quarterly", "Semi-Annually", "Annually"]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            disabled={!editMode}
            loading={isLoadingFacilitySystems}
            multiple
            options={facilitySystems ?? []}
            getOptionLabel={(option) => option.name ?? ""}
            isOptionEqualToValue={(option, value) => {
              if (!value) return [];
              return option._id === value._id;
            }}
            value={systems}
            onChange={(e, value) => {
              setSystems(value);
            }}
            renderInput={(params) => (
              <TextField autoComplete="off" {...params} label={`Systems`} />
            )}
          />
        </Grid>
        <FormSwitch
          disabled={!editMode}
          checked={autoRenew}
          onChange={(e) => setAutoRenew(e.target.checked)}
          label={"Auto Renew"}
          xs={3}
        />
        <Grid item xs={12} md={3}>
          <TextField
            autoComplete="off"
            label="Supplier Order #"
            disabled={!editMode}
            value={supplierOrderNumber}
            variant="outlined"
            onChange={(e) => setSupplierOrderNumber(e.target.value)}
            fullWidth
          />
        </Grid>
      </>
    );
  };

  const serviceContractServicesSection = () => {
    return (
      <>
        {Array.from({ length: serviceLength }).map((_, yearIndex) => {
          const serviceYearStart = moment(serviceStart).add(yearIndex, "years");
          const serviceYearEnd = moment(serviceStart).add(
            yearIndex + 1,
            "years"
          );

          const thisYearServiceDates = serviceContract?.serviceDates?.filter(
            (sd) =>
              moment(sd.serviceDate).isSameOrAfter(serviceYearStart) &&
              moment(sd.serviceDate).isBefore(serviceYearEnd)
          ).length;

          let previousOverload = 0;
          if (yearIndex > 0 && services[yearIndex - 1]) {
            const lastYearService = services[yearIndex - 1].find(
              (s) => s.service?.serviceMode === "On Site"
            );

            if (lastYearService) {
              const lastServiceYearStart = moment(serviceStart).add(
                yearIndex - 1,
                "years"
              );
              const lastServiceYearEnd = moment(serviceStart).add(
                yearIndex,
                "years"
              );

              const lastYearServiceDates = serviceContract?.serviceDates.filter(
                (sd) =>
                  moment(sd.serviceDate).isSameOrAfter(lastServiceYearStart) &&
                  moment(sd.serviceDate).isBefore(lastServiceYearEnd)
              ).length;

              previousOverload =
                lastYearService.quantity - lastYearServiceDates;
            }
          }

          return (
            <Grid container spacing={2} item xs={12} key={yearIndex}>
              <Grid item xs={12}>
                <Typography>Service Year {yearIndex + 1}</Typography>
              </Grid>
              {isInitialLoading ? (
                <Grid item xs={12}>
                  <CircularProgress />
                </Grid>
              ) : (
                (appUserLevel?.includes("Super User") ||
                  (appUserLevel?.includes("Supplier") &&
                    serviceContractID &&
                    !serviceContract.isApproved)) &&
                editMode && (
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        if (!services[yearIndex]) {
                          services[yearIndex] = [];
                        }

                        const newYearServices = [
                          ...services[yearIndex],
                          {
                            serviceDescription: "",
                            service: null,
                            quantity: 0,
                          },
                        ];
                        const newServices = [...services];
                        newServices[yearIndex] = newYearServices;
                        setServices(newServices);
                      }}
                    >
                      {serviceContractID && serviceContract.isApproved
                        ? "Add LEGACY service"
                        : "Add Service"}
                    </Button>
                  </Grid>
                )
              )}
              {services[yearIndex]?.map((service, serviceIndex) => {
                const servicesMaxLength =
                  serviceContract?.services?.[yearIndex]?.length ?? 0;

                const currentYearServiceDatesLeft =
                  service.quantity - thisYearServiceDates + previousOverload;

                const price = !service.service
                  ? null
                  : calculateServicePrice(
                      service.service.supplierServiceDetails?.find(
                        (serviceDetail) =>
                          (serviceDetail.supplier?._id ??
                            serviceDetail.supplier) === supplier?._id
                      )?.cost,
                      exchangeRate?.rate,
                      supplier?._id
                    );

                return (
                  <Grid
                    container
                    spacing={2}
                    item
                    xs={12}
                    className="vertical-centered-container"
                    key={`service${serviceIndex}` + service.serviceDate}
                  >
                    <Grid item xs={6}>
                      <Autocomplete
                        disabled={
                          !editMode ||
                          (serviceIndex < servicesMaxLength &&
                            serviceContract?.isApproved) ||
                          (!appUserLevel?.includes("Super User") &&
                            !!serviceContractID)
                        }
                        options={servicesAvailable.filter((service) =>
                          service.supplierServiceDetails.some(
                            (supplierServiceDetail) =>
                              supplierServiceDetail.cost &&
                              supplierServiceDetail.supplier._id ===
                                supplier._id
                          )
                        )}
                        getOptionLabel={(option) =>
                          getServiceOptionLabel(
                            option,
                            supplier?._id,
                            exchangeRate
                          )
                        }
                        isOptionEqualToValue={(option, value) => {
                          if (!value?.length) return [];
                          return option._id === value._id;
                        }}
                        value={service.service}
                        onChange={(e, value) => {
                          const newServices = [...services];
                          newServices[yearIndex][serviceIndex].service = value;
                          newServices[yearIndex][
                            serviceIndex
                          ].serviceDescription =
                            value?.serviceDescription ?? "";
                          if (
                            newServices[yearIndex][serviceIndex]?.service &&
                            newServices[yearIndex][serviceIndex]?.quantity
                          ) {
                            const { cost, price, total } =
                              getServicePriceDetails(
                                newServices[yearIndex][serviceIndex],
                                supplier._id
                              );
                            newServices[yearIndex][serviceIndex].cost = cost;
                            newServices[yearIndex][serviceIndex].price = price;
                            newServices[yearIndex][serviceIndex].total = total;
                          }
                          setServices(newServices);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Service"
                            variant="outlined"
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      {serviceContractID &&
                      serviceContract?.isApproved &&
                      serviceIndex < servicesMaxLength ? (
                        <Typography>
                          {service.service?.serviceMode === "On Site"
                            ? `${Math.max(0, currentYearServiceDatesLeft)}/${
                                service.quantity
                              } ${
                                service.service.billingRate === "Hourly"
                                  ? "Hours Remaining"
                                  : service.service._id ===
                                    "650732674e9d780aae39663e"
                                  ? "km Remaining"
                                  : "Days Remaining"
                              }`
                            : ""}
                        </Typography>
                      ) : (
                        <TextField
                          label="Quantity"
                          variant="outlined"
                          fullWidth
                          disabled={!editMode}
                          value={service.quantity}
                          onChange={(e) => {
                            if (e.target.value < 0) return;
                            const newServices = [...services];
                            newServices[yearIndex][serviceIndex].quantity =
                              parseFloat(e.target.value);
                            if (
                              newServices[yearIndex][serviceIndex]?.service &&
                              newServices[yearIndex][serviceIndex]?.quantity
                            ) {
                              const { cost, price, total } =
                                getServicePriceDetails(
                                  newServices[yearIndex][serviceIndex],
                                  supplier._id
                                );
                              newServices[yearIndex][serviceIndex].cost = cost;
                              newServices[yearIndex][serviceIndex].price =
                                price;
                              newServices[yearIndex][serviceIndex].total =
                                total;
                            }
                            setServices(newServices);
                          }}
                          required
                          type="number"
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                          }}
                          InputProps={{
                            endAdornment: price &&
                              service.service?.billingRate && (
                                <InputAdornment position="end">
                                  {`$${price}/${service.service?.billingRate}`}
                                </InputAdornment>
                              ),
                          }}
                        />
                      )}
                    </Grid>
                    {(appUserLevel?.includes("Super User") ||
                      !serviceContractID) &&
                      !serviceContract?.isApproved &&
                      editMode && (
                        <Grid item xs={2}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              const newServices = [...services];
                              newServices[yearIndex].splice(serviceIndex, 1);
                              setServices(newServices);
                            }}
                            color="error"
                          >
                            Remove
                          </Button>
                        </Grid>
                      )}
                  </Grid>
                );
              })}
            </Grid>
          );
        })}
      </>
    );
  };

  const serviceContractFileSection = () => {
    return (
      <Grid item xs={12} md={4}>
        {contract.data ? (
          <>
            <Button variant="contained" onClick={() => openFileModal()}>
              View Contract
            </Button>

            <IconButton
              variant="contained"
              download={name}
              href={`data:application/pdf;base64,${contract.data}`}
            >
              <DownloadIcon />
            </IconButton>
          </>
        ) : contract.fileID ? (
          <>
            <Button variant="contained" onClick={() => openFileModal()}>
              View Contract
            </Button>

            <IconButton variant="contained" download={name} href={file}>
              <DownloadIcon />
            </IconButton>
          </>
        ) : appUserLevel?.includes("Facility") ? (
          <>
            <Grid item xs={12}>
              <input
                type="file"
                accept="application/pdf"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    setContract({
                      src: e.target.files[0],
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={uploadContract}
                fullWidth
                disabled={saving || !contract}
              >
                {saving ? "Uploading..." : "Upload Contract"}
              </Button>
            </Grid>
          </>
        ) : (
          editMode && (
            <input
              type="file"
              accept="application/pdf"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setContract({
                    src: e.target.files[0],
                  });
                }
              }}
            />
          )
        )}
      </Grid>
    );
  };

  const renderContent = () => {
    return (
      <Grid container spacing={2}>
        {serviceContractDetailsSection()}
        {serviceLength && facility && serviceContractServicesSection()}
        {serviceContractID &&
          (appUserLevel?.includes("Super User") ||
            appUserLevel?.includes("Supplier")) &&
          serviceContract.isApproved &&
          services?.some((serviceYear) =>
            serviceYear?.some(
              (service) => service?.service?.serviceMode === "On Site"
            )
          ) && (
            <ManageServiceContractDates
              close={close}
              facilityID={facility._id}
              facilityName={facility.name}
              maxDaysBetweenService={maxDaysBetweenService}
              serviceContractID={serviceContract?._id}
              serviceContractName={name}
              serviceContractServiceDates={serviceContract?.serviceDates}
              supplierEmails={supplier.orderEmails}
              supplierID={supplier._id}
              services={services}
              serviceCompletion={serviceCompletion}
              serviceStart={serviceStart}
              hasServiceDateAutomation={hasServiceDateAutomation}
            />
          )}
        <ServiceRequestTotal
          GST={GST}
          PST={PST}
          subtotal={subtotal}
          total={total}
        />
        {serviceContractFileSection()}
        {serviceContractID &&
          moment(serviceContract?.serviceCompletion).isAfter(moment()) &&
          ((serviceContract?.isApproved &&
            appUserLevel?.includes("Facility")) ||
            appUserLevel?.includes("Supplier") ||
            appUserLevel?.includes("Super User")) && (
            <Grid item xs={12} md={4} className="centered-container">
              {serviceContract?.status === "Cancelled" ? (
                <Typography variant={"h4"} color="error">
                  Cancelled
                </Typography>
              ) : (
                <Button
                  variant="contained"
                  onClick={() => setConfirmModalOpen(true)}
                  fullWidth
                  color="error"
                >
                  Cancel Service Contract
                </Button>
              )}
            </Grid>
          )}

        {serviceContractID &&
          (appUserLevel.includes("Super User") ||
            appUserLevel.includes("Facility")) &&
          !serviceContract?.isApproved && (
            <ServiceContractActionButtons
              serviceContract={serviceContract}
              close={close}
            />
          )}
        {editMode && error && (
          <Grid item xs={12}>
            <Typography textAlign={"center"} color="error">
              {error}
            </Typography>
          </Grid>
        )}
        {editMode && (
          <>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={onSubmit}
                fullWidth
                disabled={!valid || saving}
              >
                {serviceContractID
                  ? saving
                    ? "Updating Service Contract"
                    : "Update Service Contract"
                  : saving
                  ? "Adding Service Contract"
                  : "Add Service Contract"}
              </Button>
            </Grid>
            {!serviceContractID && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={onSubmitRequest}
                  fullWidth
                  disabled={!valid || saving}
                >
                  {saving ? "Sending Request" : "Request Service Contract"}
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
    );
  };

  const cancelServiceContractForm = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            label={"Reason For Canceling"}
            value={removalReason}
            variant="outlined"
            onChange={(e) => {
              setRemovalReason(e.target.value);
            }}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          {/* {isLoadingCancel ? (
            <Typography>
              Thank you, Service contract with {supplier.name} has been
              canceled!
            </Typography>
          ) : ( */}
          <Button
            variant="contained"
            onClick={() => closeConfirmRemoveServiceContract(null, null, true)}
            disabled={!removalReason}
            color="error"
            fullWidth
          >
            Cancel Service Contract
          </Button>
          {/* )} */}
        </Grid>
      </Grid>
    );
  };

  if (submitError)
    return (
      <AppError
        message="There was a problem submitting the Service Contract."
        type="Service Contract"
      />
    );

  return (
    <>
      <CustomModal
        open={confirmModalOpen}
        close={closeConfirmRemoveServiceContract}
        title={"Please Confirm That You Want To Remove This Service Contract"}
      >
        {cancelServiceContractForm()}
      </CustomModal>

      <CustomModal open={fileOpen} close={closeFileModal}>
        {contract.data ? (
          <PDFViewer file={`data:application/pdf;base64,${contract.data}`} />
        ) : (
          <PDFViewer file={file} />
        )}
      </CustomModal>
      {renderContent()}
    </>
  );
};
export default ServiceContractForm;
