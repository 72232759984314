import React from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router-dom";
import ReportProblem from "../reportProblem/ReportProblem";

const AppError = (props) => {
  const { fullPage, message, type } = props;
  const navigate = useNavigate();

  const reload = () => {
    if ("caches" in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      navigate("/");
      window.location.reload(true);
    }
  };

  return (
    <Container
      sx={{ height: fullPage && "100vh" }}
      className="vertical-centered-container"
    >
      <Grid container spacing={2} className="centered-container">
        <Grid item xs={12}>
          <Typography variant="h3">Sorry, an error has occurred.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">{message}</Typography>
        </Grid>

        <Grid item xs={12} className="centered-container">
          <Typography variant="h6">Please reload and try again.</Typography>
          <Button variant="contained" onClick={reload} sx={{ ml: 1 }}>
            Reload
            <RefreshIcon />
          </Button>
        </Grid>
        <Grid item xs={12} className="centered-container">
          <Typography variant="h6">
            If the problem persists please try logging out and log back in.
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <ReportProblem type={type ?? "Main App Error"} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AppError;
