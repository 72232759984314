import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const ServiceRequestTotal = ({ subtotal, PST, GST, total }) => {
  return (
    <Grid item xs={12}>
      <Box display={"flex"} alignItems={"flex-end"} flexDirection={"column"}>
        <Typography>Subtotal: ${parseFloat(subtotal).toFixed(2)}</Typography>
        {PST > 0 && <Typography>PST: ${parseFloat(PST).toFixed(2)}</Typography>}
        {GST > 0 && <Typography>GST: ${parseFloat(GST).toFixed(2)}</Typography>}
        <Typography>Total: ${parseFloat(total).toFixed(2)}</Typography>
      </Box>
    </Grid>
  );
};

export default ServiceRequestTotal;
