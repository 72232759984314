import { Link, Typography } from "@mui/material";

export const EmailDisplay = ({ email, label }) => {
  return (
    <Typography
      sx={{
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      {label}: <Link href={`mailto:${email}`}>{email}</Link>
    </Typography>
  );
};
