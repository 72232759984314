import axios from "axios";

export const getShipperCompanies = async () => {
  return await axios
    .get(`/api/public-form/get-shipper-companies`)
    .then((res) => res.data.shipperCompanies);
};

export const getByShipperToken = async (token) => {
  return await axios
    .get(`/api/public-form/get-order-by-shipper-token?token=${token}`)
    .then((res) => res.data.order);
};

export const getBySupplierToken = async (token) => {
  return await axios
    .get(`/api/public-form/get-order-by-supplier-token?token=${token}`)
    .then((res) => res.data.order);
};

// export const confirmOrder = async ({
//   pickUpAvailableDate,
//   files,
//   token,
//   supplierOrderNumber,
// }) => {
//   const formData = new FormData();
//   formData.append("pickUpAvailableDate", pickUpAvailableDate.toISOString());
//   formData.append("token", token);
//   formData.append("supplierOrderNumber", supplierOrderNumber);

//   if (files?.length) {
//     for (let i = 0; i < files.length; i++) {
//       formData.append("files[]", files[i]);
//     }
//   }
//   return await axios.post(`/api/public-form/confirm-order`, formData);
// };

export const confirmShipping = async ({
  arriveDate,
  shipDate,
  token,
  trackingNumber,
  thirdPartyShipper,
}) => {
  return await axios.patch(`/api/public-form/confirm-shipping`, {
    arriveDate,
    shipDate,
    token,
    trackingNumber,
    thirdPartyShipper,
  });
};
