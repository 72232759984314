import { useQuery } from "@tanstack/react-query";
import { contactGetAllServiceProviders } from "../../api/contactAPI";

const useContactServiceProviders = () => {
  const {
    data: serviceProviders = [],
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["contactServiceProviders"],
    queryFn: contactGetAllServiceProviders,
  });

  return {
    serviceProviders,
    error,
    isError,
    isLoading,
  };
};

export default useContactServiceProviders;
