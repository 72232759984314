import React from "react";
import {
  TextField,
  Grid,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { getContactOptionLabel } from "../../../sharedFunctions/labels";
import { PhoneDisplay } from "../../sharedComponents/displayText/PhoneDisplay";
import { EmailDisplay } from "../../sharedComponents/displayText/EmailDisplay";
import { useQuery } from "@tanstack/react-query";
import { getEmployeeEmails } from "../../../api/supplierCompaniesAPI";
import { checkFacilityUserLevelEditor } from "../../../sharedFunctions/userLevels";
import useContactBusinesses from "../../../customHooks/contacts/useContactBusinesses";
import useCheckIfEmailExistsCallback from "../../../customHooks/contacts/useCheckIfEmailExistsCallback";
import { FormSwitch } from "../../sharedComponents";
import EmailsDisplay from "../../sharedComponents/displayText/EmailsDisplay";
import formatPhoneNumber from "../../../sharedFunctions/formatPhoneNumber";
import FormSelect from "../../sharedComponents/selectors/FormSelect";

const ContactFields = (props) => {
  const {
    billingEmail,
    setBillingEmail,
    currency,
    setCurrency,
    editMode,
    appUserLevel,
    expectedResponseTime,
    setExpectedResponseTime,
    facilityUserLevel,
    shipperCompanyUserLevel,
    supplierUserLevel,
    contact,
    companyName,
    dispatchEmail,
    setDispatchEmail,
    setCompanyName,
    isASupplierCompany,
    isAWarehouse,
    isBusiness,
    isDemoSupplier,
    setIsDemoSupplier,
    isServiceProvider,
    isSupplier,
    isShipper,
    isWarehouse,
    setEmailError,
    setAltEmailError,
    setEmailValidationError,
    name,
    setName,
    orderEmails,
    setOrderEmails,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    altEmail,
    setAltEmail,
    paymentTerms,
    setPaymentTerms,
    parentCompanies,
    setParentCompanies,
    parentCompanyOptions,
    phone,
    setPhone,
    phoneExtension,
    setPhoneExtension,
    pickUpHours,
    setPickUpHours,
    shippingDimensionUnit,
    setShippingDimensionUnit,
    shippingWeightUnit,
    setShippingWeightUnit,
    shippingEmails,
    setShippingEmails,
    ratesEmail,
    setRatesEmail,
    serviceBookingPhone,
    setServiceBookingPhone,
    mobile,
    setMobile,
    website,
    setWebsite,
    userRole,
    setUserRole,
    facilityID,
    supplierCompanyID,
    shipperCompanyID,
    useAppCredits,
    setUseAppCredits,
    useDrOPsShipping,
    setUseDrOPsShipping,
  } = props;
  const id = new URLSearchParams(window.location.search).get("id");

  const { data: employeeEmails = [] } = useQuery({
    queryKey: ["supplierCompanies", id],
    queryFn: () => getEmployeeEmails(id),
    enabled: !!((isASupplierCompany || isAWarehouse) && id),
  });

  const { contactBusinesses: companies } = useContactBusinesses();
  const checkIfEmailExistsCallback = useCheckIfEmailExistsCallback();

  const onEmailBlur = async (e, setErrorState) => {
    if (!e.target.value) {
      setErrorState(false);
      return;
    }
    const result = await checkIfEmailExistsCallback({
      email: e.target.value,
      contactID: contact?._id,
    });
    result ? setErrorState(true) : setErrorState(false);
  };

  return (
    <Grid item xs={12} container spacing={2}>
      {isBusiness || isWarehouse ? (
        <Grid item xs={12} md={6}>
          <TextField
            autoComplete="off"
            id="name"
            label="Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={!editMode}
            fullWidth
          />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              id="firstName"
              label="First Name"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled={!editMode}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              id="lastName"
              label="Last Name"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled={!editMode}
              fullWidth
            />
          </Grid>
        </>
      )}
      {isBusiness && (
        <Grid item xs={12} md={6}>
          <TextField
            autoComplete="off"
            id="companyName"
            label="Company Name"
            variant="outlined"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            disabled={!editMode}
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12} md={6} className="vertical-centered-container">
        {(isBusiness && isSupplier) ||
        (isBusiness && isServiceProvider) ||
        isWarehouse ? (
          editMode ? (
            <Autocomplete
              multiple
              disabled={!editMode}
              options={employeeEmails}
              value={orderEmails}
              onChange={(e, value) => {
                setOrderEmails(value);
              }}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Order Emails" />
              )}
            />
          ) : (
            <EmailsDisplay emails={orderEmails} label={"Order Emails"} />
          )
        ) : editMode ? (
          <TextField
            autoComplete="off"
            id="email"
            label={"Email"}
            variant="outlined"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(false);
              setEmailValidationError(false);
            }}
            onBlur={(e) => onEmailBlur(e, setEmailError)}
            disabled={
              (!(
                appUserLevel.includes("Super User") ||
                appUserLevel.includes("Facility Manager")
              ) &&
                !!contact) ||
              !editMode
            }
            fullWidth
          />
        ) : (
          <EmailDisplay email={email} label="email" />
        )}
      </Grid>
      {isWarehouse && (
        <>
          <Grid item xs={12} md={6} className="vertical-centered-container">
            {editMode ? (
              <Autocomplete
                multiple
                disabled={!editMode}
                options={employeeEmails}
                value={shippingEmails}
                onChange={(e, value) => {
                  setShippingEmails(value);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Shipping Emails" />
                )}
              />
            ) : (
              <EmailsDisplay
                emails={shippingEmails}
                label={"Shipping Emails"}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              id="pickUpHours"
              label="Pick Up Hours"
              variant="outlined"
              value={pickUpHours}
              onChange={(e) => setPickUpHours(e.target.value)}
              disabled={!editMode}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormSelect
              editMode={editMode}
              value={shippingDimensionUnit}
              onChange={(e) => setShippingDimensionUnit(e.target.value)}
              label="Shipping Dimension Unit"
              menuItems={["cm", "inch", "feet", "m3"]}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormSelect
              editMode={editMode}
              value={shippingWeightUnit}
              onChange={(e) => setShippingWeightUnit(e.target.value)}
              label="Shipping Weight Unit"
              menuItems={["lbs", "kg", "tonne"]}
            />
          </Grid>
        </>
      )}
      {((isSupplier && isWarehouse) ||
        (isBusiness && isSupplier) ||
        (isBusiness && isShipper) ||
        (isBusiness && isServiceProvider)) && (
        <>
          <Grid item xs={12} md={6} className="vertical-centered-container">
            {editMode ? (
              isBusiness && isShipper ? (
                <TextField
                  autoComplete="off"
                  id="billingEmail"
                  label={"Billing Email"}
                  variant="outlined"
                  value={billingEmail}
                  onChange={(e) => {
                    setBillingEmail(e.target.value);
                  }}
                  disabled={!editMode}
                  fullWidth
                />
              ) : (
                <Autocomplete
                  disabled={!editMode}
                  options={employeeEmails}
                  value={billingEmail}
                  onChange={(e, value) => {
                    setBillingEmail(value);
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      autoComplete="off"
                      {...params}
                      label="Billing Email"
                    />
                  )}
                />
              )
            ) : (
              <EmailDisplay email={billingEmail} label="Billing Email" />
            )}
          </Grid>
          <Grid item xs={6} md={3}>
            <FormControl fullWidth>
              <InputLabel id="currencyLabel">Currency</InputLabel>
              <Select
                disabled={!editMode || !!contact}
                required
                value={currency}
                labelId="currencyLabel"
                label="Currency"
                onChange={(e) => {
                  setCurrency(e.target.value);
                }}
              >
                <MenuItem value={"CAD"}>CAD</MenuItem>
                <MenuItem value={"USD"}>USD</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel id="paymentTermsLabel">Payment Terms</InputLabel>
              <Select
                disabled={
                  !(
                    appUserLevel.includes("Super User") ||
                    appUserLevel.includes("Facility Manager")
                  ) || !editMode
                }
                required
                value={paymentTerms}
                labelId="paymentTermsLabel"
                label="Payment Terms"
                onChange={(e) => {
                  setPaymentTerms(e.target.value);
                }}
              >
                <MenuItem value={"Pre pay"}>Pre pay</MenuItem>
                <MenuItem value={"Due on receipt"}>Due on receipt</MenuItem>
                <MenuItem value={"Net15"}>Net 15</MenuItem>
                <MenuItem value={"Net30"}>Net 30</MenuItem>
                <MenuItem value={"Net60"}>Net 60</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
      {((isBusiness && isSupplier) || (isBusiness && isServiceProvider)) && (
        <>
          <FormSwitch
            disabled={!editMode}
            label="Use App Credits"
            checked={useAppCredits}
            onChange={(e) => setUseAppCredits(e.target.checked)}
            xs={6}
            md={3}
          />
          <FormSwitch
            disabled={!editMode}
            label="Demo Supplier"
            checked={isDemoSupplier}
            onChange={(e) => setIsDemoSupplier(e.target.checked)}
            xs={6}
            md={3}
          />
        </>
      )}
      {isBusiness && isSupplier && (
        <FormSwitch
          disabled={!editMode}
          label="Use drOPs Shipping"
          checked={useDrOPsShipping}
          onChange={(e) => setUseDrOPsShipping(e.target.checked)}
          xs={6}
          md={3}
        />
      )}

      {!isBusiness && !isWarehouse && (
        <Grid item xs={12} md={6} className="vertical-centered-container">
          {editMode ? (
            <TextField
              autoComplete="off"
              id="altEmail"
              label="Alt Email"
              onChange={(e) => {
                setAltEmail(e.target.value);
                setAltEmailError(false);
              }}
              onBlur={(e) => onEmailBlur(e, setAltEmailError)}
              value={altEmail}
              variant="outlined"
              disabled={!editMode}
              fullWidth
            />
          ) : (
            <EmailDisplay email={altEmail} label="Alt Email" />
          )}
        </Grid>
      )}
      {isBusiness && isShipper && (
        <>
          <Grid item xs={12} md={6} className="vertical-centered-container">
            {editMode ? (
              <TextField
                autoComplete="off"
                id="ratesEmail"
                label="Rates Email"
                onChange={(e) => {
                  setRatesEmail(e.target.value);
                }}
                value={ratesEmail}
                variant="outlined"
                disabled={!editMode}
                fullWidth
              />
            ) : (
              <EmailDisplay email={ratesEmail} label="Rates Email" />
            )}
          </Grid>
          <Grid item xs={12} md={6} className="vertical-centered-container">
            {editMode ? (
              <TextField
                autoComplete="off"
                id="dispatchEmail"
                label="Dispatch Email"
                onChange={(e) => {
                  setDispatchEmail(e.target.value);
                }}
                value={dispatchEmail}
                variant="outlined"
                disabled={!editMode}
                fullWidth
              />
            ) : (
              <EmailDisplay email={dispatchEmail} label="Dispatch Email" />
            )}
          </Grid>
        </>
      )}

      <Grid item xs={12} md={6}>
        <Autocomplete
          required
          multiple
          options={parentCompanyOptions ?? companies}
          getOptionLabel={getContactOptionLabel}
          value={parentCompanies}
          isOptionEqualToValue={(option, value) => {
            if (!value) return [];
            return option._id === value._id;
          }}
          onChange={(e, value) => {
            if (
              contact?.parentCompanies?.length &&
              contact.parentCompanies.find(
                (parentCompany) => !value.includes(parentCompany)
              )
            ) {
              alert(
                "Use Remove Contact From Company button to remove a parent company"
              );
              return;
            }

            setParentCompanies(value);
          }}
          disabled={
            !editMode ||
            !(
              appUserLevel.includes("Super User") ||
              appUserLevel.includes("Facility Manager")
            )
          }
          fullWidth
          renderInput={(params) => (
            <TextField
              autoComplete="off"
              {...params}
              label="Parent Company"
              variant="outlined"
            />
          )}
        />
      </Grid>
      {(facilityID || supplierCompanyID || shipperCompanyID) && (
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="userRoleLabel">Role</InputLabel>
            <Select
              disabled={
                (!checkFacilityUserLevelEditor(facilityUserLevel) &&
                  !shipperCompanyUserLevel?.includes("Manager") &&
                  !supplierUserLevel?.includes("Manager")) ||
                !editMode
              }
              required
              value={userRole}
              labelId="userRoleLabel"
              label="Role"
              onChange={(e) => {
                setUserRole(e.target.value);
              }}
            >
              {facilityID && <MenuItem value={"owners"}>Owner</MenuItem>}
              <MenuItem value={"managers"}>Manager</MenuItem>
              <MenuItem value={"administrators"}>Administrator</MenuItem>
              {facilityID && (
                <MenuItem value={"leadOperators"}>Lead Operator</MenuItem>
              )}

              {facilityID && <MenuItem value={"operators"}>Operator</MenuItem>}
              {facilityID && (
                <MenuItem value={"regulators"}>Regulator</MenuItem>
              )}
              {supplierCompanyID && (
                <MenuItem value={"representatives"}>
                  Sale Representative
                </MenuItem>
              )}
              {supplierCompanyID && (
                <MenuItem value={"serviceRepresentatives"}>
                  Service Representative
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={6} md={3} className="vertical-centered-container">
        {editMode ? (
          <TextField
            id="phone"
            label="Phone"
            type="tel"
            value={phone}
            onChange={(e) => setPhone(formatPhoneNumber(e.target.value))}
            variant="outlined"
            fullWidth
            autoComplete="off"
          />
        ) : (
          <PhoneDisplay phone={phone} label="Phone" />
        )}
      </Grid>
      <Grid item xs={6} md={3}>
        <TextField
          autoComplete="off"
          id="phoneExtension"
          label="Extension"
          variant="outlined"
          value={phoneExtension}
          onChange={(e) => {
            if (e.target.value.length > 10) return;
            setPhoneExtension(e.target.value.replace(/[^0-9]/g, ""));
          }}
          disabled={!editMode}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          fullWidth
        />
      </Grid>
      {isServiceProvider && (
        <Grid item xs={6} md={4} xl={2}>
          <TextField
            disabled={!editMode}
            id="serviceBookingPhone"
            label="Service Booking Phone"
            type="tel"
            value={serviceBookingPhone}
            onChange={(e) =>
              setServiceBookingPhone(formatPhoneNumber(e.target.value))
            }
            variant="outlined"
            fullWidth
            autoComplete="off"
          />
        </Grid>
      )}
      <Grid item xs={6} md={4} className="vertical-centered-container">
        {editMode ? (
          <TextField
            disabled={!editMode}
            id="mobile"
            label="Mobile Number"
            type="tel"
            value={mobile}
            onChange={(e) => setMobile(formatPhoneNumber(e.target.value))}
            variant="outlined"
            fullWidth
            autoComplete="off"
          />
        ) : (
          <PhoneDisplay phone={mobile} label="Mobile" />
        )}
      </Grid>
      {isServiceProvider && (
        <Grid item xs={6} md={4} xl={2}>
          <TextField
            autoComplete="off"
            id="expectedResponseTime"
            label="Expected Response Time"
            variant="outlined"
            value={expectedResponseTime}
            onChange={(e) => setExpectedResponseTime(e.target.value)}
            disabled={!editMode}
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <TextField
          autoComplete="off"
          id="website"
          label="Website"
          variant="outlined"
          value={website}
          onChange={(e) => setWebsite(e.target.value)}
          disabled={!editMode}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default ContactFields;
