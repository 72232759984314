import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import moment from "moment";
import { CustomModal } from "../sharedComponents";
import { DatePicker } from "@mui/x-date-pickers";
import useAddServiceOrderServiceDates from "../../customHooks/serviceOrder/useAddServiceOrderServiceDates";

const ServiceDates = ({ close, serviceRequest, startOpen }) => {
  const [serviceDates, setServicesDate] = useState(
    serviceRequest.serviceDates.map((serviceDate) => moment(serviceDate)) ?? []
  );
  const [open, setOpen] = useState(startOpen ?? false);
  const [valid, setValid] = useState(false);

  const addServiceOrderServiceDates = useAddServiceOrderServiceDates();

  useEffect(() => {
    if (!serviceDates?.length) return setValid(false);
    setValid(true);
  }, [serviceDates]);

  const closeModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setOpen(false);
    if (startOpen) close();
  };

  const onSubmit = async () => {
    await addServiceOrderServiceDates.mutateAsync({
      serviceOrderID: serviceRequest._id,
      serviceDates,
    });
    closeModal();
  };

  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item xs={12}>
        <Button
          fullWidth
          onClick={() => {
            setOpen(true);
          }}
          variant="contained"
        >
          Manage Service Dates
        </Button>
      </Grid>

      <CustomModal open={open} close={closeModal} title="Service Dates">
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={12}>
            <Button
              fullWidth
              onClick={() => {
                setServicesDate([...serviceDates, moment()]);
              }}
              variant="contained"
            >
              Add Service Date
            </Button>
          </Grid>

          {serviceDates.map((serviceDate, index) => (
            <Grid
              container
              spacing={2}
              item
              xs={12}
              md={6}
              className="vertical-centered-container"
              key={"Service Date" + index}
            >
              <Grid item xs={8}>
                <DatePicker
                  label="Service Date *"
                  value={serviceDate}
                  onChange={(value) => {
                    const newServiceDates = [...serviceDates];
                    newServiceDates[index] = value;
                    setServicesDate(newServiceDates);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    const newServiceDates = [...serviceDates];
                    newServiceDates.splice(index, 1);
                    setServicesDate(newServiceDates);
                  }}
                  color="error"
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          ))}

          <Grid item xs={12}>
            <Button
              fullWidth
              onClick={onSubmit}
              variant="contained"
              disabled={!valid}
            >
              Update Service Dates
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </Grid>
  );
};

export default ServiceDates;
