import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateFacilityUserRole } from "../../api/facilityAPI";

const useUpdateFacilityUpdateUserRole = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateFacilityUserRole,
    onSuccess: () => {
      queryClient.invalidateQueries("facility");
    },
  });
};

export default useUpdateFacilityUpdateUserRole;
