import React, { useState, useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { useSearchParams } from "react-router-dom";
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import {
  confirmShipping,
  getShipperCompanies,
  getByShipperToken,
} from "../../api/publicFormAPI";
import { getContactOptionLabel } from "../../sharedFunctions/labels";
import Loader from "../sharedComponents/Loader";

const PublicConfirmShippingForm = ({ setInvalidLink }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [arriveDate, setArriveDate] = useState();
  const [errorMessage, setErrorMessage] = useState(
    "Please enter an estimated arrival date."
  );
  const [order, setOrder] = useState(null);
  const [thirdPartyShipper, setThirdPartyShipper] = useState(null);
  const [shipperCompanies, setShipperCompanies] = useState([]);
  const [shipDate, setShipDate] = useState(moment());
  const [trackingNumber, setTrackingNumber] = useState("");
  const [submitComplete, setSubmitComplete] = useState(false);

  const alreadySubmitted =
    (order?.shipDate && order?.arriveDate) || submitComplete;

  useEffect(() => {
    getByShipperToken(token)
      .then((order) => {
        setOrder(order);
        setArriveDate(moment(order.arriveDate));
        setThirdPartyShipper(order.thirdPartyShipper);
        setTrackingNumber(order.trackingNumber);
        setShipDate(moment(order.shipDate));
      })
      .catch(() => setInvalidLink(true));

    getShipperCompanies().then((shipperCompanies) =>
      setShipperCompanies(shipperCompanies)
    );
  }, [token, setInvalidLink]);

  useEffect(() => {
    if (!arriveDate) {
      setErrorMessage("Please enter an estimated arrival date.");
      return;
    }

    if (arriveDate && arriveDate.isBefore(shipDate, "day")) {
      setErrorMessage("Arrival date must be after ship date.");
      return;
    }

    setErrorMessage("");
  }, [arriveDate, order, shipDate]);

  const onSubmit = async () => {
    await confirmShipping({
      arriveDate: arriveDate.toISOString(),
      shipDate: shipDate,
      token,
      trackingNumber,
      thirdPartyShipper,
    })
      .then(() => setSubmitComplete(true))
      .catch((err) => alert(err));
  };

  if (!order) return <Loader />;

  return (
    <Grid container spacing={2} item={12}>
      <Grid item xs={12}>
        <Typography variant="h4">
          {alreadySubmitted
            ? "Shipping Confirmed"
            : `Confirm Shipping Order: ${order?.orderNumber}`}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} display={"flex"} justifySelf={"center"}>
        <DatePicker
          label="Ship Date"
          value={shipDate}
          onChange={setShipDate}
          renderInput={(params) => <TextField {...params} fullWidth />}
          disabled={alreadySubmitted}
        />
      </Grid>
      <Grid item xs={12} md={6} display={"flex"} justifySelf={"center"}>
        <DatePicker
          label="Est. Arrive Date"
          value={arriveDate}
          onChange={setArriveDate}
          renderInput={(params) => <TextField {...params} fullWidth />}
          disabled={alreadySubmitted}
        />
      </Grid>
      <Grid item xs={12} md={6} display={"flex"} justifySelf={"center"}>
        <Autocomplete
          options={shipperCompanies.map((option) => option.contact)}
          getOptionLabel={getContactOptionLabel}
          value={thirdPartyShipper}
          onChange={(e, value) => setThirdPartyShipper(value)}
          sx={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Shipping Carrier"
              variant="outlined"
              fullWidth
            />
          )}
          disabled={alreadySubmitted}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Tracking Number"
          variant="outlined"
          fullWidth
          value={trackingNumber}
          onChange={(e) => setTrackingNumber(e.target.value)}
          disabled={alreadySubmitted}
        />
      </Grid>
      {errorMessage && (
        <Grid item xs={12}>
          <Typography color="error">{errorMessage}</Typography>
        </Grid>
      )}
      {!alreadySubmitted && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onSubmit}
            disabled={!!errorMessage}
          >
            Confirm Shipping
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default PublicConfirmShippingForm;
