import React, { useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";
import logo from "../../imgs/drOPsLogoSplash.png";
import { useAuth0 } from "@auth0/auth0-react";

const SplashScreen = ({ hasLogIn }) => {
  const [loaded, setLoaded] = useState(false);
  const { loginWithRedirect } = useAuth0();

  return (
    <Container
      maxWidth="md"
      className="centered-container"
      sx={{ background: "#1976d2" }}
    >
      <Box className="center-screen">
        <img
          src={logo}
          alt="drOPs logo"
          width={window.innerWidth < 760 ? 350 : 500}
          onLoad={() => setLoaded(true)}
        />
        {loaded && (
          <>
            <Typography fontSize="1.2rem">
              Drop Solutions Inc.
              <CopyrightIcon
                fontSize="medium"
                sx={{ position: "relative", top: "5px" }}
              />
              {new Date().getFullYear()}
            </Typography>
            {hasLogIn && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  loginWithRedirect({
                    appState: {
                      returnTo: `${window.location.pathname}${window.location.search}`,
                    },
                  })
                }
                sx={{ marginTop: "1rem" }}
              >
                Login/Sign Up
              </Button>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};

export default SplashScreen;
