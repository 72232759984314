import { useAuth0 } from "@auth0/auth0-react";
import { Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import useEmailSendFeedback from "../../customHooks/email/useEmailSendFeedback";

const FeedbackForm = (props) => {
  const { close } = props;

  const { user } = useAuth0();

  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const emailSendFeedback = useEmailSendFeedback();

  const sendReportEmail = async (e) => {
    try {
      setSending(true);
      const body = {
        message,
        user: user.email,
      };

      await emailSendFeedback.mutateAsync({ body });

      setSending(false);
      close();
    } catch (error) {
      setSending(false);
      throw error;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          autoComplete="off"
          label="Enter Feedback"
          variant="outlined"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          type="submit"
          variant="contained"
          fullWidth
          onClick={sendReportEmail}
          disabled={sending}
        >
          {sending ? "Sending Feedback" : " Send Feedback"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default FeedbackForm;
