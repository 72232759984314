import { useState, useEffect } from "react";
import getPSTRate from "../../sharedFunctions/purchasingFunctions/getPSTRate";
import { GST as GSTRate } from "../../globalConstants";
import customDollarRound from "../../sharedFunctions/purchasingFunctions/customDollarRound";
import calculateServicePrice from "../../sharedFunctions/purchasingFunctions/calculateServicePrice";

function useServiceTotals(
  exchangeRate,
  services,
  supplierID,
  facility,
  materialsUsed
) {
  const [subtotal, setSubtotal] = useState(0);
  const [GST, setGST] = useState(0);
  const [PST, setPST] = useState(0);
  const [total, setTotal] = useState(0);
  const [supplierSubtotal, setSupplierSubtotal] = useState(0);
  const [supplierGST, setSupplierGST] = useState(0);
  const [supplierPST, setSupplierPST] = useState(0);
  const [supplierTotal, setSupplierTotal] = useState(0);

  useEffect(() => {
    if (!facility || !services?.length) return;

    let newGST = 0;
    let newPST = 0;
    let newSubtotal = 0;
    let newSupplierGST = 0;
    let newSupplierPST = 0;
    let newSupplierSubtotal = 0;

    services.forEach((service) => {
      const supplierServiceDetails =
        service.service?.supplierServiceDetails?.find(
          (supplierServiceDetail) => {
            const id =
              supplierServiceDetail.supplier?._id ??
              supplierServiceDetail.supplier;
            return id === supplierID;
          }
        );

      if (supplierServiceDetails) {
        const price = customDollarRound(
          calculateServicePrice(
            service.cost ?? supplierServiceDetails?.cost,
            exchangeRate?.rate,
            supplierID
          )
        );

        if (service.service?.hasPST) {
          if (facility?.hasPST) {
            newPST += customDollarRound(
              price *
                service.quantity *
                getPSTRate(facility?.addressObject?.province)
            );
          }
          newSupplierPST += customDollarRound(
            (service.cost ?? supplierServiceDetails?.cost) *
              service.quantity *
              getPSTRate(facility?.addressObject?.province)
          );
        }
        if (facility?.hasGST) {
          newGST += customDollarRound(price * service.quantity * GSTRate);
        }
        newSupplierGST += customDollarRound(
          (service.cost ?? supplierServiceDetails?.cost) *
            service.quantity *
            GSTRate
        );
        newSubtotal += customDollarRound(price * service.quantity);
        newSupplierSubtotal += customDollarRound(
          (service.cost ?? supplierServiceDetails?.cost) * service.quantity
        );
      }
    });

    if (materialsUsed?.length) {
      materialsUsed.forEach((material) => {
        if (!material?.price) return;
        if (facility?.hasGST) {
          newGST += customDollarRound(material.price * GSTRate);
        }
        newSupplierGST += customDollarRound(material.cost * GSTRate);
        if (material.materialType === "Other") {
          if (facility?.hasPST) {
            newPST += customDollarRound(
              material.price * getPSTRate(facility?.addressObject?.province)
            );
          }
          newSupplierPST += customDollarRound(
            material.cost * getPSTRate(facility?.addressObject?.province)
          );
        }
        newSubtotal += customDollarRound(material.price);
        newSupplierSubtotal += customDollarRound(material.cost);
      });
    }

    setSubtotal(newSubtotal);
    setGST(customDollarRound(newGST));
    setPST(newPST);
    setTotal(newSubtotal + newPST + newGST);
    setSupplierSubtotal(newSupplierSubtotal);
    setSupplierGST(newSupplierGST);
    setSupplierPST(newSupplierPST);
    setSupplierTotal(newSupplierSubtotal + newSupplierPST + newSupplierGST);
  }, [services, supplierID, facility, materialsUsed, exchangeRate?.rate]);

  return {
    subtotal,
    GST,
    PST,
    total,
    supplierSubtotal,
    supplierGST,
    supplierPST,
    supplierTotal,
  };
}

export default useServiceTotals;
