import React from "react";

import { Grid, Button } from "@mui/material";

const MaintenanceProcedureList = (props) => {
  const { maintenanceProcedures, setMaintenanceProcedure, setModalOpen } =
    props;

  const openModal = (maintenanceProcedure) => {
    setMaintenanceProcedure(maintenanceProcedure);
    setModalOpen(true);
  };

  return (
    maintenanceProcedures?.length > 0 &&
    maintenanceProcedures
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((maintenanceProcedure, i) => (
        <Grid
          item
          xs={12}
          md={6}
          key={maintenanceProcedure._id + i}
          display={"flex"}
        >
          <Button
            variant="contained"
            fullWidth
            onClick={() => openModal(maintenanceProcedure)}
          >
            {maintenanceProcedure.name}
            {maintenanceProcedure?.equipment?.name
              ? `, ${maintenanceProcedure.equipment.name}`
              : ""}
          </Button>
        </Grid>
      ))
  );
};

export default MaintenanceProcedureList;
