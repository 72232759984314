import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addCartSubmission } from "../../api/cartSubmissionAPI";

const useAddCartSubmission = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addCartSubmission,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cartSubmissions"] });
    },
  });
};

export default useAddCartSubmission;
