import { useMutation, useQueryClient } from "@tanstack/react-query";

import { addManufacturerProductDetail } from "../../api/manufacturerProductDetailsAPI";

const useAddManufacturerProductDetail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addManufacturerProductDetail,
    onSuccess: () => {
      queryClient.invalidateQueries("manufacturerProductDetails");
    },
  });
};

export default useAddManufacturerProductDetail;
