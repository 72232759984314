import { useMutation, useQueryClient } from "@tanstack/react-query";
import { manufacturerProductDetailDeleteFile } from "../../api/manufacturerProductDetailsAPI";

const useManufacturerProductDetailsDeleteFile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: manufacturerProductDetailDeleteFile,
    onSuccess: () => {
      queryClient.invalidateQueries("manufacturerProductDetails");
    },
  });
};

export default useManufacturerProductDetailsDeleteFile;
