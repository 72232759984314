/*********************************************************
 This component will display .pdf, .jpg, .jpeg, .png files
 in a grid 3 wide.
 ********************************************************/
import React, { useState } from "react";
import { Button, CircularProgress, Grid } from "@mui/material";
import CustomModal from "./modals/CustomModal";
import PDFViewer from "./PDFViewer";
import PDFPreview from "./PDFPreview";
import useImage from "../../customHooks/image/useImage";

const FileList = ({ files }) => {
  const [file, setFile] = useState(null);
  const [fileContentType, setFileContentType] = useState(null);
  const [fileAlt, setFileAlt] = useState(null);
  const [fileOpen, setFileOpen] = useState(false);

  const closeFileModal = (event, reason) => {
    if (reason !== "backdropClick") setFileOpen(false);
  };

  const openFileModal = (file, fileURL, alt) => {
    setFileContentType(file.contentType);
    setFileAlt(alt);
    setFile(fileURL);
    setFileOpen(true);
  };

  return (
    <>
      <CustomModal open={fileOpen} close={closeFileModal}>
        <Grid container className="centered-container">
          {fileContentType === "image/png" ||
          fileContentType === "image/jpg" ||
          fileContentType === "image/jpeg" ||
          fileContentType === "image/gif" ? (
            <img src={file} alt={fileAlt} width="100%" />
          ) : (
            <PDFViewer file={file} />
          )}
        </Grid>
      </CustomModal>

      {files.map((file, i) => (
        <React.Fragment key={file.fileID}>
          <DisplayFile
            fileToDisplay={file}
            i={i}
            openFileModal={openFileModal}
          />
        </React.Fragment>
      ))}
    </>
  );
};

const DisplayFile = ({ fileToDisplay, i, openFileModal }) => {
  const { file, isLoading } = useImage(fileToDisplay.fileID);

  return fileToDisplay.contentType === "image/png" ||
    fileToDisplay.contentType === "image/jpg" ||
    fileToDisplay.contentType === "image/jpeg" ||
    fileToDisplay.contentType === "image/gif" ? (
    <Grid
      container
      item
      xs={12}
      md={4}
      key={fileToDisplay.fileID}
      className="centered-container"
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid item xs={12} sx={{ height: 150 }}>
            <img src={file} alt={"img" + i} style={{ height: "100%" }} />
          </Grid>
          <Grid item xs={4} sx={{ mt: 0.5 }}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => openFileModal(fileToDisplay, file, "img" + i)}
            >
              View
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  ) : (
    <Grid
      container
      item
      xs={12}
      md={4}
      key={fileToDisplay.fileID}
      className="centered-container"
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <PDFPreview file={file} />

          <Grid item xs={4} sx={{ mt: 0.5 }}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => openFileModal(fileToDisplay, file, "file" + i)}
            >
              View
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default FileList;
