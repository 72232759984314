import { useQuery } from "@tanstack/react-query";
import { getFile } from "../../api/imageAPI";
import { useAuth0 } from "@auth0/auth0-react";

const useImage = (id) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    data: file = null,
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["file", id],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return getFile(id, token);
    },
    enabled: !!id,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    file,
    error,
    isError,
    isLoading,
  };
};

export default useImage;
