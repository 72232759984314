import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updatePickUpAvailableDate } from "../../api/productOrderAPI";

const useUpdateOrderPickUpAvailableDate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updatePickUpAvailableDate,
    onSuccess: () => {
      queryClient.invalidateQueries("orders");
    },
  });
};

export default useUpdateOrderPickUpAvailableDate;
