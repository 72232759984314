export const countries = {
  CA: "Canada",
  US: "United States of America",
  UK: "United Kingdom",
  CN: "China",
};

export const CA_provinces = {
  AB: "Alberta",
  BC: "British Columbia",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland and Labrador",
  NT: "Northwest Territories",
  NS: "Nova Scotia",
  NU: "Nunavut",
  ON: "Ontario",
  PE: "Prince Edward Island",
  QC: "Quebec",
  SK: "Saskatchewan",
  YT: "Yukon",
};

export const US_states = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

export const UK_provinces = {
  I0: "Aberconwy and Colwyn",
  I1: "Aberdeen City",
  I2: "Aberdeenshire",
  I3: "Anglesey",
  I4: "Angus",
  I5: "Antrim",
  I6: "Argyll and Bute",
  I7: "Armagh",
  I8: "Avon",
  I9: "Ayrshire",
  IB: "Bath and NE Somerset",
  IC: "Bedfordshire",
  IE: "Belfast",
  IF: "Berkshire",
  IG: "Berwickshire",
  IH: "BFPO",
  II: "Blaenau Gwent",
  IJ: "Buckinghamshire",
  IK: "Caernarfonshire",
  IM: "Caerphilly",
  IO: "Caithness",
  IP: "Cambridgeshire",
  IQ: "Cardiff",
  IR: "Cardiganshire",
  IS: "Carmarthenshire",
  IT: "Ceredigion",
  IU: "Channel Islands",
  IV: "Cheshire",
  IW: "City of Bristol",
  IX: "Clackmannanshire",
  IY: "Clwyd",
  IZ: "Conwy",
  J0: "Cornwall/Scilly",
  J1: "Cumbria",
  J2: "Denbighshire",
  J3: "Derbyshire",
  J4: "Derry/Londonderry",
  J5: "Devon",
  J6: "Dorset",
  J7: "Down",
  J8: "Dumfries and Galloway",
  J9: "Dunbartonshire",
  JA: "Dundee",
  JB: "Durham",
  JC: "Dyfed",
  JD: "East Ayrshire",
  JE: "East Dunbartonshire",
  JF: "East Lothian",
  JG: "East Renfrewshire",
  JH: "East Riding Yorkshire",
  JI: "East Sussex",
  JJ: "Edinburgh",
  JK: "England",
  JL: "Essex",
  JM: "Falkirk",
  JN: "Fermanagh",
  JO: "Fife",
  JP: "Flintshire",
  JQ: "Glasgow",
  JR: "Gloucestershire",
  JS: "Greater London",
  JT: "Greater Manchester",
  JU: "Gwent",
  JV: "Gwynedd",
  JW: "Hampshire",
  JX: "Hartlepool",
  HAW: "Hereford and Worcester",
  JY: "Hertfordshire",
  JZ: "Highlands",
  K0: "Inverclyde",
  K1: "Inverness-Shire",
  K2: "Isle of Man",
  K3: "Isle of Wight",
  K4: "Kent",
  K5: "Kincardinshire",
  K6: "Kingston Upon Hull",
  K7: "Kinross-Shire",
  K8: "Kirklees",
  K9: "Lanarkshire",
  KA: "Lancashire",
  KB: "Leicestershire",
  KC: "Lincolnshire",
  KD: "Londonderry",
  KE: "Merseyside",
  KF: "Merthyr Tydfil",
  KG: "Mid Glamorgan",
  KH: "Middlesex",
  KI: "Mid Lothian",
  KJ: "Monmouthshire",
  KK: "Moray",
  KL: "Neath & Port Talbot",
  KM: "Newport",
  KN: "Norfolk",
  KP: "North Ayrshire",
  KQ: "North East Lincolnshire",
  KR: "North Lanarkshire",
  KT: "North Lincolnshire",
  KU: "North Somerset",
  KV: "North Yorkshire",
  KO: "Northamptonshire",
  KW: "Northern Ireland",
  KX: "Northumberland",
  KZ: "Nottinghamshire",
  L0: "Orkney and Shetland Isles",
  L1: "Oxfordshire",
  L2: "Pembrokeshire",
  L3: "Perth and Kinross",
  L4: "Powys",
  L5: "Redcar and Cleveland",
  L6: "Renfrewshire",
  L7: "Rhonda Cynon Taff",
  L8: "Rutland",
  L9: "Scottish Borders",
  LB: "Shetland",
  LC: "Shropshire",
  LD: "Somerset",
  LE: "South Ayrshire",
  LF: "South Glamorgan",
  LG: "South Gloucesteshire",
  LH: "South Lanarkshire",
  LI: "South Yorkshire",
  LJ: "Staffordshire",
  LK: "Stirling",
  LL: "Stockton On Tees",
  LM: "Suffolk",
  LN: "Surrey",
  LO: "Swansea",
  LP: "Torfaen",
  LQ: "Tyne and Wear",
  LR: "Tyrone",
  LS: "Vale Of Glamorgan",
  LT: "Wales",
  LU: "Warwickshire",
  LV: "West Berkshire",
  LW: "West Dunbartonshire",
  LX: "West Glamorgan",
  LY: "West Lothian",
  LZ: "West Midlands",
  M0: "West Sussex",
  M1: "West Yorkshire",
  M2: "Western Isles",
  M3: "Wiltshire",
  M4: "Wirral",
  M5: "Worcestershire",
  M6: "Wrexham",
  M7: "York",
};
