import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addDefaultSupplierServiceDetails } from "../../api/serviceAPI";

export const useAddDefaultSupplierServiceDetails = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addDefaultSupplierServiceDetails,
    onSuccess: async () => {
      await queryClient.invalidateQueries("services");
      await queryClient.invalidateQueries("supplierServiceDetails");
    },
  });
};

export default useAddDefaultSupplierServiceDetails;
