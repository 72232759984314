import React, { useEffect, useState } from "react";
import { Button, Grid, InputAdornment } from "@mui/material";
import { CustomModal, FormSwitch } from "../sharedComponents";
import DecimalTextField from "../sharedComponents/TextFields/DecimalTextField";
import customDollarRound from "../../sharedFunctions/purchasingFunctions/customDollarRound";
import useAddShippingCost from "../../customHooks/productOrder/useAddShippingCost";

const AddShippingCost = (props) => {
  const { close, order } = props;
  const addShippingCost = useAddShippingCost();

  const [brokerageFeeCost, setBrokerageFeeCost] = useState("");
  const [brokerageFeeGST, setBrokerageFeeGST] = useState("");
  const [facilityFreeShipping, setFacilityFreeShipping] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [shippingCost, setShippingCost] = useState("");

  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (!shippingCost) return setValid(false);

    setValid(true);
  }, [shippingCost]);

  const closeModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setModalOpen(false);
  };

  const onSubmit = async () => {
    try {
      setSaving(true);

      let body = {
        orderID: order._id,
        brokerageFeeCost,
        brokerageFeeGST,
        supplierShippingCost: customDollarRound(parseFloat(shippingCost)),
        facilityFreeShipping,
      };

      await addShippingCost.mutateAsync({ body });

      close();
      setSaving(false);
      setModalOpen(false);
    } catch (error) {
      setSaving(false);
      throw error;
    }
  };

  const renderShippingConfirmation = () => {
    return (
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12} md={4}>
          <DecimalTextField
            label={"Final Shipping Cost (before tax)"}
            value={shippingCost}
            editMode={true}
            onChange={(e) => {
              setShippingCost(e.target.value);
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            allowMinus={false}
            required={true}
          />
        </Grid>
        {!order.brokerageFeeCost && (
          <>
            <Grid item xs={12} md={4}>
              <DecimalTextField
                label={"Brokerage Fee"}
                value={brokerageFeeCost}
                editMode={true}
                onChange={(e) => {
                  setBrokerageFeeCost(e.target.value);
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                allowMinus={false}
                required={false}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DecimalTextField
                label={"Brokerage Fee GST"}
                value={brokerageFeeGST}
                editMode={true}
                onChange={(e) => {
                  setBrokerageFeeGST(e.target.value);
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                allowMinus={false}
                required={false}
              />
            </Grid>
            {shippingCost !== "" && (
              <FormSwitch
                label="Facility Free Shipping"
                checked={facilityFreeShipping}
                onChange={(e) => {
                  setFacilityFreeShipping(e.target.checked);
                }}
                xs={12}
                md={4}
              />
            )}
          </>
        )}

        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={onSubmit}
            disabled={!valid || saving}
          >
            {saving ? "Confirming Shipping Cost" : "Confirm Shipping Cost"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setModalOpen(true)}
          >
            Shipping Cost
          </Button>
        </Grid>
      </Grid>
      <CustomModal open={modalOpen} close={closeModal} title={"Shipping Cost"}>
        {renderShippingConfirmation()}
      </CustomModal>
    </>
  );
};

export default AddShippingCost;
