import React from "react";
import { Typography } from "@mui/material";

const formatTextWithLineBreaks = (text) => {
  return (
    <Typography variant="body1" component="span">
      {text.split("\n").map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </Typography>
  );
};

export default formatTextWithLineBreaks;
