import { useQuery } from "@tanstack/react-query";
import { contactGetAllManufactures } from "../../api/contactAPI";

const useContactManufacturers = () => {
  const {
    data: manufacturers = [],
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["contactManufacturers"],
    queryFn: contactGetAllManufactures,
  });

  return {
    manufacturers,
    error,
    isError,
    isLoading,
  };
};

export default useContactManufacturers;
