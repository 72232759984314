import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  DISTRIBUTION_TAB_NUMBER,
  INFO_TAB_NUMBER,
  SUPPLIES_TAB_NUMBER,
  SYSTEMS_TAB_NUMBER,
} from "../../globalConstants";
import { selectFacilityUserLevel } from "../../redux/reducers/userLevelsSlice";

export const useFacilityNavigateCallback = () => {
  const navigate = useNavigate();
  const facilityUserLevel = useSelector(selectFacilityUserLevel);

  const navigateToFacility = (facility) => {
    if (!facility) return;

    // Admins go straight to chemical supplies
    if (
      facilityUserLevel.includes("Admin") &&
      facility.chemicalSupplies?.length
    ) {
      navigate(
        `/facility?id=${facility._id}&active_tab=${SUPPLIES_TAB_NUMBER}`
      );
      return;
    }

    if (facilityUserLevel?.includes("Supplier")) {
      navigate(`/facility?id=${facility._id}&active_tab=${INFO_TAB_NUMBER}`);
      return;
    }

    if (!facility.distributions?.length && facility.chemicalSupplies?.length)
      return navigate(
        `/facility?id=${facility._id}&active_tab=${SUPPLIES_TAB_NUMBER}`
      );

    if (!facility.distributions?.length && !facility.chemicalSupplies?.length)
      return navigate(
        `/facility?id=${facility._id}&active_tab=${INFO_TAB_NUMBER}`
      );

    navigate(
      `/facility?id=${
        facility._id
      }&active_tab=${SYSTEMS_TAB_NUMBER}&system_tab=${DISTRIBUTION_TAB_NUMBER}&systemID=${
        facility.distributions?.[0]?._id ?? facility.distributions[0]
      }`
    );
  };

  return navigateToFacility;
};
