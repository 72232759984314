export const checkForIPhoneBefore16_4 = () => {
  // Check if the userAgent contains "iPhone" or "iPad"
  if (!/iPad|iPhone|iPod/.test(navigator.userAgent) || window.MSStream) {
    return; // Not an iOS device or using UIWebView (iOS < 8)
  }

  // Extract the iOS version from the userAgent string
  var match = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);

  if (!match || match.length <= 1) {
    return; // Unable to extract version information
  }

  // Extract the major version numbers
  var majorVersion = parseInt(match[1], 10);
  var minorVersion = parseInt(match[2], 10);

  // Check if the major version is less than or equal to 16
  if (majorVersion < 16 || (majorVersion === 16 && minorVersion < 4)) {
    return true; // iOS version is less than 16.4
  }
};
