import { useQuery } from "@tanstack/react-query";
import { getServiceOrdersBySupplier } from "../../api/serviceOrderAPI";

const useServiceOrdersBySupplier = (supplierID) => {
  const {
    data: serviceOrders = [],
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["serviceOrders", supplierID],
    queryFn: () => getServiceOrdersBySupplier(supplierID),
    enabled: !!supplierID,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    serviceOrders,
    error,
    isError,
    isLoading,
  };
};

export default useServiceOrdersBySupplier;
