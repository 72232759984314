import { useMutation, useQueryClient } from "@tanstack/react-query";
import { refund } from "../../api/productOrderAPI";

const useRefund = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: refund,
    onSuccess: () => {
      queryClient.invalidateQueries("orders");
      queryClient.invalidateQueries("order");
    },
  });
};

export default useRefund;
