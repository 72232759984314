import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { CustomModal } from "../sharedComponents";
import ContactForm from "../contacts/contactForm/ContactForm";

const ContactButton = ({ contact, label, xs, md, lg, xl }) => {
  const [contactOpen, setContactOpen] = useState(false);

  if (!contact) return null;

  return (
    <Grid
      item
      xs={xs}
      md={md}
      lg={lg}
      xl={xl}
      className="vertical-centered-container"
    >
      <Typography sx={{ mr: 1 }}>{label}</Typography>
      <Button
        variant="contained"
        onClick={() => {
          setContactOpen(true);
        }}
      >
        {contact.name}
      </Button>
      <CustomModal
        open={contactOpen}
        close={() => setContactOpen(false)}
        title="Contact"
      >
        <ContactForm close={() => setContactOpen(false)} contact={contact} />
      </CustomModal>
    </Grid>
  );
};

export default ContactButton;
