import { useQuery } from "@tanstack/react-query";
import { getShipperCompanies } from "../../api/shipperCompanyAPI";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setShipperCompanies } from "../../redux/reducers/productsSlice";

const useShipperCompanies = () => {
  const dispatch = useDispatch();
  const { data: shipperCompanies = [] } = useQuery({
    queryKey: ["shipperCompany"],
    queryFn: () => getShipperCompanies(),
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (shipperCompanies.length) {
      dispatch(setShipperCompanies(shipperCompanies));
    }
  }, [dispatch, shipperCompanies]);

  return { shipperCompanies };
};

export default useShipperCompanies;
