import { addShipperCompany } from "../../api/shipperCompanyAPI";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useAddShipperCompany = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addShipperCompany,
    onSuccess: () => {
      queryClient.invalidateQueries("shipperCompany");
    },
  });
};

export default useAddShipperCompany;
