import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addSupplierCompany } from "../../api/supplierCompaniesAPI";

const useAddSupplierCompany = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addSupplierCompany,
    onError: (error) => {
      if (error.response?.data?.error?.code === 11000) {
        //TODO: handle duplicate supplier company
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["supplierCompanies"] });
    },
  });
};

export default useAddSupplierCompany;
