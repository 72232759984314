import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const FormSelect = (props) => {
  const {
    editMode,
    disabled,
    value,
    onChange,
    defaultMenuItem,
    menuItems,
    label,
    required = true,
  } = props;

  return (
    <FormControl fullWidth>
      <InputLabel id={`${label.replace(/\s/g, "")}label`} required={required}>
        {label}
      </InputLabel>
      <Select
        disabled={!editMode || disabled}
        required={required}
        value={value}
        labelId={`${label.replace(/\s/g, "")}label`}
        label={label}
        onChange={onChange}
      >
        {defaultMenuItem && (
          <MenuItem key={defaultMenuItem} value={"Default"}>
            {defaultMenuItem}
          </MenuItem>
        )}

        {menuItems?.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FormSelect;
