import { checkIfValidAddress } from "./checkIfValidAddress";

export const checkIfValidProductDetails = (supplierProductDetail) => {
  if (!supplierProductDetail) return false;

  const validWarehouse =
    supplierProductDetail.product?.type === "Container"
      ? true
      : supplierProductDetail.warehouses?.length &&
        supplierProductDetail.warehouses
          .map(
            (warehouse) =>
              warehouse.contact?.orderEmails?.length &&
              checkIfValidAddress(warehouse.contact?.address)
          )
          .filter((validAddress) => validAddress).length
      ? true
      : false;

  if (
    supplierProductDetail.product?.returnableContainer &&
    !supplierProductDetail.containerDeposit
  )
    return false;

  const hasCost =
    supplierProductDetail?.cost ||
    (supplierProductDetail.useTierPricing &&
      supplierProductDetail.tierPricing?.length);

  return hasCost && validWarehouse;
};
