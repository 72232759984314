import React from "react";
import { Grid, Typography } from "@mui/material";
import OrderDisplayProducts from "./OrderDisplayProducts";
import AddToOrder from "../AddToOrder";
import { useSelector } from "react-redux";
import { selectAppUserLevel } from "../../../redux/reducers/userLevelsSlice";

const OrderAddition = ({ close, order, orderAddition, orderView }) => {
  const appUserLevel = useSelector(selectAppUserLevel);

  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        {appUserLevel.includes("Super User") ||
        appUserLevel.includes("Supplier") ? (
          <Typography>Products To Add To The Above Order</Typography>
        ) : (
          <Typography>Updates Awaiting Supplier Confirmation</Typography>
        )}
      </Grid>
      <OrderDisplayProducts
        items={orderAddition.productDetails}
        orderType={"ProductOrder"}
        orderView={orderView}
      />
      {(appUserLevel.includes("Super User") ||
        appUserLevel.includes("Supplier")) && (
        <AddToOrder
          close={close}
          pendingOrder={order}
          orderAddition={orderAddition}
        />
      )}
    </Grid>
  );
};

export default OrderAddition;
