import { useMutation } from "@tanstack/react-query";
import { quickBooksUpdateVendor } from "../../api/quickBooksAPI";

const useQuickBooksUpdateVendor = () => {
  return useMutation({
    mutationFn: quickBooksUpdateVendor,
  });
};

export default useQuickBooksUpdateVendor;
