import { useQuery } from "@tanstack/react-query";
import { getFacilityInfo } from "../../api/facilityAPI";

const useFacilityInfo = (facilityID) => {
  const {
    data: facility = {},
    isLoading,
    error,
  } = useQuery({
    queryKey: ["facility", "info", facilityID],
    queryFn: () => getFacilityInfo(facilityID),
    enabled: !!facilityID,
  });

  return { facility, isLoading, error };
};

export default useFacilityInfo;
