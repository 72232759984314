import React, { useState } from "react";

import { Button, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";
import HelpIcon from "@mui/icons-material/Help";
import EmailIcon from "@mui/icons-material/Email";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
// import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

import PrivacyPolicy from "../../pdf/PrivacyPolicy.pdf";
import TermsAndConditions from "../../pdf/TermsAndConditionsOfUse.pdf";

const NavSupportButton = (props, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { blue, darkMode } = props;

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Help and Feedback">
        <Button
          onClick={handleOpenMenu}
          sx={{
            color: blue && darkMode === "light" ? "#1976d2" : "white",
            width: "100%",
            display: "flex",
          }}
        >
          <HelpIcon sx={{ mr: 2, ml: 1 }} />
          {blue && "Help and Feedback"}
        </Button>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() =>
            window.open(
              "https://dropsolutions.atlassian.net/servicedesk/customer/portal/3",
              "_blank"
            )
          }
        >
          <Typography>Help Center</Typography>
        </MenuItem>

        <MenuItem
          onClick={() =>
            (window.location.href =
              "mailto:support@dropsolutions.ca?subject=drOPs Support")
          }
        >
          <EmailIcon /> <Typography>Email Us</Typography>
        </MenuItem>
        <MenuItem
          onClick={() =>
            window.open("https://g.page/r/Cc6XxFpDBnHfEAg/review", "_blank")
          }
        >
          <StarOutlineIcon /> <Typography>Review Us</Typography>
        </MenuItem>
        <MenuItem onClick={() => window.open(PrivacyPolicy)}>
          <Typography fontSize="0.8rem">
            Drop Solutions Inc.
            <CopyrightIcon
              fontSize="small"
              sx={{ position: "relative", top: "5px" }}
            />
            {new Date().getFullYear()}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => window.open(TermsAndConditions)}>
          <Typography fontSize="0.8rem">Terms And Conditions</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default NavSupportButton;
