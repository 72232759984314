import calculateProductPrice from "./calculateProductPrice";
import calculateTotalProductAmount from "./calculateTotalProductAmount";

export const calculateTotalProductAmountWithExchange = (
  productCost,
  productInCart,
  exchangeRate
) => {
  const containerDeposit =
    productInCart?.supplierProductDetail?.containerDeposit;

  const totalProductAmount = calculateTotalProductAmount(
    calculateProductPrice(containerDeposit, exchangeRate),
    calculateProductPrice(
      productCost,
      exchangeRate,
      productInCart.supplierProductDetail.supplier._id
    ),
    productInCart.quantity
  );

  return totalProductAmount;
};
