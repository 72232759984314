import { GST_TaxRateRef, PST_SK_on_sales_TaxRateRef } from "./quickBooksCodes";

const getTaxNameFromTaxCode = (taxCode) => {
  switch (taxCode) {
    case GST_TaxRateRef.value:
      return "GST";
    case PST_SK_on_sales_TaxRateRef.value:
      return "PST";
    default:
      return "No Tax";
  }
};

export default getTaxNameFromTaxCode;
