import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { getProductOptionLabel } from "../../../sharedFunctions/labels";
import { FormSwitch } from "../../sharedComponents";
import useRefund from "../../../customHooks/productOrder/useRefund";
import DecimalTextField from "../../sharedComponents/TextFields/DecimalTextField";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { GST } from "../../../globalConstants";
import getPSTRate from "../../../sharedFunctions/purchasingFunctions/getPSTRate";
import customDollarRound from "../../../sharedFunctions/purchasingFunctions/customDollarRound";

const RefundForm = ({ close, order }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [reason, setReason] = useState("");
  const [saving, setSaving] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [supplierCreditDate, setSupplierCreditDate] = useState(moment());
  const [isCreditMemo, setIsCreditMemo] = useState(true);
  const [isVendorCredit, setIsVendorCredit] = useState(false);
  const [valid, setValid] = useState(false);

  const refund = useRefund();

  useEffect(() => {
    const allValid = selectedProducts.every(
      (product) =>
        product.refundQuantity > 0 && product.refundQuantity <= product.quantity
    );

    if (!allValid) {
      setErrorMessage("Please enter a valid refund quantity for each product.");
    } else {
      setErrorMessage("");
    }

    setValid(allValid);
  }, [selectedProducts]);

  const subtotalRefund = useMemo(() => {
    const calculateSubtotalRefund = () => {
      return selectedProducts.reduce((total, product) => {
        let amount = customDollarRound(product.refundQuantity * product.price);
        if (product?.containerDeposit > 0) {
          amount += customDollarRound(
            product.refundQuantity * product.containerDeposit
          );
        }
        return total + amount;
      }, 0);
    };

    return calculateSubtotalRefund(selectedProducts);
  }, [selectedProducts]);

  const GSTRefund = useMemo(() => {
    if (!order?.facility?.hasGST) return 0;
    const calculateGSTRefund = () => {
      return selectedProducts.reduce((total, product) => {
        if (product.product._id === "brokerage" && order.brokerageFeeGST > 0)
          return total + order.brokerageFeeGST;

        let amount = customDollarRound(
          product.refundQuantity * product.price * GST
        );
        if (product?.containerDeposit > 0) {
          customDollarRound(
            (amount += product.refundQuantity * product.containerDeposit * GST)
          );
        }
        return total + amount;
      }, 0);
    };

    return calculateGSTRefund(selectedProducts);
  }, [selectedProducts, order]);

  const PSTRefund = useMemo(() => {
    const calculatePSTRefund = () => {
      return selectedProducts.reduce((total, product) => {
        if (!product.product.hasPST || product.product._id === "brokerage")
          return total;
        let amount = customDollarRound(
          product.refundQuantity *
            product.price *
            getPSTRate(order?.facility?.addressObject?.province)
        );
        if (product?.containerDeposit > 0) {
          amount += customDollarRound(
            product.refundQuantity *
              product.containerDeposit *
              getPSTRate(order?.facility?.addressObject?.province)
          );
        }
        return total + amount;
      }, 0);
    };

    return calculatePSTRefund(selectedProducts);
  }, [selectedProducts, order]);

  const totalRefund = useMemo(() => {
    const calculateTotalRefund = () => {
      return customDollarRound(subtotalRefund + GSTRefund + PSTRefund);
    };

    return calculateTotalRefund(selectedProducts);
  }, [selectedProducts, subtotalRefund, GSTRefund, PSTRefund]);

  const handleProductSelection = (product) => {
    const isProductSelected = selectedProducts.some(
      (p) => p.product._id === product.product._id
    );

    if (isProductSelected) {
      setSelectedProducts(
        selectedProducts.filter((p) => p.product._id !== product.product._id)
      );
    } else {
      setSelectedProducts([
        ...selectedProducts,
        { ...product, refundQuantity: product.quantity },
      ]);
    }
  };

  const handleShippingSelection = (item) => {
    if (item === "brokerage") {
      const isBrokerageSelected = selectedProducts.some(
        (p) => p.product._id === "brokerage"
      );

      if (isBrokerageSelected) {
        setSelectedProducts(
          selectedProducts.filter((p) => p.product._id !== "brokerage")
        );
      } else {
        setSelectedProducts([
          ...selectedProducts,
          {
            product: { _id: "brokerage" },
            price: order.brokerageFeePrice,
            quantity: 1,
            refundQuantity: 1,
          },
        ]);
      }
    } else if (item === "shipping") {
      const isShippingSelected = selectedProducts.some(
        (p) => p.product._id === "shipping"
      );

      if (isShippingSelected) {
        setSelectedProducts(
          selectedProducts.filter((p) => p.product._id !== "shipping")
        );
      } else {
        setSelectedProducts([
          ...selectedProducts,
          {
            product: { _id: "shipping" },
            price: order.shippingCost,
            quantity: 1,
            refundQuantity: 1,
          },
        ]);
      }
    }
  };

  const handleRefundQuantityChange = (product, quantity) => {
    const updatedSelectedProducts = selectedProducts.map((p) =>
      p.product._id === product.product._id
        ? { ...p, refundQuantity: quantity }
        : p
    );
    setSelectedProducts(updatedSelectedProducts);
  };

  const onRefundOrder = async () => {
    setSaving(true);

    const formData = new FormData();

    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }

    formData.append("orderID", order._id);
    formData.append(
      "refundProducts",
      JSON.stringify(
        selectedProducts.map((product) => ({
          _id: product.product._id,
          refundQuantity: product.refundQuantity,
        }))
      )
    );
    formData.append("reason", reason);
    formData.append("supplierCreditDate", supplierCreditDate);
    formData.append("isCreditMemo", isCreditMemo);
    formData.append("isVendorCredit", isVendorCredit);

    await refund.mutateAsync(formData);

    setSaving(false);
    close();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          Please select the items you would like to refund.
        </Typography>
      </Grid>
      {order?.productDetails?.map((product) => (
        <Grid container item key={product.product._id}>
          <Grid item xs={10}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox onChange={() => handleProductSelection(product)} />
                }
                label={
                  <>
                    <Typography>
                      {getProductOptionLabel(
                        product.product,
                        product.manufacturerProductDetail?.containerSize +
                          product.manufacturerProductDetail?.containerSizeUnit
                      )}
                    </Typography>
                    <Typography>
                      Quantity: {product.quantity}, Price: $
                      {product.price.toFixed(2)},{" "}
                      {product?.containerDeposit > 0 &&
                        `Deposit: $${product.containerDeposit.toFixed(2)}, `}
                      Total: ${product.total.toFixed(2)}
                    </Typography>
                  </>
                }
              />
            </FormGroup>
          </Grid>
          {product.quantity > 1 &&
            selectedProducts.find(
              (p) => p.product._id === product.product._id
            ) && (
              <Grid item xs={2}>
                <DecimalTextField
                  label={"Refund Quantity"}
                  value={
                    selectedProducts.find(
                      (p) => p.product._id === product.product._id
                    )?.refundQuantity
                  }
                  editMode={true}
                  onChange={(e) =>
                    handleRefundQuantityChange(product, e.target.value)
                  }
                  numberProps={{ min: 0, max: product.quantity }}
                  allowMinus={false}
                  allowDecimal={false}
                />
              </Grid>
            )}
        </Grid>
      ))}
      {order.shippingCost > 0 && (
        <Grid item xs={10}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => handleShippingSelection("shipping")}
                />
              }
              label={
                <>
                  <Typography>Shipping and Handling</Typography>
                  <Typography>
                    Total: ${order.shippingCost.toFixed(2)}
                  </Typography>
                </>
              }
            />
          </FormGroup>
        </Grid>
      )}
      {order.brokerageFeePrice > 0 && (
        <Grid item xs={10}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => handleShippingSelection("brokerage")}
                />
              }
              label={
                <>
                  <Typography>
                    Shipping and Handling - Customs Brokerage Fee
                  </Typography>
                  <Typography>
                    Total: ${order.brokerageFeePrice.toFixed(2)}
                  </Typography>
                </>
              }
            />
          </FormGroup>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography display="flex" justifyContent="flex-end" color="limegreen">
          Subtotal: ${subtotalRefund.toFixed(2)}
        </Typography>
        <Typography display="flex" justifyContent="flex-end" color="limegreen">
          PST: ${PSTRefund.toFixed(2)}
        </Typography>
        <Typography display="flex" justifyContent="flex-end" color="limegreen">
          GST: ${GSTRefund.toFixed(2)}
        </Typography>
        <Typography display="flex" justifyContent="flex-end" color="limegreen">
          Total: ${totalRefund.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          label="Reason for Refund"
          variant="outlined"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </Grid>
      <FormSwitch
        disabled={false}
        checked={isCreditMemo}
        onChange={(e) => setIsCreditMemo(e.target.checked)}
        label="Create Credit Memo"
        xs={6}
        md={4}
      />
      <FormSwitch
        disabled={false}
        checked={isVendorCredit}
        onChange={(e) => setIsVendorCredit(e.target.checked)}
        label="Create Vendor Credit"
        xs={6}
        md={4}
      />
      {isVendorCredit && (
        <Grid item xs={6} md={4}>
          <DatePicker
            disabled={false}
            label={"Supplier Credit Date"}
            onChange={(value) => {
              setSupplierCreditDate(value);
            }}
            value={supplierCreditDate}
          />
        </Grid>
      )}
      {isVendorCredit && (
        <>
          <Grid item xs={12}>
            <Typography>Attachments</Typography>
          </Grid>
          <Grid item xs={12}>
            <input
              onChange={(e) => {
                setFiles(e.target.files);
              }}
              type="file"
              accept="application/pdf"
              multiple
            />
          </Grid>
        </>
      )}
      {errorMessage && (
        <Grid item xs={12}>
          <Typography color="red">{errorMessage}</Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={onRefundOrder}
          fullWidth
          disabled={!selectedProducts.length || !reason || saving || !valid}
        >
          Confirm
        </Button>
      </Grid>
    </Grid>
  );
};

export default RefundForm;
