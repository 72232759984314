import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { CustomModal } from "../../sharedComponents";
import RefundForm from "./RefundForm";

const OrderRefund = ({ order }) => {
  const [orderRefundOpen, setOrderRefundOpen] = useState(false);

  const closeOrderRefund = () => {
    setOrderRefundOpen(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => setOrderRefundOpen(true)}
          fullWidth
        >
          Refund Order
        </Button>
      </Grid>

      <CustomModal
        open={orderRefundOpen}
        close={closeOrderRefund}
        title={"Order Refund"}
      >
        <RefundForm close={closeOrderRefund} order={order} />
      </CustomModal>
    </>
  );
};

export default OrderRefund;
