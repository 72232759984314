import { useMutation, useQueryClient } from "@tanstack/react-query";

import { addProductMaintenanceProcedure } from "../../api/maintenanceProcedureAPI";

const useAddProductMaintenanceProcedure = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addProductMaintenanceProcedure,
    onSuccess: () => {
      queryClient.invalidateQueries("maintenanceProcedure");
    },
  });
};

export default useAddProductMaintenanceProcedure;
