import React, { useRef, useEffect } from "react";
import { TextField } from "@mui/material";
import GoogleMapProvider from "../GoogleMapProvider";

const Container = (props) => (
  <GoogleMapProvider>
    <GooglePlacesAutocomplete {...props} />
  </GoogleMapProvider>
);

const GooglePlacesAutocomplete = ({
  setAddress,
  isThirdPartyAddress,
  googleIsLoaded,
}) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    if (!googleIsLoaded) return;
    if (!window.google?.maps?.places?.Autocomplete) return;

    const options = {
      componentRestrictions: { country: ["ca", "cn", "us", "uk"] },
      fields: ["address_components", "geometry"],
      types: ["address"],
    };

    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", async () => {
      const place = await autoCompleteRef.current.getPlace();
      let addressObj = getFullAddress(place);

      setAddress((prev) => {
        const tempAddress = { ...prev };
        if (isThirdPartyAddress) {
          tempAddress.thirdPartyAddress = {
            city: addressObj.city,
            country: addressObj.countryLong,
            province: addressObj.provinceShort,
            postalCode: addressObj.postalCode,
            streetAddress: addressObj.address,
          };
        } else {
          tempAddress["city"] = addressObj.city;
          tempAddress["country"] = addressObj.countryLong;
          tempAddress["province"] = addressObj.provinceShort;
          tempAddress["postalCode"] = addressObj.postalCode;
          tempAddress["streetAddress"] = addressObj.address;
        }
        return tempAddress;
      });
    });
  }, [setAddress, isThirdPartyAddress, googleIsLoaded]);

  const getFullAddress = (place) => {
    let address,
      city,
      provinceShort,
      provinceLong,
      countryShort,
      countryLong,
      postalCode = "";

    // Get each component of the address from the place details,
    for (const component of place.address_components) {
      const componentType = component.types[0];

      if (componentType === "street_number") {
        address = component.long_name;
      }
      if (componentType === "route") {
        address = address
          ? `${address} ${component.short_name}`
          : `${component.short_name}`;
      }
      if (componentType === "postal_town") {
        city = component.long_name;
      }
      if (componentType === "locality") {
        if (!city) city = component.long_name;
      }
      if (componentType === "sublocality_level_1") {
        if (!city) city = component.long_name;
      }
      if (componentType === "administrative_area_level_1") {
        provinceShort = component.short_name;
        provinceLong = component.long_name;
      }
      if (componentType === "postal_code") {
        postalCode = component.long_name;
      }
      if (componentType === "postal_code_suffix") {
        postalCode = `${postalCode}-${component.long_name}`;
      }
      if (componentType === "country") {
        countryShort = component.short_name;
        countryLong =
          component.long_name === "United States"
            ? "United States of America"
            : component.long_name;
      }
    }

    let resAddress = {
      address: address,
      city: city,
      provinceShort: provinceShort,
      provinceLong: provinceLong,
      postalCode: postalCode,
      countryShort: countryShort,
      countryLong: countryLong,
    };

    return resAddress;
  };

  return <TextField label="Find your location" inputRef={inputRef} fullWidth />;
};

export default Container;
