import { useMutation, useQueryClient } from "@tanstack/react-query";
import { requestNewServiceProvider } from "../../api/serviceOrderAPI";

const useServiceOrderRequestNewServiceProvider = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: requestNewServiceProvider,
    onSuccess: async () => {
      await queryClient.invalidateQueries("serviceOrders");
    },
  });
};

export default useServiceOrderRequestNewServiceProvider;
