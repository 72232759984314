import { getAvailableShippersForFacility } from "../../customHooks/facility/useAvailableShippersForFacility";
import { convertToCM, convertToKilogram } from "../unitConversions";

const createSquareGrid = (width, length) => {
  const grid = [];
  for (let i = 0; i < length; i++) {
    grid.push(Array(width).fill(0)); // Initialize with 0 to count stacked products
  }
  return grid;
};

const canPlaceSquareProduct = (grid, product, startX, startY) => {
  const productWidth = product.manufacturerProductDetail.width;
  const productLength = product.manufacturerProductDetail.length;
  const canStack = product.manufacturerProductDetail.canStack;
  const maxStack = canStack ? 2 : 1; // Maximum stack height

  if (
    startX < 0 ||
    startY < 0 ||
    startX + productWidth > grid[0].length ||
    startY + productLength > grid.length
  ) {
    return false;
  }

  for (let y = startY; y < startY + productLength; y++) {
    for (let x = startX; x < startX + productWidth; x++) {
      //check if there is a product already in the grid
      if (grid[y][x].product) {
        //check if the product can stack and there is room to stack
        if (
          canStack &&
          grid[y][x].product.manufacturerProductDetail.canStack &&
          product._id === grid[y][x].product._id &&
          grid[y][x].stackHeight < maxStack
        ) {
          return true;
        }
        return false;
      }
    }
  }

  return true;
};

const placeSquareProduct = (grid, product, startX, startY) => {
  const productWidth = product.manufacturerProductDetail.width;
  const productLength = product.manufacturerProductDetail.length;

  for (let y = startY; y < startY + productLength; y++) {
    for (let x = startX; x < startX + productWidth; x++) {
      grid[y][x] = {
        product: product,
        stackHeight: grid[y][x].stackHeight ? grid[y][x].stackHeight + 1 : 1,
      };
    }
  }
};

const checkIfAllSquareProductsFitOnPallet = (products, pallet) => {
  const grid = createSquareGrid(pallet.width, pallet.length);
  let canFit = true;

  //sort largest products first
  const productsSorted = [...products];

  productsSorted.sort(
    (a, b) =>
      b.manufacturerProductDetail.width * b.manufacturerProductDetail.length -
      a.manufacturerProductDetail.width * a.manufacturerProductDetail.length
  );

  for (let product of productsSorted) {
    for (let i = 0; i < product.quantity; i++) {
      let productPlaced = false;

      for (let y = 0; y < grid.length; y++) {
        for (let x = 0; x < grid[0].length; x++) {
          if (canPlaceSquareProduct(grid, product, x, y)) {
            placeSquareProduct(grid, product, x, y);
            productPlaced = true;
            break;
          }
        }
        if (productPlaced) break;
      }

      if (!productPlaced) {
        canFit = false;
        break;
      }
    }

    if (!canFit) break;
  }

  pallet.grid = grid;
  return canFit;
};

const checkToUseCircleConfiguration = (products) => {
  const allCircleProducts = products.every(
    (product) => product.manufacturerProductDetail.shape === "Circle"
  );
  if (!allCircleProducts) return false;

  const circleProductsQuantity = products.reduce((acc, product) => {
    return acc + parseInt(product.quantity);
  }, 0);

  if (circleProductsQuantity !== 5) return false;

  // Check if all circle products are the same width
  const circleProductsSameWidth = products.every(
    (product) =>
      product.manufacturerProductDetail.width ===
      products[0].manufacturerProductDetail.width
  );

  if (!circleProductsSameWidth) return false;

  return true;
};

const canPlaceCircleProducts = (products, pallet) => {
  const circleDiameter = products[0].manufacturerProductDetail.width;
  const circleRadius = circleDiameter / 2;
  const spaceBetweenTopRowProducts = pallet.width - circleDiameter * 2;
  const halfSpaceBetweenTopRowProducts = spaceBetweenTopRowProducts / 2;

  const possibleDistanceBetweenContainers = Math.sqrt(
    circleRadius ** 2 + halfSpaceBetweenTopRowProducts ** 2
  );

  if (possibleDistanceBetweenContainers >= circleRadius) {
    return true;
  }

  return false;
};

const selectPallet = (products, pallets, noPalletFound) => {
  pallets.sort((a, b) => a.length * a.width - b.length * b.width);

  for (let pallet of pallets) {
    if (checkToUseCircleConfiguration(products)) {
      if (canPlaceCircleProducts(products, pallet)) {
        return pallet;
      } else {
        continue;
      }
    }

    if (checkIfAllSquareProductsFitOnPallet(products, pallet)) {
      return pallet;
    }
  }

  return noPalletFound(pallets);
};

const getPalletDimensions = (selectedPalletDetails, palletWithProducts) => {
  let shippingInfoHeight = 0;
  let shippingInfoLength = selectedPalletDetails.length;
  let shippingInfoWidth = selectedPalletDetails.width;
  let shippingInfoWeight = selectedPalletDetails.weight;
  let palletHeight = selectedPalletDetails.height;

  palletWithProducts.products.forEach((product) => {
    const manufacturerProductDetail = product.manufacturerProductDetail;

    let calculatedHeight = manufacturerProductDetail.height + palletHeight;

    if (
      manufacturerProductDetail.canStack &&
      selectedPalletDetails.grid?.length > 0
    ) {
      if (
        selectedPalletDetails.grid.some((row) =>
          row.some(
            (cell) => cell.stackHeight > 1 && cell.product._id === product._id
          )
        )
      ) {
        //double the hight of the product
        calculatedHeight += manufacturerProductDetail.height;
      }
    }
    shippingInfoHeight = Math.max(shippingInfoHeight, calculatedHeight);
    shippingInfoWeight +=
      manufacturerProductDetail.weight * (product.quantity ?? 1);
  });

  return {
    ...palletWithProducts,
    shippingInfoHeight,
    shippingInfoLength,
    shippingInfoWidth,
    shippingInfoWeight,
  };
};

export const updatePalletDimensions = ({
  pallets,
  supplierPallets,
  shippingDimensionUnit,
  shippingWeightUnit,
}) => {
  //make sure all products have a manufacturerProductDetail and manufacturerProductDetail has same dimensionUnit and weightUnit
  const allProductsHaveManufacturerProductDetail = pallets.every((pallet) =>
    pallet.products.every(
      (product) =>
        product?.manufacturerProductDetail &&
        product.manufacturerProductDetail.dimensionUnit ===
          shippingDimensionUnit &&
        product.manufacturerProductDetail.weightUnit === shippingWeightUnit
    )
  );

  if (
    !pallets?.length ||
    !supplierPallets?.length ||
    !allProductsHaveManufacturerProductDetail
  )
    return;

  return pallets.map((pallet) => {
    const selectedPallet = selectPallet(
      pallet.products,
      supplierPallets,
      (pallets) => {
        alert(
          "Too many products to fit in any pallet. Please remove some products."
        );
        return pallets[pallets.length - 1];
      }
    );

    if (!selectedPallet) return pallet;

    return getPalletDimensions(selectedPallet, pallet);
  });
};

//----------------------------- shopping cart functions
const checkFreightShipment = (productsInCartByWarehouse) => {
  return productsInCartByWarehouse.map((productInCartByWarehouse) => {
    const hazardousProducts = productInCartByWarehouse.products.some(
      (product) => product.supplierProductDetail.product.hazardous
    );

    if (hazardousProducts)
      return { ...productInCartByWarehouse, freightShipment: true };

    //we will calculate the weight with the actual weight of the product or just 1
    const { length, width, height, weight } =
      productInCartByWarehouse.products.reduce(
        (acc, product) => {
          const dimensionUnit =
            product.supplierProductDetail.manufacturerProductDetail
              .dimensionUnit;
          return {
            length:
              acc.length +
              convertToCM(
                product.supplierProductDetail.manufacturerProductDetail
                  .length ?? 1,
                dimensionUnit ?? "cm"
              ) *
                product.quantity,
            width:
              acc.width +
              convertToCM(
                product.supplierProductDetail.manufacturerProductDetail.width ??
                  1,
                dimensionUnit ?? "cm"
              ) *
                product.quantity,
            height:
              acc.height +
              convertToCM(
                product.supplierProductDetail.manufacturerProductDetail
                  .height ?? 1,
                dimensionUnit ?? "cm"
              ) *
                product.quantity,
            weight:
              acc.weight +
              convertToKilogram(
                product.supplierProductDetail.manufacturerProductDetail
                  .weight ?? 1,
                product.supplierProductDetail.manufacturerProductDetail
                  .weightUnit ?? "kg"
              ) *
                product.quantity,
          };
        },
        { length: 0, width: 0, height: 0, weight: 0 }
      );

    //if any of these are true we know it will be freight( doesn't matter if we do not have all the weights )
    if (length > 274 || width > 274 || height > 274 || weight > 150) {
      return {
        ...productInCartByWarehouse,
        freightShipment: true,
      };
    }

    //if the wight is under freight weight but we do not have all the weights we cannot predict if it is freight or not
    if (
      !productInCartByWarehouse.products.every(
        (product) =>
          product.supplierProductDetail.manufacturerProductDetail &&
          product.supplierProductDetail.manufacturerProductDetail.weight &&
          product.supplierProductDetail.manufacturerProductDetail.weightUnit
      )
    )
      return { ...productInCartByWarehouse, freightShipment: undefined };

    return { ...productInCartByWarehouse, freightShipment: false };
  });
};

const checkToUseCircleConfigurationOnOrder = (
  productsToFit,
  pallets,
  selectedPallet
) => {
  //check if there are circle products
  const circleProducts = productsToFit.filter(
    (product) => product.manufacturerProductDetail.shape === "Circle"
  );
  if (!circleProducts.length) return { productsToFit, pallets };

  const circleProductsQuantity = circleProducts.reduce((acc, product) => {
    return acc + parseInt(product.quantity);
  }, 0);

  if (circleProductsQuantity < 5) return { productsToFit, pallets };

  // Select exactly 5 circle products based on their quantity
  let remainingNeeded = 5;
  circleProducts.sort((a, b) => b.quantity - a.quantity);
  const fiveCircleProducts = [];

  for (const product of circleProducts) {
    if (remainingNeeded <= 0) break;

    const quantityToTake = Math.min(remainingNeeded, product.quantity);
    for (let i = 0; i < quantityToTake; i++) {
      fiveCircleProducts.push({ ...product, quantity: 1 });
    }

    remainingNeeded -= quantityToTake;
  }

  if (
    !canPlaceCircleProducts(
      fiveCircleProducts,
      selectedPallet.manufacturerProductDetail
    )
  ) {
    return { productsToFit, pallets };
  }

  // Properly reduce quantity or remove these products from productsToFit
  const updatedProductsToFit = productsToFit.map((product) => {
    const foundProduct = fiveCircleProducts.find((p) => p._id === product._id);
    if (foundProduct) {
      return {
        ...product,
        quantity:
          product.quantity -
          fiveCircleProducts.filter(
            (p) =>
              p.supplierProductDetail._id === product.supplierProductDetail._id
          ).length,
      };
    }
    return product;
  });

  const pallet = {
    ...selectedPallet,
    products: fiveCircleProducts,
  };
  const palletWithDimensions = getPalletDimensions(
    selectedPallet.manufacturerProductDetail,
    pallet
  );
  pallets.push(palletWithDimensions);

  return {
    productsToFit: updatedProductsToFit.filter(
      (product) => product.quantity > 0
    ),
    pallets,
    palletAdded: true,
  };
};

const squareConfigurationOnOrder = (productsToFit, pallets, selectedPallet) => {
  const grid = createSquareGrid(
    selectedPallet.manufacturerProductDetail.width,
    selectedPallet.manufacturerProductDetail.length
  );

  const productsOnPallet = [];

  for (let i = 0; i < productsToFit.length; i++) {
    const product = productsToFit[i];
    const productQuantity = product.quantity;

    for (let j = 0; j < productQuantity; j++) {
      let productPlaced = false;

      for (let y = 0; y < grid.length; y++) {
        for (let x = 0; x < grid[0].length; x++) {
          if (canPlaceSquareProduct(grid, product, x, y)) {
            placeSquareProduct(grid, product, x, y);
            productsOnPallet.push({ ...product, quantity: 1 });
            productPlaced = true;
            product.quantity--;
            break;
          }
        }

        if (productPlaced) break;
      }

      if (!productPlaced) continue;
    }
  }

  const pallet = {
    ...selectedPallet,
    products: productsOnPallet,
  };

  const palletWithDimensions = getPalletDimensions(
    selectedPallet.manufacturerProductDetail,
    pallet
  );
  pallets.push(palletWithDimensions);

  productsToFit = productsToFit.filter((product) => product.quantity > 0);

  return { pallets, productsToFit };
};

//we need to build out the pallets for all the products in the cart
const createPalletsFromProducts = (products, supplierPallets) => {
  if (!supplierPallets?.length || !products?.length) return [];

  const sortedProducts = products.map((product) => ({
    ...product,
    manufacturerProductDetail:
      product.supplierProductDetail.manufacturerProductDetail,
  }));

  sortedProducts.sort(
    (a, b) =>
      b.supplierProductDetail.manufacturerProductDetail.width *
        b.supplierProductDetail.manufacturerProductDetail.length -
      a.supplierProductDetail.manufacturerProductDetail.width *
        a.supplierProductDetail.manufacturerProductDetail.length
  );

  let productsToFit = sortedProducts;

  let pallets = [];

  while (productsToFit.length > 0) {
    //get the pallet that fits all products or the largest pallet
    const selectedPallet = selectPallet(
      productsToFit,
      supplierPallets,
      (pallets) => pallets[pallets.length - 1]
    );

    //add circle configuration if possible
    let palletAdded = false;
    ({ pallets, productsToFit, palletAdded } =
      checkToUseCircleConfigurationOnOrder(
        productsToFit,
        pallets,
        selectedPallet
      ));

    if (palletAdded) continue;

    //add rest of products using square configuration
    ({ pallets, productsToFit } = squareConfigurationOnOrder(
      productsToFit,
      pallets,
      selectedPallet
    ));
  }

  return pallets;
};

const updatePalletsInProductInCartByWarehouse = (
  productInCartByWarehouse,
  pallets
) => {
  const existingPallets = productInCartByWarehouse.products.filter(
    (product) =>
      product.supplierProductDetail.product.containerType === "Pallet"
  );

  pallets.forEach((newPallet) => {
    //if pallet does not have a cost then we do not need to add to the cart
    if (!newPallet.cost) return;

    // Find existing pallet in the cart
    const existingPallet = existingPallets.find(
      (p) => p.supplierProductDetail._id === newPallet._id
    );

    const palletQuantity = pallets.filter(
      (p) =>
        p.manufacturerProductDetail._id ===
        newPallet.manufacturerProductDetail._id
    ).length;

    if (existingPallet) {
      // Update quantity of existing pallet if new quantity is greater
      existingPallet.quantity = palletQuantity;
    } else {
      // Add new pallet if it doesn't exist
      productInCartByWarehouse.products.push({
        closestWarehouse: productInCartByWarehouse.closestWarehouse,
        quantity: palletQuantity,
        supplierProductDetail: newPallet,
      });
    }
  });

  return {
    ...productInCartByWarehouse,
    pallets: pallets.map((pallet) => {
      const products = [];

      pallet.products.forEach((product) => {
        const index = products.findIndex(
          (p) => p.product === product.supplierProductDetail.product._id
        );

        if (index === -1) {
          products.push({
            product: product.supplierProductDetail.product._id,
            quantity: 1,
          });
        } else {
          products[index].quantity++;
        }
      });

      return {
        shippingInfoHeight: pallet.shippingInfoHeight,
        shippingInfoLength: pallet.shippingInfoLength,
        shippingInfoWidth: pallet.shippingInfoWidth,
        shippingInfoWeight: pallet.shippingInfoWeight,
        shippingInfoQuantity: 1,
        products,
      };
    }),
  };
};

export const updateProductsInCartWithFreightDetails = (
  productsInCartByWarehouse,
  supplierPallets,
  shipperCompanies,
  restrictedShippers,
  facilityAddress
) => {
  if (!productsInCartByWarehouse?.length) return [];
  const updatedProductsInCartByWarehouse = checkFreightShipment([
    ...productsInCartByWarehouse,
  ]);

  return updatedProductsInCartByWarehouse.map((warehouse) => {
    if (!warehouse.closestWarehouse) return warehouse;

    const availableShippers = getAvailableShippersForFacility(
      facilityAddress,
      shipperCompanies,
      restrictedShippers,
      warehouse.closestWarehouse.contact?.address,
      warehouse.freightShipment
    );

    if (availableShippers?.length === 1)
      warehouse.shipperCompany = availableShippers[0];

    if (warehouse.freightShipment) {
      //make sure all the products have a manufacturerProductDetail with width and length to build to pallets
      if (
        !warehouse.products.every(
          (product) =>
            product.supplierProductDetail.manufacturerProductDetail.width &&
            product.supplierProductDetail.manufacturerProductDetail.length
        )
      )
        return {
          ...warehouse,
          pallets: undefined,
          products: warehouse.products.filter((product) =>
            product.supplierProductDetail.product.containerType === "Pallet"
              ? product.supplierProductDetail.isReturn
                ? true
                : false
              : true
          ),
        };

      const pallets = createPalletsFromProducts(
        warehouse.products.filter(
          (product) =>
            product.supplierProductDetail.product.containerType !== "Pallet"
        ),
        supplierPallets.filter(
          (pallet) => pallet.supplier._id === warehouse.supplier._id
        )
      );

      //make sure all the pallets used are in the shopping cart
      if (pallets?.length)
        return updatePalletsInProductInCartByWarehouse(warehouse, pallets);
    }

    return warehouse;
  });
};
