import { useMutation } from "@tanstack/react-query";
import { requestServiceContractServiceDateChange } from "../../api/serviceContractOrderAPI";

const useRequestServiceContractServiceDateChange = () => {
  return useMutation({
    mutationFn: requestServiceContractServiceDateChange,
  });
};

export default useRequestServiceContractServiceDateChange;
