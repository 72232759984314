import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectProductsInCartByWarehouse,
  setProductsInCartByWarehouse,
  setOrderFacility,
  setOrderFacilityAddress,
} from "../../redux/reducers/productsSlice";
import { getContactOptionLabel } from "../../sharedFunctions/labels";
import AppError from "../ErrorBoundaries/AppError";
import useAddCartSubmission from "../../customHooks/cartSubmissions/useAddCartSubmission";
import useEmailSendRequestOrder from "../../customHooks/email/useEmailSendRequestOrder";

const RequestOrder = ({
  cartSubmission,
  close,
  closeShoppingCart,
  customerPONumber,
  notes,
}) => {
  const dispatch = useDispatch();
  const emailSendRequestOrder = useEmailSendRequestOrder();
  const addCartSubmission = useAddCartSubmission();
  const productsInCartByWarehouse = useSelector(
    selectProductsInCartByWarehouse
  );
  const [approvedContacts, setApprovedContacts] = useState([]);
  const [approvedContact, setApprovedContact] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  useEffect(() => {
    if (!cartSubmission.facility) return;
    let tempApprovedContacts = [
      ...cartSubmission.facility.managers,
      ...cartSubmission.facility.administrators,
      ...(cartSubmission.facility.leadOperators ?? []),
    ];
    tempApprovedContacts = tempApprovedContacts.filter(
      (contact) => contact.email
    );
    setApprovedContacts(tempApprovedContacts);
  }, [cartSubmission.facility]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const cartSubmissionAdded = await addCartSubmission.mutateAsync({
        date: cartSubmission.date,
        facilityID: cartSubmission.facility._id,
        productsInCartByWarehouse,
        requestedUser: approvedContact._id,
        requestingUser: cartSubmission.requestingUser,
      });

      await emailSendRequestOrder.mutateAsync({
        ...cartSubmissionAdded,
        customerPONumber,
        notes,
      });

      dispatch(setProductsInCartByWarehouse([]));
      dispatch(setOrderFacility(null));
      dispatch(setOrderFacilityAddress(null));

      setLoading(false);
      close();
      closeShoppingCart();
    } catch (error) {
      setLoading(false);
      close();
      setSubmitError(true);
      throw error;
    }
  };
  if (submitError)
    return (
      <AppError
        message="There was a problem submitting the Order Request."
        type="Request Order"
      />
    );
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          Please select an administrator or manager to complete the order.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Autocomplete
          options={approvedContacts}
          getOptionLabel={getContactOptionLabel}
          value={approvedContact}
          isOptionEqualToValue={(option, value) => {
            if (!value.length) return [];
            return option._id === value._id;
          }}
          onChange={(e, value) => setApprovedContact(value)}
          renderInput={(params) => (
            <TextField
              autoComplete="off"
              {...params}
              label="Approved Contact"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        {loading && <Typography>Requesting order please wait...</Typography>}
      </Grid>
      <Grid item xs={6} md={2}>
        <Button
          variant="contained"
          onClick={(event, reason) => close(event, reason, true)}
          fullWidth
          color="error"
        >
          Cancel
        </Button>
      </Grid>

      <Grid item xs={6} md={2}>
        <Button
          variant="contained"
          onClick={onSubmit}
          disabled={loading ? true : approvedContact?._id ? false : true}
          fullWidth
        >
          Request
        </Button>
      </Grid>
    </Grid>
  );
};

export default RequestOrder;
