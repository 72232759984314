import { createSlice } from "@reduxjs/toolkit";

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: [],
  reducers: {
    pushNotification(state, action) {
      if (
        action.payload.title === "Error" &&
        state.find((notification) => {
          return notification.message === action.payload.message;
        })
      )
        return;
      state.push({
        title: action.payload.title,
        message: action.payload.message,
        hasViewButton: action.payload.hasViewButton,
        open: true,
        severity: action.payload.severity,
      });
    },
    closeNotification: (state) => {
      state[0] = { open: false };
    },
    getNextNotification: (state) => {
      state.shift();
    },
  },
});

// Action creators are generated for each case reducer function
export const { closeNotification, pushNotification, getNextNotification } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;

export const checkForNotificationByMessage = (state, message) => {
  return !!state.notifications.find((notification) => {
    return notification.message === message;
  })?.length;
};
export const selectNotification = (state) => state.notifications;
