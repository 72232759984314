import { useMutation, useQueryClient } from "@tanstack/react-query";
import { serviceContractUpdateServiceDates } from "../../api/serviceContractOrderAPI";

const useServiceContractUpdateServiceDates = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: serviceContractUpdateServiceDates,
    onSuccess: () => {
      queryClient.invalidateQueries("serviceContracts");
    },
  });
};

export default useServiceContractUpdateServiceDates;
