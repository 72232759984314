import React, { useState } from "react";
import { Grid, Button, TextField } from "@mui/material";
import { CustomModal } from "../../sharedComponents";
import FormSelect from "../../sharedComponents/selectors/FormSelect";
import useReportOrder from "../../../customHooks/order/useReportOrder";

const ReportedOrder = ({ closeOrderDisplay, order, userID }) => {
  const reportOrder = useReportOrder();

  const [status, setStatus] = useState("Pending");
  const [reportedOrderOpen, setReportedOrderOpen] = useState(false);
  const [notes, setNotes] = useState("");

  return (
    <>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => setReportedOrderOpen(true)}
          fullWidth
        >
          Resolve Order
        </Button>
      </Grid>

      <CustomModal
        open={reportedOrderOpen}
        close={() => {
          setReportedOrderOpen(false);
        }}
        title={"Reported Order"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormSelect
              editMode={true}
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              menuItems={["Pending", "Delivered"]}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              label="Resolution"
              multiline
              fullWidth
              variant="outlined"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={!notes}
              onClick={() => {
                reportOrder.mutate({
                  orderID: order._id,
                  status,
                  reportedHistory: {
                    date: new Date(),
                    description: "Resolution: " + notes,
                    user: userID,
                  },
                });
                setReportedOrderOpen(false);
                closeOrderDisplay();
              }}
            >
              Resolve Order
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};

export default ReportedOrder;
