import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import AppError from "../ErrorBoundaries/AppError";
import { getContactOptionLabel } from "../../sharedFunctions/labels";
import useCompleteCartSubmission from "../../customHooks/cartSubmissions/useCompleteCartSubmission";
import {
  setProductsInCartByWarehouse,
  setOrderFacility,
  setOrderFacilityAddress,
} from "../../redux/reducers/productsSlice";
import { getFacilityRecipients } from "../../sharedFunctions/getFacilityRecipients";
import { pushNotification } from "../../redux/reducers/notificationsSlice";
import useConfirmOrder from "../../customHooks/productOrder/useConfirmOrder";

const ConfirmOrder = ({
  close,
  closeShoppingCart,
  cartSubmission,
  cartSubmissionToRemove,
  contactID,
  facilityContacts,
  productsInCartByWarehouse,
}) => {
  const dispatch = useDispatch();
  let [searchParams] = useSearchParams();
  const facilityID = searchParams.get("id");
  const completeCartSubmission = useCompleteCartSubmission(contactID);
  const [loading, setLoading] = useState(false);
  const [recipient, setRecipient] = useState("");
  const [submitError, setSubmitError] = useState(false);
  const confirmOrder = useConfirmOrder();
  const checkToCompleteCartSubmission = async () => {
    if (cartSubmissionToRemove?._id)
      completeCartSubmission.mutate(cartSubmissionToRemove);
  };

  const onSubmit = async () => {
    try {
      setLoading(true);

      const body = {
        customerPONumber: cartSubmission.customerPONumber,
        date: cartSubmission.date,
        facilityID,
        notes: cartSubmission.notes,
        recipient: recipient._id,
        productsInCartByWarehouse,
        requestedUser: cartSubmission.requestedUser,
        requestingUser: cartSubmission.requestingUser,
      };

      const response = await confirmOrder.mutateAsync({ body });

      if (response.data.addedToPendingOrder) {
        dispatch(
          pushNotification({
            title: "Order",
            message: `Added to pending order!`,
            hasViewButton: false,
            severity: "success",
          })
        );
      }

      checkToCompleteCartSubmission();
      dispatch(setProductsInCartByWarehouse([]));
      dispatch(setOrderFacility(null));
      dispatch(setOrderFacilityAddress(null));

      close();
      closeShoppingCart();
      setLoading(false);
    } catch (error) {
      close();
      setLoading(false);
      setSubmitError(true);
      throw error;
    }
  };

  if (submitError)
    return (
      <AppError
        message="There was a problem submitting the Order."
        type="Order"
      />
    );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loading && <Typography>Confirming order please wait...</Typography>}
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          options={getFacilityRecipients(facilityContacts)}
          getOptionLabel={getContactOptionLabel}
          value={recipient}
          isOptionEqualToValue={(option, value) => {
            if (!value.length) return [];
            return option._id === value._id;
          }}
          onChange={(e, value) => {
            setRecipient(value);
          }}
          renderInput={(params) => (
            <TextField
              autoComplete="off"
              {...params}
              required
              label="Order Recipient"
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={onSubmit}
          disabled={loading || !recipient}
          fullWidth
          color="success"
        >
          Confirm
        </Button>
      </Grid>
    </Grid>
  );
};

export default ConfirmOrder;
