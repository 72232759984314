import { Button } from "@mui/material";
import React, { useState } from "react";
import { CustomModal } from "../sharedComponents";
import ReportProblemForm from "./ReportProblemForm";

const ReportProblem = ({
  fullWidth = true,
  handleReportProblem,
  onClose,
  nonAppProblem,
  type,
}) => {
  const [reportProblemOpen, setReportProblemOpen] = useState(false);

  const closeReportProblem = async (event, reason, details) => {
    if (reason === "backdropClick") return;

    setReportProblemOpen(false);
    if (onClose && details) await onClose(event, reason, details);
    if (details && handleReportProblem) handleReportProblem(details);
  };

  return (
    <>
      <Button
        variant={"contained"}
        onClick={() => setReportProblemOpen(true)}
        fullWidth={fullWidth}
        color={"error"}
      >
        {nonAppProblem ? "Report Problem" : "Report App Problem"}
      </Button>

      <CustomModal
        open={reportProblemOpen}
        close={closeReportProblem}
        title={"What is going on?"}
      >
        <ReportProblemForm type={type} close={closeReportProblem} />
      </CustomModal>
    </>
  );
};

export default ReportProblem;
