import { useQuery } from "@tanstack/react-query";
import { getAllServices } from "../../api/serviceAPI";

const useServices = () => {
  const {
    data: services = [],
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["services"],
    queryFn: getAllServices,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    services,
    error,
    isError,
    isLoading,
  };
};

export default useServices;
