import { Box, Typography } from "@mui/material";
import React from "react";

const ProgressBar = (props) => {
  const { incomingAmount, value, lowPercentage } = props;
  const displayValue =
    value > 0 && value <= 100
      ? value
      : value > 100
      ? 100
      : value === Infinity
      ? 100
      : 0;
  const fillerRelativePercentage = (100 / displayValue) * 100;

  return (
    <Box data-testid="healthProgressbar">
      <Box className="progressBarContainer">
        <Box
          className="progressBarFiller"
          style={{ width: `${displayValue > 0 ? displayValue : 0}%` }}
        >
          <Box
            className="progressBarFillerBackground"
            style={{
              width: `${fillerRelativePercentage}%`,
              background:
                displayValue <= (lowPercentage ?? 20) ? "red" : "#1F2D60",
            }}
          />
        </Box>
        {displayValue === 0 && (
          <Box
            data-testid="incomingAmountProgressbar"
            className="progressBarFiller"
            style={{
              width: "100%",
            }}
          >
            <Box
              className="progressBarFillerBackground"
              style={{
                background:
                  "linear-gradient(0deg, transparent 50%, red 40%, red 60%, transparent 55%)",
              }}
            />
          </Box>
        )}
        {incomingAmount > 0 && (
          <Box
            data-testid="incomingAmountProgressbar"
            className="progressBarFiller"
            style={{
              width: `${incomingAmount > 0 ? incomingAmount : 0}%`,
              left: `${displayValue}%`,
            }}
          >
            <Box
              className="progressBarFillerBackground"
              style={{
                background:
                  "repeating-linear-gradient(135deg,transparent,transparent 5px,#CCC 5px,#CCC 8px),repeating-linear-gradient(45deg,transparent,transparent 5px,#CCC 5px,#CCC 8px),linear-gradient(to bottom,#1F2D60,#1F2D60)",
              }}
            />
          </Box>
        )}
      </Box>
      <Typography
        variant="h6"
        sx={{ color: displayValue === 0 ? "red" : "inherit" }}
      >
        {parseFloat(displayValue).toFixed(0)}%
      </Typography>
    </Box>
  );
};

export default ProgressBar;
