import axios from "axios";

export const getExchangeRates = async () => {
  return axios
    .get(`/api/exchange-rate/get-all`)
    .then((res) => res?.data?.exchangeRates || []);
};

export const getExchangeRateByCurrency = async ({ currency }) => {
  if (!currency) return;
  return axios
    .get(`/api/exchange-rate/get-by-currency?currency=${currency}`)
    .then((res) => res?.data?.exchangeRate || null);
};
