import axios from "axios";

export const quickBooksAddFileAttachmentToBill = async (formData) => {
  return axios.post("/api/quick-books/add-file-attachment-to-bill", formData);
};

export const quickBooksCreateCustomer = async ({ companyName, name }) => {
  return axios.post(
    "/api/quick-books/create-customer",
    {
      companyName,
      name,
    },
    { bypass: true }
  );
};

export const quickBooksCreateVendor = async ({
  billingEmail,
  companyName,
  contactID,
  currency,
  vendorName,
}) => {
  return axios.post(
    "/api/quick-books/create-vendor",
    {
      billingEmail,
      companyName,
      contactID,
      currency,
      vendorName,
    },
    { bypass: true }
  );
};

export const quickBooksUpdateCustomer = async ({ customer }) => {
  return axios.post(
    "/api/quick-books/update-customer",
    {
      customer,
    },
    { bypass: true }
  );
};

export const quickBooksUpdateVendor = async ({ vendor }) => {
  return axios.post(
    "/api/quick-books/update-vendor",
    {
      vendor,
    },
    { bypass: true }
  );
};
