import React from "react";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";

const CustomDatePicker = ({ disabled, label, onChange, value }) => {
  //if the date starts as null we will set the time to noon
  const onDateChange = (date) => {
    if (value) return onChange(date);

    const newDate = moment(date).set({
      hour: 12,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    onChange(newDate);
  };

  return (
    <DatePicker
      disabled={disabled}
      label={label}
      onChange={onDateChange}
      value={value}
    />
  );
};

export default CustomDatePicker;
