import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateServiceOrder } from "../../api/serviceOrderAPI";

const useUpdateServiceOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateServiceOrder,
    onSuccess: async () => {
      await queryClient.invalidateQueries("serviceOrders");
    },
  });
};

export default useUpdateServiceOrder;
