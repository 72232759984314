import React, { useState } from "react";
import { Grid, Button, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import CustomModal from "../../sharedComponents/modals/CustomModal";
import MaintenanceProcedureList from "./MaintenanceProcedureList";
import { selectAppUserLevel } from "../../../redux/reducers/userLevelsSlice";
import useMaintenanceProceduresByProduct from "../../../customHooks/maintenanceProcedure/useMaintenanceProceduresByProduct";
import StandardMaintenanceProcedureForm from "../StandardMaintenanceProcedureForm";

const ProductMaintenanceSection = ({ product }) => {
  const appUserLevel = useSelector(selectAppUserLevel);

  const {
    data: productMaintenanceProcedures,
    isLoading: isLoadingProductMaintenanceProcedures,
  } = useMaintenanceProceduresByProduct(product && product?._id);

  const [maintenanceProcedure, setMaintenanceProcedure] = useState([]);
  const [maintenanceProcedureOpen, setMaintenanceProcedureOpen] =
    useState(false);

  const closeMaintenanceProcedureForm = (event, reason) => {
    if (reason === "backdropClick") return;
    setMaintenanceProcedureOpen(false);
  };

  const openMaintenanceProcedureForm = (maintenanceProcedure) => {
    setMaintenanceProcedure(maintenanceProcedure);
    setMaintenanceProcedureOpen(true);
  };

  const renderMaintenanceProcedures = () => {
    return (
      <Grid container spacing={2}>
        {(appUserLevel?.includes("Super User") ||
          appUserLevel?.includes("Super Product User")) && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={() => openMaintenanceProcedureForm(null)}
              fullWidth
            >
              Add Procedure
            </Button>
          </Grid>
        )}

        {isLoadingProductMaintenanceProcedures ? (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        ) : (
          <MaintenanceProcedureList
            maintenanceProcedures={productMaintenanceProcedures}
            setMaintenanceProcedure={setMaintenanceProcedure}
            setModalOpen={setMaintenanceProcedureOpen}
          />
        )}
      </Grid>
    );
  };

  return (
    <>
      <CustomModal
        open={maintenanceProcedureOpen}
        close={closeMaintenanceProcedureForm}
        title="Procedure"
        hasEdit
        anyoneCanEdit={appUserLevel?.includes("Super User")}
        isNew={maintenanceProcedure ? false : true}
      >
        <StandardMaintenanceProcedureForm
          close={closeMaintenanceProcedureForm}
          maintenanceProcedure={maintenanceProcedure}
          product={product}
        />
      </CustomModal>
      <Grid item xs={12}>
        {renderMaintenanceProcedures()}
      </Grid>
    </>
  );
};
export default ProductMaintenanceSection;
