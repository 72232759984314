import { Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import NewUserRequest from "./NewUserRequest";

const NewUser = () => {
  const [requestType, setRequestType] = useState("");

  if (requestType)
    return (
      <NewUserRequest
        requestType={requestType}
        setRequestType={setRequestType}
      />
    );

  return (
    <Grid container spacing={2} className="center-screen">
      <Grid item xs={12}>
        <Typography variant="h3">Welcome to drOPs</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography> What do you do?</Typography>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setRequestType("Facility")}
        >
          Operate or own a facility
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setRequestType("Supplier")}
        >
          Supply products or services
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setRequestType("Shipper")}
        >
          Ship Products
        </Button>
      </Grid>
    </Grid>
  );
};

export default NewUser;
