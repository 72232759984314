import axios from "axios";

export const notificationGetAll = async () => {
  return axios
    .get(`/api/notification/get-all`)
    .then((res) => res.data.notifications);
};

export const notificationGetAllCount = async () => {
  return axios
    .get(`/api/notification/get-all-notification-count`)
    .then((res) => res.data.notificationCount);
};

export const notificationGetAllCompleted = async () => {
  return axios
    .get(`/api/notification/get-all-completed`)
    .then((res) => res.data.notifications);
};

export const notificationGetAllNotificationsBySupplier = async (supplierID) => {
  return axios
    .get(`/api/notification/get-all-by-supplier?supplierID=${supplierID}`)
    .then((res) => res.data.notifications);
};

export const notificationGetAllNotificationsByFacility = async (facilityID) => {
  return axios
    .get(`/api/notification/get-all-by-facility?facilityID=${facilityID}`)
    .then((res) => res.data.notifications);
};

export const notificationGetAllCompletedByFacility = async (facilityID) => {
  return axios
    .get(
      `/api/notification/get-all-completed-by-facility?facilityID=${facilityID}`
    )
    .then((res) => res.data.notifications);
};

export const notificationGetAllCompletedBySupplier = async (supplierID) => {
  return axios
    .get(
      `/api/notification/get-all-completed-by-supplier?supplierID=${supplierID}`
    )
    .then((res) => res.data.notifications);
};

export const notificationAdd = async ({
  belongsTo,
  creationUser,
  dateCreated,
  dateCompleted,
  facilityID,
  id,
  itemID,
  level,
  notificationContact,
  notificationText,
  notificationTitle,
  orderID,
  type,
  supplierID,
  stageNumber,
  systemTab,
}) => {
  return axios.post(
    "/api/notification/add",
    {
      belongsTo,
      creationUser,
      dateCreated,
      dateCompleted,
      facilityID,
      id,
      itemID,
      level,
      notificationContact,
      notificationText,
      notificationTitle,
      orderID,
      type,
      supplierID,
      stageNumber,
      systemTab,
    },
    { bypass: true }
  );
};

export const notificationCheckToAdd = async ({
  belongsTo,
  dateCompleted,
  dateCreated,
  facilityID,
  id,
  itemID,
  level,
  notificationText,
  notificationTitle,
  type,
  supplierID,
  stageNumber,
  systemTab,
}) => {
  return axios.post(
    "/api/notification/check-to-add",
    {
      belongsTo,
      dateCompleted,
      dateCreated,
      facilityID,
      id,
      itemID,
      level,
      notificationText,
      notificationTitle,
      type,
      supplierID,
      stageNumber,
      systemTab,
    },
    { bypass: true }
  );
};

export const notificationAddComment = async ({ notificationID, comment }) => {
  return axios.post("/api/notification/add-comment", {
    notificationID,
    comment,
  });
};

export const notificationUpdate = async (body) => {
  return axios.put("/api/notification/update", body);
};

export const notificationComplete = async ({ notes, notificationID }) => {
  return axios.patch(
    "/api/notification/complete",
    {
      notes,
      notificationID,
    },
    { bypass: true }
  );
};

export const notificationCompleteMultiple = async ({ notificationIDs }) => {
  return axios.patch(
    "/api/notification/complete-multiple",
    {
      notificationIDs,
    },
    { bypass: true }
  );
};

export const notificationCompleteNotificationsByItemID = async ({
  itemID,
  notes,
}) => {
  return axios.patch(
    "/api/notification/complete-notifications-by-itemID",
    {
      itemID,
      notes,
    },
    { bypass: true }
  );
};

export const notificationCompleteByItemIDType = async ({
  itemID,
  notes,
  type,
}) => {
  return axios.patch(
    "/api/notification/complete-by-itemID-type",
    {
      itemID,
      notes,
      type,
    },
    { bypass: true }
  );
};

export const notificationReOpenByItemIDType = async ({
  itemID,
  newBelongsTo,
  newSupplierID,
  newFacilityID,
  notificationText,
  type,
}) => {
  return axios.patch(
    "/api/notification/re-open-by-itemID-type",
    {
      itemID,
      newBelongsTo,
      newSupplierID,
      newFacilityID,
      notificationText,
      type,
    },
    { bypass: true }
  );
};
