import { useQuery } from "@tanstack/react-query";
import { getAllEquipmentNames } from "../../api/productAPI";

const useEquipmentNames = (enabled) => {
  const { data = [], isLoading } = useQuery({
    queryKey: ["productNames", { type: "equipment" }],
    queryFn: getAllEquipmentNames,
    enabled,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};

export default useEquipmentNames;
