import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import NavSupportButton from "./NavSupportButton";
import HistoryNav from "./HistoryNav";
import logo from "../../imgs/drOPsLogoNav.png";
import ShoppingCart from "../shoppingCart/ShoppingCart";
import { clearState } from "../../redux/localStorage";
import { store } from "../../redux/store";
import { resetFacility } from "../../redux/reducers/facilities/facilitiesSlice";
import { resetProducts } from "../../redux/reducers/productsSlice";
import {
  resetUserLevels,
  selectSuperUserUserAccessEmail,
  setSuperUserUserAccessEmail,
} from "../../redux/reducers/userLevelsSlice";
import { selectAppUserLevel } from "../../redux/reducers/userLevelsSlice";
import FacilityProducts from "../facilities/facilitySections/FacilityProducts";
import { selectUser } from "../../redux/reducers/userSlice";
// import DemoOnboard from "../onboarding/DemoOnboard";

const Nav = () => {
  const url = new URL(window.location.href);
  const [searchParams] = useSearchParams();
  let facilityID = null;
  if (url.pathname === "/facility" && url.searchParams.has("id")) {
    facilityID = searchParams.get("id");
  }
  const settings = ["Profile", "Settings", "Logout"];
  const { user, logout } = useAuth0();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const appUserLevel = useSelector(selectAppUserLevel);
  const superUserUserAccessEmail = useSelector(selectSuperUserUserAccessEmail);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const theme = useTheme();
  const contact = useSelector(selectUser);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  //TODO: extract all this redux logic into one function
  const handlePageChange = (page) => {
    if (page === "Logout") {
      dispatch(resetFacility());
      dispatch(resetProducts());
      dispatch(resetUserLevels());
      clearState({
        contacts: store.getState().contacts,
        facilities: store.getState().facilities,
        products: store.getState().products,
        supplierCompanies: store.getState().supplierCompanies,
        userLevels: store.getState().userLevels,
      });
      logout({ returnTo: `${window.location.origin}/logout` });
    }
    handleCloseUserMenu();
    handleCloseNavMenu();
    navigate(page.replace(/\s/g, "-").toLowerCase());
  };

  const renderProfileSettings = (menuItem) => {
    return (
      <>
        <Tooltip title="Open settings">
          <Button
            onClick={handleOpenUserMenu}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "left",
            }}
            id={"open-settings"}
          >
            {user && (
              <Avatar
                sx={{ mr: 2 }}
                alt={user.name}
                src={contact.profilePicture}
              />
            )}
            <Typography
              sx={{ display: { xs: "block", md: "none" } }}
              color={theme?.palette?.mode === "dark" ? "white" : "#1976d2"}
            >
              Profile
            </Typography>
            {menuItem && "Personal Settings"}
          </Button>
        </Tooltip>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting) => (
            <MenuItem
              key={setting}
              id={setting}
              onClick={() => {
                handlePageChange(setting);
              }}
            >
              <Typography textAlign="center">{setting}</Typography>
            </MenuItem>
          ))}
          {superUserUserAccessEmail && (
            <MenuItem
              key={"Remove Super User Access"}
              id={"Remove Super User Access"}
              onClick={() => {
                dispatch(setSuperUserUserAccessEmail(""));
                navigate("/");
              }}
            >
              <Typography textAlign="center">
                Remove Super User Access
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </>
    );
  };

  return (
    <AppBar position="fixed">
      {/* <DemoOnboard /> */}
      <Container maxWidth={false} disableGutters>
        <Toolbar disableGutters>
          <HistoryNav />
          <Box
            sx={{
              display: "flex",
              flexGrow: { xs: 1, md: 0 },
              marginRight: { xs: 0, md: 1 },
            }}
          >
            <Button onClick={() => navigate("/")}>
              <img src={logo} alt={"drOPs logo"} width={125} height={45} />
            </Button>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              minHeight: "inherit",
            }}
          >
            {(appUserLevel?.includes("Supplier") ||
              appUserLevel?.includes("Super User")) && (
              <MenuItem
                key={
                  appUserLevel?.includes("Super User") ? "Suppliers" : "Home"
                }
                selected={
                  location.pathname.includes("/suppliers") ||
                  (appUserLevel?.includes("suppliers") &&
                    location.pathname === "/")
                }
                onClick={() => handlePageChange("suppliers")}
              >
                {appUserLevel?.includes("Super User") ? "Suppliers" : "Home"}
              </MenuItem>
            )}
            {!appUserLevel?.includes("Shipper") && (
              <MenuItem
                key={"Facilities"}
                id={"facilities-nav-button"}
                selected={
                  location.pathname === "/facilities" ||
                  ((appUserLevel?.includes("Super User") ||
                    appUserLevel?.includes("Facility")) &&
                    location.pathname === "/") ||
                  location.pathname.substring(0, 9) === "/facility"
                }
                onClick={() => handlePageChange("Facilities")}
              >
                {"Facilities"}
              </MenuItem>
            )}
            {(appUserLevel?.includes("Super User") ||
              appUserLevel?.includes("Shipper")) && (
              <MenuItem
                key={"Shippers"}
                selected={
                  (appUserLevel?.includes("Shipper") &&
                    location.pathname === "/") ||
                  location.pathname.substring(0, 9) === "/shippers"
                }
                onClick={() => handlePageChange("Shippers")}
              >
                {appUserLevel?.includes("Super User") ? "Shippers" : "Home"}
              </MenuItem>
            )}
            <MenuItem
              key={"Orders"}
              id={"orders-nav-button"}
              selected={location.pathname === "/orders"}
              onClick={() => handlePageChange("Orders")}
            >
              {"Orders"}
            </MenuItem>

            {appUserLevel?.includes("Super User") && (
              <MenuItem
                key={"Map"}
                id={"map-nav-button"}
                selected={location.pathname === "/map"}
                onClick={() => handlePageChange("Map")}
              >
                {"Map"}
              </MenuItem>
            )}

            {(appUserLevel?.includes("Super User") ||
              appUserLevel?.includes("Facility")) && (
              <MenuItem
                key={"Reports"}
                selected={location.pathname.substring(0, 8) === "/reports"}
                onClick={() => handlePageChange("Reports")}
              >
                {"Reports"}
              </MenuItem>
            )}
            {/* {(appUserLevel?.includes("Super User") ||
              appUserLevel?.includes("Supplier")) && (
              <MenuItem
                key={"Shipments"}
                selected={location.pathname === "/shipments"}
                onClick={() => handlePageChange("Shipments")}
              >
                {"Shipments"}
              </MenuItem>
            )} */}
            {(appUserLevel?.includes("Super User") ||
              appUserLevel?.includes("Super Product User")) && (
              <MenuItem
                key={"Super User"}
                selected={location.pathname.includes(`/super-user`)}
                onClick={() => handlePageChange("Super User?tab=0")}
              >
                {"Super User"}
              </MenuItem>
            )}
          </Box>

          {facilityID && (
            <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
              <FacilityProducts facilityID={facilityID} />
            </Box>
          )}
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <NavSupportButton />
          </Box>
          <ShoppingCart />
          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {(appUserLevel?.includes("Supplier") ||
                appUserLevel?.includes("Super User")) && (
                <MenuItem
                  key={
                    appUserLevel?.includes("Super User") ? "Suppliers" : "Home"
                  }
                  selected={
                    location.pathname.includes("/suppliers") ||
                    (appUserLevel?.includes("Supplier") &&
                      location.pathname === "/")
                  }
                  sx={{
                    color:
                      theme?.palette?.mode === "dark" ? "white" : "#1976d2",
                    p: 2,
                  }}
                  onClick={() => handlePageChange("suppliers")}
                >
                  {appUserLevel?.includes("Super User") ? "Suppliers" : "Home"}
                </MenuItem>
              )}

              {!appUserLevel?.includes("Shipper") && (
                <MenuItem
                  key={"Facilities"}
                  selected={
                    location.pathname === "/facilities" ||
                    ((appUserLevel?.includes("Super User") ||
                      appUserLevel?.includes("Facility")) &&
                      location.pathname === "/") ||
                    location.pathname.substring(0, 9) === "/facility"
                  }
                  onClick={() => handlePageChange("Facilities")}
                  sx={{
                    color:
                      theme?.palette?.mode === "dark" ? "white" : "#1976d2",
                    p: 2,
                  }}
                >
                  Facilities
                </MenuItem>
              )}
              {(appUserLevel?.includes("Super User") ||
                appUserLevel?.includes("Shipper")) && (
                <MenuItem
                  key={"Shippers"}
                  selected={
                    (appUserLevel?.includes("Shipper") &&
                      location.pathname === "/") ||
                    location.pathname.substring(0, 9) === "/shippers"
                  }
                  onClick={() => handlePageChange("Shippers")}
                  sx={{
                    color:
                      theme?.palette?.mode === "dark" ? "white" : "#1976d2",
                    p: 2,
                  }}
                >
                  {appUserLevel?.includes("Super User") ? "Shippers" : "Home"}
                </MenuItem>
              )}
              <MenuItem
                key={"Orders"}
                selected={location.pathname === "/orders"}
                onClick={() => handlePageChange("Orders")}
                sx={{
                  color: theme?.palette?.mode === "dark" ? "white" : "#1976d2",
                  p: 2,
                }}
              >
                Orders
              </MenuItem>
              <MenuItem
                key={"Map"}
                selected={location.pathname === "/map"}
                onClick={() => handlePageChange("Map")}
                sx={{
                  color: theme?.palette?.mode === "dark" ? "white" : "#1976d2",
                  p: 2,
                }}
              >
                {"Map"}
              </MenuItem>
              {(appUserLevel?.includes("Super User") ||
                appUserLevel?.includes("Facility")) && (
                <MenuItem
                  key={"Reports"}
                  selected={location.pathname.substring(0, 8) === "/reports"}
                  onClick={() => handlePageChange("Reports")}
                  sx={{
                    color:
                      theme?.palette?.mode === "dark" ? "white" : "#1976d2",
                    p: 2,
                  }}
                >
                  {"Reports"}
                </MenuItem>
              )}
              {/* {(appUserLevel?.includes("Super User") ||
                appUserLevel?.includes("Supplier")) && (
                <MenuItem
                  key={"Shipments"}
                  selected={location.pathname === "/shipments"}
                  onClick={() => handlePageChange("Shipments")}
                  sx={{
                    color:
                      theme?.palette?.mode === "dark" ? "white" : "#1976d2",
                    p: 2,
                  }}
                >
                  {"Shipments"}
                </MenuItem>
              )} */}
              {appUserLevel?.includes("Super User") && (
                <MenuItem
                  key={"Super User"}
                  selected={location.pathname.includes(`/super-user`)}
                  onClick={() => handlePageChange("Super User?tab=0")}
                  sx={{
                    color:
                      theme?.palette?.mode === "dark" ? "white" : "#1976d2",
                    p: 2,
                  }}
                >
                  {"Super User"}
                </MenuItem>
              )}
              {facilityID && (
                <MenuItem style={{ display: "flex", justifyContent: "center" }}>
                  <FacilityProducts facilityID={facilityID} />
                </MenuItem>
              )}
              <MenuItem className="centered-container" sx={{ px: 0 }}>
                <NavSupportButton blue darkMode={theme?.palette?.mode} />
              </MenuItem>
              <Box sx={{ flexGrow: 0 }}>{renderProfileSettings()}</Box>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            {renderProfileSettings()}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Nav;
