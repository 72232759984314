import axios from "axios";

export const getAllServices = async () => {
  return axios.get("/api/service/get-all").then((res) => res?.data?.services);
};

export const getSupplierServiceDetails = async (supplierID) => {
  return axios
    .get("/api/service/get-supplier-service-details", {
      params: { supplierID },
    })
    .then((res) => res?.data?.supplierServiceDetails);
};

export const getServiceProvidersByType = async ({ serviceType }) => {
  return axios
    .get("/api/service/get-service-providers-by-type", {
      params: { serviceType },
    })
    .then((res) => res?.data?.serviceProviders);
};

export const addService = async (body) => {
  return axios.post("/api/service/add", body).then((res) => res?.data?.service);
};

export const addSupplierServiceDetails = async (body) => {
  return axios
    .post("/api/service/add-supplier-service-details", body)
    .then((res) => res?.data?.supplierServiceDetails);
};

export const updateService = async (body) => {
  return axios
    .put("/api/service/update", body)
    .then((res) => res?.data?.service);
};

export const updateSupplierServiceDetail = async (body) => {
  return axios
    .put("/api/service/update-supplier-service-detail", body)
    .then((res) => res?.data?.supplierServiceDetail);
};

export const removeSupplierServiceDetails = async (body) => {
  return axios
    .put("/api/service/remove-supplier-service-details", body)
    .then((res) => res?.data?.supplierServiceDetails);
};

export const addDefaultSupplierServiceDetails = async (supplierID) => {
  return axios.put("/api/service/add-default-supplier-service-details", {
    supplierID,
  });
};

export const supplierServiceDetailsBestForFacility = async ({
  facilityID,
  useTravelCost,
}) => {
  return axios
    .get("/api/supplier-service-detail/get-best-for-facility", {
      params: { facilityID, useTravelCost },
    })
    .then((res) => res?.data?.bestThreeServices);
};
