import axios from "axios";
import { superUserEmails } from "../globalConstants";

export const getShipperCompanies = async () => {
  return axios
    .get("/api/shipper-company/get-all")
    .then((res) =>
      res.data.shipperCompanies.sort((a, b) =>
        a.contact.name.localeCompare(b.contact.name)
      )
    );
};

export const getShipperCompaniesByContact = async ({ contact }) => {
  return axios
    .get(
      superUserEmails.includes(contact.email)
        ? "/api/shipper-company/get-all"
        : `/api/shipper-company/get-all-by-contactID?contactID=${contact._id}`
    )
    .then((res) => res.data.shipperCompanies);
};

export const getShipperCompany = async (id) => {
  return axios
    .get(`/api/shipper-company/get-by-id?id=${id}`)
    .then((res) => res.data.shipperCompany);
};

export const getThirdPartyShippers = async (id) => {
  return axios
    .get(`/api/shipper-company/get-third-party-shippers?id=${id}`)
    .then((res) => res.data.thirdPartyShippers ?? []);
};

export const addShipperCompany = async ({ contactID }) => {
  axios.post("/api/shipper-company/add", {
    contactID,
  });
};

export const addShipperHub = async ({ address, name, shipperCompanyID }) => {
  return axios.patch("/api/shipper-company/add-shipper-hub", {
    address,
    name,
    shipperCompanyID,
  });
};

export const updateShipperHub = async ({ shipperHub, shipperCompanyID }) => {
  return axios.patch("/api/shipper-company/update-shipper-hub", {
    shipperHub,
    shipperCompanyID,
  });
};

export const updateShipperCompanyEmployees = async ({
  administrators,
  managers,
  shipperCompanyID,
}) => {
  return axios.patch("/api/shipper-company/update-employees", {
    administrators,
    managers,
    shipperCompanyID,
  });
};

export const updateShipperCompanyUserRole = async ({
  contactID,
  shipperCompanyID,
  userRole,
}) => {
  return axios.patch("/api/shipper-company/update-user-role", {
    contactID,
    shipperCompanyID,
    userRole,
  });
};

export const updateThirdPartyShippers = async ({ id, thirdPartyShippers }) => {
  return axios.patch("/api/shipper-company/update-third-party-shippers", {
    id,
    thirdPartyShippers,
  });
};

export const updateShipperCompany = async ({ shipperCompany }) => {
  return axios.patch("/api/shipper-company/update", {
    shipperCompany,
  });
};

export const removeContactFromShipperCompany = async ({
  contact,
  parentCompanyContactID,
}) => {
  axios
    .patch("/api/shipper-company/remove-contact", {
      contact,
      parentCompanyContactID,
    })
    .then((res) => res.data.supplierCompany);
};
