import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateProductApproveProduct } from "../../api/productAPI";

const useUpdateProductApproveProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateProductApproveProduct,
    onSuccess: () => {
      queryClient.invalidateQueries("products");
    },
  });
};

export default useUpdateProductApproveProduct;
