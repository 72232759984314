import React from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import useProductsByManufacturer from "../../customHooks/products/useProductsByManufacturer";

const ContactProducts = (props) => {
  const { contact } = props;
  const { products, isLoading } = useProductsByManufacturer({
    contactID: contact._id,
  });

  if (isLoading)
    return (
      <Grid item xs={12}>
        <CircularProgress />
      </Grid>
    );

  if (!products || !products.length) return <></>;

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography>Products Manufactured:</Typography>
      </Grid>
      {products.map((product) => (
        <Grid item xs={12} key={product._id}>
          <Typography>{product.name}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default ContactProducts;
