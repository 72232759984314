import { Grid, Typography } from "@mui/material";
import React from "react";

const OrderDisplayBrokerageFee = ({
  brokerageFeeCost,
  brokerageFeePrice,
  brokerageFeeGST,
  orderView,
  useDrOPsShipping,
}) => {
  if (orderView === "facility" && brokerageFeePrice > 0) {
    return (
      <Grid item xs={12}>
        <Typography>Shipping and Handling - Customs Brokerage Fee</Typography>
        <Typography>{`Total: $${brokerageFeePrice.toFixed(2)}`}</Typography>
      </Grid>
    );
  }

  if (orderView === "supplier" && brokerageFeeCost > 0 && !useDrOPsShipping) {
    return (
      <Grid item xs={12}>
        <Typography>Shipping and Handling - Customs Brokerage Fee</Typography>
        <Typography>{`Total: $${brokerageFeeCost.toFixed(2)}`}</Typography>
      </Grid>
    );
  }

  if (orderView === "shipper" && brokerageFeeCost > 0) {
    return (
      <>
        <Grid item xs={12}>
          <Typography>Shipping and Handling - Customs Brokerage Fee</Typography>
          <Typography>{`Total: $${brokerageFeeCost.toFixed(2)}`}</Typography>
        </Grid>

        {brokerageFeeGST > 0 && (
          <Grid item xs={12}>
            <Typography>
              Shipping and Handling - Customs Brokerage Fee GST
            </Typography>
            <Typography>{`Total: $${brokerageFeeGST.toFixed(2)}`}</Typography>
          </Grid>
        )}
      </>
    );
  }
};

export default OrderDisplayBrokerageFee;
