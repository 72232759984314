import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { CustomModal } from "../sharedComponents";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

import useUpdateOrderPickUpAvailableDate from "../../customHooks/productOrder/useUpdateOrderPickUpAvailableDate";

const UpdatePickUpAvailableDate = ({ close, order }) => {
  const updateOrderPickUpAvailableDate = useUpdateOrderPickUpAvailableDate();

  const [pickUpAvailableDate, setPickUpAvailableDate] = useState(
    moment(order.pickUpAvailableDate)
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const onClose = (event, reason, order) => {
    if (reason === "backdropClick") return;
    setModalOpen(false);
    if (order) close(null, null, order);
  };

  const onSubmit = async () => {
    setSaving(true);
    await updateOrderPickUpAvailableDate.mutateAsync({
      pickUpAvailableDate: pickUpAvailableDate.toISOString(),
      orderID: order._id,
    });

    onClose(null, null, order);
    setSaving(false);
  };

  return (
    <>
      <CustomModal open={modalOpen} close={onClose} title="Update Pick Up Date">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DatePicker
              label="Pick Up Date"
              value={pickUpAvailableDate}
              onChange={setPickUpAvailableDate}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={onSubmit}
              fullWidth
              disabled={saving}
            >
              {saving ? "Updating Pick Up Date" : "Update Pick Up Date"}
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
      <Button variant="contained" onClick={() => setModalOpen(true)}>
        Update Pick Up Date
      </Button>
    </>
  );
};

export default UpdatePickUpAvailableDate;
