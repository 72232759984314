import React, { useState } from "react";
import { Box, Button, Grid, IconButton, debounce } from "@mui/material";
import { CustomModal, FormSwitch } from "../../sharedComponents";
import {
  checkFacilityUserLevelLogger,
  checkFacilityUserLevelViewer,
} from "../../../sharedFunctions/userLevels";
import { getProductOptionLabel } from "../../../sharedFunctions/labels";
import { ProductView } from "../../products";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import CustomToolbar from "../../sharedComponents/tables/CustomToolbar";
import useProductFilter from "../../suppliers/productTables/useProductFilter";
import useFacilityProducts from "../../../customHooks/facility/useFacilityProducts";
import { selectFacilityUserLevel } from "../../../redux/reducers/userLevelsSlice";
import { Star, StarBorder } from "@mui/icons-material";
import useUpdateFacilityFavouriteProducts from "../../../customHooks/facility/useUpdateFacilityFavouriteProducts";
import { useQueryClient } from "@tanstack/react-query";

const FacilityProducts = ({ facilityID }) => {
  const queryClient = useQueryClient();

  const facilityUserLevel = useSelector(selectFacilityUserLevel);
  const [viewCatalogue, setViewCatalogue] = useState(false);

  const { facilityProducts, isLoading } = useFacilityProducts({
    facilityID,
    viewCatalogue,
  });
  const { onFilterModelChange, subTypeOptions, typeOptions } =
    useProductFilter();

  const [productOpen, setProductOpen] = useState(false);
  const [productsOpen, setProductsOpen] = useState(false);
  const [product, setProduct] = useState(null);

  const updateFacilityFavouriteProducts = useUpdateFacilityFavouriteProducts();

  const openProducts = () => {
    setProductsOpen(true);
  };

  const closeProducts = (event, reason) => {
    if (reason === "backdropClick") return;
    setProductsOpen(false);
  };

  const openProduct = (product) => {
    setProduct(product);
    setProductOpen(true);
  };

  const closeProduct = (event, reason) => {
    if (reason !== "backdropClick") setProductOpen(false);
  };

  const debouncedUpdate = debounce((productID) => {
    updateFacilityFavouriteProducts.mutate({
      facilityID,
      productID,
    });
  }, 3000); // 3000 ms delay

  const toggleFavourite = (productID) => {
    queryClient.setQueryData(
      ["facilityProducts", facilityID, viewCatalogue],
      (oldData) => {
        const updatedProducts = oldData.products.map((product) =>
          product._id === productID
            ? { ...product, isFavourite: !product.isFavourite }
            : product
        );

        return { ...oldData, products: updatedProducts };
      }
    );

    debouncedUpdate(productID);
  };

  const openButton = (product) => {
    return (
      <Button
        variant="contained"
        onClick={() => openProduct(product)}
        fullWidth
        disabled={!checkFacilityUserLevelLogger(facilityUserLevel)}
      >
        View Pricing
      </Button>
    );
  };
  const filterOperators = getGridSingleSelectOperators().filter(({ value }) =>
    ["isAnyOf"].includes(value)
  );

  let columns = [
    {
      field: "View",
      width: 110,
      renderCell: (params) =>
        params.row.type !== "Container" ? openButton(params.row) : <></>,
      filterable: false,
    },
  ];

  if (!viewCatalogue)
    columns.push({
      field: "isFavourite",
      headerName: "",
      width: 50,
      renderCell: (params) => (
        <IconButton
          sx={{ color: "yellow" }}
          onClick={() => toggleFavourite(params.row._id)}
        >
          {params.row.isFavourite ? <Star /> : <StarBorder />}
        </IconButton>
      ),

      filterable: false,
    });

  columns.push(
    {
      field: "name",
      width: 500,
      headerName: "Name",
      valueGetter: (value, row) => getProductOptionLabel(row),
    },
    {
      field: "type",
      headerName: "Type",
      type: "singleSelect",
      filterOperators,
      valueOptions: typeOptions,
      width: 130,
    },
    {
      field: "subType",
      headerName: "Sub Type",
      type: "singleSelect",
      filterOperators,
      valueOptions: subTypeOptions,
      valueGetter: (value, row) =>
        value ??
        row.chemicalType ??
        row.filterType ??
        row.equipmentType ??
        row.membraneType,

      width: 130,
    },
    {
      field: "system",
      headerName: "System",
      width: 200,
    },
    {
      field: "equipment",
      headerName: "Equipment",
      width: 200,
    },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      width: 130,
    }
  );

  const TableSwitch = () => {
    return (
      <Grid item xs={12} md={4} container spacing={2}>
        <FormSwitch
          label="All Products"
          checked={viewCatalogue}
          onChange={() => setViewCatalogue(!viewCatalogue)}
          xs={6}
        />
      </Grid>
    );
  };

  const renderProducts = () => {
    return (
      <Box
        sx={{
          height: window.innerHeight - 175,
          width: "100%",
        }}
      >
        <DataGrid
          getRowId={(row) => row._id}
          rows={facilityProducts ?? []}
          columns={columns}
          rowsPerPageOptions={[12, 25, 50, 100]}
          disableRowSelectionOnClick
          localeText={{
            noRowsLabel: "No Products Found",
          }}
          slots={{
            toolbar: () => CustomToolbar({ Buttons: TableSwitch }),
          }}
          loading={isLoading}
          onFilterModelChange={onFilterModelChange}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: "orderNumber",
                  sort: "desc",
                },
              ],
            },
          }}
        />
      </Box>
    );
  };

  return (
    <>
      <CustomModal
        open={productOpen}
        title={getProductOptionLabel(product)}
        close={closeProduct}
        isNew={product ? false : true}
      >
        <ProductView
          product={product}
          close={closeProduct}
          defaultAmount={product?.defaultAmount ?? 1}
        />
      </CustomModal>

      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={openProducts}
          fullWidth
          disabled={!checkFacilityUserLevelViewer(facilityUserLevel)}
        >
          View Products
        </Button>
      </Grid>

      <CustomModal
        open={productsOpen}
        title={"Products"}
        close={closeProducts}
        maxWidth="xl"
      >
        <Grid item xs={12}>
          {renderProducts()}
        </Grid>
      </CustomModal>
    </>
  );
};

export default FacilityProducts;
