import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import { CustomModal } from "../sharedComponents";
import useRemoveContactFromCompany from "../../customHooks/supplierCompany/useRemoveContactFromCompany";
import { getContactOptionLabel } from "../../sharedFunctions/labels";

const RemoveContactFromCompany = ({
  closeForm,
  contact,
  parentCompanies,
  type,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [parentCompany, setParentCompany] = useState(null);
  const removeContactFromCompanyMutation = useRemoveContactFromCompany({
    type: type,
  });

  const onClose = async (e, r, removed) => {
    if (r === "backdropClick") return;

    if (removed) {
      const body = {
        contact: {
          _id: contact._id,
        },
        parentCompanyContactID: parentCompany._id,
      };

      body.contact.isSupplier = contact.isSupplier;
      body.contact.isWarehouse = contact.isWarehouse;

      await removeContactFromCompanyMutation.mutateAsync(body);
      closeForm();
    }

    setModalOpen(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => setModalOpen(true)}
          color="error"
        >
          Remove Contact From Company
        </Button>
      </Grid>
      <CustomModal
        open={modalOpen}
        close={onClose}
        title="Choose the parent company to remove the contact from"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              options={parentCompanies}
              value={parentCompany}
              onChange={(e, value) => {
                setParentCompany(value);
              }}
              getOptionLabel={getContactOptionLabel}
              isOptionEqualToValue={(option, value) => {
                if (!value) return [];
                return option._id === value._id;
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Parent Company"
                  autoComplete="false"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              color="error"
              onClick={() => onClose(null, null, true)}
              disabled={!parentCompany}
            >
              Remove
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </>
  );
};
export default RemoveContactFromCompany;
