import { useMutation, useQueryClient } from "@tanstack/react-query";
import { confirmShipping } from "../../api/productOrderAPI";

const useConfirmShipping = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: confirmShipping,
    onSuccess: () => {
      queryClient.invalidateQueries("orders");
      queryClient.invalidateQueries("order");
    },
  });
};

export default useConfirmShipping;
