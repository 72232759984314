import React, { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { CustomModal, FormSwitch } from "../sharedComponents";
import ShippingConfirmation from "./ShippingConfirmation";
import useCancelProductOrder from "../../customHooks/productOrder/useCancelProductOrder";
import DropsShippingOrderConfirmation from "./DropsShippingOrderConfirmation";
import { useSelector } from "react-redux";
import { selectAppUserLevel } from "../../redux/reducers/userLevelsSlice";

const SupplierOrderConfirmation = ({ close, order }) => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const cancelProductOrder = useCancelProductOrder();

  const [cancelOrderOpen, setCancelOrderOpen] = useState(false);
  const [cancelationReason, setCancelationReason] = useState("");
  const [confirmOrder, setConfirmOrder] = useState(false);

  const [useDrOPsShipping, setUseDrOPsShipping] = useState(
    order.shippingIncluded ? false : order.supplier.useDrOPsShipping ?? true
  );
  const [saving, setSaving] = useState(false);

  const closeConfirmModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setConfirmOrder(false);
  };
  const closeCancelModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setCancelOrderOpen(false);
  };

  const submitCancelOrder = async () => {
    try {
      setSaving(true);
      await cancelProductOrder.mutateAsync({
        order: { _id: order._id, cancelationReason },
      });
      setCancelOrderOpen(false);
      close();
      setSaving(false);
    } catch (error) {
      throw error;
    }
  };

  const renderConfirmOrder = () => {
    return (
      <Grid container spacing={2}>
        {!order.pickUp && (
          <FormSwitch
            checked={useDrOPsShipping}
            label="Use drOPs Shipping"
            onChange={(e) => setUseDrOPsShipping(e.target.checked)}
            xs={12}
          />
        )}
        {order.pickUp || useDrOPsShipping ? (
          <DropsShippingOrderConfirmation
            appUserLevel={appUserLevel}
            order={order}
            close={close}
            setConfirmOrder={setConfirmOrder}
          />
        ) : (
          <ShippingConfirmation
            close={close}
            order={order}
            supplierConfirmingShipping={true}
          />
        )}
      </Grid>
    );
  };

  const renderCancelOrder = () => {
    return (
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <TextField
            required
            autoComplete="off"
            fullWidth
            variant="outlined"
            label="Cancelation Reason"
            value={cancelationReason}
            onChange={(e) => {
              setCancelationReason(e.target.value);
            }}
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={submitCancelOrder}
            disabled={!cancelationReason || saving}
            color="error"
          >
            {saving ? "Cancelling Order" : "Cancel Oder"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setConfirmOrder(true)}
          >
            Confirm
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color="error"
            variant="contained"
            fullWidth
            onClick={() => setCancelOrderOpen(true)}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>

      <CustomModal
        open={confirmOrder}
        close={closeConfirmModal}
        title={"Confirm Order"}
      >
        {renderConfirmOrder()}
      </CustomModal>
      <CustomModal
        open={cancelOrderOpen}
        close={closeCancelModal}
        title={"Cancel Order"}
      >
        {renderCancelOrder()}
      </CustomModal>
    </>
  );
};

export default SupplierOrderConfirmation;
