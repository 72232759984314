import React, { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  CardHeader,
  CardContent,
  Card,
  Grid,
  Typography,
  TextField,
  Box,
} from "@mui/material/";
import useEmailSendNewUserRequest from "../../customHooks/email/useEmailSendNewUserRequest";

const NewUserRequest = ({ requestType, setRequestType }) => {
  const { user } = useAuth0();
  const emailSendNewUserRequest = useEmailSendNewUserRequest();
  const [name, setName] = useState("");
  const [requested, setRequested] = useState(null);
  const [requestingNewUser, setRequestingNewUser] = useState(false);

  const requestNewUserEmail = async (e) => {
    setRequestingNewUser(true);
    await emailSendNewUserRequest.mutateAsync({
      userEmail: user.email,
      name,
      requestType,
    });
    setRequested(true);
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{ height: "90vh" }}
      className="vertical-centered-container"
    >
      {requested ? (
        <Grid item xs={12}>
          <Typography variant="h3">
            Thank you your request has been made
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Card sx={{ mt: 2 }}>
            <CardHeader
              title={
                <Box display="flex" alignItems="center">
                  <Box flex={1} /> {/* Left spacer */}
                  <Typography variant="h5" sx={{ textAlign: "center" }}>
                    {"Please Request a " + requestType}
                  </Typography>
                  <Box flex={1} display="flex" justifyContent="flex-end">
                    {/* Right spacer */}
                    {setRequestType && (
                      <Button
                        variant="contained"
                        onClick={() => setRequestType(null)}
                      >
                        Go Back
                      </Button>
                    )}
                  </Box>
                </Box>
              }
            />

            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="off"
                      required
                      id="from_name"
                      label="Username"
                      name="from_name"
                      value={user.email}
                      sx={{ my: 1 }}
                      variant="outlined"
                      onChange={(e) => {
                        return;
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="off"
                      required
                      id="name"
                      label={requestType + " Name"}
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      sx={{ my: 1 }}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={!user?.email || !name || requestingNewUser}
                      sx={{ my: 1 }}
                      fullWidth
                      onClick={requestNewUserEmail}
                    >
                      {requestingNewUser ? "Requesting" : "Request"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export default NewUserRequest;
