import axios from "axios";
import { superUserEmails } from "../globalConstants";

export const getReports = async ({ contact }) => {
  if (!contact) return [];
  return axios
    .get(
      superUserEmails.includes(contact.email)
        ? `/api/reports/get-all`
        : `/api/facility/get-all-by-contact?id=${contact._id}`
    )
    .then((res) => res?.data?.reports || []);
};

export const getReportsByFacility = async ({ facilityID }) => {
  return axios
    .get(`/api/reports/get-by-facility?facilityID=${facilityID}`)
    .then((res) => res?.data?.reports || []);
};

export const getReportsByContact = async ({ contactID }) => {
  if (!contactID) return [];

  return axios
    .get(`/api/facility/get-all-by-contact?id=${contactID}`)
    .then((res) => res?.data?.reports || []);
};

export const getWSARG131PDF = async ({ reportID }) => {
  return axios
    .get(`/api/reports/get-wsa-rg131-pdf?reportID=${reportID}`, {
      responseType: "blob", // Set the response type to 'blob'
    })
    .then((res) => res.data);
};

export const getWSARG137PDF = async ({ reportID }) => {
  return axios
    .get(`/api/reports/get-wsa-rg137-pdf?reportID=${reportID}`, {
      responseType: "blob", // Set the response type to 'blob'
    })
    .then((res) => res.data);
};

export const getWSARG154PDF = async ({ reportID }) => {
  return axios
    .get(`/api/reports/get-wsa-rg154-pdf?reportID=${reportID}`, {
      responseType: "blob", // Set the response type to 'blob'
    })
    .then((res) => res.data);
};

export const addReport = async ({ facilityID, body }) => {
  return axios.post(`/api/reports/add`, { facilityID, body });
};

export const updateReport = async ({ facilityID, body }) => {
  return axios.put(`/api/reports/update`, { facilityID, body });
};

export const updateReportWellLevel = async ({
  facilityID,
  body,
  wellLevel,
}) => {
  return axios.put(`/api/reports/update-well-level`, {
    facilityID,
    body,
    wellLevel,
  });
};

export const uploadReport = async (formData) => {
  return axios.put("/api/reports/upload-report", formData);
};
