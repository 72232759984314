import axios from "axios";

export const getAllCartSubmissionsByRequestedUser = async (id) => {
  return axios
    .get(`/api/cart-submission/get-all-by-requested-user?id=${id}`)
    .then((res) => res.data.cartSubmissions);
};

export const getAllCartSubmissionsByFacility = async (id) => {
  return axios
    .get(`/api/cart-submission/get-all-by-facility?id=${id}`)
    .then((res) => res.data.cartSubmissions);
};

export const addCartSubmission = async (cartSubmission) => {
  return axios.post("/api/cart-submission/add", cartSubmission).then((res) => {
    return res.data.cartSubmission;
  });
};

export const cancelCartSubmission = async (cartSubmission) => {
  return axios
    .patch("/api/cart-submission/cancel", cartSubmission)
    .then((res) => {
      return res.data.cartSubmission;
    });
};

export const completeCartSubmission = async (cartSubmission) => {
  return axios
    .patch("/api/cart-submission/complete", cartSubmission)
    .then((res) => {
      return res.data.cartSubmission;
    });
};
