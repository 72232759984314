import { useMutation } from "@tanstack/react-query";
import {
  agendaServiceContract,
  agendaProductSupplierValidTill,
  agendaLogReminder,
  agendaUFPerformCleaning,
  agendaUpdateOrderStatus,
  agendaOrderDelivered,
  agendaCheckIfInvoicePaid,
  agendaSendInvoiceEmail,
  agendaCancelOrderDelivered,
  agendaCancelProductPriceValidNotification,
  agendaCancelServiceContractExpiringNotification,
  agendaCancelMaintenanceNotification,
  agendaCancelLogReminder,
  agendaCancelUFPerformCleaning,
  agendaSendServiceContractInvoiceEmail,
  agendaServiceDate,
  agendaCompleteSurvey,
  agendaCancelServiceContractAutoRenew,
  agendaServiceContractAutoRenew,
  agendaConfirmShippingDetails,
  agendaCompleteServiceDates,
  agendaCancelConfirmShippingDetails,
} from "../api/agendaAPI";

const useAgendas = () => {
  const agendaServiceContractMutation = useMutation({
    mutationFn: agendaServiceContract,
  });
  const agendaProductSupplierValidTillMutation = useMutation({
    mutationFn: agendaProductSupplierValidTill,
  });
  const agendaCancelLogReminderMutation = useMutation({
    mutationFn: agendaCancelLogReminder,
  });
  const agendaCancelProductPriceValidNotificationMutation = useMutation({
    mutationFn: agendaCancelProductPriceValidNotification,
  });
  const agendaLogReminderMutation = useMutation({
    mutationFn: agendaLogReminder,
  });
  const agendaServiceContractAutoRenewMutation = useMutation({
    mutationFn: agendaServiceContractAutoRenew,
  });
  const agendaCancelServiceContractAutoRenewMutation = useMutation({
    mutationFn: agendaCancelServiceContractAutoRenew,
  });
  const agendaUFPerformCleaningMutation = useMutation({
    mutationFn: agendaUFPerformCleaning,
  });
  const agendaCancelUFPerformCleaningMutation = useMutation({
    mutationFn: agendaCancelUFPerformCleaning,
  });
  const agendaCheckIfInvoicePaidMutation = useMutation({
    mutationFn: agendaCheckIfInvoicePaid,
  });
  const agendaSendInvoiceEmailMutation = useMutation({
    mutationFn: agendaSendInvoiceEmail,
  });
  const agendaSendServiceContractInvoiceEmailMutation = useMutation({
    mutationFn: agendaSendServiceContractInvoiceEmail,
  });
  const agendaCancelOrderDeliveredMutation = useMutation({
    mutationFn: agendaCancelOrderDelivered,
  });
  const agendaCancelServiceContractExpiringNotificationMutation = useMutation({
    mutationFn: agendaCancelServiceContractExpiringNotification,
  });
  const agendaCancelMaintenanceNotificationMutation = useMutation({
    mutationFn: agendaCancelMaintenanceNotification,
  });
  const agendaUpdateOrderStatusMutation = useMutation({
    mutationFn: agendaUpdateOrderStatus,
  });
  const agendaOrderDeliveredMutation = useMutation({
    mutationFn: agendaOrderDelivered,
  });

  const agendaServiceDateMutation = useMutation({
    mutationFn: agendaServiceDate,
  });

  const agendaCompleteSurveyMutation = useMutation({
    mutationFn: agendaCompleteSurvey,
  });

  const agendaConfirmShippingDetailsMutation = useMutation({
    mutationFn: agendaConfirmShippingDetails,
  });

  const agendaCancelConfirmShippingDetailsMutation = useMutation({
    mutationFn: agendaCancelConfirmShippingDetails,
  });

  const agendaCompleteServiceDatesMutation = useMutation({
    mutationFn: agendaCompleteServiceDates,
  });

  const createValidTillDateAgenda = async ({
    supplierEmails,
    supplierID,
    validTillDate,
  }) => {
    await agendaCancelProductPriceValidNotificationMutation.mutateAsync({
      supplierID,
    });

    await agendaProductSupplierValidTillMutation.mutateAsync({
      supplierEmails,
      supplierID,
      validTillDate,
    });
  };

  const createLogReminderAgenda = async ({
    facilityID,
    systemName,
    systemTab,
    systemID,
  }) => {
    await agendaCancelLogReminderMutation.mutateAsync({
      facilityID,
      systemID,
    });

    await agendaLogReminderMutation.mutateAsync({
      facilityID,
      systemName,
      systemTab,
      systemID,
    });
  };

  const createUFPerformCleaningAgenda = async ({
    facilityID,
    UFID,
    UFName,
  }) => {
    await agendaCancelUFPerformCleaningMutation.mutateAsync({
      facilityID,
      UFID,
    });

    await agendaUFPerformCleaningMutation.mutateAsync({
      facilityID,
      UFID,
      UFName,
    });
  };

  const createOrderDeliveredAgenda = async ({
    arriveDate,
    chemicals,
    facilityID,
    orderID,
    orderNumber,
    preFilters,
    productsBeingReplaced,
  }) => {
    await agendaCancelOrderDeliveredMutation.mutateAsync({
      orderID,
    });

    await agendaOrderDeliveredMutation.mutateAsync({
      arriveDate,
      chemicals,
      facilityID,
      orderID,
      orderNumber,
      preFilters,
      productsBeingReplaced,
    });
  };

  return {
    createValidTillDateAgenda,
    createLogReminderAgenda,
    createUFPerformCleaningAgenda,
    createOrderDeliveredAgenda,
    agendaUpdateOrderStatusMutation,
    agendaCheckIfInvoicePaidMutation,
    agendaSendInvoiceEmailMutation,
    agendaSendServiceContractInvoiceEmailMutation,
    agendaServiceContractMutation,
    agendaCancelLogReminderMutation,
    agendaCancelServiceContractExpiringNotificationMutation,
    agendaCancelMaintenanceNotificationMutation,
    agendaCancelServiceContractAutoRenewMutation,
    agendaServiceContractAutoRenewMutation,
    agendaCancelUFPerformCleaningMutation,
    agendaServiceDateMutation,
    agendaCompleteSurveyMutation,
    agendaConfirmShippingDetailsMutation,
    agendaCancelConfirmShippingDetailsMutation,
    agendaCompleteServiceDatesMutation,
  };
};

export default useAgendas;
