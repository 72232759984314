import React from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import keys from "../../config/keys";
const libraries = ["places"];

const GoogleMapProvider = ({ children }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: keys.GOOGLE_MAP_API,
    libraries,
  });

  return React.cloneElement(children, { googleIsLoaded: isLoaded });
};

export default GoogleMapProvider;
