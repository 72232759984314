import { useMutation, useQueryClient } from "@tanstack/react-query";

import { adjustServiceContractRequest } from "../../api/serviceContractOrderAPI";

const useAdjustServiceContractRequest = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: adjustServiceContractRequest,
    onSuccess: () => {
      queryClient.invalidateQueries("serviceContracts");
    },
  });
};

export default useAdjustServiceContractRequest;
