import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteManufacturerProductDetail } from "../../api/manufacturerProductDetailsAPI";

const useDeleteManufacturerProductDetail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteManufacturerProductDetail,
    onSuccess: () => {
      queryClient.invalidateQueries("manufacturerProductDetails");
    },
  });
};

export default useDeleteManufacturerProductDetail;
