import { Grid, Typography } from "@mui/material";
import React from "react";

const OrderDisplayShipping = ({
  containersToReturn,
  orderView,
  shippingCost,
  supplierShippingCost,
  useDrOPsShipping,
}) => {
  if (orderView === "facility" && shippingCost > 0) {
    return (
      <Grid item xs={12}>
        {containersToReturn.length ? (
          <Typography>Shipping for Container Return</Typography>
        ) : (
          <Typography>Shipping and Handling</Typography>
        )}
        <Typography>{`Total: $${shippingCost.toFixed(2)}`}</Typography>
      </Grid>
    );
  }

  if (
    orderView === "supplier" &&
    supplierShippingCost > 0 &&
    !useDrOPsShipping
  ) {
    return (
      <Grid item xs={12}>
        <Typography>Shipping and Handling</Typography>
        <Typography>{`Total: $${supplierShippingCost.toFixed(2)}`}</Typography>
      </Grid>
    );
  }

  if (orderView === "shipper" && supplierShippingCost > 0) {
    return (
      <Grid item xs={12}>
        <Typography>Shipping and Handling</Typography>
        <Typography>{`Total: $${supplierShippingCost.toFixed(2)}`}</Typography>
      </Grid>
    );
  }
};

export default OrderDisplayShipping;
