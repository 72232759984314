import { useQuery } from "@tanstack/react-query";
import { getChemicalSuppliesByFacility } from "../../api/chemicalSupplyAPI";

const useChemicalSuppliesByFacility = (facilityID) => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ["chemicalSupply", facilityID],
    queryFn: () => getChemicalSuppliesByFacility(facilityID),
    enabled: !!facilityID,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    error,
    isError,
    isLoading,
  };
};

export default useChemicalSuppliesByFacility;
