import { useMutation, useQueryClient } from "@tanstack/react-query";
import { contactUpdate } from "../../api/contactAPI";

const useUpdateContact = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: contactUpdate,
    onSuccess: async () => {
      queryClient.invalidateQueries("contacts");
      await queryClient.invalidateQueries("contact");
    },
  });
};

export default useUpdateContact;
