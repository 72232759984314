import { useQuery } from "@tanstack/react-query";
import { getSupplierCompanyEmployees } from "../../api/supplierCompaniesAPI";

export const useSupplierCompanyEmployees = (supplierID) => {
  const { data: employees = [] } = useQuery({
    queryKey: ["employees", supplierID],
    queryFn: () => getSupplierCompanyEmployees(supplierID),
    enabled: !!supplierID,
  });

  return { employees };
};

export default useSupplierCompanyEmployees;
