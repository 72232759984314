const getPSTRate = (province) => {
  return province === "AB" ||
    province === "NB" ||
    province === "NL" ||
    province === "NT" ||
    province === "NS" ||
    province === "NU" ||
    province === "ON" ||
    province === "QC" ||
    province === "PE" ||
    province === "YT"
    ? 0
    : province === "MB" || province === "BC"
    ? 0.07
    : province === "SK"
    ? 0.06
    : 0.06;
};

export default getPSTRate;
