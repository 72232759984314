import axios from "axios";

export const getContainerSupplyByFacility = async (facilityID) => {
  return axios
    .get(`/api/container-supply/get-by-facility?facilityID=${facilityID}`)
    .then((res) => {
      return res?.data?.containerSupplies;
    });
};

export const addContainerSupply = async (body) => {
  return axios.post("/api/container-supply/add", body).then((res) => {
    return res?.data?.containerSupply;
  });
};

export const updateContainerSupply = async (body) => {
  return axios.put("/api/container-supply/update", body);
};

export const updateContainerSupplyUpdateMultiple = async (body) => {
  return axios.patch("/api/container-supply/update-multiple", body);
};
