import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { CustomModal } from "../sharedComponents";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import useUpdateOrderArriveDate from "../../customHooks/productOrder/useUpdateOrderArriveDate";

const UpdateDeliveryDate = ({ close, order }) => {
  const updateOrderArriveDate = useUpdateOrderArriveDate();
  const [arriveDate, setArriveDate] = useState(moment(order.arriveDate));
  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const onClose = (event, reason, order) => {
    if (reason === "backdropClick") return;
    setModalOpen(false);
    if (order) close(null, null, order);
  };

  const updateDeliveryDate = async () => {
    setSaving(true);

    const orderUpdated = await updateOrderArriveDate.mutateAsync({
      arriveDate,
      orderID: order._id,
    });

    onClose(null, null, orderUpdated);
    setSaving(false);
  };

  return (
    <>
      <CustomModal
        open={modalOpen}
        close={onClose}
        title="Update Delivery Date"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DatePicker
              label="Est. Delivery Date"
              value={arriveDate}
              onChange={setArriveDate}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={updateDeliveryDate}
              fullWidth
              disabled={saving}
            >
              {saving ? "Updating Delivery Date" : "Update Delivery Date"}
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
      <Button variant="contained" onClick={() => setModalOpen(true)}>
        Update Delivery Date
      </Button>
    </>
  );
};

export default UpdateDeliveryDate;
