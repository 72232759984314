import React from "react";

import { Box, IconButton } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useNavigate } from "react-router";

const HistoryNav = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", flexGrow: { xs: 1, md: 0 } }}>
      <IconButton
        aria-label="Back"
        style={{ color: "white" }}
        onClick={() => navigate(-1)}
        size="large"
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          if ("caches" in window) {
            caches.keys().then((names) => {
              names.forEach((name) => {
                caches.delete(name);
              });
            });
            window.location.reload(true);
          }
        }}
        aria-label="delete"
        sx={{ color: "white" }}
        size="large"
      >
        <RefreshIcon />
      </IconButton>
    </Box>
  );
};

export default HistoryNav;
