import { useQuery } from "@tanstack/react-query";
import { getServiceContractByID } from "../../api/serviceContractOrderAPI";

const useServiceContractByID = ({ serviceContractID }) => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ["serviceContracts", serviceContractID],
    queryFn: () => getServiceContractByID({ serviceContractID }),
    enabled: !!serviceContractID,
    suspense: true,
  });

  return {
    data,
    error,
    isError,
    isLoading,
  };
};

export default useServiceContractByID;
