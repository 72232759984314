import { useMutation, useQueryClient } from "@tanstack/react-query";
import { completeServiceOrderRequest } from "../../api/serviceOrderAPI";

const useCompleteServiceOrderRequest = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: completeServiceOrderRequest,
    onSuccess: async () => {
      await queryClient.invalidateQueries("serviceOrders");
    },
  });
};

export default useCompleteServiceOrderRequest;
