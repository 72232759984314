import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addServiceOrder } from "../../api/serviceOrderAPI";

const useAddServiceOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addServiceOrder,
    onSuccess: async () => {
      await queryClient.invalidateQueries("serviceOrders");
    },
  });
};

export default useAddServiceOrder;
