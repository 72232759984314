import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import {
  getProductOptionLabel,
  getServiceDescriptionLabel,
} from "../../../sharedFunctions/labels";
import customDollarRound from "../../../sharedFunctions/purchasingFunctions/customDollarRound";

const OrderDisplayProducts = (props) => {
  const { items, orderType, orderView } = props;

  const renderProductOrderProducts = () => {
    if (orderView === "shipper" || items?.length <= 0) return;
    return (
      <>
        <Divider sx={{ mb: 2 }} />
        {items.map((productDetail, i) => {
          if (
            productDetail.product.type === "Container" &&
            productDetail?.isReturn
          )
            return null;
          let priceDetails = `Price: ${
            orderView === "supplier"
              ? productDetail.cost.toFixed(2)
              : productDetail.price.toFixed(2)
          }`;
          if (productDetail.containerDeposit)
            priceDetails += `, Deposit: ${productDetail.containerDeposit.toFixed(
              2
            )}`;
          priceDetails += `, Quantity: ${productDetail.quantity}, Total: $${
            orderView === "supplier"
              ? productDetail.supplierTotal.toFixed(2)
              : productDetail.total.toFixed(2)
          }`;

          return (
            <Grid item xs={12} key={productDetail.product._id}>
              <Typography
                color={
                  productDetail.product.type === "Container" &&
                  productDetail?.isReturn &&
                  "limegreen"
                }
              >
                {productDetail.product.type === "Container" &&
                  productDetail?.isReturn &&
                  "Container Returned: "}
                {getProductOptionLabel(productDetail.product)}
              </Typography>
              {productDetail?.manufacturerProductDetail?.partNumber && (
                <Typography>
                  Part #: {productDetail.manufacturerProductDetail.partNumber}
                </Typography>
              )}
              <Typography
                color={
                  productDetail.product?.type === "Container" &&
                  productDetail?.isReturn &&
                  "limegreen"
                }
              >
                {priceDetails}
                {productDetail.product?.type !== "Container" &&
                productDetail?.isReturn ? (
                  <span style={{ color: "red" }}> RETURNED</span>
                ) : (
                  ""
                )}
              </Typography>
              {i !== items.length - 1 && <br />}
            </Grid>
          );
        })}
      </>
    );
  };

  const renderServiceOrderServices = () => {
    if (items?.services?.length <= 0) return;
    return (
      <>
        <Divider sx={{ mb: 2 }} />
        {items?.services?.map((serviceDetail) => (
          <Grid item xs={12} key={serviceDetail.service._id}>
            <Typography>{getServiceDescriptionLabel(serviceDetail)}</Typography>

            <Typography>
              Price:{" "}
              {orderView === "supplier"
                ? serviceDetail.cost?.toFixed(2)
                : serviceDetail.price?.toFixed(2)}
              , Quantity: {serviceDetail.quantity}, Total:{" "}
              {orderView === "supplier"
                ? (serviceDetail.cost * serviceDetail.quantity).toFixed(2)
                : serviceDetail.total?.toFixed(2)}
            </Typography>
            {<br />}
          </Grid>
        ))}
        {items?.materialsUsed?.map((material) => (
          <Grid item xs={12} key={material._id}>
            <Typography>{material.description}</Typography>
            <Typography>
              Price:{" "}
              {orderView === "supplier"
                ? material.cost.toFixed(2)
                : material.price.toFixed(2)}
            </Typography>
            {<br />}
          </Grid>
        ))}
      </>
    );
  };

  const renderServiceContractOrderServices = () => {
    if (items?.services?.length <= 0) return;
    return (
      <>
        <Divider sx={{ mb: 2 }} />
        {items.services.map((service, i) => (
          <Grid item xs={12} key={i}>
            <Typography>Service Year {i + 1}</Typography>
            {service.map((serviceDetail) => {
              const priceDetails =
                orderView === "supplier"
                  ? `Price: ${serviceDetail.cost.toFixed(2)}, Quantity: ${
                      serviceDetail.quantity
                    }, Total: ${customDollarRound(
                      serviceDetail.cost * serviceDetail.quantity
                    ).toFixed(2)}`
                  : `Price: ${serviceDetail.price.toFixed(2)}, Quantity: ${
                      serviceDetail.quantity
                    }, Total: ${serviceDetail.total.toFixed(2)}`;
              return (
                <Grid item xs={12} key={serviceDetail._id}>
                  <Typography>{serviceDetail.service.name}</Typography>
                  <Typography>{priceDetails}</Typography>
                  {<br />}
                </Grid>
              );
            })}
          </Grid>
        ))}
      </>
    );
  };

  return (
    <Grid item xs={12}>
      {orderType === "ProductOrder" && renderProductOrderProducts()}
      {orderType === "ServiceOrder" && renderServiceOrderServices()}
      {orderType === "ServiceContractOrder" &&
        renderServiceContractOrderServices()}
    </Grid>
  );
};

export default OrderDisplayProducts;
