import { useMutation, useQueryClient } from "@tanstack/react-query";
import { confirmOrder } from "../../api/productOrderAPI";

const useConfirmOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: confirmOrder,
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
      queryClient.invalidateQueries("orders");
      queryClient.invalidateQueries("order");
      queryClient.invalidateQueries("chemicalSupply");
      queryClient.invalidateQueries("containerSupply");
      queryClient.invalidateQueries("facilityPreFilterSupplies");
    },
  });
};

export default useConfirmOrder;
