import { useQuery } from "@tanstack/react-query";
import { getAllProductNames } from "../../api/productAPI";

const useProductNames = ({ enabled }) => {
  const { data: products = [], isLoading } = useQuery({
    queryKey: ["productNames"],
    queryFn: getAllProductNames,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
    enabled: enabled,
  });

  return { products, isLoading };
};

export default useProductNames;
