import { useQuery } from "@tanstack/react-query";
import { getCreditMemo } from "../../api/creditMemoAPI";

const useCreditMemo = (creditMemoID) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["creditMemo", creditMemoID],
    queryFn: () => getCreditMemo(creditMemoID),
    enabled: !!creditMemoID,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading, error };
};

export default useCreditMemo;
