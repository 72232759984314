import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteOrderAddition } from "../../api/orderAdditionAPI";

const useDeleteOrderAddition = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteOrderAddition,
    onSuccess: () => {
      queryClient.invalidateQueries("orderAddition");
      queryClient.invalidateQueries("orders");
    },
  });
};

export default useDeleteOrderAddition;
