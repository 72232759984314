import { useMutation, useQueryClient } from "@tanstack/react-query";
import { manufacturerProductDetailUploadPSSFiles } from "../../api/manufacturerProductDetailsAPI";

const useManufacturerProductDetailsUploadPSSFiles = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: manufacturerProductDetailUploadPSSFiles,
    onSuccess: () => {
      queryClient.invalidateQueries("manufacturerProductDetails");
    },
  });
};

export default useManufacturerProductDetailsUploadPSSFiles;
