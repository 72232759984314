import axios from "axios";

export const getAllProducts = async () => {
  return axios.get("/api/product/get-all").then((res) => {
    return res.data.products;
  });
};

export const getAllProductNames = async () => {
  return axios.get("/api/product/get-all-names").then((res) => {
    return res.data.products;
  });
};

export const getProduct = async ({ productID }) => {
  return axios.get(`/api/product/get-by-id?id=${productID}`).then((res) => {
    return res.data.product;
  });
};

export const getAllAntiscalants = async () => {
  return axios.get("/api/product/get-all-antiscalants").then((res) => {
    return res.data.antiscalants;
  });
};

export const getAllChemicals = async () => {
  return axios.get("/api/product/get-all-chemicals").then((res) => {
    return res.data.chemicals;
  });
};

export const getAllContainers = async () => {
  return axios.get("/api/product/get-all-containers").then((res) => {
    return res.data.containers;
  });
};

export const getAllTestingMaterialsSupplies = async () => {
  return axios
    .get("/api/product/get-all-testing-materials-supplies")
    .then((res) => {
      return res.data.testingMaterials;
    });
};
export const getAllEquipments = async () => {
  return axios.get("/api/product/get-all-equipments").then((res) => {
    return res.data.equipments;
  });
};

export const getAllFilters = async () => {
  return axios.get("/api/product/get-all-filters").then((res) => {
    return res.data.filters;
  });
};

export const getAllMembranes = async () => {
  return axios.get("/api/product/get-all-membranes").then((res) => {
    return res.data.membranes;
  });
};

export const getAllParts = async () => {
  return axios.get("/api/product/get-all-parts").then((res) => {
    return res?.data?.parts ?? [];
  });
};

export const getAllChemicalNames = async () => {
  return axios.get("/api/product/get-all-chemical-names").then((res) => {
    return res?.data?.chemicals ?? [];
  });
};

export const getAllTestingMaterialsSupplyNames = async () => {
  return axios
    .get("/api/product/get-all-testing-materials-supply-names")
    .then((res) => {
      return res?.data?.testingMaterials ?? [];
    });
};
export const getAllEquipmentNames = async () => {
  return axios.get("/api/product/get-all-equipment-names").then((res) => {
    return res?.data?.equipments ?? [];
  });
};

export const getAllFilterNames = async () => {
  return axios.get("/api/product/get-all-filter-names").then((res) => {
    return res?.data?.filters ?? [];
  });
};

export const getAllMembraneNames = async () => {
  return axios.get("/api/product/get-all-membrane-names").then((res) => {
    return res?.data?.membranes ?? [];
  });
};

export const getAllPartNames = async () => {
  return axios.get("/api/product/get-all-part-names").then((res) => {
    return res?.data?.parts ?? [];
  });
};

export const getAllJanitorialSupplyNames = async () => {
  return axios
    .get("/api/product/get-all-janitorial-supply-names")
    .then((res) => {
      return res?.data?.janitorialSupplies ?? [];
    });
};

export const getProductsByManufacturer = async ({ contactID }) => {
  return axios
    .get(`/api/product/get-all-by-manufacturer?id=${contactID}`)
    .then((res) => {
      return res?.data?.products ?? [];
    });
};

export const getProductEquivalents = async (productID) => {
  return axios
    .get(`/api/product/get-product-equivalents?id=${productID}`)
    .then((res) => {
      return res?.data?.productEquivalents ?? [];
    });
};

export const getProductNamesForMaintenanceProcedures = async () => {
  return axios
    .get("/api/product/get-product-names-for-maintenance-procedures")
    .then((res) => {
      return res?.data?.products ?? [];
    });
};

export const addProduct = async ({ body }) => {
  return axios
    .post("/api/product/add", { body })
    .then((res) => res.data.product);
};

export const copyProduct = async (body) => {
  return axios.post("/api/product/copy", body);
};

export const updateProduct = async ({ body }) => {
  return axios.put("/api/product/update", { body });
};

export const updateProductAddRequestingFacility = async ({
  facilityID,
  productID,
}) => {
  return axios.patch("/api/product/add-requesting-facility", {
    facilityID,
    productID,
  });
};

export const checkToRemoveRequestingFacility = async ({
  facilityID,
  supplierIDs,
}) => {
  return axios.patch("/api/product/check-to-remove-requesting-facility", {
    facilityID,
    supplierIDs,
  });
};

export const updateProductApproveProduct = async ({ productID }) => {
  return axios.patch("/api/product/approve-product", {
    productID,
  });
};
