import { useQuery } from "@tanstack/react-query";
import { getFacilityProducts } from "../../api/facilityAPI";

const useFacilityProducts = ({ facilityID, viewCatalogue }) => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ["facilityProducts", facilityID, viewCatalogue],
    queryFn: () => getFacilityProducts({ facilityID, viewCatalogue }),
    enabled: !!facilityID,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    facilityProducts: data?.products,
    error,
    isError,
    isLoading,
  };
};

export default useFacilityProducts;
