import customDollarRound from "./customDollarRound";

const calculateTotalProductAmount = (
  containerDeposit,
  amount,
  quantity,
  shipping
) => {
  if (containerDeposit) {
    const priceWithDeposit = amount + containerDeposit;
    return customDollarRound(priceWithDeposit * quantity + (shipping ?? 0));
  }

  return customDollarRound(amount * quantity + (shipping ?? 0));
};

export default calculateTotalProductAmount;
