import { useQuery } from "@tanstack/react-query";
import { getFacility } from "../../api/facilityAPI";
import { useDispatch } from "react-redux";
import { setFacilityUserLevel } from "../../redux/reducers/userLevelsSlice";
import { useEffect } from "react";

const useFacility = (id, contactID) => {
  const dispatch = useDispatch();

  const {
    data: facility,
    error,
    isError,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ["facility", id, contactID],
    queryFn: () => getFacility(id, contactID),
    enabled: !!id && !!contactID,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isSuccess) {
      if (facility && facility.userLevel) {
        dispatch(setFacilityUserLevel(facility.userLevel));
      }
    }
  }, [isSuccess, facility, dispatch]);

  return {
    error,
    facility,
    facilityUserLevel: facility?.userLevel,
    isError,
    isLoading,
  };
};

export default useFacility;
