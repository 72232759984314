import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { CustomModal } from "../sharedComponents";
import useDropOffConfirmation from "../../customHooks/productOrder/useDropOffConfirmation";

const ContainersReturnedConfirmation = (props) => {
  const { close, order } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const dropOffConfirmation = useDropOffConfirmation();

  const closeModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setModalOpen(false);
  };

  const onSubmit = async () => {
    try {
      setSaving(true);

      await dropOffConfirmation.mutateAsync({
        order: { _id: order._id },
      });

      setSaving(false);
      setModalOpen(false);
      close(null, null);
    } catch (error) {
      throw error;
    }
  };

  const renderDropOffConfirmation = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Please confirm that returnable containers for order:{" "}
            {order.orderNumber} have been dropped off.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={onSubmit}
            disabled={saving}
          >
            {saving ? "Confirming Drop Off" : "Confirm Drop Off"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setModalOpen(true)}
          >
            Open Drop Off Confirmation
          </Button>
        </Grid>
      </Grid>
      <CustomModal
        open={modalOpen}
        close={closeModal}
        title={"Confirm Drop Off"}
      >
        {renderDropOffConfirmation()}
      </CustomModal>
    </>
  );
};

export default ContainersReturnedConfirmation;
