import { getUnavailableServiceDates } from "../../sharedFunctions/serviceDateFunctions/getUnavailableServiceDates";
import useServiceOrdersBySupplier from "../serviceOrder/useServiceOrdersBySupplier";
import useServiceContractsBySupplier from "../serviceContractOrder/useServiceContractsBySupplier";
import useSupplierCompanyAvailableServiceDates from "./useSupplierCompanyAvailableServiceDates";

const useSupplierCompanyUnavailableServiceDates = (supplierID) => {
  const { availableServiceDates } =
    useSupplierCompanyAvailableServiceDates(supplierID);
  const { serviceOrder: serviceRequests, isLoading: loading } =
    useServiceOrdersBySupplier(supplierID);
  const { serviceContracts, isLoading } = useServiceContractsBySupplier({
    supplierID,
    toggleCompleted: false,
  });

  const unavailableServiceDates = getUnavailableServiceDates(
    serviceContracts,
    serviceRequests,
    availableServiceDates?.unavailableDates
  );

  return { unavailableServiceDates, isLoading: loading || isLoading };
};

export default useSupplierCompanyUnavailableServiceDates;
