import { useMutation, useQueryClient } from "@tanstack/react-query";
import { serviceOrderCancelRequest } from "../../api/serviceOrderAPI";

const useServiceOrderCancelRequest = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: serviceOrderCancelRequest,
    onSuccess: async () => {
      await queryClient.invalidateQueries("serviceOrders");
    },
  });
};

export default useServiceOrderCancelRequest;
