import { useMutation, useQueryClient } from "@tanstack/react-query";
import { contactAdd } from "../../api/contactAPI";

const useAddContact = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: contactAdd,
    onSuccess: async () => {
      await queryClient.invalidateQueries("contacts");
    },
  });
};

export default useAddContact;
