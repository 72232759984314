import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { CustomModal } from "../sharedComponents";
import ManufacturerProductDetailsForm from "./ManufacturerProductDetailsForm";

const ManufacturerProductDetails = ({
  editMode,
  manufacturerProductDetails,
  product,
  isLoading,
}) => {
  const [manufacturerProductDetail, setManufacturerProductDetail] =
    useState(null);
  const [manufacturerProductDetailOpen, setManufacturerProductDetailOpen] =
    useState(false);

  const openManufacturerProductDetail = (manufacturerProductDetail) => {
    setManufacturerProductDetail(manufacturerProductDetail);
    setManufacturerProductDetailOpen(true);
  };

  const closeManufacturerProductDetail = () => {
    setManufacturerProductDetailOpen(false);
    setManufacturerProductDetail(null);
  };

  return (
    <>
      <CustomModal
        open={manufacturerProductDetailOpen}
        close={closeManufacturerProductDetail}
        title={"Manufacturer Product Detail"}
        hasEdit
        isNew={manufacturerProductDetail ? false : true}
      >
        <ManufacturerProductDetailsForm
          close={closeManufacturerProductDetail}
          manufacturerProductDetail={manufacturerProductDetail}
          product={product}
        />
      </CustomModal>

      {product && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">Manufacturers</Typography>
          </Grid>
          {editMode && (
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  setManufacturerProductDetailOpen(true);
                }}
              >
                Add Manufacturer
              </Button>
            </Grid>
          )}
          {isLoading && (
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          )}
          <Grid container spacing={2} item xs={12}>
            {manufacturerProductDetails?.map((manufacturerProductDetail) => (
              <Grid item xs={6} key={manufacturerProductDetail._id}>
                <Button
                  variant="contained"
                  color={
                    manufacturerProductDetail?.isDeleted ? "error" : "primary"
                  }
                  fullWidth
                  onClick={() =>
                    openManufacturerProductDetail(manufacturerProductDetail)
                  }
                >
                  {manufacturerProductDetail.manufacturer.name}
                </Button>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default ManufacturerProductDetails;
