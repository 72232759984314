import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateFacilityFavouriteProducts } from "../../api/facilityAPI";

const useUpdateFacilityFavouriteProducts = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateFacilityFavouriteProducts,
    onSuccess: () => {
      queryClient.invalidateQueries("facilityFavourites");
    },
  });
};

export default useUpdateFacilityFavouriteProducts;
