import React from "react";
import { Grid, Typography, Divider } from "@mui/material";
import { getProductOptionLabel } from "../../../sharedFunctions/labels";

const OrderDisplayContainers = ({
  creditsAcceptedBySupplier,
  creditsEarned,
  containerReturnGST,
  facilityHasGST,
  containersToReturn,
  containersNotAcceptedReason,
}) => {
  let subTotal = 0;

  const products = containersToReturn.map((productDetail) => {
    let priceDetails = `Price: ${productDetail.price.toFixed(2)}`;
    priceDetails += `, Quantity: ${
      productDetail.quantity
    }, Total: $${productDetail.total.toFixed(2)}`;

    subTotal += productDetail.total;
    return (
      <Grid item xs={12} key={productDetail._id}>
        <Typography color={"limegreen"}>
          Container Returned:{" "}
          {productDetail.chemical
            ? getProductOptionLabel(productDetail.chemical)
            : getProductOptionLabel(productDetail.container)}
        </Typography>
        <Typography color={"limegreen"}>{priceDetails}</Typography>
        <br />
      </Grid>
    );
  });

  if (!products.length) return;

  return (
    <Grid item xs={12}>
      <Divider sx={{ mb: 2 }} />
      {products}
      {containersNotAcceptedReason && (
        <>
          <Typography>Reason containers were not accepted:</Typography>
          <Typography>{containersNotAcceptedReason}</Typography>
        </>
      )}
      {subTotal > 0 && (
        <Typography display="flex" justifyContent="flex-end" color="limegreen">
          Subtotal: ${subTotal.toFixed(2)}
        </Typography>
      )}
      {facilityHasGST && containerReturnGST > 0 && (
        <Typography display="flex" justifyContent="flex-end" color="limegreen">
          GST: ${containerReturnGST.toFixed(2)}
        </Typography>
      )}
      {creditsEarned > 0 && (
        <Typography display="flex" justifyContent="flex-end" color="limegreen">
          Pending Credit: ${creditsEarned.toFixed(2)}
        </Typography>
      )}
      {creditsAcceptedBySupplier > 0 && (
        <Typography display="flex" justifyContent="flex-end" color="limegreen">
          Credit Earned: ${creditsAcceptedBySupplier.toFixed(2)}
        </Typography>
      )}
    </Grid>
  );
};

export default OrderDisplayContainers;
