import {
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { Grid } from "@mui/material";

const CustomToolbar = ({ Buttons, disableExport }) => {
  return (
    <GridToolbarContainer>
      <Grid
        container
        spacing={2}
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid item xs={12} md={4} display="flex">
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          {!disableExport && (
            <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
          )}
          <GridToolbarFilterButton />
        </Grid>
        <Grid item xs={12} md={3} display="flex">
          <GridToolbarQuickFilter />
        </Grid>
        {Buttons && <Buttons />}
      </Grid>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
