import { useQuery } from "@tanstack/react-query";
import { getAllFilterNames } from "../../api/productAPI";

const useFilterNames = (enabled) => {
  const { data = [], isLoading } = useQuery({
    queryKey: ["productNames", { type: "filter" }],
    queryFn: getAllFilterNames,
    enabled,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};

export default useFilterNames;
