import React from "react";
import { CircularProgress, Divider, Grid, Typography } from "@mui/material";
import useGetFacilitiesNamesByProduct from "../../customHooks/facility/useGetFacilitiesByProduct";

const ProductFacilities = (props) => {
  const { product } = props;

  const {
    facilities,
    isError,
    isLoading: isLoadingFacilities,
  } = useGetFacilitiesNamesByProduct(product._id);

  if (isLoadingFacilities)
    return (
      <Grid item xs={12}>
        <CircularProgress />
      </Grid>
    );

  if (!facilities?.length) return <></>;

  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6">Facilities</Typography>
      </Grid>

      {isError && (
        <Grid item xs={12}>
          <Typography>Error loading facilities. Please refresh.</Typography>
        </Grid>
      )}

      {facilities.map((facility) => {
        return (
          <Grid item xs={12} key={facility._id}>
            <Typography>{facility.name}</Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ProductFacilities;
