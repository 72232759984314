import React from "react";
import { Grid, Typography } from "@mui/material";
import { GST } from "../../../globalConstants";
import customDollarRound from "../../../sharedFunctions/purchasingFunctions/customDollarRound";

const OrderDisplayTotals = ({ order, orderView }) => {
  const renderLineItem = (label, value) => {
    // Check if the value exists before rendering
    const shouldRenderValue = value !== undefined && value !== null;

    return shouldRenderValue ? (
      <Typography display="flex" justifyContent="flex-end">
        {label}: ${value.toFixed(2)}
      </Typography>
    ) : null;
  };

  if (orderView === "facility" && (order.subtotal > 0 || order.total > 0)) {
    return (
      <Grid item xs={12}>
        {renderLineItem("Subtotal", order.subtotal)}
        {renderLineItem("PST", order.PST)}
        {renderLineItem("GST", order.GST)}
        {order.creditsUsed > 0 && (
          <Typography
            display="flex"
            justifyContent="flex-end"
            color="limegreen"
          >
            Credits: - ${order.creditsUsed?.toFixed(2)}
          </Typography>
        )}
        {renderLineItem("Total", order.total)}
      </Grid>
    );
  }

  if (
    orderView === "supplier" &&
    (order.supplierSubtotal > 0 || order.supplierTotal > 0)
  ) {
    return (
      <Grid item xs={12}>
        {renderLineItem("Subtotal", order.supplierSubtotal)}
        {renderLineItem("PST", order.supplierPST)}
        {renderLineItem("GST", order.supplierGST)}
        {order.supplierCreditsUsed > 0 && (
          <Typography
            display="flex"
            justifyContent="flex-end"
            color="limegreen"
          >
            Credits: - ${order.supplierCreditsUsed?.toFixed(2)}
          </Typography>
        )}
        {renderLineItem("Total", order.supplierTotal)}
      </Grid>
    );
  }

  if (
    orderView === "shipper" &&
    order.useDrOPsShipping &&
    (order.supplierShippingCost > 0 || order.brokerageFeeCost > 0)
  ) {
    return (
      <Grid item xs={12}>
        {renderLineItem(
          "Subtotal",
          order.supplierShippingCost +
            order.brokerageFeeCost +
            order.brokerageFeeGST
        )}
        {renderLineItem(
          "GST",
          order.brokerageFeeCost > 0
            ? 0
            : customDollarRound(order.supplierShippingCost * GST)
        )}
        {renderLineItem(
          "Total",
          customDollarRound(
            order.brokerageFeeCost +
              order.brokerageFeeGST +
              order.supplierShippingCost *
                (1 + (order.brokerageFeeCost > 0 ? 0 : GST))
          )
        )}
      </Grid>
    );
  }
};

export default OrderDisplayTotals;
