import { useQuery } from "@tanstack/react-query";
import { contactGetThemeSettings } from "../../api/contactAPI";

const useContactThemeSettings = (contactID) => {
  const {
    data: themeSettings,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["contactThemeSettings", contactID],
    queryFn: () => contactGetThemeSettings(contactID),
    enabled: !!contactID,
    staleTime: 10 * 60 * 1000, // 10 minutes
    refetchOnWindowFocus: false,
  });

  return { themeSettings, isLoading, isError };
};

export default useContactThemeSettings;
