import axios from "axios";
import { superUserEmails } from "../globalConstants";

export const getFacilities = async (appUserLevel, contact) => {
  if (!contact) return [];

  return axios
    .get(
      superUserEmails.includes(contact.email)
        ? `/api/facility/get-all`
        : appUserLevel.includes("Supplier")
        ? `/api/facility/get-all-by-supplier?id=${contact._id}`
        : `/api/facility/get-all-by-contact?id=${contact._id}`
    )
    .then((res) => res.data.facilities || []);
};

export const getFacilitiesBySupplier = async (supplierID) => {
  return axios
    .get(`/api/facility/get-all-by-supplier?id=${supplierID}`)
    .then((res) => res.data.facilities || []);
};

export const getFacilitiesNames = async (appUserLevel, contact) => {
  if (!contact) return [];

  return axios
    .get(
      superUserEmails.includes(contact.email)
        ? `/api/facility/get-all-names`
        : appUserLevel.includes("Supplier")
        ? `/api/facility/get-all-names-by-supplier?id=${contact._id}`
        : `/api/facility/get-all-names-by-contact?id=${contact._id}`
    )
    .then((res) => res.data.facilities || []);
};

export const getFacilityData = async (facilityID, contactID) => {
  return axios
    .get(`/api/facility/get-data?id=${facilityID}&contactID=${contactID}`)
    .then((res) => res.data.facility || null);
};

export const getFacilitiesNamesForReports = async (appUserLevel, contact) => {
  if (!contact) return [];

  return axios
    .get(
      superUserEmails.includes(contact.email)
        ? `/api/facility/get-all-names-for-reports`
        : appUserLevel.includes("Supplier")
        ? `/api/facility/get-all-names-by-supplier-for-reports?id=${contact._id}`
        : `/api/facility/get-all-names-by-contact-for-reports?id=${contact._id}`
    )
    .then((res) => res.data.facilities || []);
};

export const getFacility = async (id, contactID) => {
  if (!id) return;
  return axios
    .get(`/api/facility/get-by-id?id=${id}&contactID=${contactID}`)
    .then((res) => res?.data?.facility || null);
};

export const getFacilityInfo = async (id) => {
  if (!id) return;
  return axios
    .get(`/api/facility/get-info?id=${id}`)
    .then((res) => res?.data?.facility || null);
};

export const getFacilityNotificationSettings = async (facilityID) => {
  return axios
    .get(`/api/facility/get-notification-settings?facilityID=${facilityID}`)
    .then((res) => res?.data?.facility || null);
};

export const getRestrictedShippers = async (id) => {
  return axios
    .get(`/api/facility/get-restricted-shippers?id=${id}`)
    .then((res) => res.data.restrictedShippers || []);
};

export const getFacilityAddress = async (facilityID) => {
  if (!facilityID) return;
  return axios
    .get(`/api/facility/get-address?facilityID=${facilityID}`)
    .then((res) => res?.data?.facilityAddress || null);
};

export const getFacilityContacts = async (id) => {
  if (!id) return;
  return axios

    .get(`/api/facility/get-contacts?id=${id}`)
    .then((res) => res?.data?.facilityContacts || null);
};

export const getAllFacilityNamesByContact = async (id) => {
  if (!id) return [];
  return axios
    .get(`/api/facility/get-all-facility-names-by-contact?id=${id}`)
    .then((res) => res?.data?.facilities || []);
};

export const getFacilityAvailableManufacturers = async (facilityID) => {
  if (!facilityID) return [];
  return axios
    .get(`/api/facility/get-available-manufacturers?facilityID=${facilityID}`)
    .then((res) => res?.data?.availableManufacturers || []);
};

export const getFacilitiesNamesByProduct = async (id) => {
  if (!id) return [];
  return axios
    .get(`/api/facility/get-facility-names-by-product?id=${id}`, {
      bypass: true,
    })
    .then((res) => res?.data?.facilities || []);
};

export const getFacilityApprovedSuppliers = async (facilityID) => {
  if (!facilityID) return [];
  return axios
    .get(`/api/facility/get-approved-suppliers?facilityID=${facilityID}`)
    .then((res) => res?.data?.facility);
};

export const getFacilityProducts = async ({ facilityID, viewCatalogue }) => {
  if (!facilityID) return [];

  if (viewCatalogue) {
    return axios.get(`/api/product/get-all`).then((res) => res?.data);
  }

  return axios
    .get(`/api/facility/get-products?id=${facilityID}`)
    .then((res) => res?.data);
};

export const getFacilityProductNames = async (facilityID) => {
  if (!facilityID) return [];

  return axios
    .get(`/api/facility/get-product-names?id=${facilityID}`)
    .then((res) => res?.data?.facilityProducts || []);
};

export const getFavouriteProducts = async (facilityID) => {
  if (!facilityID) return [];
  return axios
    .get(`/api/facility/get-favourite-products?facilityID=${facilityID}`)
    .then((res) => res?.data?.favouriteProducts || []);
};

export const getFacilityProductCatalogue = async (id) => {
  if (!id) return [];
  return axios
    .get(`/api/facility/get-product-catalogue?id=${id}`)
    .then((res) => res?.data?.facilityProductCatalogue || []);
};

/*
@params
body: facility
*/
export const addFacility = async ({ body, userID }) => {
  return axios
    .post("/api/facility/add", { body, userID })
    .then((res) => res?.data?.facility);
};

/*
@params
body: facility
*/
export const updateFacility = async ({ body, userID }) => {
  return axios
    .put("/api/facility/update", { body, userID })
    .then((res) => res?.data?.facility);
};

export const getFacilitySystems = async (facilityID) => {
  if (!facilityID) return [];
  return axios
    .get(`/api/facility/get-systems?facilityID=${facilityID}`)
    .then((res) => res?.data?.facilitySystems || []);
};

export const getFacilityPreFilterSupplies = async (facilityID) => {
  if (!facilityID) return [];
  return axios
    .get(`/api/facility/get-preFilter-supplies?facilityID=${facilityID}`)
    .then((res) => res?.data?.preFilterSupplies || []);
};

export const getFacilityCredits = async (facilityID) => {
  if (!facilityID) return [];
  return axios
    .get(`/api/facility/get-credits?facilityID=${facilityID}`)
    .then((res) => res?.data?.facilityCredits || []);
};

export const getFacilitySuperUserNotes = async (facilityID) => {
  if (!facilityID) return [];
  return axios
    .get(`/api/facility/get-super-user-notes?facilityID=${facilityID}`)
    .then((res) => res?.data?.superUserNotes || []);
};

/*
@params
facility: {
  _id: facility._id,
  administrators: facility.administrators,
  leadOperators: facility.leadOperators,
  managers: facility.managers,
  operators: facility.operators,
  owners: facility.owners,
  regulators: facility.regulators,
}
*/
export const updateFacilityContacts = async ({ facility, userID }) => {
  return axios.patch("/api/facility/update-contacts", { facility, userID });
};

/*
@params
facilityID: facility._id
systemType: distributions || ROs || UFs || greensandFilters || biologicalFilters || chemicalSystems
systemID: system._id
*/
export const updateFacilityRemoveSystem = async ({
  facilityID,
  systemID,
  systemType,
  userID,
}) => {
  return axios.patch("/api/facility/remove-system", {
    facilityID,
    systemID,
    systemType,
    userID,
  });
};

/*
@params
facility: {
  _id: facility._id,
  approvedSuppliers: facility.approvedSuppliers,
  suggestNonApprovedSuppliers: facility.suggestNonApprovedSuppliers,
}
*/
export const updateFacilityApprovedSuppliers = async ({ facility, userID }) => {
  return axios.patch("/api/facility/update-approvedSuppliers", {
    facility,
    userID,
  });
};

/*
@params
facility: {
  _id: facility._id,
  address: facility.address,
}
*/
export const updateFacilityAddress = async ({ facility }) => {
  return axios.patch("/api/facility/update-address", { facility });
};

/*
@params
facility: {
  _id: facility._id,
  emails: facility.emails,
}
*/
export const updateFacilityEmail = async ({ facility }) => {
  return axios.patch("/api/facility/update-email", { facility });
};

/*
@params
facility: {
  _id: facility._id,
  superUserNotes: facility.superUserNotes,
}
*/
export const updateFacilitySuperUserNotes = async ({ facility }) => {
  return axios.patch("/api/facility/update-super-user-notes", { facility });
};

/*
@params
facilityID: facility._id,
*/
export const updateFacilitySetSuggestNewSuppliers = async ({ facilityID }) => {
  return axios.patch("/api/facility/set-suggest-new-suppliers", { facilityID });
};

/*
@params
facility: {
  _id: facility._id,
  preFilterSupply: preFilterSupplyToAdd,
}
*/
export const updateFacilityAddPreFilterSupply = async ({ facility }) => {
  return axios.patch("/api/facility/add-pre-filter-supply", { facility });
};

/*
@params
facility: {
  _id: facility._id,
  preFilterSupply: preFilterSupplyToUpdate,
}
*/
export const updateFacilityUpdatePreFilterSupply = async ({ facility }) => {
  return axios.patch("/api/facility/update-pre-filter-supply", { facility });
};

/*
@params
facility: {
  _id: facility._id,
  preFilterSupply: preFilterSupplyToRemove,
}
*/
export const updateFacilityRemovePreFilterSupply = async ({ facility }) => {
  return axios.patch("/api/facility/remove-pre-filter-supply", { facility });
};

/*
@params
facility: {
  _id: facility._id,
  preFilterSupplies: preFilterSuppliesToRemove,
}
*/
export const updateFacilityRemoveMultiplePreFilterSupply = async ({
  facility,
}) => {
  return axios.patch("/api/facility/remove-multiple-pre-filter-supply", {
    facility,
  });
};

/*
@params
contactID: contact._id,
facilityID: facility._id,
userRole: owners || managers || administrators || leadOperators  || operators || regulators
*/
export const updateFacilityUserRole = async ({
  contactID,
  facilityID,
  userRole,
}) => {
  return axios.patch("/api/facility/update-user-role", {
    contactID,
    facilityID,
    userRole,
  });
};

export const removeContactFromFacility = async ({
  contact,
  parentCompanyContactID,
}) => {
  axios.patch("/api/facility/remove-contact", {
    contact,
    parentCompanyContactID,
  });
};

export const updateFacilityAddOrRemoveCredits = async ({
  amount,
  facilityID,
  reason,
  user,
}) => {
  return axios.patch("/api/facility/add-or-remove-credits", {
    amount,
    facilityID,
    reason,
    user,
  });
};

export const addRestrictedShipper = async ({
  facilityID,
  shipperCompanyID,
  userID,
}) => {
  return axios.patch("/api/facility/add-approved-shipper", {
    facilityID,
    shipperCompanyID,
    userID,
  });
};

export const removeRestrictedShipper = async ({
  facilityID,
  shipperCompanyID,
  userID,
}) => {
  return axios.patch("/api/facility/remove-approved-shipper", {
    facilityID,
    shipperCompanyID,
    userID,
  });
};

export const updateFacilityFavouriteProducts = async (body) => {
  return axios.patch("/api/facility/update-favourite-products", body);
};
