import { useQuery } from "@tanstack/react-query";

import { getMaintenanceProceduresByProduct } from "../../api/maintenanceProcedureAPI";

const useMaintenanceProceduresByProduct = (productID) => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ["maintenanceProcedure", productID],
    queryFn: () => getMaintenanceProceduresByProduct(productID),
    enabled: !!productID,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    error,
    isError,
    isLoading,
  };
};

export default useMaintenanceProceduresByProduct;
