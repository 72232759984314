export const findTierPricingCost = (productInCart) => {
  if (!productInCart.supplierProductDetail.useTierPricing)
    return productInCart.supplierProductDetail.cost;

  const tierPricing = productInCart.supplierProductDetail.tierPricing;
  const quantity = productInCart.quantity;

  const tier = tierPricing.find(
    (tier, i, items) =>
      tier.quantity <= quantity &&
      (!items[i + 1] || items[i + 1].quantity > quantity)
  );
  return tier.cost;
};
