import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateManufacturerProductDetail } from "../../api/manufacturerProductDetailsAPI";

const useUpdateManufacturerProductDetail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateManufacturerProductDetail,
    onSuccess: () => {
      queryClient.invalidateQueries("manufacturerProductDetails");
    },
  });
};

export default useUpdateManufacturerProductDetail;
