import { useQuery } from "@tanstack/react-query";
import { getSupplierPallets } from "../../api/supplierProductDetailsAPI";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSupplierPallets } from "../../redux/reducers/productsSlice";

const useSupplierPallets = ({ enabled }) => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ["supplierPallets"],
    queryFn: getSupplierPallets,
    staleTime: 10 * 60 * 1000,
    enabled,
    refetchOnWindowFocus: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!data) return;
    dispatch(setSupplierPallets(data));
  }, [data, dispatch]);

  return {
    data,
    error,
    isError,
    isLoading,
  };
};

export default useSupplierPallets;
