import { useQuery } from "@tanstack/react-query";

import { getMaintenanceProcedureNamesByFacility } from "../../api/maintenanceProcedureAPI";

const useMaintenanceProcedureNamesByFacility = (facilityID) => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ["maintenanceProcedureNames", facilityID],
    queryFn: () => getMaintenanceProcedureNamesByFacility(facilityID),
    enabled: !!facilityID,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    error,
    isError,
    isLoading,
  };
};

export default useMaintenanceProcedureNamesByFacility;
