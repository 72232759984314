import { pushNotificationSetFirebaseToken } from "../../api/pushNotificationAPI";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const usePushNotificationSetFirebaseToken = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: pushNotificationSetFirebaseToken,
    onSuccess: () => {
      queryClient.invalidateQueries("pushNotificationSettings");
    },
  });
};

export default usePushNotificationSetFirebaseToken;
