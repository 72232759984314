import { Button } from "@mui/material";
import React, { useState } from "react";
import { CustomModal } from "../sharedComponents";
import FeedbackForm from "./FeedbackForm";

const Feedback = () => {
  const [feedbackOpen, setFeedbackOpen] = useState(false);

  const closeFeedback = async (event, reason) => {
    if (reason === "backdropClick") return;

    setFeedbackOpen(false);
  };

  return (
    <>
      <Button variant={"contained"} onClick={() => setFeedbackOpen(true)}>
        Send Feedback
      </Button>

      <CustomModal open={feedbackOpen} close={closeFeedback} title="Feedback">
        <FeedbackForm close={closeFeedback} />
      </CustomModal>
    </>
  );
};

export default Feedback;
