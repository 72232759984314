import { useQuery } from "@tanstack/react-query";

import { getFacilitiesNamesByProduct } from "../../api/facilityAPI";

const useGetFacilitiesNamesByProduct = (id) => {
  const {
    data: facilities = [],
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["facilities", id],
    queryFn: () => getFacilitiesNamesByProduct(id),
    enabled: !!id,
  });

  return {
    error,
    facilities,
    isError,
    isLoading,
  };
};

export default useGetFacilitiesNamesByProduct;
