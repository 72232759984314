import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import CustomDatePicker from "../sharedComponents/datePicker/CustomDatePicker";
import useRequestServiceContractServiceDateChange from "../../customHooks/serviceContractOrder/useRequestServiceContractServiceDateChange";
import useRequestServiceOrderServiceDateChange from "../../customHooks/serviceOrder/useRequestServiceOrderServiceDateChange";

const RequestServiceDateChangeForm = ({
  close,
  previousServiceDate,
  serviceContract,
  serviceRequest,
}) => {
  const [newServiceDate, setNewServiceDate] = useState();
  const [reason, setReason] = useState("");
  const [saving, setSaving] = useState(false);

  const requestServiceOrderServiceDateChange =
    useRequestServiceOrderServiceDateChange();
  const requestServiceContractServiceDateChange =
    useRequestServiceContractServiceDateChange();

  const onSubmit = async () => {
    setSaving(true);

    if (serviceRequest) {
      await requestServiceOrderServiceDateChange.mutateAsync({
        serviceOrderID: serviceRequest._id,
        newServiceDate: newServiceDate.toISOString(),
        previousServiceDate: previousServiceDate,
        reason,
      });
    } else {
      await requestServiceContractServiceDateChange.mutateAsync({
        serviceContractID: serviceContract._id,
        newServiceDate: newServiceDate.toISOString(),
        previousServiceDate: previousServiceDate.toISOString(),
        reason,
      });
    }

    setSaving(false);
    close(null, null, true);
  };

  return (
    <Grid container spacing={2}>
      {saving ? (
        <Grid item xs={12}>
          <Typography>
            Your service provider has received the request to change this
            service date.
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography>
              To reschedule your service, please fill in the details below:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label="Reason for Rescheduling"
              variant="outlined"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomDatePicker
              label="New Service Date"
              value={newServiceDate}
              onChange={(date) => setNewServiceDate(date)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              fullWidth
              disabled={!reason}
            >
              Request Service Date Change
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default RequestServiceDateChangeForm;
