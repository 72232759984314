import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateProductAddRequestingFacility } from "../../api/productAPI";

const useUpdateProductAddRequestingFacility = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateProductAddRequestingFacility,
    onSuccess: () => {
      queryClient.invalidateQueries("products");
    },
  });
};

export default useUpdateProductAddRequestingFacility;
