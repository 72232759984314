import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addProduct } from "../../api/productAPI";

const useAddProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addProduct,
    onSuccess: () => {
      queryClient.invalidateQueries("products");
      queryClient.invalidateQueries(["productsSupplier"]);
      queryClient.invalidateQueries(["productsManufacturer"]);
    },
  });
};

export default useAddProduct;
