import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addShippingCost } from "../../api/productOrderAPI";

const useAddShippingCost = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addShippingCost,
    onSuccess: () => {
      queryClient.invalidateQueries("orders");
      queryClient.invalidateQueries("order");
    },
  });
};

export default useAddShippingCost;
