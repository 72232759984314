import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useContact from "../../../customHooks/contacts/useContact";
import { useSelector } from "react-redux";
import { selectAppUserLevel } from "../../../redux/reducers/userLevelsSlice";
import useSurveysByContact from "../../../customHooks/surveys/useSurveyByContact";
import useAddSurveyResponse from "../../../customHooks/surveys/useAddSurveyResponse";

const SurveyResponse = ({
  completedResponses,
  close,
  displayOnly,
  surveyProp,
}) => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const { contact } = useContact();

  const { surveys } = useSurveysByContact({
    appUserLevel,
    contactID: contact._id,
    firstLogInDate: contact.firstLogInDate,
  });
  const addSurveyResponse = useAddSurveyResponse();

  const [openSurvey, setOpenSurvey] = useState(true);
  const [responses, setResponses] = useState(completedResponses ?? []);
  const [valid, setValid] = useState(false);

  const survey = surveyProp ?? surveys[0];

  useEffect(() => {
    if (!survey) return;

    const requiredQuestions = survey.questions.filter(
      (question) => question.required
    );
    const requiredQuestionIDs = requiredQuestions.map(
      (question) => question._id
    );
    const answeredQuestionIDs = responses.map(
      (response) => response.questionID
    );
    const allRequiredQuestionsAnswered = requiredQuestionIDs.every(
      (questionID) => answeredQuestionIDs.includes(questionID)
    );
    setValid(allRequiredQuestionsAnswered);
  }, [responses, survey]);

  const closeSurvey = (event, reason) => {
    if (reason === "backdropClick") return;

    if (!completedResponses && !displayOnly) {
      const surveyResponse = {
        contact: contact._id,
        survey: survey._id,
        responses,
        completedAt: new Date(),
        fullyCompleted: false,
      };

      addSurveyResponse.mutate(surveyResponse);
    }

    setOpenSurvey(false);
    if (close) close();
  };

  const comeBackLater = (event, reason) => {
    if (reason === "backdropClick") return;
    setOpenSurvey(false);
    if (close) close();
  };

  const setQuestionResponse = (
    questionID,
    answerData,
    isMultipleChoice = false
  ) => {
    const tempResponses = [...responses];
    const existingResponseIndex = tempResponses.findIndex(
      (resp) => resp.questionID === questionID
    );

    if (existingResponseIndex !== -1) {
      if (isMultipleChoice) {
        const existingAnswerIndex = tempResponses[
          existingResponseIndex
        ].answers.findIndex((ans) => ans.option === answerData.option);
        if (existingAnswerIndex !== -1) {
          tempResponses[existingResponseIndex].answers[existingAnswerIndex] =
            answerData;
        } else {
          tempResponses[existingResponseIndex].answers.push(answerData);
        }
      } else {
        tempResponses[existingResponseIndex].answer = answerData;
      }
    } else {
      if (isMultipleChoice) {
        tempResponses.push({ questionID, answers: [answerData] });
      } else {
        tempResponses.push({ questionID, answer: answerData });
      }
    }

    setResponses(tempResponses);
  };

  const onSubmit = () => {
    const surveyResponse = {
      contact: contact._id,
      survey: survey._id,
      responses,
      completedAt: new Date(),
      fullyCompleted: true,
    };

    addSurveyResponse.mutate(surveyResponse);
    setOpenSurvey(false);
  };

  if (!survey) return null;

  return (
    <Modal
      sx={{
        mt: 2,
        overflow: "scroll",
      }}
      open={openSurvey}
      onClose={closeSurvey}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid
        container
        maxWidth={"md"}
        sx={{
          margin: "auto",
        }}
      >
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <Grid
                  container
                  spacing={2}
                  item
                  xs={12}
                  display="flex"
                  justifyContent={"center"}
                >
                  <Grid item xs={4}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={comeBackLater}
                    >
                      Come Back Later
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button variant="contained" fullWidth onClick={closeSurvey}>
                      Skip Survey
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">Hi {contact.name},</Typography>
                  </Grid>
                </Grid>
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                {survey.questions.map((question, index) => {
                  switch (question.questionType) {
                    case "Multiple Choice":
                      return (
                        <Grid item xs={12} key={question._id}>
                          <Typography>{question.questionText}</Typography>
                          {question.options?.map((option, optIndex) => (
                            <Grid item xs={12} key={option._id}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={responses
                                      .find(
                                        (resp) =>
                                          resp.questionID === question._id
                                      )
                                      ?.answers?.find(
                                        (ans) => ans.option === option
                                      )}
                                    onChange={(e) => {
                                      const answerData = {
                                        option: option,
                                        answer: e.target.checked,
                                      };
                                      setQuestionResponse(
                                        question._id,
                                        answerData,
                                        true
                                      );
                                    }}
                                    disabled={!!completedResponses}
                                  />
                                }
                                label={option}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      );
                    case "Short Answer":
                      return (
                        <Grid item xs={12} key={question._id}>
                          <Typography>{question.questionText}</Typography>
                          <TextField
                            placeholder="Your answer here"
                            fullWidth
                            onChange={(e) =>
                              setQuestionResponse(question._id, e.target.value)
                            }
                            value={
                              responses.find(
                                (resp) => resp.questionID === question._id
                              )?.answer || ""
                            }
                            disabled={!!completedResponses}
                          />
                        </Grid>
                      );
                    case "Rating":
                      return (
                        <Grid
                          container
                          spacing={0}
                          item
                          xs={12}
                          key={question._id}
                          className={
                            question.questionText ===
                              "How would you rate your experience using drOPs?" &&
                            "centered-container"
                          }
                        >
                          <Grid item xs={12}>
                            <Typography
                              variant={
                                question.questionText ===
                                "How would you rate your experience using drOPs?"
                                  ? "h6"
                                  : "p"
                              }
                            >
                              {question.questionText}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Rating
                              size={
                                question.questionText ===
                                "How would you rate your experience using drOPs?"
                                  ? "large"
                                  : "medium"
                              }
                              onChange={(event, newValue) =>
                                setQuestionResponse(
                                  question._id,
                                  newValue.toString()
                                )
                              }
                              value={parseInt(
                                responses.find(
                                  (resp) => resp.questionID === question._id
                                )?.answer || "0",
                                10
                              )}
                              disabled={!!completedResponses}
                            />
                          </Grid>
                        </Grid>
                      );
                    case "Yes/No":
                      return (
                        <Grid item xs={12} key={question._id}>
                          <Typography>{question.questionText}</Typography>
                          <RadioGroup
                            onChange={(e) =>
                              setQuestionResponse(question._id, e.target.value)
                            }
                            value={
                              responses.find(
                                (resp) => resp.questionID === question._id
                              )?.answer || ""
                            }
                            row
                          >
                            <FormControlLabel
                              value={true}
                              control={
                                <Radio disabled={!!completedResponses} />
                              }
                              label="Yes"
                            />
                            <FormControlLabel
                              value={false}
                              control={
                                <Radio disabled={!!completedResponses} />
                              }
                              label="No"
                            />
                          </RadioGroup>
                        </Grid>
                      );
                    default:
                      return null;
                  }
                })}
                <Grid item xs={12}>
                  <Typography>Thank you very much for your time.</Typography>
                  <br />
                  <Typography fontWeight={"bold"}>Jacinthe</Typography>
                  <Typography>Customer Success</Typography>
                  <Typography>Drop Solutions Inc.</Typography>
                </Grid>
                {!completedResponses && !displayOnly && (
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={onSubmit}
                      disabled={!valid}
                    >
                      Submit Survey
                    </Button>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Modal>
  );
};
export default SurveyResponse;
