import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../../firebaseMessaging";
import { useCallback, useEffect, useState } from "react";
import keys from "../../config/keys";
import usePushNotificationSetFirebaseToken from "../../customHooks/pushNotifications/usePushNotificationSetFirebaseToken";
import useContact from "../../customHooks/contacts/useContact";
import { checkForIPhoneBefore16_4 } from "../../sharedFunctions/checkForIPhone16_4";

const NotificationHandler = () => {
  const { contact } = useContact();
  const [tokenSetSuccess, setTokenSetSuccess] = useState(false);

  const pushNotificationSetFirebaseToken =
    usePushNotificationSetFirebaseToken();

  const memoizedPushNotificationSetFirebaseToken = useCallback(
    ({ token }) => {
      pushNotificationSetFirebaseToken.mutate({ token });
      setTokenSetSuccess(true);
    },
    [pushNotificationSetFirebaseToken]
  );

  useEffect(() => {
    if (!contact) return;
    if (contact?.pushNotificationSettings?.disablePushNotifications) return;
    if (tokenSetSuccess) return;
    if (!("Notification" in window)) return;
    if (Notification.permission === "denied") return;

    getToken(messaging, {
      vapidKey: keys.FIREBASE_VAPID_KEY,
    })
      .then((token) => {
        memoizedPushNotificationSetFirebaseToken({ token });
      })
      .catch((error) => {
        if (checkForIPhoneBefore16_4())
          return console.error("Device not supported For Notifications");
        if (error.code === "messaging/permission-blocked")
          return console.error("Notification Permissions blocked");
        throw error;
      });
  }, [contact, memoizedPushNotificationSetFirebaseToken, tokenSetSuccess]);

  onMessage(messaging, (payload) => {
    if ("serviceWorker" in navigator && "Notification" in window) {
      navigator.serviceWorker.ready.then(function (registration) {
        registration.showNotification(payload.notification.title, {
          body: payload.notification.body,
          icon: "https://drops-staging.herokuapp.com/drOPsLogoWhiteBack.png",
        });
      });
    } else {
      new Notification(payload.notification.title, {
        body: payload.notification.body,
        icon: "https://drops-staging.herokuapp.com/drOPsLogoWhiteBack.png",
      });
    }
  });
};

export default NotificationHandler;
