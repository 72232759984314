import { useQuery } from "@tanstack/react-query";
import { getOrderByID } from "../../api/orderAPI";

const useOrder = ({ orderID, orderNumber }) => {
  const { data: order, isInitialLoading: isLoading } = useQuery({
    queryKey: ["order", orderID, orderNumber],
    queryFn: () => getOrderByID({ orderID, orderNumber }),
    enabled: !!orderID,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { order, isLoading };
};

export default useOrder;
