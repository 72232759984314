import { useQuery } from "@tanstack/react-query";
import { getFacilityProductNames } from "../../api/facilityAPI";

const useFacilityProductNames = (facilityID) => {
  const { data: facilityProducts = [], isLoading } = useQuery({
    queryKey: ["facilityProductNames", facilityID],
    queryFn: () => getFacilityProductNames(facilityID),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10,
    enabled: !!facilityID,
  });

  return { facilityProducts, isLoading };
};

export default useFacilityProductNames;
