import * as Sentry from "@sentry/browser";

export const loadState = (store) => {
  try {
    const serializedState = localStorage.getItem(store);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    throw error;
  }
};

const getSize = () => {
  let total = 0;
  for (let i = 0; i < localStorage.length; i++) {
    total += localStorage.getItem(localStorage.key(i)).length;
  }
  return total;
};

export const saveState = (state) => {
  try {
    const MAX_STORAGE_SIZE = 5 * 1024 * 1024; // 5 MB
    const { facilities, formData, products, user, userLevels } = state;
    localStorage.clear();

    if (JSON.stringify(userLevels).length + getSize() >= MAX_STORAGE_SIZE)
      return;
    localStorage.setItem("userLevels", JSON.stringify(userLevels));
    if (JSON.stringify(user).length + getSize() >= MAX_STORAGE_SIZE) return;
    localStorage.setItem("user", JSON.stringify(user));
    if (JSON.stringify(products).length + getSize() >= MAX_STORAGE_SIZE) return;
    localStorage.setItem("products", JSON.stringify(products));
    if (JSON.stringify(formData).length + getSize() >= MAX_STORAGE_SIZE) return;
    localStorage.setItem("formData", JSON.stringify(formData));
    if (JSON.stringify(facilities).length + getSize() >= MAX_STORAGE_SIZE)
      return;
    localStorage.setItem("facilities", JSON.stringify(facilities));
  } catch (error) {
    const { facilities, formData, products, user, userLevels } = state;
    Sentry.captureException(error, {
      extra: {
        action: "saveState",
        state: state,
        userLevelSize: JSON.stringify(userLevels).length + getSize(),
        userSize: JSON.stringify(user).length + getSize(),
        productsSize: JSON.stringify(products).length + getSize(),
        formDataSize: JSON.stringify(formData).length + getSize(),
        facilitiesSize: JSON.stringify(facilities).length + getSize(),
      },
    });
    throw error;
  }
};

export const clearState = (state) => {
  try {
    const { contacts, facilities, formData, products, user, userLevels } =
      state;
    localStorage.removeItem("contacts", JSON.stringify(contacts));
    localStorage.removeItem("facilities", JSON.stringify(facilities));
    localStorage.removeItem("formData", JSON.stringify(formData));
    localStorage.removeItem("products", JSON.stringify(products));
    localStorage.removeItem("userLevels", JSON.stringify(userLevels));
    localStorage.removeItem("user", JSON.stringify(user));
  } catch (error) {
    throw error;
  }
};
