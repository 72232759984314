import { useMutation, useQueryClient } from "@tanstack/react-query";

import { removeProductMaintenanceProcedure } from "../../api/maintenanceProcedureAPI";

const useRemoveProductMaintenanceProcedure = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: removeProductMaintenanceProcedure,
    onSuccess: async () => {
      await queryClient.invalidateQueries("maintenanceProcedure");
    },
  });
};

export default useRemoveProductMaintenanceProcedure;
