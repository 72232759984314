import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateProductOrder } from "../../api/productOrderAPI";

const useUpdateProductOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateProductOrder,
    onSuccess: () => {
      queryClient.invalidateQueries("orders");
    },
  });
};

export default useUpdateProductOrder;
