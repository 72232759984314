import { useQuery } from "@tanstack/react-query";
import { getProductsByManufacturer } from "../../api/productAPI";

const useProductsByManufacturer = ({ contactID }) => {
  const { data: products = [], isLoading } = useQuery({
    queryKey: ["productsManufacturer"],
    queryFn: () => getProductsByManufacturer({ contactID }),
    enabled: !!contactID,
  });

  return { products, isLoading };
};

export default useProductsByManufacturer;
