import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { CustomModal } from "../../sharedComponents";
import PDFPreview from "../../sharedComponents/PDFPreview";
import PDFViewer from "../../sharedComponents/PDFViewer";
import useImage from "../../../customHooks/image/useImage";

const ImagePreview = ({ item }) => {
  const { file } = useImage(item.imageID);
  const [fileOpen, setFileOpen] = useState(false);

  const closeFileModal = (event, reason) => {
    if (reason !== "backdropClick") setFileOpen(false);
  };

  const openFileModal = () => {
    setFileOpen(true);
  };

  if (!item) return;

  if (item.img)
    return (
      <>
        <CustomModal open={fileOpen} close={closeFileModal}>
          <Grid container className="centered-container">
            {item.img.type === "image/png" ||
            item.img.type === "image/jpg" ||
            item.img.type === "image/jpeg" ? (
              <img
                src={URL.createObjectURL(item.img)}
                alt={item.label}
                width="100%"
              />
            ) : (
              <PDFViewer file={URL.createObjectURL(item.img)} />
            )}
          </Grid>
        </CustomModal>

        {item.img.type === "image/png" ||
        item.img.type === "image/jpg" ||
        item.img.type === "image/jpeg" ? (
          <Grid item xs={12}>
            <Grid
              container
              item
              xs={12}
              sx={{ width: 300 }}
              className="centered-container"
            >
              <img
                className="maintenance-form-img"
                src={URL.createObjectURL(item.img)}
                alt={item.label}
              />
              <Grid item xs={12} sx={{ marginTop: 0.5 }}>
                <Button variant="contained" onClick={() => openFileModal()}>
                  View
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid
              container
              item
              xs={12}
              sx={{ width: 300 }}
              className="centered-container"
            >
              <PDFPreview file={URL.createObjectURL(item.img)} />
              <Grid item xs={12} sx={{ marginTop: 0.5 }}>
                <Button variant="contained" onClick={() => openFileModal()}>
                  View
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );

  if (item.imageID) {
    return (
      <>
        <CustomModal open={fileOpen} close={closeFileModal}>
          <Grid container className="centered-container">
            {item.contentType === "image/png" ||
            item.contentType === "image/jpg" ||
            item.contentType === "image/jpeg" ? (
              <img src={file} alt={item.label} width="100%" />
            ) : (
              <PDFViewer file={file} />
            )}
          </Grid>
        </CustomModal>

        {item.contentType === "image/png" ||
        item.contentType === "image/jpg" ||
        item.contentType === "image/jpeg" ? (
          <Grid item xs={12}>
            <Grid
              container
              item
              xs={12}
              sx={{ width: 300 }}
              className="centered-container"
            >
              <img
                className="maintenance-form-img"
                src={file}
                alt={item.label}
              />
              <Grid item xs={12} sx={{ mt: 0.5 }}>
                <Button variant="contained" onClick={() => openFileModal()}>
                  View
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid
              container
              item
              xs={12}
              sx={{ width: 300 }}
              className="centered-container"
            >
              <PDFPreview file={file} />
              <Grid item xs={12} sx={{ mt: 0.5 }}>
                <Button variant="contained" onClick={() => openFileModal()}>
                  View
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  }

  if (item.imageSRC) {
    let data = item?.imageSRC?.data;
    if (item?.imageSRC?.data?.data) {
      let buff = Buffer.from(item?.imageSRC?.data?.data);
      data = buff.toString("base64");
    }

    return (
      <img
        className="maintenance-form-img"
        src={`data:image/png;base64,${data}`}
        alt={item.label}
      />
    );
  }
};

export default ImagePreview;
