import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import { CustomModal } from "../sharedComponents";
import { useDispatch, useSelector } from "react-redux";
import {
  addToProductsInCartByWarehouse,
  selectProductsInCartByWarehouse,
  removeProductFromCartByWarehouse,
} from "../../redux/reducers/productsSlice";
import CloseIcon from "@mui/icons-material/Close";
import calculateProductPrice from "../../sharedFunctions/purchasingFunctions/calculateProductPrice";
import { GST } from "../../globalConstants";
import customDollarRound from "../../sharedFunctions/purchasingFunctions/customDollarRound";
import { getProductOptionLabel } from "../../sharedFunctions/labels";
import { getProductsInCartByWarehouse } from "./helperFunctions/getProductsInCartByWarehouse";
import ReturnableContainersShoppingCartForm from "../containerSupplies/ReturnableContainersShoppingCartForm";
import useSupplierProductDetailContainersByFacility from "../../customHooks/supplierProductDetails/useSupplierProductDetailContainersByFacility";

const ReturnableContainers = ({
  exchangeRates,
  facility,
  containerSupplies,
}) => {
  let credits = 0;
  const dispatch = useDispatch();
  const [containersToReturn, setContainersToReturn] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [supplier, setSupplier] = useState("");
  const [valid, setValid] = useState(false);
  const [warehouse, setWarehouse] = useState("");
  const productsInCartByWarehouse = useSelector(
    selectProductsInCartByWarehouse
  );
  const { data: supplierProductDetails } =
    useSupplierProductDetailContainersByFacility(facility._id);

  useEffect(() => {
    if (!containersToReturn || Object.keys(containersToReturn).length === 0)
      return;
    let isValid = true;
    for (const key in containersToReturn) {
      if (containersToReturn.hasOwnProperty(key)) {
        const containerValue = containersToReturn[key];

        if (containerValue < 0 || containerValue === "") {
          isValid = false;
          break;
        }
      }
    }

    // Not valid if all values are 0
    if (
      Object.values(containersToReturn).every((value) => parseInt(value) === 0)
    ) {
      isValid = false;
    }

    setValid(isValid);
  }, [containerSupplies, containersToReturn]);

  const closeModal = (event, reason, address) => {
    if (reason === "backdropClick") return;
    setModalOpen(false);
  };

  const onSubmit = () => {
    for (const key in containersToReturn) {
      if (containersToReturn.hasOwnProperty(key)) {
        const containerSupply = containerSupplies.find(
          (containerSupply) => containerSupply._id === key
        );
        const containerAmount = containersToReturn[key];

        if (containerAmount > 0) {
          const containerToReturn = containerSupplies.find(
            (returnableContainer) => returnableContainer._id === key
          );

          const supplierProductDetail = supplierProductDetails.find(
            (supplierProductDetail) =>
              supplierProductDetail.supplier._id === supplier._id &&
              supplierProductDetail.product._id ===
                containerToReturn.container._id
          );

          const containerProduct = {
            closestWarehouse: warehouse,
            chemical: containerSupply.chemical,
            quantity: containerAmount,
            supplierProductDetail: { ...supplierProductDetail, isReturn: true },
          };

          dispatch(addToProductsInCartByWarehouse(containerProduct));
        }
      }
    }
    closeModal();
  };

  const renderReturnableContainerProducts = () => {
    return getProductsInCartByWarehouse(productsInCartByWarehouse)
      .filter((productInCart) => productInCart.supplierProductDetail.isReturn)
      .map((productInCart, i) => {
        credits +=
          calculateProductPrice(
            productInCart.supplierProductDetail.cost,
            exchangeRates.find(
              (exchangeRate) =>
                exchangeRate.currency ===
                productInCart.supplierProductDetail.supplier.currency
            )?.rate,
            null
          ) * productInCart.quantity;
        return (
          <Grid
            item
            xs={12}
            container
            spacing={1}
            key={
              productInCart.chemical?._id ??
              productInCart.supplierProductDetail.product._id
            }
          >
            <Grid
              container
              spacing={0}
              item
              xs={6}
              className="vertical-centered-container"
            >
              <Grid item xs={9}>
                <Typography>{`${
                  productInCart.chemical
                    ? getProductOptionLabel(productInCart.chemical)
                    : productInCart.supplierProductDetail.product.containerType
                } Returned`}</Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  autoComplete="off"
                  value={productInCart.quantity}
                  size="small"
                  sx={{
                    width: 50,
                  }}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              item
              xs={6}
              className="vertical-centered-container"
            >
              <Grid item xs={10}>
                <Typography
                  sx={{ display: "flex", justifyContent: "end" }}
                  color="limegreen"
                >
                  Credits: $
                  {(
                    calculateProductPrice(
                      productInCart.supplierProductDetail.cost,
                      exchangeRates.find(
                        (exchangeRate) =>
                          exchangeRate.currency ===
                          productInCart.supplierProductDetail.supplier.currency
                      )?.rate,
                      null
                    ) * productInCart.quantity
                  ).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  aria-label="Remove Product"
                  size="small"
                  onClick={() => {
                    dispatch(removeProductFromCartByWarehouse(productInCart));
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        );
      });
  };

  const renderTotals = () => {
    if (credits <= 0) return;

    return (
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}
      >
        <Typography color="limegreen">
          Subtotal: ${parseFloat(credits).toFixed(2)}
        </Typography>
        {facility.hasGST && (
          <Typography color="limegreen">
            GST: ${customDollarRound(credits * GST).toFixed(2)}
          </Typography>
        )}
        <Typography color="limegreen">
          Pending Credits: $
          {facility.hasGST
            ? customDollarRound(credits * (1 + GST)).toFixed(2)
            : parseFloat(credits).toFixed(2)}
        </Typography>
      </Grid>
    );
  };
  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Add Returnable Containers
        </Button>
      </Grid>
      {renderReturnableContainerProducts()}
      {renderTotals()}

      <CustomModal
        close={closeModal}
        open={modalOpen}
        title={"Add Returnable Containers"}
      >
        <Grid container spacing={2}>
          <ReturnableContainersShoppingCartForm
            containerSupplies={containerSupplies}
            containersToReturn={containersToReturn}
            facility={facility}
            setContainersToReturn={setContainersToReturn}
            supplier={supplier}
            setSupplier={setSupplier}
            setWarehouse={setWarehouse}
            hasShippingToggle={false}
          />
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              disabled={!valid}
              onClick={onSubmit}
            >
              Add Containers To Order
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </Grid>
  );
};
export default ReturnableContainers;
