import React, { useEffect } from "react";
import TermsAndConditions from "../termsAndConditions/TermsAndConditions";
import SplashScreen from "../splashScreen/SplashScreen";
import { useAxiosInterceptor } from "../../customHooks/useAxiosInterceptor";
import AppError from "../ErrorBoundaries/AppError";
import useContact from "../../customHooks/contacts/useContact";
import { getAppUserLevel } from "../../sharedFunctions/userLevels";
import {
  selectSuperUserUserAccessEmail,
  setAppUserLevel,
} from "../../redux/reducers/userLevelsSlice";
import { useDispatch, useSelector } from "react-redux";
import EmailConfirmation from "./EmailConfirmation";
import { setUser } from "../../redux/reducers/userSlice";
import { useAuth0 } from "@auth0/auth0-react";

const ContactProvider = ({ children }) => {
  //TODO: consider moving axios Interceptor to a wrapper above this?
  useAxiosInterceptor();
  const { user } = useAuth0();
  const { contact, isLoading, isError, isVerified } = useContact();
  const superUserUserAccessEmail = useSelector(selectSuperUserUserAccessEmail);

  const dispatch = useDispatch();

  useEffect(() => {
    if (contact) {
      const tempUserLevels = getAppUserLevel(contact);
      dispatch(setAppUserLevel(tempUserLevels));
      const { profilePicture } = contact;

      if (contact.useSocialLogInProfilePicture ?? !profilePicture) {
        contact.profilePicture = user.picture;
      }
      dispatch(setUser({ ...contact }));
    }
  }, [contact, dispatch, user.picture]);

  if (isLoading) return <SplashScreen />;
  if (isError) return <AppError fullPage />;
  if (!isVerified) return <EmailConfirmation />;
  if (!contact?.acceptedTermsAndConditions && !superUserUserAccessEmail)
    return <TermsAndConditions contact={contact} />;

  // return <button onClick={subscribeUser}>Click Here</button>;
  return children;
};
export default ContactProvider;
