import React, { useState } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { CustomModal, Loader } from "../sharedComponents";
import PDFViewer from "../sharedComponents/PDFViewer";
import moment from "moment";
import {
  getWSARG131PDF,
  getWSARG137PDF,
  getWSARG154PDF,
} from "../../api/reportsAPI";
import { useSearchParams } from "react-router-dom";
import useReportsByFacility from "../../customHooks/reports/useReportsByFacility";
import CustomToolbar from "../sharedComponents/tables/CustomToolbar";
import useFacilityAddress from "../../customHooks/facility/useFacilityAddress";
import DownloadBlankForms from "./DownloadBlankForms";
import Feedback from "../feedback/Feedback";

const ReportsTable = () => {
  const [searchParams] = useSearchParams();
  const facilityID = searchParams.get("facilityID");

  const { reports, isLoading: isLoadingReports } = useReportsByFacility({
    facilityID,
  });
  const { facility, isLoading: isLoadingFacility } =
    useFacilityAddress(facilityID);

  const [report, setReport] = useState(null);
  const [fileOpen, setFileOpen] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [reportFile, setReportFile] = useState(null);

  const getCurrentReport = async (report) => {
    if (!report) return null;
    setIsLoadingFile(true);
    let response;
    if (report.formType === "RG-131") {
      response = await getWSARG131PDF({ reportID: report._id });
    } else if (report.formType === "RG-137") {
      response = await getWSARG137PDF({ reportID: report._id });
    } else if (report.formType === "RG-154") {
      response = await getWSARG154PDF({ reportID: report._id });
    } else {
      setIsLoadingFile(false);
      return;
    }

    setIsLoadingFile(false);
    setReportFile(URL.createObjectURL(response));
    return URL.createObjectURL(response);
  };

  const downloadReport = async (report) => {
    try {
      let response;
      if (report.formType === "RG-131") {
        response = await getWSARG131PDF({ reportID: report._id });
      } else if (report.formType === "RG-137") {
        response = await getWSARG137PDF({ reportID: report._id });
      } else if (report.formType === "RG-154") {
        response = await getWSARG154PDF({ reportID: report._id });
      } else {
        return;
      }
      // Create a download link
      const downloadUrl = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `${report.formType}.pdf`);
      document.body.appendChild(link);
      // Trigger the download
      link.click();
      // Clean up the download link
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };

  const printReport = async (report) => {
    setReport(report);
    const file = await getCurrentReport(report);
    const pdfWindow = window.open(file);
    pdfWindow.onload = () => {
      // Trigger the print dialog
      pdfWindow.print();
    };
  };

  const openFileModal = async (report) => {
    setReport(report);
    await getCurrentReport(report);
    setFileOpen(true);
  };

  const closeFileModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setFileOpen(false);
    setReport(null);
    setReportFile(null);
  };

  const TableButtons = () => {
    return (
      <Grid
        container
        item
        spacing={2}
        display="flex"
        justifyContent={{ xs: "center", md: "flex-end" }}
      >
        <DownloadBlankForms />
      </Grid>
    );
  };

  const viewButton = (report) => {
    return (
      <Button
        variant="contained"
        onClick={() => openFileModal(report)}
        fullWidth
      >
        View
      </Button>
    );
  };

  const downloadButton = (report) => {
    return (
      <Button
        variant="contained"
        onClick={() => downloadReport(report)}
        fullWidth
      >
        Download
      </Button>
    );
  };

  const printButton = (report) => {
    const hasMissingData = report?.reportData?.some(
      (data) => data?.missingData
    );
    const isCurrentYear =
      new Date(report?.date).getFullYear() === new Date().getFullYear();
    return (
      <Button
        variant="contained"
        color={hasMissingData ? "error" : isCurrentYear ? "grey" : "primary"}
        onClick={() => printReport(report)}
        fullWidth
      >
        Print
      </Button>
    );
  };

  let columns = [
    {
      field: "formType",
      width: 220,
      headerName: "Form",
      renderCell: (params) => {
        switch (params.row?.formType) {
          case "RG-131":
            return "RG-131 - Raw Ground Water";
          case "RG-137":
            return "RG-137 - Raw Surface Water";
          case "RG-154":
            return "RG-154 - Treated Water";
          default:
            return "";
        }
      },
    },
    {
      field: "waterSource",
      headerName: "Water Source Name",
      width: 150,
      valueGetter: (value, row) => row?.waterSourceName ?? "",
    },
    {
      field: "date",
      width: 70,
      headerName: "Year",
      valueGetter: (value) => moment(value).format("YYYY") ?? "",
    },
    {
      field: "View Report",
      width: 100,
      renderCell: (params) => viewButton(params.row),
      filterable: false,
    },
    {
      field: "Print Report",
      width: 100,
      renderCell: (params) => printButton(params.row),
      filterable: false,
    },
    {
      field: "Notifications",
      width: 600,
      renderCell: (params) => {
        let notificationMessage = "";
        params.row.reportData.forEach((data, i) => {
          if (data?.missingData) {
            notificationMessage += `${data.month} Distribution Log`;

            if (notificationMessage !== "") {
              notificationMessage += ", ";
            }
          }

          if (params.row.formType === "RG-131") {
            if (data?.dateMeasured === null) {
              notificationMessage += `${data.month} Well Level Measurements, `;
            }
          }
        });

        if (notificationMessage !== "") {
          notificationMessage = `Missing Data: ${notificationMessage}`;
        }

        if (
          new Date(params.row.date).getFullYear() ===
            new Date().getFullYear() &&
          notificationMessage === ""
        ) {
          notificationMessage = `Report Available Jan. 1, ${moment()
            .add(1, "year")
            .format("YYYY")}`;
        }
        return notificationMessage;
      },
    },
  ];

  if (isLoadingFacility || isLoadingReports) {
    return <Loader />;
  }

  return (
    <>
      <CustomModal open={fileOpen} close={closeFileModal}>
        {isLoadingFile ? (
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {downloadButton(report)}
            </Grid>
            <Grid item xs={12}>
              <PDFViewer file={reportFile} />
            </Grid>
          </Grid>
        )}
      </CustomModal>

      {facility &&
      facility.type === "Municipal" &&
      facility.addressObject.province === "SK" ? (
        <Grid container sx={{ mt: 2 }}>
          <Box
            sx={{
              height: window.innerHeight - 175,
              width: "100%",
            }}
          >
            <DataGrid
              rows={
                reports?.map((report) => {
                  return {
                    id: report._id,
                    ...report,
                  };
                }) ?? []
              }
              columns={columns}
              rowsPerPageOptions={[12, 25, 50, 100]}
              disableRowSelectionOnClick
              localeText={{
                noRowsLabel: "No Reports Found",
              }}
              slots={{
                toolbar: () => CustomToolbar({ Buttons: TableButtons }),
              }}
              loading={isLoadingReports}
            />
          </Box>
        </Grid>
      ) : (
        <Grid container spacing={2} sx={{ mt: "50px" }}>
          <Grid item xs={12}>
            <Typography>
              Currently only Water Security Agency reports are available.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Let us know what other reports you would like to see here!
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Feedback />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ReportsTable;
