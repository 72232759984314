import axios from "axios";

export const getOrderAdditionByID = async (orderAdditionID) => {
  return axios
    .get(`/api/order-addition/get-by-id?orderAdditionID=${orderAdditionID}`)
    .then((res) => res.data.orderAddition);
};

export const getOrderAdditionByPendingOrderID = async (orderID) => {
  return axios
    .get(`/api/order-addition/get-by-pending-order-id?orderID=${orderID}`)
    .then((res) => res.data.orderAddition);
};

export const addOrderAddition = async ({ body }) => {
  return axios
    .post("/api/order-addition/add", { body })
    .then((res) => res.data.orderAddition);
};

export const updateOrderAddition = async ({ body, orderAdditionID }) => {
  return axios.put("/api/order-addition/update", { body, orderAdditionID });
};

export const deleteOrderAddition = async ({ orderAdditionID }) => {
  return axios.delete(
    `/api/order-addition/delete?orderAdditionID=${orderAdditionID}`
  );
};
