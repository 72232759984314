import { useMutation, useQueryClient } from "@tanstack/react-query";
import { dropOffConfirmation } from "../../api/productOrderAPI";

const useDropOffConfirmation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: dropOffConfirmation,
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
      queryClient.invalidateQueries("orders");
      queryClient.invalidateQueries("order");
    },
  });
};

export default useDropOffConfirmation;
