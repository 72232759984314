import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateShipperCompanyUserRole } from "../../api/shipperCompanyAPI";

const useUpdateShipperCompanyUserRole = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateShipperCompanyUserRole,
    onSuccess: () => {
      queryClient.invalidateQueries("shipperCompany");
    },
  });
};

export default useUpdateShipperCompanyUserRole;
