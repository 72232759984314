export const getFacilityRecipients = (facility) => {
  return [
    ...(facility?.operators?.filter((operator) => operator.email) ?? []),
    ...(facility?.managers?.filter((manager) => manager.email) ?? []),
    ...(facility?.leadOperators?.filter((manager) => manager.email) ?? []),
    ...(facility?.administrators?.filter(
      (administrator) => administrator.email
    ) ?? []),
  ];
};
