import React, { useEffect, useState } from "react";

import { Autocomplete, TextField } from "@mui/material";

import InfoText from "../InfoText";
import useFacilityContacts from "../../../customHooks/facility/useFacilityContacts";

const FacilityEmailTextField = ({
  emails,
  facilityID,
  setEmails,
  multiple,
}) => {
  const { facilityContacts } = useFacilityContacts(facilityID);
  const [contactEmails, setContactEmails] = useState([]);

  useEffect(() => {
    const contacts = [];
    if (facilityContacts?.operators?.length)
      contacts.push(...facilityContacts.operators);
    if (facilityContacts?.managers?.length)
      contacts.push(...facilityContacts.managers);
    if (facilityContacts?.leadOperators?.length)
      contacts.push(...facilityContacts.leadOperators);
    if (facilityContacts?.administrators?.length)
      contacts.push(...facilityContacts.administrators);
    if (facilityContacts?.owners?.length)
      contacts.push(...facilityContacts.owners);

    const contactEmails = contacts
      .filter((contact) => contact?.email)
      .map((contact) => contact.email);

    setContactEmails(contactEmails);
  }, [
    facilityContacts,
    facilityContacts?.administrators,
    facilityContacts?.leadOperators,
    facilityContacts?.managers,
    facilityContacts?.operators,
    facilityContacts?.owners,
  ]);

  return (
    <div style={{ display: "flex" }}>
      <Autocomplete
        sx={{ width: "100%" }}
        multiple={multiple}
        id="Email"
        options={contactEmails}
        isOptionEqualToValue={(option, value) =>
          option === value || value === ""
        }
        value={emails}
        onChange={(e, value) => setEmails(value)}
        renderInput={(params) => (
          <TextField
            autoComplete="off"
            {...params}
            label={multiple ? "Billing Emails" : "Contact Email"}
          />
        )}
      />
      {multiple ? (
        <InfoText text="The selected emails will receive invoices from orders." />
      ) : (
        <InfoText text="The selected email will be used to contact the facility contacts." />
      )}
    </div>
  );
};

export default FacilityEmailTextField;
