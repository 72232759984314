import { useQuery } from "@tanstack/react-query";
import { contactGetAll } from "../../api/contactAPI";

const useContacts = () => {
  const {
    data: contacts = [],
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["contacts"],
    queryFn: contactGetAll,
    refetchOnWindowFocus: false,
    staleTime: 10 * 60 * 1000,
  });

  return {
    contacts,
    error,
    isError,
    isLoading,
  };
};

export default useContacts;
