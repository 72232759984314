import { useQuery } from "@tanstack/react-query";
import { getFacilityAddress } from "../../api/facilityAPI";

const useFacilityAddress = (facilityID) => {
  const {
    data: facility,
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["facilityAddress", facilityID],
    queryFn: () => getFacilityAddress(facilityID),
    enabled: !!facilityID,
    staleTime: 10 * 60 * 1000,
  });

  return { facility, error, isError, isLoading };
};

export default useFacilityAddress;
