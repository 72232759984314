import { useQuery } from "@tanstack/react-query";
import { getSupplierCompanyAvailableServiceDates } from "../../api/supplierCompaniesAPI";

export const useSupplierCompanyAvailableServiceDates = (id) => {
  const { data: availableServiceDates } = useQuery({
    queryKey: ["availableServiceDates", id],
    queryFn: () => getSupplierCompanyAvailableServiceDates(id),
    enabled: !!id,
  });

  return { availableServiceDates };
};

export default useSupplierCompanyAvailableServiceDates;
