import { useEffect, useState } from "react";
import useContacts from "../../../customHooks/contacts/useContacts";
import { productTypes } from "../../../globalConstants";

const defaultSubTypeOptions = [
  "Antiscalant",
  "Bag",
  "Cartridge",
  "Coagulant",
  "Corrosion Inhibitor",
  "Dosing Pump",
  "Air Compressor",
  "Air Blower",
  "Filter Cartridge Housing",
  "Instrument",
  "Membrane Pressure Vessel",
  "Mineral",
  "MF",
  "NF",
  "Other",
  "Piping and Tubing",
  "RO",
  "RO/NF Cleaning Chemical",
  "UF",
  "UF/MF Cleaning Chemical",
  "Valve",
  "Water Pump",

  "Chemicals",
  "Cleaning Accessories",
  "Cleaning Tools",
  "Facility Essentials",
  "Other",
];

const typeOptions = productTypes;

const useProductFilter = () => {
  const [typeFilters, setTypeFilters] = useState([]);
  const [subTypeOptions, setSubTypeOptions] = useState(defaultSubTypeOptions);

  const { contacts } = useContacts();
  const manufacturers = contacts
    .filter((contact) => contact.isManufacturer)
    .map((contact) => contact.name);

  const onFilterModelChange = (model) => {
    let temp = [];
    model.items.forEach((item) => {
      if (item.field === "type") {
        temp = item.value;
      }
    });
    if (JSON.stringify(temp) !== JSON.stringify(typeFilters))
      setTypeFilters(temp);
  };

  useEffect(() => {
    if (!typeFilters || !typeFilters.length)
      return setSubTypeOptions(defaultSubTypeOptions);
    const options = [];

    if (typeFilters.includes("Chemical"))
      options.push(
        "Antiscalant",
        "Coagulant",
        "Corrosion Inhibitor",
        "RO/NF Cleaning Chemical",
        "UF/MF Cleaning Chemical",
        "Other"
      );
    if (typeFilters.includes("Equipment"))
      options.push(
        "Air Compressor",
        "Air Blower",
        "Dosing Pump",
        "Filter Cartridge Housing",
        "Instrument",
        "Membrane Pressure Vessel",
        "Piping and Tubing",
        "Valve",
        "Water Pump"
      );
    if (typeFilters.includes("Filter")) options.push("Bag", "Cartridge");
    if (
      typeFilters.includes("Spiral Wound Membrane") ||
      typeFilters.includes("Hollow Fiber Membrane")
    )
      options.push("MF", "NF", "RO", "UF");

    setSubTypeOptions(options);
  }, [typeFilters]);

  return {
    manufacturers,
    onFilterModelChange,
    typeOptions,
    subTypeOptions,
  };
};

export default useProductFilter;
