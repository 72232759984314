import { useQuery } from "@tanstack/react-query";
import { contactGetAllBusiness } from "../../api/contactAPI";

const useContactBusinesses = () => {
  const {
    data: contactBusinesses = [],
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["contactBusinesses"],
    queryFn: contactGetAllBusiness,
  });

  return {
    contactBusinesses,
    error,
    isError,
    isLoading,
  };
};

export default useContactBusinesses;
