import { useQuery } from "@tanstack/react-query";

import { getReportsByFacility } from "../../api/reportsAPI";

const useReportsByFacility = ({ facilityID }) => {
  const {
    data: reports,
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["reports", facilityID],
    queryFn: () => getReportsByFacility({ facilityID }),
    enabled: !!facilityID,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    reports,
    error,
    isError,
    isLoading,
  };
};

export default useReportsByFacility;
