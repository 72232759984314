import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { fetchOrCreateContact } from "../../api/contactAPI";
import { selectSuperUserUserAccessEmail } from "../../redux/reducers/userLevelsSlice";

const useContact = () => {
  const { user } = useAuth0();
  const superUserUserAccessEmail = useSelector(selectSuperUserUserAccessEmail);
  const {
    data: contact,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["contact", user, superUserUserAccessEmail],
    queryFn: () =>
      fetchOrCreateContact(
        superUserUserAccessEmail ? { email: superUserUserAccessEmail } : user,
        superUserUserAccessEmail ? true : false
      ),
    enabled: !!superUserUserAccessEmail || !!user,
    staleTime: 10 * 60 * 1000, // 10 minutes
    refetchOnWindowFocus: false,
  });

  return {
    contact,
    isLoading,
    isError,
    isVerified: user?.email_verified,
  };
};

export default useContact;
