import { useMutation } from "@tanstack/react-query";
import { quickBooksAddFileAttachmentToBill } from "../../api/quickBooksAPI";

const useQuickBooksAddFileAttachmentToBill = () => {
  return useMutation({
    mutationFn: quickBooksAddFileAttachmentToBill,
  });
};

export default useQuickBooksAddFileAttachmentToBill;
