import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { CustomModal } from "../sharedComponents";
import usePickUpConfirmation from "../../customHooks/productOrder/usePickUpConfirmation";

const PickUpConfirmation = (props) => {
  const { close, order } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const pickUpConfirmation = usePickUpConfirmation();

  const closeModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setModalOpen(false);
  };

  const onSubmit = async () => {
    try {
      setSaving(true);

      await pickUpConfirmation.mutateAsync({ order: { _id: order._id } });

      setSaving(false);
      setModalOpen(false);
      close(null, null);
    } catch (error) {
      throw error;
    }
  };

  const renderPickUpConfirmation = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>
            Please confirm that order: {order.orderNumber} has been picked up by{" "}
            {order.recipient.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={onSubmit}
            disabled={saving}
          >
            {saving ? "Confirming Pick Up" : "Confirm Pick Up"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => setModalOpen(true)}
          >
            Open Pick Up Confirmation
          </Button>
        </Grid>
      </Grid>
      <CustomModal
        open={modalOpen}
        close={closeModal}
        title={"Confirm Pick Up"}
      >
        {renderPickUpConfirmation()}
      </CustomModal>
    </>
  );
};

export default PickUpConfirmation;
