export const getProductShippingPrice = ({
  amount,
  shippingRate,
  supplierProductDetail,
  warehouseDistance,
}) => {
  if (!amount || !shippingRate || !warehouseDistance) return;

  const distance = warehouseDistance / 1000;
  const { baseRate, distanceCoefficient, flatRate, weightCoefficient } =
    shippingRate;

  const weight = supplierProductDetail.manufacturerProductDetail?.weight;
  const totalWeight = amount * weight;

  if (!weight || !distance) return;

  const predictedBaseCost =
    weightCoefficient * totalWeight + distanceCoefficient * distance;
  const finalShippingCost = predictedBaseCost * baseRate + flatRate;

  if (isNaN(finalShippingCost)) return;

  return finalShippingCost;
};
