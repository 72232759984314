import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notificationCompleteNotificationsByItemID } from "../../api/notificationAPI";

const useCompleteNotificationByItemID = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: notificationCompleteNotificationsByItemID,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
      });
      queryClient.invalidateQueries({
        queryKey: ["facilityNotificationCount"],
      });
      queryClient.invalidateQueries({
        queryKey: ["supplierCompanyNotificationCount"],
      });
    },
  });
};

export default useCompleteNotificationByItemID;
