import { useQuery } from "@tanstack/react-query";
import { getAllTestingMaterialsSupplyNames } from "../../api/productAPI";

const useTestingMaterialsSupplyNames = (enabled) => {
  const { data = [], isLoading } = useQuery({
    queryKey: ["productNames", { type: "testingMaterials" }],
    queryFn: getAllTestingMaterialsSupplyNames,
    enabled,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};

export default useTestingMaterialsSupplyNames;
