export const checkIfValidAddress = (address) => {
  if (!address) return false;
  if (
    address.hasCoordinates &&
    (!address.coordinates ||
      !address.city ||
      !address.country ||
      !address.province ||
      !address.postalCode ||
      !address.thirdPartyAddress?.streetAddress ||
      !address.thirdPartyAddress?.city ||
      !address.thirdPartyAddress?.country ||
      !address.thirdPartyAddress?.province ||
      !address.thirdPartyAddress?.postalCode)
  )
    return false;

  if (
    !address.hasCoordinates &&
    (!address.streetAddress ||
      !address.city ||
      !address.country ||
      !address.province ||
      !address.postalCode)
  )
    return false;

  if (
    address.hasPOBox &&
    (!address.POBoxAddress?.POBox ||
      !address.POBoxAddress?.city ||
      !address.POBoxAddress?.country ||
      !address.POBoxAddress?.province ||
      !address.POBoxAddress?.postalCode)
  )
    return false;

  return true;
};
