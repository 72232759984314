import moment from "moment";

export const getUnavailableServiceDates = (
  serviceContracts,
  serviceRequests,
  unavailableDates
) => {
  const unavailableServiceDates = [];

  serviceRequests?.forEach((serviceRequest) => {
    serviceRequest.serviceDates?.forEach((serviceDate) => {
      if (!serviceDate.serviceDate) return;
      unavailableServiceDates.push(
        moment(serviceDate.serviceDate).format("YYYY-MM-DD")
      );
    });
  });
  serviceContracts?.forEach((serviceContract) => {
    serviceContract.serviceDates?.forEach((serviceDate) => {
      if (!serviceDate.serviceDate) return;
      if (moment(serviceDate.serviceDate).isBefore(moment(), "day")) return;
      unavailableServiceDates.push(
        moment(serviceDate.serviceDate).format("YYYY-MM-DD")
      );
    });
  });
  unavailableDates?.forEach((bookedDate) => {
    unavailableServiceDates.push(moment(bookedDate).format("YYYY-MM-DD"));
  });

  return unavailableServiceDates;
};
