import React from "react";
import { Button, Grid, Typography } from "@mui/material";

const ConfirmModal = (props) => {
  const { action, close, message } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">{message}</Typography>
      </Grid>
      <Grid item xs={6} md={3}>
        <Button variant="contained" onClick={close} fullWidth color="error">
          Cancel
        </Button>
      </Grid>
      <Grid item xs={6} md={3}>
        <Button
          variant="contained"
          onClick={() => close(null, null, true, action)}
          fullWidth
          color="success"
        >
          Confirm
        </Button>
      </Grid>
    </Grid>
  );
};

export default ConfirmModal;
