import { useMutation, useQueryClient } from "@tanstack/react-query";
import { removeContainerDepositsByProductID } from "../../api/supplierProductDetailsAPI";

const useRemoveContainerDepositsByProductID = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeContainerDepositsByProductID,
    onSuccess: () => {
      queryClient.invalidateQueries("supplierProductDetails");
    },
  });
};

export default useRemoveContainerDepositsByProductID;
