import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateFacilityAddress } from "../../api/facilityAPI";

const useUpdateFacilityAddress = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateFacilityAddress,
    onSuccess: () => {
      queryClient.invalidateQueries("facility");
    },
  });
};

export default useUpdateFacilityAddress;
