import React, { useMemo } from "react";

import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import useContact from "../customHooks/contacts/useContact";
import useContactThemeSettings from "../customHooks/contacts/useContactThemeSettings";

const ThemeProviderWrapper = ({ children }) => {
  const { contact } = useContact();
  const { themeSettings } = useContactThemeSettings(contact?._id);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = useMemo(() => {
    const darkMode = themeSettings?.useDefaultSettings
      ? prefersDarkMode
      : themeSettings?.darkMode;

    return createTheme({
      palette: {
        mode: darkMode ? "dark" : "light",
        primary: {
          main: "#1976d2",
        },
        grey: {
          main: "#d3d3d3",
        },
      },
      components: {
        MuiDataGrid: {
          styleOverrides: {
            row: {
              "&.Mui-selected": {
                backgroundColor: darkMode ? "#1976d2" : "#92c2f2",
              },
            },
          },
        },
        MuiMenuItem: {
          styleOverrides: {
            root: {
              "&.Mui-selected": {
                backgroundColor: darkMode ? "#1976d2" : "#92c2f2",
              },
            },
          },
        },
        MuiInputBase: !darkMode && {
          styleOverrides: {
            root: {
              "&.Mui-disabled": {
                "& .MuiInputBase-input": {
                  WebkitTextFillColor: "rgba(0, 0, 0, 1)",
                },
              },
            },
          },
        },
        MuiFormLabel: !darkMode && {
          styleOverrides: {
            root: {
              "&.Mui-disabled": {
                WebkitTextFillColor: "rgba(0, 0, 0, 1)",
              },
            },
          },
        },
        MuiFormControlLabel: !darkMode && {
          styleOverrides: {
            root: {
              "&.Mui-disabled": {
                WebkitTextFillColor: "rgba(0, 0, 0, 1)",
              },
            },
          },
        },
        MuiCssBaseline: {
          styleOverrides: {
            body: darkMode && {
              scrollbarColor: "#6b6b6b #2b2b2b",
              "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                backgroundColor: "#2b2b2b",
              },
              "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                borderRadius: 8,
                backgroundColor: "#6b6b6b",
                minHeight: 24,
                border: "3px solid #2b2b2b",
              },
              "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                {
                  backgroundColor: "#959595",
                },
              "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                {
                  backgroundColor: "#959595",
                },
              "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                {
                  backgroundColor: "#959595",
                },
              "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                backgroundColor: "#2b2b2b",
              },
            },
          },
        },
      },
    });
  }, [prefersDarkMode, themeSettings]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default ThemeProviderWrapper;
