import { useMutation, useQueryClient } from "@tanstack/react-query";
import { manufacturerProductDetailUploadSDSFiles } from "../../api/manufacturerProductDetailsAPI";

const useManufacturerProductDetailsUploadSDSFiles = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: manufacturerProductDetailUploadSDSFiles,
    onSuccess: () => {
      queryClient.invalidateQueries("manufacturerProductDetails");
    },
  });
};

export default useManufacturerProductDetailsUploadSDSFiles;
