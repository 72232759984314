import axios from "axios";

export const getChemicalSuppliesByFacility = async (facilityID) => {
  return axios
    .get(`/api/chemical-supply/get-by-facility?facilityID=${facilityID}`)
    .then((res) => res?.data?.chemicalSupplies || []);
};

export const addChemicalSupply = async ({ body, facilityID }) => {
  return axios.post("/api/chemical-supply/add", {
    body,
    facilityID,
  });
};

export const updateChemicalSupply = async ({ body, facilityID }) => {
  return axios.put("/api/chemical-supply/update", {
    body,
    facilityID,
  });
};

export const updateChemicalSupplyReplenishMultipleChemical = async ({
  chemicals,
  facilityID,
}) => {
  return axios.patch("/api/chemical-supply/replenish-multiple-chemical", {
    chemicals,
    facilityID,
  });
};

export const updateChemicalSupplyRemoveMultipleIncomingChemical = async ({
  chemicals,
  facilityID,
}) => {
  return axios.patch("/api/chemical-supply/remove-multiple-incoming-chemical", {
    chemicals,
    facilityID,
  });
};

export const updateChemicalSupplySetMultipleIncomingAmount = async (
  chemicalSuppliesToUpdate
) => {
  return axios.patch(
    "/api/chemical-supply/set-multiple-incoming-amount",
    chemicalSuppliesToUpdate
  );
};

/*
@params
amount: amount used
chemicalSupplyID: chemicalSupply._id
*/
export const updateChemicalSupplyChemicalAmountQuery = async ({
  chemicalSupplyID,
  chemicalAmountQuery,
}) => {
  return axios
    .patch("/api/chemical-supply/set-chemical-amount-query", {
      chemicalSupplyID,
      chemicalAmountQuery,
    })
    .then((res) => res?.data?.chemicalSupply || null);
};

/*
@params
chemicalSuppliesToUpdate: [
  {
    chemicalSupplyID: chemicalSupply._id,
    amount: amount used
  }
]
*/
export const updateChemicalSupplyMultipleChemicalAmountQuery = async (
  chemicalSuppliesToUpdate
) => {
  return axios.patch(
    "/api/chemical-supply/set-multiple-chemical-amount-query",
    chemicalSuppliesToUpdate
  );
};
