import { useMutation } from "@tanstack/react-query";
import { emailSendProductOrderConfirmShippingDetails } from "../../api/emailAPI";

const useEmailSendProductOrderConfirmShippingDetails = () => {
  return useMutation({
    mutationFn: emailSendProductOrderConfirmShippingDetails,
  });
};

export default useEmailSendProductOrderConfirmShippingDetails;
