import { useQuery } from "@tanstack/react-query";
import { getFacilitySystems } from "../../api/facilityAPI";

const useFacilitySystems = (facilityID) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["facilitySystems", facilityID],
    queryFn: () => getFacilitySystems(facilityID),
    enabled: !!facilityID,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading, error };
};

export default useFacilitySystems;
