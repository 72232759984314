import React, { useState } from "react";
import { CustomModal } from "../../sharedComponents";
import {
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  TextField,
  RadioGroup,
} from "@mui/material";
import { useSelector } from "react-redux";
import { selectAppUserLevel } from "../../../redux/reducers/userLevelsSlice";
import useApproveServiceOrderRequest from "../../../customHooks/serviceOrder/useApproveServiceOrderRequest";
import useServiceOrderRequestNewServiceProvider from "../../../customHooks/serviceOrder/useServiceOrderRequestNewServiceProvider";
import useServiceOrderRequestQuoteUpdate from "../../../customHooks/serviceOrder/useServiceOrderRequestQuoteUpdate";
import useServiceOrderCancelRequest from "../../../customHooks/serviceOrder/useServiceOrderCancelRequest";

const ServiceOrderActionButtons = ({ close, order }) => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [adjustModalOpen, setAdjustModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [customerPONumber, setCustomerPONumber] = useState("");

  const [adjustServiceProvider, setAdjustServiceProvider] = useState(false);
  const [notes, setNotes] = useState("");

  const requestNewServiceProvider = useServiceOrderRequestNewServiceProvider();
  const requestQuoteUpdate = useServiceOrderRequestQuoteUpdate();
  const cancelRequest = useServiceOrderCancelRequest();
  const approveServiceOrderRequest = useApproveServiceOrderRequest();

  const closeModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setNotes("");
    setAdjustServiceProvider(false);
    setApproveModalOpen(false);
    setAdjustModalOpen(false);
    setCancelModalOpen(false);
  };

  const approveServiceRequest = async () => {
    await approveServiceOrderRequest.mutateAsync({
      serviceOrderID: order._id,
      customerPONumber,
    });

    closeModal();
    close();
  };

  const requestNewProvider = async () => {
    await requestNewServiceProvider.mutateAsync({
      serviceOrderID: order._id,
      reason: notes,
    });

    closeModal();
    close();
  };

  const suggestAdjustment = async () => {
    await requestQuoteUpdate.mutateAsync({
      serviceOrderID: order._id,
      reason: notes,
    });

    closeModal();
    close();
  };

  const cancelServiceRequest = async () => {
    await cancelRequest.mutateAsync({
      serviceOrderID: order._id,
      reason: notes,
    });

    closeModal();
    close();
  };

  return (
    <Grid container spacing={2} item xs={12}>
      {(appUserLevel.includes("Super User") ||
        appUserLevel.includes("Facility")) &&
        order?.status === "Awaiting Facility Approval" && (
          <Grid
            item
            xs={order?.status === "Awaiting Facility Approval" ? 4 : 6}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => setApproveModalOpen(true)}
              fullWidth
            >
              Approve
            </Button>
          </Grid>
        )}
      {(appUserLevel.includes("Super User") ||
        appUserLevel.includes("Facility")) &&
        order.supplier &&
        (order?.status === "Pending" ||
          order?.status === "Adjustment Requested" ||
          order?.status === "Awaiting Facility Approval") && (
          <Grid
            item
            xs={order?.status === "Awaiting Facility Approval" ? 4 : 6}
          >
            <Button
              variant="contained"
              color="warning"
              onClick={() => setAdjustModalOpen(true)}
              fullWidth
            >
              Request Adjustment
            </Button>
          </Grid>
        )}

      {(order?.status === "Pending" ||
        order?.status === "Adjustment Requested" ||
        order?.status === "Awaiting Facility Approval" ||
        order?.status === "Awaiting Service") && (
        <Grid
          item
          xs={
            !order.supplier
              ? 12
              : order?.status === "Awaiting Facility Approval"
              ? 4
              : 6
          }
        >
          <Button
            variant="contained"
            color="error"
            onClick={() => setCancelModalOpen(true)}
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
      )}

      <CustomModal
        open={approveModalOpen}
        close={closeModal}
        title="Approve Service Request"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              By clicking "Approve" you are confirming that the service quote is
              accurate and you are ready to proceed with the service.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Customer PO #"
              variant="outlined"
              fullWidth
              value={customerPONumber}
              onChange={(e) => setCustomerPONumber(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                approveServiceRequest();
              }}
              fullWidth
            >
              Approve
            </Button>
          </Grid>
        </Grid>
      </CustomModal>

      <CustomModal
        open={adjustModalOpen}
        close={closeModal}
        title="Service Adjustment"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Would you like to request a new service provider or adjust the
              service details? Please provide a reason for the adjustment.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <RadioGroup
              value={adjustServiceProvider}
              onChange={(e) => {
                setAdjustServiceProvider(
                  e.target.value === "true" ? true : false
                );
              }}
              row
            >
              <FormControlLabel
                value={false}
                control={<Radio />}
                label="Adjust Services"
              />
              <FormControlLabel
                value={true}
                control={<Radio />}
                label="Request New Provider"
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Description"
              variant="outlined"
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (adjustServiceProvider) {
                  requestNewProvider();
                } else {
                  suggestAdjustment();
                }
              }}
              fullWidth
              disabled={!notes}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </CustomModal>

      <CustomModal
        open={cancelModalOpen}
        close={closeModal}
        title="Service Cancellation"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Please enter a reason for canceling the service request.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Reason for Cancellation"
              variant="outlined"
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={cancelServiceRequest}
              fullWidth
              disabled={!notes}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </CustomModal>
    </Grid>
  );
};

export default ServiceOrderActionButtons;
