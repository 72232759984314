import { useMutation, useQueryClient } from "@tanstack/react-query";
import { removeContactFromSupplierCompany } from "../../api/supplierCompaniesAPI";
import { removeContactFromShipperCompany } from "../../api/shipperCompanyAPI";
import { removeContactFromFacility } from "../../api/facilityAPI";

export const useRemoveContactFromCompany = ({ type }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn:
      type === "supplier"
        ? removeContactFromSupplierCompany
        : type === "shipper"
        ? removeContactFromShipperCompany
        : type === "facility"
        ? removeContactFromFacility
        : null,
    onSuccess: async () => {
      if (type === "supplier") {
        await queryClient.invalidateQueries("supplierCompany");
      } else if (type === "shipper") {
        await queryClient.invalidateQueries("shipperCompany");
      } else if (type === "facility") {
        await queryClient.invalidateQueries("facility");
      }
      await queryClient.invalidateQueries("contacts");
    },
  });
};

export default useRemoveContactFromCompany;
