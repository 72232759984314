import { useQuery } from "@tanstack/react-query";
import { getFacilityContacts } from "../../api/facilityAPI";

const useFacilityContacts = (facilityID) => {
  const {
    data: facilityContacts,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["facilityContacts", facilityID],
    queryFn: () => getFacilityContacts(facilityID),
    enabled: !!facilityID,
  });

  return { facilityContacts, isLoading, error };
};

export default useFacilityContacts;
