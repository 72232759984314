import { useQuery } from "@tanstack/react-query";
import { getContainerSupplyByFacility } from "../../api/containerSupplyAPI";

const useContainerSupplyByFacility = (facilityID) => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ["containerSupply", facilityID],
    queryFn: () => getContainerSupplyByFacility(facilityID),
    enabled: !!facilityID,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    error,
    isError,
    isLoading,
  };
};

export default useContainerSupplyByFacility;
