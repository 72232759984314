export const checkIfEquipmentSupplies = (type) => {
  if (
    type === "Equipment" ||
    type === "Part" ||
    type === "Testing and Calibration Materials" ||
    type === "Janitorial Supplies"
  )
    return true;
  return false;
};
