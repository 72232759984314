import { useQuery } from "@tanstack/react-query";
import { getAllChemicalNames } from "../../api/productAPI";

const useProductChemicalNames = (enabled) => {
  const { data = [], isLoading } = useQuery({
    queryKey: ["productNames", { type: "chemical" }],
    queryFn: getAllChemicalNames,
    enabled,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { data, isLoading };
};

export default useProductChemicalNames;
