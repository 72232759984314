import { useQuery } from "@tanstack/react-query";

import { getEquivalentSupplierProductDetailsByProduct } from "../../api/supplierProductDetailsAPI";

const useEquivalentSupplierProductDetailsByProduct = (productID) => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ["supplierProductDetails", "equivalents", productID],
    queryFn: () => getEquivalentSupplierProductDetailsByProduct(productID),
    enabled: !!productID,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    error,
    isError,
    isLoading,
  };
};

export default useEquivalentSupplierProductDetailsByProduct;
