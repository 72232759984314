import { useQuery } from "@tanstack/react-query";
import { getSupplierCompanyShippingRate } from "../../api/supplierCompaniesAPI";

const useSupplierCompanyShippingRate = (id) => {
  const { data: shippingRate } = useQuery({
    queryKey: ["supplierCompanyShippingRate", id],
    queryFn: () => getSupplierCompanyShippingRate(id),
    staleTime: 1000 * 60 * 10,
    enabled: !!id,
  });

  return { shippingRate };
};

export default useSupplierCompanyShippingRate;
