import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "../localStorage";
const preloadedState = loadState("formData");

export const formDataSlice = createSlice({
  name: "formData",
  initialState: {
    formData: preloadedState?.formData ?? {},
  },
  reducers: {
    addToFormData: (state, action) => {
      state.formData = Object.assign(state.formData, action.payload);
    },
    removeFromFormData: (state, action) => {
      delete state.formData[action.payload];
    },
    resetFormData: (state, action) => {
      state = {
        formData: {},
      };
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
  },
});

export const { addToFormData, removeFromFormData, resetFormData, setFormData } =
  formDataSlice.actions;

export default formDataSlice.reducer;

export const selectFormData = (state, formType, formID) => {
  return state.formData.formData[formType]?.formID === formID
    ? state.formData.formData[formType]
    : null;
};
