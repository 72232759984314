import { useCallback } from "react";
import useCheckIfEmailExists from "./useCheckIfEmailExists";

const useCheckIfEmailExistsCallback = () => {
  const checkIfEmailExists = useCheckIfEmailExists();

  const checkIfEmailExistsCallback = useCallback(
    async ({ email, contactID }) => {
      const result = await checkIfEmailExists.mutateAsync({ email, contactID });
      return result;
    },
    [checkIfEmailExists]
  );

  return checkIfEmailExistsCallback;
};

export default useCheckIfEmailExistsCallback;
