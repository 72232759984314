import { useQuery } from "@tanstack/react-query";
import { getServiceContractsBySupplierID } from "../../api/serviceContractOrderAPI";

const useServiceContractsBySupplier = ({ supplierID, toggleCompleted }) => {
  const {
    data: serviceContracts = [],
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["serviceContracts", supplierID, toggleCompleted],
    queryFn: () =>
      getServiceContractsBySupplierID({
        supplierID,
        toggleCompleted,
      }),
    enabled: !!supplierID,
  });

  return {
    serviceContracts,
    error,
    isError,
    isLoading,
  };
};

export default useServiceContractsBySupplier;
