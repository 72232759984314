import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addMultipleProductOrders } from "../../api/productOrderAPI";

const useAddMultipleProductOrders = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addMultipleProductOrders,
    onSuccess: () => {
      queryClient.invalidateQueries("orders");
      queryClient.invalidateQueries("notifications");
    },
  });
};

export default useAddMultipleProductOrders;
