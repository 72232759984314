import { useQuery } from "@tanstack/react-query";

import { getExchangeRates } from "../../api/exchangeRateAPI";

const useExchangeRates = () => {
  const {
    data: exchangeRates = [],
    error,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["exchangeRates"],
    queryFn: () => getExchangeRates(),
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    error,
    exchangeRates,
    isError,
    isLoading,
  };
};

export default useExchangeRates;
