import { useQuery } from "@tanstack/react-query";
import { getAllFacilityNamesByContact } from "../../api/facilityAPI";

const useFacilityNamesByContact = (contactId) => {
  const { data: facilities = [], isInitialLoading } = useQuery({
    queryKey: ["facilityNames"],
    queryFn: () => getAllFacilityNamesByContact(contactId),
  });

  return { facilities, isInitialLoading };
};

export default useFacilityNamesByContact;
