import axios from "axios";

export const getManufacturerProductDetails = async () => {
  return axios
    .get(`/api/manufacturer-product-detail/get-all`)
    .then((res) => res.data.manufacturerProductDetails);
};

export const getManufacturerProductDetailsByProductID = async (productID) => {
  return axios
    .get(
      `/api/manufacturer-product-detail/get-by-product?productID=${productID}`
    )
    .then((res) => res.data.manufacturerProductDetails);
};

export const getManufacturerProductDetailChemicals = async () => {
  return axios
    .get(`/api/manufacturer-product-detail/get-all-chemicals`)
    .then((res) => res.data.manufacturerProductDetails);
};

export const getManufacturerProductDetailPalletBySupplier = async (
  supplierID
) => {
  return axios
    .get(
      `/api/manufacturer-product-detail/get-pallets-by-supplier?supplierID=${supplierID}`
    )
    .then((res) => res.data.manufacturerProductDetailPallets);
};

export const addManufacturerProductDetail = async (body) => {
  return axios.post(`/api/manufacturer-product-detail/add`, body);
};

export const deleteManufacturerProductDetail = async (body) => {
  return axios.patch(`/api/manufacturer-product-detail/delete`, body);
};

export const updateManufacturerProductDetail = async (body) => {
  return axios.put(`/api/manufacturer-product-detail/update`, body);
};

export const manufacturerProductDetailUploadSDSFiles = async (body) => {
  return axios.patch(`/api/manufacturer-product-detail/upload-SDS-files`, body);
};

export const manufacturerProductDetailUploadPSSFiles = async (body) => {
  return axios.patch(`/api/manufacturer-product-detail/upload-PSS-files`, body);
};

export const manufacturerProductDetailDeleteFile = async (body) => {
  return axios.patch(`/api/manufacturer-product-detail/delete-file`, body);
};
