import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cancelCartSubmission } from "../../api/cartSubmissionAPI";
import { useDispatch } from "react-redux";
import {
  setProductsInCartByWarehouse,
  setOrderFacility,
  setOrderFacilityAddress,
} from "../../redux/reducers/productsSlice";

const useCancelCartSubmission = (id) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: cancelCartSubmission,
    onSuccess: (cartSubmission) => {
      const currentCartSubmissions = queryClient.getQueryData([
        "cartSubmissions",
        id,
      ]);
      queryClient.invalidateQueries({ queryKey: ["cartSubmissions"] });
      const cartSubmissions = currentCartSubmissions.filter(
        (thisCartSubmissions) => {
          return cartSubmission.date !== thisCartSubmissions.date;
        }
      );
      if (cartSubmissions?.length) {
        dispatch(setOrderFacility(cartSubmissions[0].facility._id));
        dispatch(setOrderFacilityAddress(cartSubmissions[0].facility.address));
        dispatch(
          setProductsInCartByWarehouse(
            JSON.parse(cartSubmissions[0].productsInCartByWarehouse)
          )
        );
      } else {
        dispatch(setOrderFacility(null));
        dispatch(setOrderFacilityAddress(null));
        dispatch(setProductsInCartByWarehouse([]));
      }
    },
  });
};

export default useCancelCartSubmission;
