import { useQuery } from "@tanstack/react-query";

import { getProductMaintenanceProcedureNames } from "../../api/maintenanceProcedureAPI";

const useProductMaintenanceProcedureNames = (enabled) => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ["productMaintenanceProcedureNames"],
    queryFn: () => getProductMaintenanceProcedureNames(),
    enabled: enabled,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    error,
    isError,
    isLoading,
  };
};

export default useProductMaintenanceProcedureNames;
