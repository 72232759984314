import { useQuery } from "@tanstack/react-query";
import { getSurveysByContact } from "../../api/surveyAPI";
import moment from "moment";

const useSurveysByContact = ({ appUserLevel, contactID, firstLogInDate }) => {
  const { isLoading, data: surveys = [] } = useQuery({
    queryKey: ["surveys", contactID, appUserLevel],
    queryFn: () =>
      getSurveysByContact({ appUserLevel, contactID, firstLogInDate }),
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    enabled: !moment(firstLogInDate).isAfter(moment().subtract(30, "days")),
  });

  return { isLoading, surveys };
};

export default useSurveysByContact;
