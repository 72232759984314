import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addSurveyResponse } from "../../api/surveyAPI";

const useAddSurveyResponse = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addSurveyResponse,
    onSuccess: () => {
      queryClient.invalidateQueries(["surveys"]);
    },
  });
};

export default useAddSurveyResponse;
