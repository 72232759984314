import axios from "axios";

export const getAllSurveys = async () => {
  return axios.get("/api/surveys/get-all").then((res) => res.data.surveys);
};

export const getAllSurveyResponses = async () => {
  return axios
    .get("/api/surveys/get-all-responses")
    .then((res) => res.data.surveyResponses);
};

export const getSurveyByID = async (id) => {
  return axios
    .get(`/api/surveys/get-by-id/${id}`)
    .then((res) => res.data.survey);
};

export const getSurveysByContact = async ({ appUserLevel, contactID }) => {
  return axios
    .get("/api/surveys/get-by-contact", {
      params: {
        contactID,
        appUserLevel: appUserLevel[0],
      },
    })
    .then((res) => res.data.surveys);
};

export const addSurvey = async (surveyBody) => {
  return axios
    .post("/api/surveys/add", surveyBody)
    .then((res) => res.data.survey);
};

export const addSurveyResponse = async (surveyResponseBody) => {
  return axios
    .post("/api/surveys/add-response", surveyResponseBody)
    .then((res) => res.data.surveyResponse);
};

export const updateSurvey = async (surveyBody) => {
  return axios
    .patch("/api/surveys/update", surveyBody)
    .then((res) => res.data.survey);
};

export const launchSurvey = async ({ surveyID, startDate, endDate }) => {
  return axios
    .patch("/api/surveys/launch-survey", { surveyID, startDate, endDate })
    .then((res) => res.data.survey);
};
