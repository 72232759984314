import React from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import useFacilityNamesByContact from "../../customHooks/facility/useFacilityNamesByContact";

const ContactFacilities = ({ contact }) => {
  const { facilities, isInitialLoading } = useFacilityNamesByContact(
    contact?._id
  );

  if (isInitialLoading)
    return (
      <Grid item xs={12}>
        <CircularProgress />
      </Grid>
    );

  if (!facilities?.length) return <></>;

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography>Facilities:</Typography>
      </Grid>
      {facilities.map((facility) => (
        <Grid item xs={12} key={facility._id}>
          <Typography>{facility.name}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default ContactFacilities;
