import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import {
  setFacilities,
  setFacilityStatus,
} from "../../redux/reducers/facilities/facilitiesSlice";
import {
  selectSupplierUserLevel,
  setSupplierUserLevel,
} from "../../redux/reducers/userLevelsSlice";
import { getSupplierUserLevel } from "../../sharedFunctions/userLevels";
import { getSupplierCompanyByID } from "../../api/supplierCompaniesAPI";
import useContact from "../contacts/useContact";

const useSupplierCompany = (id) => {
  const dispatch = useDispatch();
  const { contact } = useContact();
  const supplierUserLevel = useSelector(selectSupplierUserLevel);

  const { data: supplierCompany, isLoading } = useQuery({
    queryKey: ["supplierCompany", id],
    queryFn: () => getSupplierCompanyByID(id),
    staleTime: 1000 * 60 * 10,
    enabled: !!id,
  });

  useEffect(() => {
    if (!contact || !supplierCompany) return;
    dispatch(
      setSupplierUserLevel(getSupplierUserLevel(contact, supplierCompany))
    );
  }, [contact, dispatch, supplierCompany]);

  useEffect(() => {
    if (!contact || !supplierCompany || !supplierUserLevel?.length) return;

    let tempFacilitiesSupplied = supplierCompany.facilitiesSupplied ?? [];
    if (supplierUserLevel?.includes("Representative")) {
      if (supplierCompany.facilityAssignments?.length)
        tempFacilitiesSupplied = supplierCompany.facilityAssignments.find(
          (facilityAssignment) =>
            facilityAssignment.representative._id === contact._id
        )?.facilities;
    }
    dispatch(setFacilities(tempFacilitiesSupplied));
    dispatch(setFacilityStatus("succeeded"));
  }, [contact, dispatch, supplierCompany, supplierUserLevel]);

  return {
    contact,
    isLoading,
    supplierCompany,
    supplierUserLevel,
  };
};

export default useSupplierCompany;
