import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import SupplierOrderConfirmation from "../SupplierOrderConfirmation";
import { selectAppUserLevel } from "../../../redux/reducers/userLevelsSlice";
import ShippingConfirmation from "../ShippingConfirmation";
import PickUpConfirmation from "../PickUpConfirmation";
import ReportProblem from "../../reportProblem/ReportProblem";
import OrderDisplayProducts from "./OrderDisplayProducts";
import OrderDisplayTotals from "./OrderDisplayTotals";
import OrderDisplayDetails from "./OrderDisplayDetails";
import OrderDisplayContainers from "./OrderDisplayContainers";
import {
  getInvoicePDF,
  getPOPDF,
  getSupplierInvoicePDF,
} from "../../../api/orderAPI";
import {
  getBillOfLadingPDF,
  getCreditReceiptPDF,
} from "../../../api/productOrderAPI";
import OrderCancelation from "../OrderCancelation";
import UpdateDeliveryDate from "../UpdateDeliveryDate";
import useEmailSendPOToSupplier from "../../../customHooks/email/useEmailSendPOToSupplier";
import useOrder from "../../../customHooks/order/useOrder";
import ContainersReturnedConfirmation from "../ContainersReturnedConfirmation";
import OrderAddition from "./OrderAddition";
import useOrderAdditionByPendingOrder from "../../../customHooks/orderAddition/useOrderAdditionByPendingOrder";
import UpdatePickUpAvailableDate from "../UpdatePickUpAvailableDate";
import AddShippingCost from "../AddShippingCost";
import { CustomModal } from "../../sharedComponents";
import ConfirmModal from "../../sharedComponents/modals/ConfirmModal";
import useEmailSendInvoiceToFacility from "../../../customHooks/email/useEmailSendInvoiceToFacility";
import useEmailSendShippingDetails from "../../../customHooks/email/useEmailSendShippingDetails";
import ServiceRequestForm from "../../services/ServiceRequestForm";
import OrderDisplayShipping from "./OrderDisplayShipping";
import useResetShipping from "../../../customHooks/productOrder/useResetShipping";
import useAgendas from "../../../customHooks/useAgendas";
import useUpdateProductOrder from "../../../customHooks/productOrder/useUpdateProductOrder";
import AttachFilesToQuickBooksBill from "../AttachFilesToQuickBooksBill";
import OrderDisplayBrokerageFee from "./OrderDisplayBrokerageFee";
import ReportedOrder from "./ReportedOrder";
import { selectUser } from "../../../redux/reducers/userSlice";
import useReportOrder from "../../../customHooks/order/useReportOrder";
import ServiceOrderActionButtons from "./ServiceOrderActionButtons";
import OrderRefund from "../orderRefund/OrderRefund";
import ServiceContractActionButtons from "./ServiceContractActionButtons";
import useEmailConfirmShipping from "../../../customHooks/email/useEmailConfirmShipping";
import ServiceContractForm from "../../serviceContracts/ServiceContractForm";
import SuperUserShipperSelection from "../SuperUserShipperSelection";
import formatDate from "../../../sharedFunctions/formatDate";
import useCreditMemo from "../../../customHooks/creditMemo/useCreditMemo";
import getTaxNameFromTaxCode from "../../../sharedFunctions/getTaxNameFromTaxCode";
import useEmailSendProductOrderConfirmShippingDetails from "../../../customHooks/email/useEmailSendProductOrderConfirmShippingDetails";
import useCompleteOrder from "../../../customHooks/order/useCompleteOrder";

const OrderDisplay = ({ close, orderID, orderNumber, orderType, editMode }) => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const user = useSelector(selectUser);

  const [
    addFileAttachmentToSupplierBillOpen,
    setAddFileAttachmentToSupplierBillOpen,
  ] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [shipperSelectionModalOpen, openShipperSelectionModal] =
    useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalAction, setModalAction] = useState("");
  const [serviceContractOpen, setServiceContractOpen] = useState(false);
  const [serviceRequestOpen, setServiceRequestOpen] = useState(false);
  const [shipper, setShipper] = useState(null);
  const [orderView, setOrderView] = useState(
    appUserLevel.includes("Supplier")
      ? "supplier"
        ? appUserLevel.includes("Shipper")
        : "shipper"
      : "facility"
  );
  const agendas = useAgendas();
  const emailSendPOToSupplier = useEmailSendPOToSupplier();
  const { order: fetchedOrder, isLoading: isLoadingOrder } = useOrder(
    orderType !== "CreditMemo" && {
      orderID,
      orderNumber,
    }
  );
  const { data: creditMemo, isLoading: isLoadingCreditMemo } = useCreditMemo(
    orderType === "CreditMemo" && orderID
  );

  const order = fetchedOrder || creditMemo;

  const { orderAddition, isLoading: isLoadingOrderAddition } =
    useOrderAdditionByPendingOrder(orderType !== "CreditMemo" && orderID);
  const emailSendInvoiceToFacility = useEmailSendInvoiceToFacility();
  const emailSendShippingDetails = useEmailSendShippingDetails();
  const resetShipping = useResetShipping();
  const updateOrder = useUpdateProductOrder();
  const reportOrder = useReportOrder();
  const sendConfirmShipping = useEmailConfirmShipping();
  const completeOrder = useCompleteOrder();
  const sendConfirmShippingDetails =
    useEmailSendProductOrderConfirmShippingDetails();

  const handleReportProblem = (details) => {
    reportOrder.mutate({
      orderID: order._id,
      status: "Reported",
      reportedHistory: {
        date: details.date,
        description: details.notes,
        user: details.user._id,
      },
    });
  };

  const openConfirmModal = (action, message) => {
    setConfirmModalOpen(true);
    setModalMessage(message);
    setModalAction(action);
  };

  const closeConfirmModal = async (event, reason, confirm, action) => {
    if (reason === "backdropClick") return;
    if (confirm) {
      if (action === "SendInvoiceEmail") {
        await emailSendInvoiceToFacility.mutateAsync({
          orderID: order._id,
          orderNumber: order.orderNumber,
        });
      } else if (action === "SendPOEmail") {
        emailSendPOToSupplier.mutate({ orderID: order._id });
      } else if (action === "SendConfirmShipping") {
        await sendConfirmShipping.mutateAsync({
          orderID: order._id,
        });
      } else if (action === "SendConfirmShippingDetails") {
        await sendConfirmShippingDetails.mutateAsync(order._id);
      } else if (action === "CompleteOrder") {
        await completeOrder.mutateAsync({
          orderID: orderID,
          orderNumber: orderNumber,
        });
        close();
      } else if (action === "SendShippingDetailsEmail") {
        const formData = new FormData();
        formData.append("orderID", order._id);
        await emailSendShippingDetails.mutateAsync(formData);
      } else if (action === "ResetShipping") {
        resetShipping.mutate({ orderID: order._id });
        close();
      } else if (action === "InStock") {
        await updateOrder.mutateAsync({
          order: {
            _id: order._id,
            status: "Pending",
            pickUpAvailableDate: null,
          },
        });
        agendas.agendaCancelConfirmShippingDetailsMutation.mutate({
          orderID: order._id,
        });
        close();
      }
    }
    setModalAction("");
    setModalMessage("");
    setConfirmModalOpen(false);
  };

  const handleDownloadInvoice = async () => {
    try {
      const response = await getInvoicePDF({
        orderID,
        orderNumber: order.orderNumber,
      });

      // Create a download link
      const downloadUrl = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `invoice-${order.orderNumber}.pdf`);
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up the download link
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };

  const handleDownloadPO = async () => {
    try {
      const response = await getPOPDF({
        orderID,
      });

      // Create a download link
      const downloadUrl = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `PO-${order.orderNumber}.pdf`);
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up the download link
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };

  const handleDownloadSupplierInvoice = async () => {
    try {
      const response = await getSupplierInvoicePDF({
        orderID,
        orderNumber: order.orderNumber,
      });

      // Create a download link
      const downloadUrl = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        `supplier-invoice-${order.orderNumber}.pdf`
      );
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up the download link
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };

  const handleDownloadBillOfLading = async () => {
    try {
      const response = await getBillOfLadingPDF(orderID);

      // Create a download link
      const downloadUrl = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `BOL-${order.orderNumber}.pdf`);
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up the download link
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };

  const handleDownloadCreditReceipt = async () => {
    const response = await getCreditReceiptPDF(orderID);

    // Create a download link
    const downloadUrl = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", `Credit-Receipt-${order.orderNumber}.pdf`);
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up the download link
    link.remove();
    window.URL.revokeObjectURL(downloadUrl);
  };

  const completeOrderButton = () => {
    return (
      <Grid item xs={4} md={2} display={"flex"}>
        <Button
          onClick={() => openConfirmModal("CompleteOrder", "Complete Order")}
          variant="contained"
          fullWidth
        >
          Complete Order
        </Button>
      </Grid>
    );
  };

  const downloadInvoiceButton = () => {
    return (
      <Grid item xs={4} md={2} display={"flex"}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadInvoice}
        >
          Download Invoice
        </Button>
      </Grid>
    );
  };

  const downloadPOButton = () => {
    return (
      <Grid item xs={4} md={2} display={"flex"}>
        <Button variant="contained" color="primary" onClick={handleDownloadPO}>
          Download PO
        </Button>
      </Grid>
    );
  };

  const downloadSupplierInvoiceButton = () => {
    return (
      <Grid item xs={4} md={2} display={"flex"}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadSupplierInvoice}
        >
          Download Supplier Invoice
        </Button>
      </Grid>
    );
  };

  const downloadCreditReceiptButton = () => {
    return (
      <Grid item xs={4} md={2} display={"flex"}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadCreditReceipt}
        >
          Download Credit Receipt
        </Button>
      </Grid>
    );
  };

  const downloadBillOfLadingButton = () => {
    return (
      <Grid item xs={4} md={2} display={"flex"}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadBillOfLading}
        >
          Download Bill of Lading
        </Button>
      </Grid>
    );
  };

  const sendInvoiceEmailButton = () => {
    return (
      <Grid item xs={4} md={2} display={"flex"}>
        <Button
          variant="contained"
          onClick={() =>
            openConfirmModal("SendInvoiceEmail", "Send Invoice Email")
          }
        >
          Send Invoice Email
        </Button>
      </Grid>
    );
  };

  const addFileAttachmentToSupplierBillButton = () => {
    return (
      <Grid item xs={4} md={2} display={"flex"}>
        <Button
          variant="contained"
          onClick={() => setAddFileAttachmentToSupplierBillOpen(true)}
        >
          Add File Attachment To Supplier Bill
        </Button>
      </Grid>
    );
  };

  const renderProductOrderButtons = () => {
    return (
      <Grid item xs={12} container spacing={2}>
        {(appUserLevel.includes("Supplier") ||
          appUserLevel.includes("Super User")) &&
          order.status === "Awaiting Stock" && (
            <Grid item xs={4} md={2} display={"flex"}>
              <Button
                onClick={() => openConfirmModal("InStock", "In Stock")}
                variant="contained"
                fullWidth
              >
                In Stock
              </Button>
            </Grid>
          )}
        {appUserLevel.includes("Super User") &&
          (order.status === "On Route" ||
            order.status === "Delivered" ||
            (order.status === "Processing" && order.shipper)) && (
            <Grid item xs={4} md={2} display={"flex"}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  openConfirmModal("ResetShipping", "Reset Shipping");
                }}
              >
                Reset Shipping
              </Button>
            </Grid>
          )}

        {(order.status === "On Route" ||
          order.status === "Delivered" ||
          order.status === "Completed") &&
          sendInvoiceEmailButton()}
        {appUserLevel.includes("Super User") &&
          !!order.productDetails.length && (
            <Grid item xs={4} md={2} display={"flex"}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  openConfirmModal("SendPOEmail", "Send PO Email");
                }}
              >
                Send PO
              </Button>
            </Grid>
          )}
        {(appUserLevel.includes("Super User") ||
          appUserLevel.includes("Supplier")) &&
          !!order.productDetails.length &&
          downloadPOButton()}
        {appUserLevel.includes("Super User") &&
          (order.status === "Pending" ||
            order.status === "Processing" ||
            order.status === "Awaiting Stock") && (
            <Grid item xs={4} md={2} display={"flex"}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  openConfirmModal(
                    "SendConfirmShippingDetails",
                    "Send Confirm Shipping Details"
                  );
                }}
              >
                Send Confirm Shipping Details
              </Button>
            </Grid>
          )}
        {appUserLevel.includes("Super User") &&
          order.useDrOPsShipping &&
          order.status !== "Pending" &&
          order.status !== "Awaiting Stock" &&
          order.status !== "Processing" &&
          !order.pickUp && (
            <Grid item xs={4} md={2} display={"flex"}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  openConfirmModal(
                    "SendShippingDetailsEmail",
                    "Send Shipping Details Email"
                  );
                }}
              >
                Send Shipping Details Email
              </Button>
            </Grid>
          )}
        {appUserLevel.includes("Super User") &&
          (order.status === "Processing" ||
            order.status === "Ready For Delivery") &&
          !order.pickUp && (
            <Grid item xs={4} md={2} display={"flex"}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  order.shipper
                    ? openConfirmModal(
                        "SendConfirmShipping",
                        "Send Confirm Shipping"
                      )
                    : openShipperSelectionModal(true);
                }}
              >
                {order.shipper ? "Send Confirm Shipping" : "Select Shipper"}
              </Button>
            </Grid>
          )}

        {appUserLevel.includes("Super User") &&
          order.status === "Delivered" &&
          completeOrderButton()}
        {appUserLevel.includes("Super User") &&
          (order.status === "Delivered" || order.status === "Completed") &&
          addFileAttachmentToSupplierBillButton()}
        {(order.status === "On Route" ||
          order.status === "Delivered" ||
          order.status === "Completed") &&
          downloadInvoiceButton()}
        {(appUserLevel.includes("Super User") ||
          appUserLevel.includes("Supplier")) &&
          !order.containersToReturn?.length &&
          (order.status === "On Route" ||
            order.status === "Delivered" ||
            order.status === "Completed") &&
          downloadSupplierInvoiceButton()}
        {(order.status === "On Route" ||
          order.status === "Delivered" ||
          order.status === "Completed") &&
          order.creditsAcceptedBySupplier > 0 &&
          downloadCreditReceiptButton()}
        {!order.pickUp &&
          (order.status === "Ready For Delivery" ||
            order.status === "Processing" ||
            order.status === "On Route" ||
            order.status === "Delivered" ||
            order.status === "Completed") &&
          downloadBillOfLadingButton()}
        {(appUserLevel.includes("Supplier") ||
          appUserLevel.includes("Super User")) &&
          (order.status === "Ready For Pick Up" ||
            order.status === "Processing") && (
            <Grid item xs={4} md={2} display={"flex"}>
              <UpdatePickUpAvailableDate close={close} order={order} />
            </Grid>
          )}
        {appUserLevel.includes("Super User") &&
          (order.status === "On Route" ||
            order.status === "Ready For Delivery" ||
            order.status === "Delivered") &&
          order.arriveDate && (
            <Grid item xs={4} md={2} display={"flex"}>
              <UpdateDeliveryDate close={close} order={order} />
            </Grid>
          )}
      </Grid>
    );
  };

  const supplierPricingSwitch = () => {
    return (
      appUserLevel.includes("Super User") && (
        <Grid item xs={12}>
          <RadioGroup
            defaultValue={true}
            value={orderView}
            onChange={(e) => setOrderView(e.target.value)}
            row
          >
            <FormControlLabel
              value={"facility"}
              control={<Radio />}
              label="Facility"
            />
            <FormControlLabel
              value={"supplier"}
              control={<Radio />}
              label="Supplier"
            />
            {order.orderType === "ProductOrder" && order.useDrOPsShipping && (
              <FormControlLabel
                value={"shipper"}
                control={<Radio />}
                label="Shipper"
              />
            )}
          </RadioGroup>
        </Grid>
      )
    );
  };

  const renderProductOrder = () => {
    return (
      <>
        {renderProductOrderButtons()}
        <OrderDisplayDetails
          appUserLevel={appUserLevel}
          editMode={editMode}
          order={order}
        />
        {supplierPricingSwitch()}
        <OrderDisplayProducts
          items={order.productDetails}
          orderType={order.orderType}
          orderView={orderView}
        />
        <OrderDisplayShipping
          containersToReturn={order.containersToReturn}
          orderView={orderView}
          shippingCost={order.shippingCost}
          supplierShippingCost={order.supplierShippingCost}
          useDrOPsShipping={order.useDrOPsShipping}
        />
        <OrderDisplayBrokerageFee
          brokerageFeeCost={order.brokerageFeeCost}
          brokerageFeePrice={order.brokerageFeePrice}
          brokerageFeeGST={order.brokerageFeeGST}
          orderView={orderView}
          useDrOPsShipping={order.useDrOPsShipping}
        />
        <OrderDisplayTotals order={order} orderView={orderView} />
        {orderView !== "shipper" && (
          <OrderDisplayContainers
            creditsAcceptedBySupplier={order.creditsAcceptedBySupplier}
            creditsEarned={order.creditsEarned}
            containerReturnGST={order.containerReturnGST}
            facilityHasGST={order.facility?.hasGST}
            containersToReturn={order.containersToReturn}
            containersNotAcceptedReason={order.containersNotAcceptedReason}
          />
        )}
        {!orderAddition && (
          <Grid item xs={12}>
            <ReportProblem
              type={`Order: ${order.orderNumber}`}
              handleReportProblem={handleReportProblem}
              nonAppProblem={true}
            />
          </Grid>
        )}
        {appUserLevel.includes("Super User") && order.status === "Reported" && (
          <>
            <ReportedOrder
              closeOrderDisplay={close}
              order={order}
              userID={user._id}
            />
            <OrderRefund order={order} />
          </>
        )}
        {(appUserLevel.includes("Super User") ||
          appUserLevel.includes("Supplier")) &&
          (order.status === "Pending" || order.status === "Processing") &&
          !orderAddition && (
            <SupplierOrderConfirmation order={order} close={close} />
          )}
        {!order.pickUp &&
          (appUserLevel.includes("Super User") ||
            appUserLevel.includes("Supplier") ||
            appUserLevel.includes("Shipper")) &&
          (order.status === "Ready For Delivery" ||
            order.status === "Ready For Shipment") && (
            <ShippingConfirmation order={order} close={close} />
          )}
        {(appUserLevel.includes("Super User") ||
          appUserLevel.includes("Supplier")) &&
          order.status === "Ready For Pick Up" && (
            <PickUpConfirmation order={order} close={close} />
          )}
        {(appUserLevel.includes("Super User") ||
          appUserLevel.includes("Supplier")) &&
          order.status === "Returning Containers" && (
            <ContainersReturnedConfirmation order={order} close={close} />
          )}
        {(appUserLevel.includes("Super User") ||
          appUserLevel.includes("Supplier")) &&
          order.status === "Ready For Shipment" && (
            <OrderCancelation order={order} close={close} />
          )}
        {orderAddition && (
          <OrderAddition
            order={order}
            orderAddition={orderAddition}
            orderView={orderView}
            close={close}
          />
        )}
        {(appUserLevel.includes("Super User") ||
          appUserLevel.includes("Supplier")) &&
          order.status === "Delivered" &&
          (order?.shippingCost === undefined || order?.shippingCost === null) &&
          !order?.shippingIncluded &&
          !order?.pickUp && <AddShippingCost order={order} close={close} />}
      </>
    );
  };

  const renderServiceOrder = () => {
    return (
      <>
        {order?.status !== "Awaiting Payment" &&
          order?.status !== "Completed" && (
            <Grid item xs={4} md={2} display={"flex"}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => setServiceRequestOpen(true)}
              >
                Manage
              </Button>
            </Grid>
          )}
        {(order.status === "Awaiting Payment" ||
          order.status === "Completed") &&
          sendInvoiceEmailButton()}
        {appUserLevel.includes("Super User") &&
          (order.status === "Awaiting Payment" ||
            order.status === "Completed") &&
          addFileAttachmentToSupplierBillButton()}
        {(appUserLevel.includes("Super User") ||
          appUserLevel.includes("Supplier")) &&
          (order.status === "Awaiting Facility Approval" ||
            order.status === "Awaiting Payment" ||
            order.status === "Completed") &&
          downloadPOButton()}
        {(order.status === "Awaiting Payment" ||
          order.status === "Completed") &&
          downloadInvoiceButton()}
        {(appUserLevel.includes("Super User") ||
          appUserLevel.includes("Supplier")) &&
          (order.status === "Awaiting Payment" ||
            order.status === "Completed") &&
          downloadSupplierInvoiceButton()}
        {appUserLevel.includes("Super User") &&
          order.status === "Awaiting Payment" &&
          completeOrderButton()}

        <OrderDisplayDetails
          appUserLevel={appUserLevel}
          editMode={editMode}
          order={order}
        />
        {supplierPricingSwitch()}
        <OrderDisplayProducts
          items={{
            services: order.services,
            materialsUsed: order.materialsUsed,
          }}
          orderType={order.orderType}
          orderView={orderView}
        />
        <OrderDisplayTotals order={order} orderView={orderView} />
        <ServiceOrderActionButtons order={order} close={close} />
      </>
    );
  };

  const renderServiceContractOrder = () => {
    return (
      <>
        <Grid item xs={4} md={2} display={"flex"}>
          <Button
            onClick={() => setServiceContractOpen(true)}
            variant="contained"
          >
            View Contract
          </Button>
        </Grid>
        {sendInvoiceEmailButton()}
        {appUserLevel.includes("Super User") &&
          order.status === "Awaiting Payment" &&
          completeOrderButton()}
        {downloadInvoiceButton()}
        {(appUserLevel.includes("Super User") ||
          appUserLevel.includes("Supplier")) &&
          downloadSupplierInvoiceButton()}
        {appUserLevel.includes("Super User") &&
          (order.status === "Awaiting Payment" ||
            order.status === "Completed") &&
          addFileAttachmentToSupplierBillButton()}
        <OrderDisplayDetails
          appUserLevel={appUserLevel}
          editMode={editMode}
          order={order}
        />
        {supplierPricingSwitch()}
        <OrderDisplayProducts
          items={{ services: order.services, orders: order.orders }}
          orderType={order.orderType}
          orderView={orderView}
        />
        <OrderDisplayTotals order={order} orderView={orderView} />
        <ServiceContractActionButtons order={order} close={close} />
      </>
    );
  };

  const renderCreditMemoOrder = () => {
    return (
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12}>
          <Typography>{formatDate(order.date)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Credit Memo #{order.docNumber}</Typography>
        </Grid>
        {order?.order?.orderNumber && (
          <Grid item xs={12}>
            <Typography>
              Credits From Order #{order.order.orderNumber}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography>{order.customer.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Items</Typography>
        </Grid>
        {order?.lineItems?.map((lineItem) => (
          <Grid container item xs={12} key={lineItem._id}>
            <Grid item xs={12}>
              <Typography>{lineItem.description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                QTY: {lineItem.quantity}, Price: $
                {lineItem.unitPrice?.toFixed(2)}, Total: $
                {lineItem.amount?.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          {order.taxDetail?.line?.map((tax) => (
            <Typography
              color={"limegreen"}
              style={{ textAlign: "right" }}
              key={tax._id}
            >
              {getTaxNameFromTaxCode(tax.taxRateRef.value)} $
              {tax.amount?.toFixed(2)}
            </Typography>
          ))}
          <Typography color={"limegreen"} style={{ textAlign: "right" }}>
            Total Credit ${order.amount?.toFixed(2)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Transactions</Typography>
        </Grid>
        {order?.linkedTxn?.map((linkedTxn) => (
          <Grid container item xs={12} key={linkedTxn._id}>
            <Grid item xs={12}>
              <Typography>
                {formatDate(linkedTxn.date)} - {linkedTxn.txnType}{" "}
                {linkedTxn.docNumber}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Amount: ${linkedTxn?.amount?.toFixed(2)}</Typography>
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Typography color={"limegreen"} style={{ textAlign: "right" }}>
            Remaining Balance: ${order.balance?.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  if (!order || isLoadingOrder || isLoadingOrderAddition || isLoadingCreditMemo)
    return <CircularProgress />;

  return (
    <>
      <CustomModal
        open={confirmModalOpen}
        close={closeConfirmModal}
        title={"Please Confirm"}
      >
        <ConfirmModal
          action={modalAction}
          close={closeConfirmModal}
          message={modalMessage}
        />
      </CustomModal>

      <CustomModal
        open={addFileAttachmentToSupplierBillOpen}
        close={() => {
          setAddFileAttachmentToSupplierBillOpen(false);
        }}
        title={"Add File Attachment To Supplier Bill"}
      >
        <AttachFilesToQuickBooksBill
          orderNumber={order.orderNumber}
          setAddFileAttachmentToSupplierBillOpen={
            setAddFileAttachmentToSupplierBillOpen
          }
        />
      </CustomModal>

      <CustomModal
        open={serviceRequestOpen}
        close={() => {
          setServiceRequestOpen(false);
        }}
        title={"Service Request"}
        hasEdit={true}
        anyoneCanEdit={
          appUserLevel.includes("Supplier") &&
          (order?.status !== "Awaiting Facility Approval" ||
            order?.status !== "Awaiting Payment" ||
            order?.status !== "Completed")
        }
      >
        <ServiceRequestForm
          close={() => {
            setServiceRequestOpen(false);
            close();
          }}
          serviceRequest={order}
        />
      </CustomModal>
      <CustomModal
        open={serviceContractOpen}
        close={() => {
          setServiceContractOpen(false);
        }}
        title={"Service Contract"}
        hasEdit={appUserLevel.includes("Super User")}
      >
        <ServiceContractForm
          close={() => {
            setServiceContractOpen(false);
            close();
          }}
          serviceContractID={order._id}
        />
      </CustomModal>
      <CustomModal
        open={shipperSelectionModalOpen}
        close={() => openShipperSelectionModal(false)}
        title={"Select Shipper"}
      >
        <Grid container spacing={2}>
          <SuperUserShipperSelection
            order={order}
            shipper={shipper}
            setShipper={setShipper}
            size="lg"
          />

          <Grid item xs={6}>
            <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={() => openShipperSelectionModal(false)}
            >
              Close
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="success"
              fullWidth
              onClick={async () => {
                await updateOrder.mutateAsync({
                  order: {
                    _id: order._id,
                    shipper: shipper._id,
                  },
                });

                openShipperSelectionModal(false);
              }}
              disabled={!shipper}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </CustomModal>

      <Grid container spacing={2}>
        {order.orderType === "ProductOrder" && renderProductOrder()}
        {order.orderType === "ServiceOrder" && renderServiceOrder()}
        {order.orderType === "ServiceContractOrder" &&
          renderServiceContractOrder()}
        {order.type === "CreditMemo" && renderCreditMemoOrder()}
      </Grid>
    </>
  );
};

export default OrderDisplay;
