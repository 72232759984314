import React from "react";
import { Typography, Grid, Divider } from "@mui/material";
import FileList from "./FileList";
import FileUpload from "./FileUpload";

const ImageSection = (props) => {
  const {
    editMode,
    deleteFunction,
    files,
    uploadFileTypes,
    uploadFunction,
    itemID,
    title,
  } = props;

  return (
    <>
      {!editMode && files?.length > 0 && (
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">{title}</Typography>
          </Grid>

          <FileList files={files} />
        </Grid>
      )}

      {editMode && (
        <Grid container spacing={2} item xs={12}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">{title}</Typography>
          </Grid>
          <FileUpload
            itemID={itemID}
            deleteFunction={deleteFunction}
            files={files}
            uploadFunction={uploadFunction}
            fileTypes={uploadFileTypes}
          />
        </Grid>
      )}
    </>
  );
};

export default ImageSection;
