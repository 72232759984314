import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateArriveDate } from "../../api/productOrderAPI";

const useUpdateOrderArriveDate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateArriveDate,
    onSuccess: () => {
      queryClient.invalidateQueries("orders");
    },
  });
};

export default useUpdateOrderArriveDate;
