import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateFacilityEmail } from "../../api/facilityAPI";

const useUpdateFacilityEmail = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateFacilityEmail,
    onSuccess: () => {
      queryClient.invalidateQueries("facility");
    },
  });
};

export default useUpdateFacilityEmail;
