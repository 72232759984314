import { useQuery } from "@tanstack/react-query";

import { getExchangeRateByCurrency } from "../../api/exchangeRateAPI";

const useExchangeRate = ({ currency }) => {
  const {
    data: exchangeRate = null,
    error,
    isError,
    isInitialLoading,
    isLoading,
  } = useQuery({
    queryKey: ["exchangeRate", currency],
    queryFn: () => getExchangeRateByCurrency({ currency }),
    enabled: !!currency && currency !== "CAD",
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    error,
    exchangeRate,
    isError,
    isInitialLoading,
    isLoading,
  };
};

export default useExchangeRate;
