export const convertToKilogram = (weight, unit) => {
  if (!weight) return null;
  switch (unit) {
    case "lbs":
      return weight * 0.45359237;
    case "tonne":
      return weight * 1000;
    case "kg":
      return weight;
    default:
      return weight;
  }
};

export const convertToCM = (height, unit) => {
  if (!height) return null;
  switch (unit) {
    case "ft":
      return height * 30.48;
    case "m":
      return height * 100;
    case "cm":
      return height;
    case "in":
      return height * 2.54;
    default:
      return height;
  }
};
