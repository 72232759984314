import { useQuery } from "@tanstack/react-query";
import { getProductNamesForMaintenanceProcedures } from "../../api/productAPI";

const useProductNamesForMaintenanceProcedures = () => {
  const {
    data = [],
    error,
    isLoading,
  } = useQuery({
    queryKey: ["productNames", "maintenanceProcedures"],
    queryFn: getProductNamesForMaintenanceProcedures,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { data, error, isLoading };
};

export default useProductNamesForMaintenanceProcedures;
