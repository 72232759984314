import { useMutation, useQueryClient } from "@tanstack/react-query";
import { requestServiceOrderQuoteUpdate } from "../../api/serviceOrderAPI";

const useServiceOrderRequestQuoteUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: requestServiceOrderQuoteUpdate,
    onSuccess: async () => {
      await queryClient.invalidateQueries("serviceOrders");
    },
  });
};

export default useServiceOrderRequestQuoteUpdate;
