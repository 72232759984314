import {
  COMMISSION,
  COMMISSION_DROPS,
  DROP_WATER_CONTACT_ID,
} from "../../globalConstants";
import customDollarRound from "./customDollarRound";

const calculateServicePrice = (cost, exchangeRate, supplierID) => {
  const commission =
    supplierID === DROP_WATER_CONTACT_ID ? COMMISSION_DROPS : COMMISSION;

  let price;
  if (exchangeRate) {
    price = cost * exchangeRate;
    price = price / (1 - commission);
  } else {
    price = cost / (1 - commission);
  }

  return customDollarRound(price);
};

export default calculateServicePrice;
