import React from "react";
import { FormSwitch } from "../../sharedComponents";

const ToggleFields = (props) => {
  const {
    editMode,
    appUserLevel,
    isBusiness,
    setIsBusiness,
    isContractingShipper,
    setIsContractingShipper,
    isFacilityManager,
    setIsFacilityManager,
    isManufacturer,
    setIsManufacturer,
    isRegulator,
    setIsRegulator,
    isServiceProvider,
    setIsServiceProvider,
    isShipper,
    setIsShipper,
    isSupplier,
    setIsSupplier,
    isWarehouse,
    setIsWarehouse,
  } = props;

  return (
    <>
      <FormSwitch
        disabled={!editMode || !appUserLevel?.includes("Super User")}
        checked={isBusiness}
        onChange={(e) => setIsBusiness(e.target.checked)}
        label="Business"
        xs={6}
        md={3}
      />

      <FormSwitch
        disabled={!editMode || !appUserLevel?.includes("Super User")}
        checked={isFacilityManager}
        onChange={(e) => setIsFacilityManager(e.target.checked)}
        label="Facility Manager"
        xs={6}
        md={3}
      />

      <FormSwitch
        disabled={!editMode || !appUserLevel?.includes("Super User")}
        checked={isManufacturer}
        onChange={(e) => setIsManufacturer(e.target.checked)}
        label="Manufacturer"
        xs={6}
        md={3}
      />

      <FormSwitch
        disabled={!editMode || !appUserLevel?.includes("Super User")}
        checked={isRegulator}
        onChange={(e) => setIsRegulator(e.target.checked)}
        label="Regulator"
        xs={6}
        md={3}
      />

      <FormSwitch
        disabled={!editMode || !appUserLevel?.includes("Super User")}
        checked={isServiceProvider}
        onChange={(e) => setIsServiceProvider(e.target.checked)}
        label="Service Supplier"
        xs={6}
        md={3}
      />

      <FormSwitch
        disabled={!editMode || !appUserLevel?.includes("Super User")}
        checked={isShipper}
        onChange={(e) => setIsShipper(e.target.checked)}
        label="Shipper"
        xs={6}
        md={3}
      />

      {isShipper && (
        <FormSwitch
          disabled={!editMode || !appUserLevel?.includes("Super User")}
          checked={isContractingShipper}
          onChange={(e) => setIsContractingShipper(e.target.checked)}
          label="Contracting Shipper"
          xs={6}
          md={3}
        />
      )}

      <FormSwitch
        disabled={!editMode || !appUserLevel?.includes("Super User")}
        checked={isSupplier}
        onChange={(e) => setIsSupplier(e.target.checked)}
        label="Product Supplier"
        xs={6}
        md={3}
      />

      <FormSwitch
        disabled={!editMode || !appUserLevel?.includes("Super User")}
        checked={isWarehouse}
        onChange={(e) => setIsWarehouse(e.target.checked)}
        label="Warehouse"
        xs={6}
        md={3}
      />
    </>
  );
};

export default ToggleFields;
