import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "../localStorage";
const preloadedState = loadState("user");

const userSlice = createSlice({
  name: "user",
  initialState: {
    _id: "",
    email: "",
    firstName: "",
    lastName: "",
    profilePicture: null,
    ...preloadedState?.user,
  },
  reducers: {
    setUser: (state, action) => {
      const { _id, email, firstName, lastName, profilePicture } =
        action.payload;
      state._id = _id;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.profilePicture = profilePicture;
    },
    resetUser: (state) => {
      state._id = "";
      state.email = "";
      state.firstName = "";
      state.lastName = "";
      state.profilePicture = null;
    },
  },
});

export const { setUser, resetUser } = userSlice.actions;
export const selectUser = (state) => state.user;
export default userSlice.reducer;
