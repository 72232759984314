import React, { useEffect, useState } from "react";
import { Grid, TextField, Button } from "@mui/material";
import { CustomModal } from "../sharedComponents";
import useCancelProductOrder from "../../customHooks/productOrder/useCancelProductOrder";

const OrderCancelation = ({ close, order }) => {
  const [cancelOrderOpen, setCancelOrderOpen] = useState(false);
  const [cancelationReason, setCancelationReason] = useState("");
  const [saving, setSaving] = useState(false);
  const [valid, setValid] = useState(false);
  const cancelProductOrder = useCancelProductOrder();

  useEffect(() => {
    if (!cancelationReason) return setValid(false);
    setValid(true);
  }, [cancelationReason]);

  const closeCancelModal = (event, reason) => {
    if (reason === "backdropClick") return;
    setCancelOrderOpen(false);
  };

  const submitCancelOrder = async () => {
    setSaving(true);
    try {
      await cancelProductOrder.mutateAsync({
        order: { _id: order._id, cancelationReason },
      });
      setCancelOrderOpen(false);
      close();
      setSaving(false);
    } catch (error) {
      throw error;
    }
  };

  const renderCancelOrder = () => {
    return (
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <TextField
            required
            autoComplete="off"
            fullWidth
            variant="outlined"
            label="Cancelation Reason"
            value={cancelationReason}
            onChange={(e) => {
              setCancelationReason(e.target.value);
            }}
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={submitCancelOrder}
            disabled={!valid || saving}
            color="error"
          >
            {saving ? "Cancelling Order" : "Cancel Oder"}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <Button
            color="error"
            variant="contained"
            fullWidth
            onClick={() => setCancelOrderOpen(true)}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>

      <CustomModal
        open={cancelOrderOpen}
        close={closeCancelModal}
        title={"Cancel Order"}
      >
        {renderCancelOrder()}
      </CustomModal>
    </>
  );
};

export default OrderCancelation;
