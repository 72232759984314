import { useMutation, useQueryClient } from "@tanstack/react-query";
import { pickUpConfirmation } from "../../api/productOrderAPI";

const usePickUpConfirmation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: pickUpConfirmation,
    onSuccess: () => {
      queryClient.invalidateQueries("notifications");
      queryClient.invalidateQueries("orders");
      queryClient.invalidateQueries("order");
    },
  });
};

export default usePickUpConfirmation;
