import React, { useState } from "react";
import { Button, Box, Grid } from "@mui/material/";
import ProductForm from "./ProductForm";
import { CustomModal } from "../sharedComponents";
import { getProductOptionLabel } from "../../sharedFunctions/labels";
import useProducts from "../../customHooks/products/useProducts";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import CustomToolbar from "../sharedComponents/tables/CustomToolbar";
import useProductFilter from "../suppliers/productTables/useProductFilter";
import { useSelector } from "react-redux";
import { selectAppUserLevel } from "../../redux/reducers/userLevelsSlice";

const Products = () => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const { products, isLoading } = useProducts();
  const [product, setProduct] = useState(null);
  const [productOpen, setProductOpen] = useState(false);

  const { onFilterModelChange, subTypeOptions, typeOptions } =
    useProductFilter();

  const openProduct = (product) => {
    setProduct(product);
    setProductOpen(true);
  };

  const closeProduct = (e, reason) => {
    if (reason === "backdropClick") return;
    setProduct(null);
    setProductOpen(false);
  };

  const TableButtons = () => (
    <Grid
      item
      xs={12}
      md={4}
      display="flex"
      justifyContent={{ xs: "center", md: "flex-end" }}
    >
      <Button variant="contained" onClick={() => setProductOpen(true)}>
        Add Product
      </Button>
    </Grid>
  );

  const filterOperators = getGridSingleSelectOperators().filter(({ value }) =>
    ["isAnyOf"].includes(value)
  );

  const columns = [
    {
      field: "View",
      width: 100,
      renderCell: (params) => (
        <Button variant="contained" onClick={() => openProduct(params.row)}>
          View
        </Button>
      ),
      filterable: false,
    },

    {
      field: "name",
      width: 500,
      headerName: "Name",
      valueGetter: (value, row) => getProductOptionLabel(row),
    },
    {
      field: "type",
      headerName: "Type",
      type: "singleSelect",
      filterOperators,
      valueOptions: typeOptions,
      width: 130,
    },
    {
      field: "subType",
      headerName: "Sub Type",
      type: "singleSelect",
      filterOperators,
      valueOptions: subTypeOptions,
      valueGetter: (value, row) =>
        row.chemicalType ??
        row.filterType ??
        row.equipmentType ??
        row.membraneType,

      width: 130,
    },
    {
      field: "facilityCount",
      width: 100,
      headerName: "Facilities",
    },
    {
      field: "supplierCount",
      width: 100,
      headerName: "Suppliers",
    },
    {
      field: "requestingFacilities",
      width: 200,
      headerName: "Requesting Facilities",
      valueGetter: (value, row) => {
        return row?.requestingFacilities?.map((facility) => facility.name);
      },
    },
    {
      field: "requestedProduct",
      width: 200,
      headerName: "Supplier Requested Product",
      valueGetter: (value, row) => {
        return row?.needsApproval ? "Needs Approval" : "";
      },
    },
  ];

  return (
    <>
      <CustomModal
        open={productOpen}
        close={closeProduct}
        title="Product"
        hasEdit
        anyOneCanEdit={appUserLevel?.includes("Super Product User")}
        isNew={product ? false : true}
      >
        <ProductForm product={product} close={closeProduct} />
      </CustomModal>

      <Box
        sx={{
          height: window.innerHeight - 175,
          width: "100%",
          "& .product-approval-true": {
            background: "rgba(255, 255, 0.7)",
          },
          "& .product-approval-true:hover": {
            background: "rgba(255, 255, 0, 0.5) !important",
          },
          "& .facility-no-suppliers-true": {
            background: "rgba(255, 0, 0)",
          },
          "& .facility-no-suppliers-true:hover": {
            background: "rgba(255, 0, 0, 0.7) !important",
          },
          "& .facility-requested-true": {
            background: "rgba(255, 125, 0)",
          },
          "& .facility-requested-true:hover": {
            background: "rgba(255, 125, 0, 0.7) !important",
          },
        }}
      >
        <DataGrid
          getRowId={(row) => row._id}
          rows={products}
          columns={columns}
          rowsPerPageOptions={[25, 50, 100]}
          disableRowSelectionOnClick
          slots={{
            toolbar: () => CustomToolbar({ Buttons: TableButtons }),
          }}
          onFilterModelChange={onFilterModelChange}
          loading={isLoading}
          getRowClassName={(value, row) => {
            if (value.row?.needsApproval) {
              return "product-approval-true";
            } else if (
              value.row?.facilityCount > 0 &&
              value.row?.supplierCount === 0
            ) {
              return "facility-no-suppliers-true";
            } else if (value.row?.requestingFacilities?.length > 0) {
              return "facility-requested-true";
            }
          }}
        />
      </Box>
    </>
  );
};

export default Products;
