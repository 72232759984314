import calculateProductPrice from "./calculateProductPrice";

export const getProductAndShippingTotal = ({
  amount,
  exchangeRate,
  shippingCost,
  supplierProductDetail,
  tierToUse,
}) => {
  const shipCost = shippingCost ? shippingCost / amount : 0;

  const productPrice = calculateProductPrice(
    tierToUse ?? supplierProductDetail.cost,
    exchangeRate?.rate,
    supplierProductDetail.supplier._id
  );

  const containerDeposit =
    calculateProductPrice(
      supplierProductDetail.containerDeposit,
      exchangeRate?.rate,
      null
    ) ?? 0;

  return containerDeposit + productPrice + shipCost;
};
