import React from "react";
import { Typography, Link } from "@mui/material";

const EmailsDisplay = (props) => {
  const { emails, label } = props;
  return (
    <Typography
      sx={{
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      {label}:{" "}
      {emails?.map((email, i) => (
        <React.Fragment key={email}>
          <Link href={`mailto:${email}`}>{email}</Link>
          {emails.length - 1 !== i && ", "}
        </React.Fragment>
      ))}
    </Typography>
  );
};

export default EmailsDisplay;
