import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addToOrder } from "../../api/productOrderAPI";

const useAddToOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addToOrder,
    onSuccess: () => {
      queryClient.invalidateQueries("orders");
      queryClient.invalidateQueries("order");
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
      });
    },
  });
};

export default useAddToOrder;
