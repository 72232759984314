import { useMutation, useQueryClient } from "@tanstack/react-query";
import { copyProduct } from "../../api/productAPI";

const useCopyProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: copyProduct,
    onSuccess: () => {
      queryClient.invalidateQueries("products");
      queryClient.invalidateQueries(["productsSupplier"]);
      queryClient.invalidateQueries(["productsManufacturer"]);
    },
  });
};

export default useCopyProduct;
