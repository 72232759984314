import { useQuery } from "@tanstack/react-query";

import { getManufacturerProductDetailsByProductID } from "../../api/manufacturerProductDetailsAPI";

const useManufacturerProductDetailsByProduct = (productID) => {
  const { data, error, isError, isLoading } = useQuery({
    queryKey: ["manufacturerProductDetails", productID],
    queryFn: () => getManufacturerProductDetailsByProductID(productID),
    enabled: !!productID,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return {
    data,
    error,
    isError,
    isLoading,
  };
};

export default useManufacturerProductDetailsByProduct;
