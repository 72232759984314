import { useMutation, useQueryClient } from "@tanstack/react-query";
import { requestServiceOrderFacilityApproval } from "../../api/serviceOrderAPI";

const useRequestServiceOrderFacilityApproval = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: requestServiceOrderFacilityApproval,
    onSuccess: async () => {
      await queryClient.invalidateQueries("serviceOrders");
    },
  });
};

export default useRequestServiceOrderFacilityApproval;
