import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import calculateOrderTotals from "../../sharedFunctions/purchasingFunctions/calculateOrderTotals";

const ShoppingCartTotal = ({
  exchangeRates,
  hasOnlyShippingIncluded,
  facilityCredits,
  facilityHasGST,
  facilityHasPST,
  facilityProvince,
  productsInCartByWarehouse,
}) => {
  const {
    credits,
    deposits,
    GSTAmount,
    PSTAmount,
    productTotal,
    orderTotal,
    shippingCost,
    subtotal,
  } = calculateOrderTotals({
    exchangeRates,
    facilityCredits,
    facilityHasGST,
    facilityHasPST,
    facilityProvince,
    productsInCartByWarehouse,
  });

  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
      >
        <Typography>Product Total: ${productTotal.toFixed(2)}</Typography>
        {deposits > 0 && (
          <Typography>Deposits: ${deposits.toFixed(2)}</Typography>
        )}

        {shippingCost > 0 && (
          <Typography>Shipping: ${shippingCost.toFixed(2)}</Typography>
        )}
        <Typography>Sub Total: ${subtotal.toFixed(2)}</Typography>
        {GSTAmount > 0 && <Typography>GST: ${GSTAmount.toFixed(2)}</Typography>}
        {PSTAmount > 0 && <Typography>PST: ${PSTAmount.toFixed(2)}</Typography>}
        {credits > 0 && (
          <Typography color="limegreen">
            Credits: ${credits.toFixed(2)}
          </Typography>
        )}
        <Typography>Total: ${orderTotal.toFixed(2)}</Typography>

        {!hasOnlyShippingIncluded && !shippingCost && (
          <Typography color="error">Shipping may be extra</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default ShoppingCartTotal;
