import { useQuery } from "@tanstack/react-query";
import { getRestrictedShippers } from "../../api/facilityAPI";
import { useEffect } from "react";
import { setRestrictedShippers } from "../../redux/reducers/productsSlice";
import { useDispatch } from "react-redux";

const useFacilityRestrictedShippers = (id) => {
  const dispatch = useDispatch();

  const { data: restrictedShippers = [], isLoading } = useQuery({
    queryKey: ["restrictedShippers", id],
    queryFn: () => getRestrictedShippers(id),
    enabled: !!id,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (restrictedShippers.length) {
      dispatch(setRestrictedShippers(restrictedShippers));
    }
  }, [dispatch, restrictedShippers]);

  return { restrictedShippers, isLoading };
};

export default useFacilityRestrictedShippers;
