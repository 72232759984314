import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { CustomModal } from "../sharedComponents";
import {
  getWSARG131PDF,
  getWSARG137PDF,
  getWSARG154PDF,
} from "../../api/reportsAPI";

const DownloadBlankForms = () => {
  const [blankFormOpen, setBlankFormOpen] = useState(false);

  const handleDownloadWSA_RG_131 = async () => {
    try {
      const response = await getWSARG131PDF({ reportID: null });
      // Create a download link
      const downloadUrl = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `RG-131.pdf`);
      document.body.appendChild(link);
      // Trigger the download
      link.click();
      // Clean up the download link
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };

  const handleDownloadWSA_RG_137 = async () => {
    try {
      const response = await getWSARG137PDF({ reportID: null });
      // Create a download link
      const downloadUrl = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `RG-137.pdf`);
      document.body.appendChild(link);
      // Trigger the download
      link.click();
      // Clean up the download link
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };

  const handleDownloadWSA_RG_154 = async () => {
    try {
      const response = await getWSARG154PDF({ reportID: null });
      // Create a download link
      const downloadUrl = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", `RG-154.pdf`);
      document.body.appendChild(link);
      // Trigger the download
      link.click();
      // Clean up the download link
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      // Handle the error
      console.error(error);
    }
  };

  const closeBlankFormModal = () => {
    setBlankFormOpen(false);
  };

  return (
    <>
      <CustomModal
        close={closeBlankFormModal}
        open={blankFormOpen}
        title={"Blank Report Forms"}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleDownloadWSA_RG_131}
            >
              RG-131 Ground Water Supply Raw Water Use Data Report
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleDownloadWSA_RG_137}
            >
              RG-137 Surface Water Supply Raw Water Use Report
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={handleDownloadWSA_RG_154}
            >
              RG-154 Treated Water Use Report
            </Button>
          </Grid>
        </Grid>
      </CustomModal>

      <Grid item>
        <Button variant="contained" onClick={() => setBlankFormOpen(true)}>
          Download Blank Forms
        </Button>
      </Grid>
    </>
  );
};

export default DownloadBlankForms;
