import { Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useServiceContractUpdateServiceDates from "../../customHooks/serviceContractOrder/useServiceContractUpdateServiceDates";
import moment from "moment";
import CustomModal from "../sharedComponents/modals/CustomModal";
import RequestServiceDateChangeForm from "../services/RequestServiceDateChangeForm";
import { useSelector } from "react-redux";
import { selectAppUserLevel } from "../../redux/reducers/userLevelsSlice";
import useSupplierCompanyUnavailableServiceDates from "../../customHooks/supplierCompany/useSupplierCompanyUnavailableServiceDates";
import CustomDatePicker from "../sharedComponents/datePicker/CustomDatePicker";

const ManageServiceContractDates = ({
  close,
  facilityID,
  facilityName,
  maxDaysBetweenService,
  serviceContractID,
  serviceContractName,
  serviceContractServiceDates,
  supplierEmails,
  supplierID,
  services,
  serviceCompletion,
  serviceStart,
  hasServiceDateAutomation,
}) => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const [error, setError] = useState(false);
  const [errorIndex, setErrorIndex] = useState(null);
  const [editServiceDates, setEditServiceDates] = useState(false);
  const [message, setMessage] = useState("");
  const [serviceDates, setServiceDates] = useState(
    serviceContractServiceDates?.map((sd) => {
      return {
        notes: sd.notes,
        serviceDate: moment(sd.serviceDate),
        status: sd.status,
      };
    }) ?? []
  );
  const [requestServiceDateChangeOpen, setRequestServiceDateChangeOpen] =
    useState(false);
  const [previousServiceDate, setPreviousServiceDate] = useState(null);

  const updateServiceContractServiceDates =
    useServiceContractUpdateServiceDates();

  const { unavailableServiceDates } =
    useSupplierCompanyUnavailableServiceDates(supplierID);

  const closeRequestServiceDateChange = (e, reason, confirmed) => {
    if (reason === "backdropClick") return;
    setRequestServiceDateChangeOpen(false);
    setPreviousServiceDate(null);
    if (confirmed) close();
  };

  useEffect(() => {
    if (!serviceDates?.length) return;
    for (let j = 1; j < serviceDates.length; j++) {
      const currentServiceDate = moment(serviceDates[j].serviceDate);
      const previousServiceDate = moment(serviceDates[j - 1].serviceDate);

      if (currentServiceDate.isBefore(previousServiceDate)) {
        setError(true);
        setErrorIndex(j);
        setMessage("Service dates are not in order.");
        return;
      }
    }

    const uniqueDatesSet = new Set(
      serviceDates.map((sd) => moment(sd.serviceDate).format("YYYY-MM-DD"))
    );

    if (uniqueDatesSet.size < serviceDates.length) {
      setError(true);
      setErrorIndex(serviceDates?.length - 1);
      setMessage("Duplicate service date found.");
      return;
    }

    let serviceDaysRemaining = 0;
    let lastYearIndex = -1;

    for (let i = 0; i < serviceDates.length; i++) {
      //if this service date exists in the previous service dates skip
      if (
        serviceContractServiceDates.some(
          (sd) =>
            moment(sd.serviceDate).format("YYYY-MM-DD") ===
            moment(serviceDates[i].serviceDate).format("YYYY-MM-DD")
        )
      ) {
        continue;
      }

      const serviceDateMoment = moment(serviceDates[i].serviceDate);
      const yearIndex = serviceDateMoment.diff(moment(serviceStart), "year");

      if (
        unavailableServiceDates.includes(serviceDateMoment.format("YYYY-MM-DD"))
      ) {
        setError(true);
        setErrorIndex(i);
        setMessage("Service date is already booked.");
        return;
      }

      if (
        serviceDateMoment.isBefore(serviceStart, "day") ||
        yearIndex >= services.length
      ) {
        setError(true);
        setErrorIndex(i);
        setMessage("Service date is out of contract period.");
        return;
      }

      const onSiteService = services[yearIndex]?.find(
        (service) => service.service?.serviceMode === "On Site"
      );

      if (lastYearIndex !== yearIndex) {
        serviceDaysRemaining += onSiteService?.quantity ?? 0;
        lastYearIndex = yearIndex;
      }

      if (!onSiteService || serviceDaysRemaining <= 0) {
        const futureOnSiteService = services
          .slice(yearIndex + 1)
          .find((yearServices) =>
            yearServices.some(
              (service) => service.service?.serviceMode === "On Site"
            )
          );

        if (futureOnSiteService) {
          serviceDaysRemaining--;
          continue; // Future availability found, skip without error
        } else {
          setError(true);
          setErrorIndex(i);
          setMessage(
            "No on-site service available for this year and no future availability."
          );
          return;
        }
      }

      if (serviceDaysRemaining <= 0) {
        setError(true);
        setErrorIndex(i);
        setMessage("All on-site services for this year have been used.");
        return;
      }

      serviceDaysRemaining--;
    }

    setError(false);
    setMessage("");
  }, [
    serviceDates,
    serviceContractServiceDates,
    unavailableServiceDates,
    serviceStart,
    services,
  ]);

  const getNextAutomatedServiceDate = (
    maxDaysBetweenService,
    serviceCompletion,
    serviceDate
  ) => {
    const nextServiceDate = moment(serviceDate).add(
      maxDaysBetweenService,
      "days"
    );
    if (nextServiceDate.isAfter(serviceCompletion)) {
      return moment(serviceCompletion);
    }
    return nextServiceDate;
  };

  const onSubmit = async () => {
    await updateServiceContractServiceDates.mutateAsync({
      hasServiceDateAutomation,
      maxDaysBetweenService,
      serviceContractID: serviceContractID,
      serviceCompletion,
      serviceDates,
    });

    setEditServiceDates(false);
  };

  const renderServiceDates = () => {
    return (
      <Grid container spacing={2} item xs={12}>
        {editServiceDates && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => {
                let serviceDate = moment();
                if (serviceDates.length > 0) {
                  const daysBetween = maxDaysBetweenService ?? 0;

                  serviceDate = getNextAutomatedServiceDate(
                    daysBetween,
                    serviceCompletion,
                    serviceDates[serviceDates.length - 1]?.serviceDate
                  );
                }

                setServiceDates([
                  ...serviceDates,
                  {
                    serviceDate,
                    notes: "",
                  },
                ]);
              }}
            >
              Add Service Date
            </Button>
          </Grid>
        )}
        {serviceDates.map((serviceDate, i) => (
          <Grid
            container
            spacing={2}
            item
            xs={12}
            key={`serviceDate${i}` + serviceDate.serviceDate}
            className="vertical-centered-container"
          >
            <Grid item xs={4}>
              <CustomDatePicker
                label="Service Date *"
                value={serviceDate.serviceDate}
                onChange={(date) => {
                  const newServiceDates = [...serviceDates];
                  newServiceDates[i].serviceDate = date;
                  setServiceDates(newServiceDates);
                }}
                disabled={!editServiceDates}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="off"
                label="Notes"
                value={serviceDate.notes}
                onChange={(e) => {
                  const newServiceDates = [...serviceDates];
                  newServiceDates[i].notes = e.target.value;
                  setServiceDates(newServiceDates);
                }}
                variant="outlined"
                fullWidth
                multiline
                disabled={!editServiceDates}
              />
            </Grid>
            {!editServiceDates &&
              (appUserLevel.includes("Facility") ||
                appUserLevel.includes("Super User")) &&
              serviceDate.status !== "Completed" && (
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      setRequestServiceDateChangeOpen(true);
                      setPreviousServiceDate(serviceDate.serviceDate);
                    }}
                  >
                    Request Date Change
                  </Button>
                </Grid>
              )}

            {editServiceDates && (
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    const newServiceDates = [...serviceDates];
                    newServiceDates.splice(i, 1);
                    setServiceDates(newServiceDates);
                  }}
                  color="error"
                >
                  Remove
                </Button>
              </Grid>
            )}
            {hasServiceDateAutomation && i === serviceDates.length - 1 && (
              <Grid item xs={12}>
                <Typography>
                  Next Automated Service Date:{" "}
                  {getNextAutomatedServiceDate(
                    maxDaysBetweenService,
                    serviceCompletion,
                    serviceDate.serviceDate
                  ).format("MM/DD/YYYY")}
                </Typography>
              </Grid>
            )}
            {message && errorIndex === i && (
              <Grid item xs={12}>
                <Typography color={"red"}>{message}</Typography>
              </Grid>
            )}
          </Grid>
        ))}
        {editServiceDates && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={onSubmit}
              disabled={!!error}
            >
              Update Service Dates
            </Button>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => setEditServiceDates(!editServiceDates)}
        >
          Manage Service Dates
        </Button>
      </Grid>
      {renderServiceDates()}
      <CustomModal
        open={requestServiceDateChangeOpen}
        close={closeRequestServiceDateChange}
        title={"Request Service Date Change"}
      >
        <RequestServiceDateChangeForm
          close={closeRequestServiceDateChange}
          serviceContract={{
            _id: serviceContractID,
            name: serviceContractName,
            facility: {
              _id: facilityID,
              name: facilityName,
            },
            supplier: {
              _id: supplierID,
              orderEmails: supplierEmails,
            },
            serviceDates: serviceContractServiceDates,
          }}
          previousServiceDate={previousServiceDate}
        />
      </CustomModal>
    </Grid>
  );
};

export default ManageServiceContractDates;
