import { useMutation } from "@tanstack/react-query";
import { quickBooksCreateVendor } from "../../api/quickBooksAPI";

const useQuickBooksCreateVendor = () => {
  return useMutation({
    mutationFn: quickBooksCreateVendor,
  });
};

export default useQuickBooksCreateVendor;
