import { useQuery } from "@tanstack/react-query";
import { getAllCartSubmissionsByRequestedUser } from "../../api/cartSubmissionAPI";
import { useDispatch } from "react-redux";
import {
  setProductsInCartByWarehouse,
  setOrderFacility,
  setOrderFacilityAddress,
} from "../../redux/reducers/productsSlice";
import { useEffect } from "react";

const useCartSubmissionsByRequestedUser = (id) => {
  const dispatch = useDispatch();

  const { data: cartSubmissions = [] } = useQuery({
    queryKey: ["cartSubmissions", id],
    queryFn: () => getAllCartSubmissionsByRequestedUser(id),
    enabled: !!id,
    staleTime: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!cartSubmissions?.length) return;
    dispatch(setOrderFacility(cartSubmissions[0].facility._id));
    dispatch(
      setOrderFacilityAddress(cartSubmissions[0].facility.addressObject)
    );
    dispatch(
      setProductsInCartByWarehouse(cartSubmissions[0].productsInCartByWarehouse)
    );
  }, [cartSubmissions, dispatch]);

  return { cartSubmissions };
};

export default useCartSubmissionsByRequestedUser;
