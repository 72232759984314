import { createSlice } from "@reduxjs/toolkit";
import { loadState } from "../localStorage";
const preloadedState = loadState("userLevels");

export const userLevelsSlice = createSlice({
  name: "userLevels",
  initialState: {
    appUserLevel: preloadedState?.appUserLevel ?? [],
    facilityUserLevel: preloadedState?.facilityUserLevel ?? [],
    shipperUserLevel: preloadedState?.shipperUserLevel ?? [],
    supplierUserLevel: preloadedState?.supplierUserLevel ?? [],
    superUserUserAccessEmail: preloadedState?.superUserUserAccessEmail ?? "",
  },
  reducers: {
    resetUserLevels: (state, action) => {
      state = {
        appUserLevel: [],
        facilityUserLevel: [],
        shipperUserLevel: [],
        supplierUserLevel: [],
        superUserUserAccessEmail: "",
      };
    },
    setAppUserLevel: (state, action) => {
      state.appUserLevel = action.payload;
    },
    setFacilityUserLevel: (state, action) => {
      state.facilityUserLevel = action.payload;
    },
    setShipperUserLevel: (state, action) => {
      state.shipperUserLevel = action.payload;
    },
    setSupplierUserLevel: (state, action) => {
      state.supplierUserLevel = action.payload;
    },
    setSuperUserUserAccessEmail: (state, action) => {
      state.superUserUserAccessEmail = action.payload;
    },
  },
});

export const {
  resetUserLevels,
  setAppUserLevel,
  setFacilityUserLevel,
  setShipperUserLevel,
  setSupplierUserLevel,
  setSuperUserUserAccessEmail,
} = userLevelsSlice.actions;

export default userLevelsSlice.reducer;

export const selectAppUserLevel = (state) => {
  return state.userLevels.appUserLevel;
};
export const selectFacilityUserLevel = (state) => {
  return state.userLevels.facilityUserLevel;
};
export const selectShipperUserLevel = (state) => {
  return state.userLevels.shipperUserLevel;
};
export const selectSupplierUserLevel = (state) => {
  return state.userLevels.supplierUserLevel;
};
export const selectSuperUserUserAccessEmail = (state) => {
  return state.userLevels.superUserUserAccessEmail;
};
