import React from "react";

import { Button, CircularProgress, Grid } from "@mui/material";
import PDFPreview from "./PDFPreview";
import useImage from "../../customHooks/image/useImage";

const FileUpload = (props) => {
  const { itemID, deleteFunction, files, uploadFunction, fileTypes } = props;

  return (
    <Grid container spacing={2} item xs={12}>
      <Grid item xs={6} md={4}>
        <input
          onChange={(event) => uploadFunction(event, itemID)}
          type="file"
          accept={fileTypes}
          multiple
        />
      </Grid>
      {files?.length > 0 &&
        files.map((file, i) => (
          <React.Fragment key={file.fileID}>
            <DisplayFile
              itemID={itemID}
              fileToDisplay={file}
              i={i}
              deleteFunction={deleteFunction}
            />
          </React.Fragment>
        ))}
    </Grid>
  );
};

const DisplayFile = ({ itemID, fileToDisplay, i, deleteFunction }) => {
  const { file, isLoading } = useImage(fileToDisplay.fileID);

  return fileToDisplay.contentType === "image/png" ||
    fileToDisplay.contentType === "image/jpg" ||
    fileToDisplay.contentType === "image/jpeg" ? (
    <Grid container item xs={12} md={4} className="centered-container">
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid item xs={12} style={{ position: "relative", height: 150 }}>
            <img
              src={file}
              loading="lazy"
              alt={"file" + i}
              style={{ height: "100%" }}
            />
          </Grid>
          <Grid item xs={4} sx={{ mt: 1 }}>
            <Button
              variant="contained"
              fullWidth
              color="error"
              onClick={() => deleteFunction(fileToDisplay.fileID)}
            >
              Remove
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  ) : (
    <Grid
      container
      item
      xs={12}
      md={4}
      key={fileToDisplay.fileID}
      className="centered-container"
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <PDFPreview file={file} />

          <Grid item xs={4} sx={{ mt: 1 }}>
            <Button
              variant="contained"
              fullWidth
              color="error"
              onClick={() => deleteFunction(fileToDisplay.fileID, itemID)}
            >
              Remove
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default FileUpload;
