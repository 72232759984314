import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reportOrder } from "../../api/orderAPI";

const useReportOrder = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: reportOrder,
    onSuccess: (data) => {
      queryClient.invalidateQueries("orders");
      queryClient.invalidateQueries(["orders", data._id]);
    },
  });
};

export default useReportOrder;
