import React, { useEffect, useState } from "react";
import axios from "axios";
import { TextField, Grid, Typography, Autocomplete } from "@mui/material";
import GooglePlacesAutocomplete from "./GooglePlacesAutocomplete";
import FormSelect from "../selectors/FormSelect";
import {
  CA_provinces,
  countries,
  US_states,
} from "../../../sharedFunctions/countryData";
import FormSwitch from "../toggles/FormSwitch";

const AddressFields = (props) => {
  const { address, editMode, isBusiness, setAddress, isFacility } = props;

  const [cities, setCities] = useState([]);
  const [thirdPartyCities, setThirdPartyCities] = useState([]);
  const [POBoxCities, setPOBoxCities] = useState([]);

  useEffect(() => {
    if (
      (!address.province || !address.country) &&
      address.country !== "United Kingdom" &&
      address.country !== "China"
    )
      return;

    if (address.country === "United Kingdom" || address.country === "China") {
      axios
        .post(
          "https://countriesnow.space/api/v0.1/countries/cities",
          {
            country: address.country,
          },
          { bypass: true }
        )
        .then((res) => {
          if (res.data) setCities(res.data.data);
        });
    } else {
      const provinceFullName =
        CA_provinces[address.province] || US_states[address.province];

      axios
        .post(
          "https://countriesnow.space/api/v0.1/countries/state/cities",
          {
            country:
              address.country === "United States of America"
                ? "United States"
                : address.country,
            state: provinceFullName,
          },
          { bypass: true }
        )
        .then((res) => {
          if (res.data) setCities(res.data.data);
        });
    }
  }, [address.country, address.province]);

  useEffect(() => {
    if (
      (!address?.thirdPartyAddress?.province ||
        !address?.thirdPartyAddress?.country) &&
      address.thirdPartyAddress?.country !== "United Kingdom" &&
      address.thirdPartyAddress?.country !== "China"
    )
      return;

    if (address.thirdPartyAddress.country === "United Kingdom") {
      axios
        .post(
          "https://countriesnow.space/api/v0.1/countries/cities",
          {
            country: address.thirdPartyAddress.country,
          },
          { bypass: true }
        )
        .then((res) => {
          if (res.data) setThirdPartyCities(res.data.data);
        });
    } else {
      const provinceFullName =
        CA_provinces[address.thirdPartyAddress.province] ||
        US_states[address.thirdPartyAddress.province];

      axios
        .post(
          "https://countriesnow.space/api/v0.1/countries/state/cities",
          {
            country:
              address.thirdPartyAddress.country === "United States of America"
                ? "United States"
                : address.thirdPartyAddress.country,
            state: provinceFullName,
          },
          { bypass: true }
        )
        .then((res) => {
          if (res.data) setThirdPartyCities(res.data.data);
        });
    }
  }, [
    address?.thirdPartyAddress?.country,
    address?.thirdPartyAddress?.province,
  ]);

  useEffect(() => {
    if (
      (!address?.POBoxAddress?.province || !address?.POBoxAddress?.country) &&
      address.POBoxAddress?.country !== "United Kingdom" &&
      address.POBoxAddress?.country !== "China"
    )
      return;

    if (address.POBoxAddress.country === "United Kingdom") {
      axios
        .post(
          "https://countriesnow.space/api/v0.1/countries/cities",
          {
            country: address.POBoxAddress.country,
          },
          { bypass: true }
        )
        .then((res) => {
          if (res.data) setPOBoxCities(res.data.data);
        });
    } else {
      const provinceFullName =
        CA_provinces[address.POBoxAddress.province] ||
        US_states[address.POBoxAddress.province];

      axios
        .post(
          "https://countriesnow.space/api/v0.1/countries/state/cities",
          {
            country:
              address.POBoxAddress.country === "United States of America"
                ? "United States"
                : address.POBoxAddress.country,
            state: provinceFullName,
          },
          { bypass: true }
        )
        .then((res) => {
          if (res.data) setPOBoxCities(res.data.data);
        });
    }
  }, [address?.POBoxAddress?.country, address?.POBoxAddress?.province]);

  const setAddressObject = (item, value, isThirdPartyAddress, isPOBox) => {
    const tempAddress = { ...address };
    if (isThirdPartyAddress) {
      const tempThirdPartyAddress = { ...tempAddress.thirdPartyAddress };
      tempThirdPartyAddress[item] = value;
      tempAddress.thirdPartyAddress = tempThirdPartyAddress;
    } else if (isPOBox) {
      const tempPOBoxAddress = { ...tempAddress.POBoxAddress };
      tempPOBoxAddress[item] = value;
      tempAddress.POBoxAddress = tempPOBoxAddress;
    } else tempAddress[item] = value;

    setAddress(tempAddress);
  };

  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12}>
        <Typography>Address</Typography>
      </Grid>
      {isFacility && (
        <FormSwitch
          disabled={!editMode}
          label="Third Party Address"
          checked={address.hasCoordinates}
          onChange={(e) => {
            if (e.target.checked) {
              const tempAddress = { ...address };
              if (!address.thirdPartyAddress) {
                tempAddress.thirdPartyAddress = {
                  city: "",
                  country: "",
                  province: "",
                  postalCode: "",
                  streetAddress: "",
                };
              }
              if (!address.POBoxAddress) {
                tempAddress.POBoxAddress = {
                  city: "",
                  country: "",
                  province: "",
                  postalCode: "",
                  streetAddress: "",
                };
              }
              tempAddress.streetAddress = "";
              tempAddress.secondaryAddress = "";
              tempAddress.hasCoordinates = true;
              tempAddress.hasPOBox = true;
              setAddress(tempAddress);
            } else {
              setAddressObject("hasCoordinates", e.target.checked);
            }
          }}
          name="hasThirdPartyAddress"
          xs={12}
          infoText={
            "Toggle this if the facility does not have a street address"
          }
        />
      )}

      {isFacility && address.hasCoordinates && (
        <>
          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              label="Coordinates"
              variant="outlined"
              value={address.coordinates}
              onChange={(e) => setAddressObject("coordinates", e.target.value)}
              fullWidth
              disabled={!editMode}
              required
            />
          </Grid>
        </>
      )}

      {editMode && !address.hasCoordinates && (
        <Grid item xs={12}>
          <GooglePlacesAutocomplete setAddress={setAddress} />
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <FormSelect
          editMode={editMode}
          value={address.country}
          onChange={(e) => {
            const tempAddress = { ...address };
            tempAddress["country"] = e.target.value;
            tempAddress["province"] = "";
            setAddress(tempAddress);
          }}
          menuItems={Object.entries(countries).map(([key, country]) => country)}
          label={"Country"}
        />
      </Grid>
      {address.country !== "United Kingdom" && address.country !== "China" && (
        <Grid item xs={12} md={6}>
          <FormSelect
            editMode={editMode}
            value={address.province}
            onChange={(e) => {
              const tempAddress = { ...address };
              tempAddress["province"] = e.target.value;
              tempAddress["city"] = "";
              setAddress(tempAddress);
            }}
            menuItems={
              address.country === "Canada"
                ? Object.keys(CA_provinces).map((province) => province)
                : address.country === "United States of America"
                ? Object.keys(US_states).map((province) => province)
                : [""]
            }
            label={"Province / State"}
          />
        </Grid>
      )}

      {!address.hasCoordinates && (
        <Grid item xs={12} md={6}>
          <TextField
            autoComplete="off"
            label={"Street Address"}
            variant="outlined"
            value={address.streetAddress}
            onChange={(e) => setAddressObject("streetAddress", e.target.value)}
            fullWidth
            disabled={!editMode}
            required
          />
        </Grid>
      )}

      {!address.hasCoordinates && (
        <Grid item xs={12} md={6}>
          <TextField
            autoComplete="off"
            id="secondaryAddress"
            label="Secondary Address"
            variant="outlined"
            value={address.secondaryAddress}
            onChange={(e) =>
              setAddressObject("secondaryAddress", e.target.value)
            }
            disabled={!editMode}
            fullWidth
          />
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <Autocomplete
          disabled={!editMode}
          freeSolo
          options={cities}
          value={address.city}
          onInputChange={(e, value) => {
            setAddressObject("city", value);
          }}
          onChange={(e, value) => {
            setAddressObject("city", value);
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              autoComplete="off"
              {...params}
              label="City / Town"
              required
            />
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          autoComplete="off"
          label="Postal / Zip Code"
          variant="outlined"
          value={address.postalCode}
          onChange={(e) => setAddressObject("postalCode", e.target.value)}
          fullWidth
          disabled={!editMode}
          required
        />
      </Grid>

      {isBusiness && (
        <Grid item xs={12} md={4}>
          <TextField
            autoComplete="off"
            id="FOBLocation"
            label="FOB Location"
            variant="outlined"
            value={address.city ? address.city + ", " + address.province : ""}
            disabled={true}
            fullWidth
          />
        </Grid>
      )}

      {isFacility && address.hasCoordinates && (
        <>
          <Grid item xs={12}>
            <Typography>Parcels Ship To</Typography>
          </Grid>
          {editMode && (
            <Grid item xs={12}>
              <GooglePlacesAutocomplete
                setAddress={setAddress}
                isThirdPartyAddress={true}
              />
            </Grid>
          )}

          <Grid item xs={12} md={6}>
            <FormSelect
              editMode={editMode}
              value={address.thirdPartyAddress?.country}
              onChange={(e) => {
                const tempAddress = { ...address };
                const tempThirdPartyAddress = { ...address.thirdPartyAddress };
                tempThirdPartyAddress["country"] = e.target.value;
                tempThirdPartyAddress["province"] = "";
                tempAddress.thirdPartyAddress = tempThirdPartyAddress;
                setAddress(tempAddress);
              }}
              menuItems={Object.entries(countries).map(
                ([key, country]) => country
              )}
              label={"Country"}
            />
          </Grid>

          {address.thirdPartyAddress?.country !== "United Kingdom" &&
            address.thirdPartyAddress?.country !== "China" && (
              <Grid item xs={12} md={6}>
                <FormSelect
                  editMode={editMode}
                  value={address.thirdPartyAddress?.province}
                  onChange={(e) => {
                    const tempAddress = { ...address };
                    const tempThirdPartyAddress = {
                      ...address.thirdPartyAddress,
                    };
                    tempThirdPartyAddress["province"] = e.target.value;
                    tempThirdPartyAddress["city"] = "";
                    tempAddress.thirdPartyAddress = tempThirdPartyAddress;
                    setAddress(tempAddress);
                  }}
                  menuItems={
                    address.thirdPartyAddress?.country === "Canada"
                      ? Object.keys(CA_provinces).map((province) => province)
                      : address.thirdPartyAddress?.country ===
                        "United States of America"
                      ? Object.keys(US_states).map((province) => province)
                      : [""]
                  }
                  label={"Province / State"}
                />
              </Grid>
            )}

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              label={"Street Address"}
              variant="outlined"
              value={address.thirdPartyAddress?.streetAddress}
              onChange={(e) =>
                setAddressObject("streetAddress", e.target.value, true)
              }
              fullWidth
              disabled={!editMode}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              id="secondaryAddress"
              label="Secondary Address"
              variant="outlined"
              value={address.thirdPartyAddress?.secondaryAddress}
              onChange={(e) =>
                setAddressObject("secondaryAddress", e.target.value, true)
              }
              disabled={!editMode}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              disabled={!editMode}
              freeSolo
              options={thirdPartyCities}
              value={address.thirdPartyAddress?.city}
              onInputChange={(e, value) => {
                setAddressObject("city", value, true);
              }}
              onChange={(e, value) => {
                setAddressObject("city", value, true);
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  label="City / Town"
                  required
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              label="Postal / Zip Code"
              variant="outlined"
              value={address.thirdPartyAddress?.postalCode}
              onChange={(e) =>
                setAddressObject("postalCode", e.target.value, true)
              }
              fullWidth
              disabled={!editMode}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              label={"Care Of"}
              variant="outlined"
              value={address.thirdPartyAddress?.careOf}
              onChange={(e) => setAddressObject("careOf", e.target.value, true)}
              fullWidth
              disabled={!editMode}
            />
          </Grid>
        </>
      )}
      {isFacility && (
        <FormSwitch
          disabled={!editMode}
          label="Canada Post PO Box"
          checked={address.hasPOBox}
          onChange={(e) => {
            if (e.target.checked) {
              const tempAddress = { ...address };
              if (!address.POBoxAddress) {
                tempAddress.POBoxAddress = {
                  city: "",
                  country: "",
                  province: "",
                  postalCode: "",
                  POBox: "",
                };
              }
              tempAddress.hasPOBox = true;
              setAddress(tempAddress);
            } else {
              setAddressObject("hasPOBox", e.target.checked);
            }
          }}
          name="hasPOBox"
          xs={12}
        />
      )}
      {address.hasPOBox && (
        <>
          <Grid item xs={12}>
            <Typography>Canada Post PO Box</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              label={"PO Box"}
              variant="outlined"
              value={address.POBoxAddress?.POBox}
              onChange={(e) =>
                setAddressObject("POBox", e.target.value, false, true)
              }
              fullWidth
              disabled={!editMode}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormSelect
              editMode={editMode}
              value={address.POBoxAddress?.country}
              onChange={(e) => {
                const tempAddress = { ...address };
                const tempPOBoxAddress = { ...address.POBoxAddress };
                tempPOBoxAddress["country"] = e.target.value;
                tempPOBoxAddress["province"] = "";
                tempAddress.POBoxAddress = tempPOBoxAddress;
                setAddress(tempAddress);
              }}
              menuItems={Object.entries(countries).map(
                ([key, country]) => country
              )}
              label={"Country"}
            />
          </Grid>

          {address.POBoxAddress?.country !== "United Kingdom" &&
            address.POBoxAddress?.country !== "China" && (
              <Grid item xs={12} md={6}>
                <FormSelect
                  editMode={editMode}
                  value={address.POBoxAddress?.province}
                  onChange={(e) => {
                    const tempAddress = { ...address };
                    const tempPOBoxAddress = { ...address.POBoxAddress };
                    tempPOBoxAddress["province"] = e.target.value;
                    tempPOBoxAddress["city"] = "";
                    tempAddress.POBoxAddress = tempPOBoxAddress;
                    setAddress(tempAddress);
                  }}
                  menuItems={
                    address.POBoxAddress?.country === "Canada"
                      ? Object.keys(CA_provinces).map((province) => province)
                      : address.POBoxAddress?.country ===
                        "United States of America"
                      ? Object.keys(US_states).map((province) => province)
                      : [""]
                  }
                  label={"Province / State"}
                />
              </Grid>
            )}

          <Grid item xs={12} md={6}>
            <Autocomplete
              disabled={!editMode}
              freeSolo
              options={POBoxCities}
              value={address.POBoxAddress?.city}
              onInputChange={(e, value) => {
                setAddressObject("city", value, false, true);
              }}
              onChange={(e, value) => {
                setAddressObject("city", value, false, true);
              }}
              fullWidth
              renderInput={(params) => (
                <TextField
                  autoComplete="off"
                  {...params}
                  label="City / Town"
                  required
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              autoComplete="off"
              label="Postal / Zip Code"
              variant="outlined"
              value={address.POBoxAddress?.postalCode}
              onChange={(e) =>
                setAddressObject("postalCode", e.target.value, false, true)
              }
              fullWidth
              disabled={!editMode}
              required
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default AddressFields;
