import { useQuery } from "@tanstack/react-query";
import { getFacilityPreFilterSupplies } from "../../api/facilityAPI";

const useFacilityPreFilterSupplies = (facilityID) => {
  const {
    data: preFilterSupplies = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["facilityPreFilterSupplies", facilityID],
    queryFn: () => getFacilityPreFilterSupplies(facilityID),
    enabled: !!facilityID,
  });

  return { preFilterSupplies, isLoading, error };
};

export default useFacilityPreFilterSupplies;
