import React from "react";
import { FormControlLabel, FormGroup, Grid, Switch } from "@mui/material";
import InfoText from "../InfoText";

const FormSwitch = ({
  checked,
  onChange,
  label,
  name,
  disabled,
  xs,
  md,
  lg,
  xl,
  sx,
  infoText,
  justifyContent,
}) => {
  return (
    <Grid
      item
      xs={xs}
      md={md}
      lg={lg}
      xl={xl}
      sx={sx}
      justifyContent={justifyContent}
      className="vertical-centered-container"
    >
      <FormGroup sx={{ ml: 1 }}>
        <FormControlLabel
          control={
            <Switch disabled={disabled} checked={checked} onChange={onChange} />
          }
          label={label}
          name={name}
        />
      </FormGroup>
      {infoText && <InfoText text={infoText} />}
    </Grid>
  );
};

export default FormSwitch;
