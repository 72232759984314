import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material/";
import useContact from "../../customHooks/contacts/useContact";
import useServices from "../../customHooks/service/useServices";
import {
  getContactOptionLabel,
  getServiceOptionLabel,
} from "../../sharedFunctions/labels";
import useAddServiceOrder from "../../customHooks/serviceOrder/useAddServiceOrder";
import useUpdateServiceOrder from "../../customHooks/serviceOrder/useUpdateServiceOrder";
import ServiceRequestTotal from "./ServiceRequestTotal";
import moment from "moment";
import useServiceTotals from "../../customHooks/service/useServiceTotals";
import customDollarRound from "../../sharedFunctions/purchasingFunctions/customDollarRound";
import calculateServicePrice from "../../sharedFunctions/purchasingFunctions/calculateServicePrice";
import useExchangeRate from "../../customHooks/exchangeRates/useExchangeRate";
import CustomModal from "../sharedComponents/modals/CustomModal";
import RequestServiceDateChangeForm from "./RequestServiceDateChangeForm";
import { useSelector } from "react-redux";
import { selectAppUserLevel } from "../../redux/reducers/userLevelsSlice";
import { FormSwitch } from "../sharedComponents";
import useContactServiceProviders from "../../customHooks/contacts/useContactServiceProviders";
import useFacilitiesNames from "../../customHooks/facility/useFacilitiesNames";
import DecimalTextField from "../sharedComponents/TextFields/DecimalTextField";
import ServiceDates from "./ServiceDates";
import CustomDatePicker from "../sharedComponents/datePicker/CustomDatePicker";
import useSupplierCompanyEmployees from "../../customHooks/supplierCompany/useSupplierCompanyEmployees";
import useRequestServiceOrderFacilityApproval from "../../customHooks/serviceOrder/useRequestServiceOrderFacilityApproval";
import useCompleteServiceOrderRequest from "../../customHooks/serviceOrder/useCompleteServiceOrderRequest";
import sanitizeText from "../../sharedFunctions/sanitizeText";
import FileList from "../sharedComponents/FileList";
import ContactButton from "../contacts/ContactButton";
import InfoText from "../sharedComponents/InfoText";
import formatTextWithLineBreaks from "../../sharedFunctions/formatTextWithLineBreaks";

const checkIfServicesChanged = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return true;
  // Create a copy of the arrays if you do not want to modify the original arrays
  let sortedArr1 = [...arr1].sort((a, b) =>
    JSON.stringify(a).localeCompare(JSON.stringify(b))
  );
  let sortedArr2 = [...arr2].sort((a, b) =>
    JSON.stringify(a).localeCompare(JSON.stringify(b))
  );

  for (let i = 0; i < sortedArr1.length; i++) {
    if (JSON.stringify(sortedArr1[i]) !== JSON.stringify(sortedArr2[i])) {
      return true;
    }
  }

  return false;
};

const ServiceRequestForm = ({ close, editMode, serviceRequest }) => {
  const appUserLevel = useSelector(selectAppUserLevel);
  const { contact } = useContact();
  const { services: serviceOptions, isLoading } = useServices();
  const { serviceProviders } = useContactServiceProviders(
    serviceRequest ? false : true
  );

  const [files, setFiles] = useState([]);
  const [materialsUsed, setMaterialsUsed] = useState(
    serviceRequest?.materialsUsed.map((material) => ({ ...material })) ?? []
  );
  const [requestServiceDateChangeOpen, setRequestServiceDateChangeOpen] =
    useState(false);
  const [services, setServices] = useState(
    serviceRequest?.services.map((service) => ({ ...service })) ?? []
  );
  const [serviceDateChanged, setServiceDateChanged] = useState(null);
  const [facility, setFacility] = useState(serviceRequest?.facility ?? "");
  const [serviceRequestDescription, setServiceRequestDescription] = useState(
    serviceRequest?.serviceRequestDescription ?? ""
  );
  const [serviceNotes, setServiceNotes] = useState(
    serviceRequest?.serviceNotes ?? ""
  );
  const [serviceRepresentative, setServiceRepresentative] = useState(
    serviceRequest?.serviceRepresentative ?? ""
  );
  const [supplier, setSupplier] = useState(serviceRequest?.supplier ?? "");
  const [supplierOrderNumber, setSupplierOrderNumber] = useState(
    serviceRequest?.supplierOrderNumber ?? ""
  );
  const [useSupplierPricing, setUseSupplierPricing] = useState(
    appUserLevel.includes("Supplier") || appUserLevel.includes("Super User")
  );
  const [valid, setValid] = useState(false);

  const { exchangeRate, isInitialLoading } = useExchangeRate({
    currency: serviceRequest?.supplier?.currency,
  });
  const { facilities, isLoading: facilitiesLoading } = useFacilitiesNames({
    contact,
    disable: !!serviceRequest,
  });

  const { employees } = useSupplierCompanyEmployees(supplier._id);

  const addServiceRequest = useAddServiceOrder();
  const updateServiceOrder = useUpdateServiceOrder();
  const requestServiceOrderFacilityApproval =
    useRequestServiceOrderFacilityApproval();
  const completeServiceOrderRequest = useCompleteServiceOrderRequest();

  const {
    subtotal,
    GST,
    PST,
    total,
    supplierSubtotal,
    supplierGST,
    supplierPST,
    supplierTotal,
  } = useServiceTotals(
    exchangeRate,
    services,
    supplier?._id,
    facility,
    materialsUsed
  );

  useEffect(() => {
    if (
      !facility ||
      !supplier ||
      !services?.length ||
      !serviceRequestDescription
    )
      return setValid(false);

    const serviceValid = services.every(
      (service) => service.service && service.quantity > 0 && service.cost > 0
    );
    if (!serviceValid) return setValid(false);

    const materialValid = materialsUsed.every(
      (material) => material.description && material.cost > 0
    );
    if (!materialValid) return setValid(false);

    setValid(true);
  }, [materialsUsed, facility, services, supplier, serviceRequestDescription]);

  const closeRequestServiceDateChange = (e, reason, confirmed) => {
    if (reason === "backdropClick") return;
    setRequestServiceDateChangeOpen(false);
    setServiceDateChanged(null);
    if (confirmed) close();
  };

  const onAddServiceRequest = async () => {
    close();
    let body = {
      serviceOrder: {
        supplierOrderNumber,
        GST,
        materialsUsed,
        PST,
        requestingUser: contact._id,
        serviceRepresentative: serviceRepresentative?._id,
        services,
        facility: facility._id,
        serviceRequestDescription,
        serviceNotes,
        supplier,
        status: "Awaiting Facility Approval",
        subtotal,
        supplierGST,
        supplierPST,
        supplierSubtotal,
        supplierTotal,
        total,
      },
    };

    await addServiceRequest.mutateAsync(body);
  };

  const getServicePriceDetails = (service, supplierID) => {
    const supplierServiceDetails = service.service.supplierServiceDetails?.find(
      (supplierServiceDetail) =>
        (supplierServiceDetail.supplier._id ??
          supplierServiceDetail.supplier) === supplierID
    );

    const cost =
      service.cost !== undefined
        ? customDollarRound(service.cost)
        : customDollarRound(supplierServiceDetails?.cost);

    const price = calculateServicePrice(
      service.cost !== undefined
        ? service.cost ?? 0
        : supplierServiceDetails?.cost,
      exchangeRate?.rate,
      supplierServiceDetails?.supplier?._id ?? supplierServiceDetails.supplier
    );
    const total = customDollarRound(price * (service.quantity ?? 0));
    return { cost, price, total };
  };

  const onRequestFacilityApproval = async () => {
    close();
    let body = {
      serviceOrder: {
        _id: serviceRequest._id,
        supplierOrderNumber,
        GST,
        materialsUsed,
        PST,
        services,
        facility: facility._id,
        serviceNotes,
        serviceRequestDescription,
        serviceRepresentative: serviceRepresentative?._id,
        supplier,
        status: "Awaiting Facility Approval",
        subtotal,
        supplierGST,
        supplierPST,
        supplierSubtotal,
        supplierTotal,
        total,
      },
    };

    await requestServiceOrderFacilityApproval.mutateAsync(body);
  };

  const onUpdateServiceRequest = async () => {
    let body;

    body = {
      serviceOrder: {
        _id: serviceRequest._id,
        supplierOrderNumber,
        serviceRepresentative: serviceRepresentative?._id,
      },
    };

    if (
      checkIfServicesChanged(serviceRequest.services, services) ||
      checkIfServicesChanged(serviceRequest.materialsUsed, materialsUsed)
    ) {
      body = {
        serviceOrder: {
          _id: serviceRequest._id,
          serviceRepresentative: serviceRepresentative?._id,
          supplierOrderNumber,
          GST,
          materialsUsed,
          PST,
          services,
          serviceNotes,
          serviceRequestDescription,
          subtotal,
          supplierGST,
          supplierPST,
          supplierSubtotal,
          supplierTotal,
          total,
        },
      };
    } else if (serviceRequest.serviceNotes !== serviceNotes) {
      body = {
        serviceOrder: {
          _id: serviceRequest._id,
          supplierOrderNumber,
          serviceNotes,
          serviceRequestDescription,
          serviceRepresentative: serviceRepresentative?._id,
        },
      };
    }

    close();
    await updateServiceOrder.mutateAsync(body);
  };

  const onCompleteServiceRequest = async () => {
    const formData = new FormData();
    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
    }

    const serviceRequestBody = {
      _id: serviceRequest._id,
      supplierOrderNumber,
      GST,
      materialsUsed,
      PST,
      services,
      serviceNotes,
      serviceRequestDescription,
      subtotal,
      supplierGST,
      supplierPST,
      supplierSubtotal,
      supplierTotal,
      total,
    };

    formData.append("serviceOrder", JSON.stringify(serviceRequestBody));

    await completeServiceOrderRequest.mutateAsync(formData);

    close();
  };

  const loading = addServiceRequest.isLoading ?? updateServiceOrder.isLoading;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Autocomplete
          disabled={!!serviceRequest}
          options={facilities.filter((facility) => facility.emails?.length)}
          getOptionLabel={(option) => option.name ?? ""}
          isOptionEqualToValue={(option, value) => {
            if (!value.length) return [];
            return option._id === value._id;
          }}
          value={facility}
          onChange={(e, value) => setFacility(value)}
          loading={facilitiesLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Serviced Facility"
              variant="outlined"
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          disabled={!!serviceRequest}
          options={serviceProviders}
          getOptionLabel={getContactOptionLabel}
          isOptionEqualToValue={(option, value) => {
            if (!value.length) return [];
            return option._id === value._id;
          }}
          value={supplier}
          onChange={(e, value) => setSupplier(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Service Provider"
              variant="outlined"
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          disabled={!editMode}
          options={employees}
          getOptionLabel={getContactOptionLabel}
          isOptionEqualToValue={(option, value) => {
            if (!value.length) return [];
            return option._id === value._id;
          }}
          value={serviceRepresentative}
          onChange={(e, value) => setServiceRepresentative(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Service Representative"
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          autoComplete="off"
          label="Supplier Order #"
          disabled={!editMode}
          value={supplierOrderNumber}
          variant="outlined"
          onChange={(e) => setSupplierOrderNumber(e.target.value)}
          fullWidth
        />
      </Grid>

      <ContactButton
        contact={serviceRequest?.recipient}
        label={"Facility Recipient: "}
        md={3}
        xs={6}
      />
      {serviceRequest?.facilityServiceDescription && (
        <Grid container item spacing={1} xs={12}>
          <Grid item xs={12}>
            <Typography variant="h6" component="span">
              Facility Service Description:&nbsp;
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {formatTextWithLineBreaks(
              serviceRequest.facilityServiceDescription
            )}
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} className="vertical-centered-container">
        <TextField
          label="Service Description"
          variant="outlined"
          fullWidth
          value={serviceRequestDescription}
          multiline
          onChange={(e) => {
            setServiceRequestDescription(e.target.value);
          }}
          disabled={!editMode}
          required
        />
        <InfoText text="Describe the service that is being done." />
      </Grid>
      {serviceRequest?.status === "Awaiting Service" && (
        <ServiceDates close={close} serviceRequest={serviceRequest} />
      )}
      {serviceRequest?.serviceDates.length > 0 && (
        <>
          <Grid item xs={12}>
            <Typography variant="h6">Service Dates:</Typography>
          </Grid>
          {serviceRequest?.serviceDates.map((serviceDate, i) => (
            <Grid
              item
              container
              spacing={2}
              xs={12}
              md={6}
              key={"serviceDate" + i}
              className="vertical-centered-container"
            >
              <Grid item xs={8}>
                <CustomDatePicker
                  label="Service Date"
                  value={moment(serviceDate)}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={4} display={"flex"}>
                {(appUserLevel.includes("Facility") ||
                  appUserLevel.includes("Super User")) &&
                  (serviceRequest.status === "Pending" ||
                    serviceRequest.status === "Awaiting Service") && (
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        setRequestServiceDateChangeOpen(true);
                        setServiceDateChanged(serviceDate);
                      }}
                    >
                      Request Date Change
                    </Button>
                  )}
              </Grid>
            </Grid>
          ))}
        </>
      )}
      {serviceRequest?.files?.length > 0 && (
        <FileList files={serviceRequest.files} />
      )}
      <Grid item xs={12} className="vertical-centered-container">
        <TextField
          label="Service Notes"
          variant="outlined"
          fullWidth
          value={serviceNotes}
          onChange={(e) => setServiceNotes(sanitizeText(e.target.value))}
          multiline
          disabled={!editMode}
        />
        <InfoText text="Add any additional notes." />
      </Grid>
      {isLoading || isInitialLoading ? (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      ) : (
        supplier &&
        facility && (
          <>
            {editMode && (
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() =>
                    setServices([
                      ...services,
                      {
                        cost: 0,
                        serviceDescription: "",
                        service: null,
                        quantity: 0,
                      },
                    ])
                  }
                >
                  Add Service
                </Button>
              </Grid>
            )}
            {services.map((service, i) => {
              return (
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  key={"service " + i}
                  className="vertical-centered-container"
                >
                  <Grid item xs={12} md={8}>
                    <Autocomplete
                      disabled={!editMode}
                      options={serviceOptions.filter((service) =>
                        service.supplierServiceDetails.some(
                          (supplierServiceDetail) =>
                            supplierServiceDetail.cost &&
                            supplierServiceDetail.supplier._id === supplier._id
                        )
                      )}
                      getOptionLabel={(option) =>
                        getServiceOptionLabel(
                          option,
                          supplier._id,
                          exchangeRate
                        )
                      }
                      isOptionEqualToValue={(option, value) => {
                        if (!value || !value._id) return false;
                        return option._id === value._id;
                      }}
                      value={service.service}
                      onChange={(e, value) => {
                        const newServices = [...services];
                        newServices[i].service = value;
                        if (!value) {
                          newServices[i].serviceDescription = "";
                          newServices[i].cost = 0;
                          newServices[i].price = 0;
                          newServices[i].total = 0;
                          setServices(newServices);
                          return;
                        }

                        const supplierServiceDetails =
                          value.supplierServiceDetails?.find(
                            (supplierServiceDetail) =>
                              (supplierServiceDetail.supplier._id ??
                                supplierServiceDetail.supplier) === supplier._id
                          );

                        newServices[i].serviceDescription =
                          supplierServiceDetails?.serviceDescription ??
                          value.description ??
                          "";
                        newServices[i].cost = undefined;

                        if (newServices[i]?.service) {
                          const { cost, price, total } = getServicePriceDetails(
                            newServices[i],
                            supplier._id
                          );
                          newServices[i].cost = cost;
                          newServices[i].price = price;
                          newServices[i].total = total;
                        }
                        setServices(newServices);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Service"
                          variant="outlined"
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <DecimalTextField
                      editMode={editMode}
                      label={"Quantity"}
                      value={service.quantity}
                      onChange={(e) => {
                        if (e.target.value < 0) return;
                        const newServices = [...services];
                        newServices[i].quantity =
                          e.target.value === "" ? "" : Number(e.target.value);
                        if (
                          newServices[i]?.service &&
                          newServices[i]?.quantity
                        ) {
                          const { cost, price, total } = getServicePriceDetails(
                            newServices[i],
                            supplier._id
                          );
                          newServices[i].cost = cost;
                          newServices[i].price = price;
                          newServices[i].total = total;
                        }
                        setServices(newServices);
                      }}
                      required={true}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <DecimalTextField
                      editMode={editMode}
                      label="Cost"
                      value={service.cost}
                      onChange={(e) => {
                        const newServices = [...services];
                        newServices[i].cost =
                          e.target.value === "" ? "" : e.target.value;
                        const { price, total } = getServicePriceDetails(
                          newServices[i],
                          supplier._id
                        );
                        newServices[i].price = price;
                        newServices[i].total = total;

                        setServices(newServices);
                      }}
                      required={true}
                    />
                  </Grid>

                  <Grid item xs={12} md={10}>
                    <TextField
                      label="Description"
                      variant="outlined"
                      fullWidth
                      value={service.serviceDescription}
                      multiline
                      onChange={(e) => {
                        const newServices = [...services];
                        newServices[i].serviceDescription = e.target.value;
                        setServices(newServices);
                      }}
                      disabled={!editMode}
                      required
                    />
                  </Grid>

                  {editMode && (
                    <Grid item xs={6} md={2}>
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          const newServices = [...services];
                          newServices.splice(i, 1);
                          setServices(newServices);
                        }}
                        color="error"
                      >
                        Remove
                      </Button>
                    </Grid>
                  )}
                </Grid>
              );
            })}

            {editMode && (
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() =>
                    setMaterialsUsed([
                      ...materialsUsed,
                      {
                        cost: 0,
                        description: "",
                        materialType: "Chemical/Filter",
                        price: 0,
                      },
                    ])
                  }
                >
                  Add Chemical/Filter Material
                </Button>
              </Grid>
            )}
            {editMode && (
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() =>
                    setMaterialsUsed([
                      ...materialsUsed,
                      {
                        cost: 0,
                        description: "",
                        materialType: "Other",
                        price: 0,
                      },
                    ])
                  }
                >
                  Add Other Material
                </Button>
              </Grid>
            )}
            {materialsUsed.map((material, i) => (
              <Grid item xs={12} container spacing={2} key={"material " + i}>
                <Grid item xs={6}>
                  <TextField
                    label={material.materialType + " Description"}
                    variant="outlined"
                    fullWidth
                    value={material.description}
                    onChange={(e) => {
                      const newMaterialsUsed = [...materialsUsed];
                      newMaterialsUsed[i].description = e.target.value;
                      setMaterialsUsed(newMaterialsUsed);
                    }}
                    disabled={!editMode}
                    required
                  />
                </Grid>
                <Grid item xs={4}>
                  <DecimalTextField
                    editMode={editMode}
                    label={"Cost"}
                    value={material.cost}
                    onChange={(e) => {
                      const newMaterialsUsed = [...materialsUsed];
                      newMaterialsUsed[i].cost = customDollarRound(
                        parseFloat(e.target.value)
                      );
                      newMaterialsUsed[i].price = calculateServicePrice(
                        parseFloat(e.target.value),
                        exchangeRate?.rate,
                        supplier._id
                      );
                      setMaterialsUsed(newMaterialsUsed);
                    }}
                    required={true}
                  />
                </Grid>
                {editMode && (
                  <Grid item xs={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={() => {
                        const newMaterialsUsed = [...materialsUsed];
                        newMaterialsUsed.splice(i, 1);
                        setMaterialsUsed(newMaterialsUsed);
                      }}
                      color="error"
                    >
                      Remove
                    </Button>
                  </Grid>
                )}
              </Grid>
            ))}
            {appUserLevel.includes("Super User") && (
              <FormSwitch
                label="Use Supplier Pricing"
                checked={useSupplierPricing}
                onChange={(e) => setUseSupplierPricing(e.target.checked)}
                name="useSupplierPricing"
                xs={12}
              />
            )}

            <ServiceRequestTotal
              GST={useSupplierPricing ? supplierGST : GST}
              PST={useSupplierPricing ? supplierPST : PST}
              subtotal={useSupplierPricing ? supplierSubtotal : subtotal}
              total={useSupplierPricing ? supplierTotal : total}
            />
          </>
        )
      )}

      {editMode &&
        serviceRequest?.serviceDates.length > 0 &&
        moment(
          serviceRequest?.serviceDates[serviceRequest?.serviceDates.length - 1]
        ).isSameOrBefore(moment()) &&
        serviceRequest?.status === "Awaiting Service" && (
          <>
            <Grid item xs={12}>
              <Typography>Attachments</Typography>
            </Grid>
            <Grid item xs={12}>
              <input
                onChange={(e) => {
                  setFiles(e.target.files);
                }}
                type="file"
                accept="application/pdf"
                multiple
              />
            </Grid>
          </>
        )}

      {editMode && !serviceRequest && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={onAddServiceRequest}
            disabled={loading || !valid}
            color="success"
          >
            Request Facility Approval
          </Button>
        </Grid>
      )}

      {editMode &&
        serviceRequest &&
        serviceRequest?.status !== "Awaiting Payment" &&
        serviceRequest?.status !== "Completed" && (
          <Grid item xs={12}>
            {(serviceRequest?.status === "Pending" ||
              serviceRequest?.status === "Adjustment Requested") && (
              <Button
                variant="contained"
                fullWidth
                onClick={onRequestFacilityApproval}
                disabled={loading || !valid}
                color="success"
              >
                Request Facility Approval
              </Button>
            )}
            {(serviceRequest?.status === "Awaiting Facility Approval" ||
              ((checkIfServicesChanged(serviceRequest.services, services) ||
                checkIfServicesChanged(
                  serviceRequest.materialsUsed,
                  materialsUsed
                ) ||
                serviceRequest.serviceNotes !== serviceNotes) &&
                serviceRequest?.status === "Awaiting Service")) && (
              <Button
                variant="contained"
                fullWidth
                onClick={onUpdateServiceRequest}
                disabled={loading || !valid}
                color="success"
              >
                Update Service Request
              </Button>
            )}
          </Grid>
        )}

      {serviceRequest?.status === "Awaiting Service" &&
        serviceRequest?.serviceDates.length > 0 &&
        moment(
          serviceRequest?.serviceDates[serviceRequest?.serviceDates.length - 1]
        ).isSameOrBefore(moment()) && (
          <Grid item xs={12}>
            <Button
              variant="contained"
              fullWidth
              onClick={onCompleteServiceRequest}
              disabled={loading || !valid}
              color="success"
            >
              Complete Service Request
            </Button>
          </Grid>
        )}
      <CustomModal
        open={requestServiceDateChangeOpen}
        close={closeRequestServiceDateChange}
        title={"Request Service Date Change"}
      >
        <RequestServiceDateChangeForm
          close={closeRequestServiceDateChange}
          serviceRequest={{
            _id: serviceRequest?._id,
            facility,
            supplier,
            status: serviceRequest?.status,
          }}
          previousServiceDate={serviceDateChanged}
        />
      </CustomModal>
    </Grid>
  );
};

export default ServiceRequestForm;
