import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import useCancelCartSubmission from "../../customHooks/cartSubmissions/useCancelCartSubmission";

const CancelCartSubmission = (props) => {
  const { close, cartSubmission, contactID } = props;
  const cancelCartSubmission = useCancelCartSubmission(contactID);
  const [cancelling, setCancelling] = useState(false);

  const cancelOrder = async () => {
    try {
      setCancelling(true);
      await cancelCartSubmission.mutateAsync(cartSubmission);
      close();
    } catch (error) {
      throw error;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={cancelOrder}
          fullWidth
          disabled={cancelling}
        >
          {cancelling ? "Canceling order" : "Yes Cancel Order"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default CancelCartSubmission;
