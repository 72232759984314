import {
  Autocomplete,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import DecimalTextField from "../sharedComponents/TextFields/DecimalTextField";
import useGetClosestWarehouse from "../../customHooks/products/useGetClosestWarehouse";
import useSupplierCompany from "../../customHooks/supplierCompany/useSupplierCompany";
import GoogleMapProvider from "../sharedComponents/GoogleMapProvider";
import { getProductOptionLabel } from "../../sharedFunctions/labels";

const Container = (props) => (
  <GoogleMapProvider>
    <ReturnableContainersShoppingCartForm {...props} />
  </GoogleMapProvider>
);

const ReturnableContainersShoppingCartForm = ({
  containerSupplies,
  containersToReturn,
  facility,
  setContainersToReturn,
  supplier,
  setSupplier,
  setWarehouse,
  isPickUp,
  setIsPickUp,
  hasShippingToggle,
}) => {
  const { supplierCompany } = useSupplierCompany(supplier?._id);
  const getClosestWarehouse = useGetClosestWarehouse();
  const uniqueSuppliers = Array.from(
    new Set(containerSupplies.map((obj) => obj.supplier._id))
  ).map(
    (_id) => containerSupplies.find((obj) => obj.supplier._id === _id).supplier
  );

  useEffect(() => {
    if (!supplier || !supplierCompany) return;

    const fetchClosestWarehouse = async () => {
      const closesWarehouse = await getClosestWarehouse({
        facilityAddressObject: facility.addressObject,
        googleIsLoaded: true,
        warehouses: supplierCompany.warehouses,
      });

      setWarehouse(closesWarehouse);
    };

    fetchClosestWarehouse();
  }, [
    facility.addressObject,
    getClosestWarehouse,
    setWarehouse,
    supplier,
    supplierCompany,
  ]);

  useEffect(() => {
    if (!supplier) return;
    let tempValues = {};
    containerSupplies?.forEach((containerSupply) => {
      if (containerSupply.supplier._id === supplier._id) {
        tempValues = {
          ...tempValues,
          [containerSupply._id]: containerSupply.currentSupply,
        };
      }
    });
    setContainersToReturn(tempValues);
  }, [containerSupplies, setContainersToReturn, supplier]);

  return (
    <>
      <Grid item xs={12} md={4}>
        <Autocomplete
          options={uniqueSuppliers}
          getOptionLabel={(o) => {
            return o.name ?? "";
          }}
          value={supplier}
          isOptionEqualToValue={(option, value) => {
            if (!value?.length) return [];
            return option._id === value._id;
          }}
          onChange={(e, value) => {
            setSupplier(value);
          }}
          style={{ display: "flex" }}
          renderInput={(params) => (
            <TextField
              autoComplete="off"
              {...params}
              label={`Supplier`}
              required
            />
          )}
        />
      </Grid>
      {hasShippingToggle && (
        <Grid item xs={12} md={4}>
          <RadioGroup
            value={isPickUp}
            onChange={(e) => {
              setIsPickUp(e.target.value);
            }}
            row
          >
            <FormControlLabel value={false} control={<Radio />} label="Ship" />
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Drop Off"
            />
          </RadioGroup>
        </Grid>
      )}
      {supplier && (
        <Grid item container>
          <Grid item xs={4} md={5}>
            <Typography>Container</Typography>
          </Grid>
          <Grid item xs={5} md={3}>
            <Typography>Quantity To Return</Typography>
          </Grid>
        </Grid>
      )}
      {containerSupplies
        .filter(
          (containerSupply) => containerSupply.supplier._id === supplier?._id
        )
        .map((containerSupply) => {
          return (
            <Grid item container key={containerSupply._id}>
              <Grid item xs={5} md={5}>
                <Typography sx={{ mt: 2 }}>
                  {containerSupply.chemical
                    ? getProductOptionLabel(containerSupply.chemical)
                    : containerSupply.container.containerType}
                </Typography>
              </Grid>
              <Grid item xs={2} md={2}>
                <DecimalTextField
                  editMode={true}
                  value={containersToReturn[containerSupply._id] ?? ""}
                  onChange={(e) => {
                    const newValues = { ...containersToReturn };
                    newValues[containerSupply._id] = e.target.value;
                    setContainersToReturn({ ...newValues });
                  }}
                  label={"Quantity To Return"}
                  required={true}
                  numberProps={{ min: 0 }}
                  allowMinus={false}
                  allowDecimal={false}
                />
              </Grid>
            </Grid>
          );
        })}
    </>
  );
};

export default Container;
