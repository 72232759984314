import axios from "axios";

export const agendaServiceContract = async ({
  autoRenew,
  facilityID,
  serviceCompletion,
  serviceContractID,
  supplierID,
  serviceContractName,
  supplierName,
}) => {
  return axios.post(
    "/api/agenda/service-contract",
    {
      autoRenew,
      facilityID,
      serviceCompletion,
      serviceContractID,
      supplierID,
      serviceContractName,
      supplierName,
    },
    { bypass: true }
  );
};

export const agendaServiceContractAutoRenew = async ({ serviceContractID }) => {
  return axios.post(
    "/api/agenda/service-contract-auto-renew",
    { serviceContractID },
    { bypass: true }
  );
};

export const agendaCancelServiceContractAutoRenew = async ({
  serviceContractID,
}) => {
  return axios.post(
    "/api/agenda/cancel-service-contract-auto-renew",
    { serviceContractID },
    { bypass: true }
  );
};
export const agendaConfirmShippingDetails = async (body) => {
  return axios.post("/api/agenda/confirm-shipping-details", body, {
    bypass: true,
  });
};

export const agendaCancelConfirmShippingDetails = async ({ orderID }) => {
  return axios.post(
    "/api/agenda/cancel-confirm-shipping-details",
    { orderID },
    { bypass: true }
  );
};

export const agendaProductSupplierValidTill = async ({
  supplierEmails,
  supplierID,
  validTillDate,
}) => {
  return axios.post(
    "/api/agenda/product-supplier-valid-till",
    {
      supplierEmails,
      supplierID,
      validTillDate,
    },
    { bypass: true }
  );
};

export const agendaLogReminder = async ({
  facilityID,
  systemName,
  systemTab,
  systemID,
}) => {
  return axios.post(
    "/api/agenda/log-reminder",
    {
      facilityID,
      systemName,
      systemTab,
      systemID,
    },
    { bypass: true }
  );
};

export const agendaUFPerformCleaning = async ({ facilityID, UFID, UFName }) => {
  return axios.post(
    "/api/agenda/UF-perform-cleaning",
    {
      facilityID,
      UFID,
      UFName,
    },
    { bypass: true }
  );
};

export const agendaUpdateOrderStatus = async ({ orderID, date, status }) => {
  return axios.post(
    "/api/agenda/update-order-status",
    {
      orderID,
      date,
      status,
    },
    { bypass: true }
  );
};

export const agendaOrderDelivered = async ({
  arriveDate,
  chemicals,
  facilityID,
  orderID,
  orderNumber,
  preFilters,
  productsBeingReplaced,
}) => {
  return axios.post(
    "/api/agenda/order-delivered",
    {
      arriveDate,
      chemicals,
      facilityID,
      orderID,
      orderNumber,
      preFilters,
      productsBeingReplaced,
    },
    { bypass: true }
  );
};

export const agendaCheckIfInvoicePaid = async ({ payDueDate, orderNumber }) => {
  return axios.post(
    "/api/agenda/check-if-invoice-paid",
    {
      payDueDate,
      orderNumber,
    },
    { bypass: true }
  );
};

export const agendaSendInvoiceEmail = async ({ orderID, shipDate }) => {
  return axios.post(
    "/api/agenda/send-invoice-email",
    { orderID, shipDate },
    { bypass: true }
  );
};

export const agendaSendServiceContractInvoiceEmail = async ({
  serviceContractID,
}) => {
  return axios.post(
    "/api/agenda/send-service-contract-invoice-email",
    { serviceContractID },
    { bypass: true }
  );
};

export const agendaServiceDate = async ({
  facilityID,
  notificationText,
  serviceDate,
  serviceContractID,
  supplierID,
}) => {
  return axios.post(
    "/api/agenda/service-date",
    {
      facilityID,
      notificationText,
      serviceDate,
      serviceContractID,
      supplierID,
    },
    { bypass: true }
  );
};

export const agendaCompleteSurvey = async ({ endDate, surveyID }) => {
  return axios.post(
    "/api/agenda/complete-survey",
    { endDate, surveyID },
    { bypass: true }
  );
};

export const agendaCompleteServiceDates = async ({
  facilityID,
  facilityName,
  serviceDates,
  serviceRequestID,
  supplierID,
}) => {
  return axios.post(
    "/api/agenda/complete-service-dates",
    { facilityID, facilityName, serviceDates, serviceRequestID, supplierID },
    { bypass: true }
  );
};

export const agendaCancelOrderDelivered = async ({ orderID }) => {
  return axios.post(
    "/api/agenda/cancel-order-delivered",
    {
      orderID,
    },
    { bypass: true }
  );
};

export const agendaCancelProductPriceValidNotification = async ({
  supplierID,
}) => {
  return axios.post(
    "/api/agenda/cancel-product-price-valid-notification",
    {
      supplierID,
    },
    { bypass: true }
  );
};

export const agendaCancelServiceContractExpiringNotification = async ({
  itemID,
  supplierID,
  facilityID,
}) => {
  return axios.post(
    "/api/agenda/cancel-service-contract-expiring-notification",
    {
      itemID,
      supplierID,
      facilityID,
    }
  );
};

export const agendaCancelMaintenanceNotification = async ({
  itemID,
  facilityID,
}) => {
  return axios.post(
    "/api/agenda/cancel-maintenance-notification",
    {
      itemID,
      facilityID,
    },
    { bypass: true }
  );
};

export const agendaCancelLogReminder = async ({ facilityID, systemID }) => {
  return axios.post(
    "/api/agenda/cancel-log-reminder",
    {
      facilityID,
      systemID,
    },
    { bypass: true }
  );
};

export const agendaCancelUFPerformCleaning = async ({ facilityID, UFID }) => {
  return axios.post(
    "/api/agenda/cancel-UF-perform-cleaning",
    {
      facilityID,
      UFID,
    },
    { bypass: true }
  );
};
