import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateSupplierCompanyUserRole } from "../../api/supplierCompaniesAPI";

const useUpdateSupplierCompanyUserRole = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSupplierCompanyUserRole,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["supplierCompany"] });
    },
  });
};

export default useUpdateSupplierCompanyUserRole;
