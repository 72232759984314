import { useQuery } from "@tanstack/react-query";
import { getThirdPartyShippers } from "../../api/shipperCompanyAPI";

const useShipperCompanyThirdPartyShippers = (id) => {
  const { data: thirdPartyShippers = [], isLoading } = useQuery({
    queryKey: ["thirdPartyShippers", id],
    queryFn: () => getThirdPartyShippers(id),
    staleTime: 1000 * 60 * 10,
    enabled: !!id,
  });

  return { isLoading, thirdPartyShippers };
};

export default useShipperCompanyThirdPartyShippers;
