import { useQuery } from "@tanstack/react-query";
import { getOrderAdditionByPendingOrderID } from "../../api/orderAdditionAPI";

const useOrderAdditionByPendingOrder = (orderID) => {
  const { data: orderAddition, isInitialLoading: isLoading } = useQuery({
    queryKey: ["orderAddition", orderID],
    queryFn: () => getOrderAdditionByPendingOrderID(orderID),
    enabled: !!orderID,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  return { orderAddition, isLoading };
};

export default useOrderAdditionByPendingOrder;
