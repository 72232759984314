import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import InfoIcon from "@mui/icons-material/Info";

const InfoText = ({ disableMargin, text }) => {
  return (
    <Tooltip
      title={<Typography style={{ whiteSpace: "pre-line" }}>{text}</Typography>}
    >
      <Box
        className="vertical-centered-container"
        sx={{ ml: disableMargin ? 0 : 1 }}
      >
        <InfoIcon color="primary" />
      </Box>
    </Tooltip>
  );
};

export default InfoText;
