const getUserRole = (contactID, userRoles) => {
  for (let role in userRoles) {
    if (userRoles.hasOwnProperty(role)) {
      if (userRoles[role]?.includes(contactID)) {
        return role;
      }
    }
  }
  return null;
};

export default getUserRole;
