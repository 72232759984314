import { combineReducers, configureStore } from "@reduxjs/toolkit";
import notificationsSlice from "./reducers/notificationsSlice";
import productsSlice from "./reducers/productsSlice";
import shippingRoutesSlice from "./reducers/shippingRoutesSlice";
import userLevelsSlice from "./reducers/userLevelsSlice";
import facilitiesSlice from "./reducers/facilities/facilitiesSlice";
import formDataSlice from "./reducers/formDataSlice";
import userSlice from "./reducers/userSlice";

import { saveState } from "./localStorage";

const reducer = combineReducers({
  facilities: facilitiesSlice,
  formData: formDataSlice,
  notifications: notificationsSlice,
  products: productsSlice,
  shippingRoutes: shippingRoutesSlice,
  userLevels: userLevelsSlice,
  user: userSlice,
});

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
});

store.subscribe(() => {
  saveState({
    facilities: store.getState().facilities,
    formData: store.getState().formData,
    notifications: store.getState().notifications,
    products: store.getState().products,
    userLevels: store.getState().userLevels,
    user: store.getState().user,
  });
});
