import { useMutation } from "@tanstack/react-query";
import { emailSendConfirmShipping } from "../../api/emailAPI";

const useEmailSendConfirmShipping = () => {
  return useMutation({
    mutationFn: emailSendConfirmShipping,
  });
};

export default useEmailSendConfirmShipping;
